import React from 'react';
import { ListTag } from '../../../../../../listTag';
import styles from './listTagWrapper.module.css';
import { Tag } from '../../saveEditModal.typing';

type ListTagWrapperProps = {
  tags: Tag[];
  handleClick: (params: any) => void;
  type: string;
};

export const ListTagWrapper = ({ tags, handleClick, type }: ListTagWrapperProps) => {
  return (
    <>
      {tags &&
        tags.map((tag, index) => {
          const key = `tag-${tag.id || index}`;
          return (
            <div className={styles._tag__container} key={`tag-${key}`}>
              <ListTag handleClick={handleClick} tag={tag} type={type} />
            </div>
          );
        })}
    </>
  );
};
