// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".conferencingForm-module___conferencing_container___\\+\\+thH {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  margin-bottom: 8px;\n  border-radius: 4px;\n}\n\n.conferencingForm-module___conferencing_container_marked___9poMD {\n  background-color: var(--bloobirds);\n}\n\n.conferencingForm-module___google_meet_icon___JAPKO {\n  margin-top: 2px;\n  margin-left: 4px;\n  margin-right: 8px !important;\n}\n\n.conferencingForm-module___title___4KcAc {\n  display: flex;\n  align-items: center;\n}\n\n.conferencingForm-module___title___4KcAc > * {\n  margin-right: 4px;\n}\n", "",{"version":3,"sources":["webpack://./src/js/components/newMeetingModal/components/conferencingForm/conferencingForm.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,8BAA8B;EAC9B,kBAAkB;EAClB,kBAAkB;AACpB;;AAEA;EACE,kCAAkC;AACpC;;AAEA;EACE,eAAe;EACf,gBAAgB;EAChB,4BAA4B;AAC9B;;AAEA;EACE,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,iBAAiB;AACnB","sourcesContent":["._conferencing_container {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  margin-bottom: 8px;\n  border-radius: 4px;\n}\n\n._conferencing_container_marked {\n  background-color: var(--bloobirds);\n}\n\n._google_meet_icon {\n  margin-top: 2px;\n  margin-left: 4px;\n  margin-right: 8px !important;\n}\n\n._title {\n  display: flex;\n  align-items: center;\n}\n\n._title > * {\n  margin-right: 4px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"_conferencing_container": "conferencingForm-module___conferencing_container___++thH",
	"_conferencing_container_marked": "conferencingForm-module___conferencing_container_marked___9poMD",
	"_google_meet_icon": "conferencingForm-module___google_meet_icon___JAPKO",
	"_title": "conferencingForm-module___title___4KcAc"
};
export default ___CSS_LOADER_EXPORT___;
