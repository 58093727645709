import { usePicklistValues, useMediaQuery } from '../../../hooks';
import { ACTIVITY_FIELDS_LOGIC_ROLE } from '../../../constants/activity';
import { useGlobalPicklistValues } from '../../../hooks/usePicklistValues';
import {
  SubhomeFilterGroup,
  SubhomeFilters,
} from '../../../layouts/subhomeLayout/subhomeContent/subhomeFilters/subhomeFilters';
import {
  Button,
  CheckItem,
  Chip,
  MultiSelect,
  RelativeDatePicker,
  Text,
} from '@bloobirds-it/flamingo-ui';
import styles from '../inboxPage.module.css';
import { sortBy } from 'lodash';
import React, { useEffect } from 'react';
import { useInboxCallsFilters } from './useInboxCalls';
import { useIsAccountAdmin } from '../../../hooks/usePermissions';

export const CallsFilters = () => {
  const {
    dateFilter,
    activityUserFilter,
    directionFilter,
    sourceFilter,
    showUnknownContactsFilter,
    showReportedFilter,
    setShowUnknownContactsFilter,
    setActivityUserFilter,
    setShowReportedFilter,
    setDateFilter,
    setDirectionFilter,
    setSourceFilter,
    resetAllFilters,
    usingDefaultFilters,
  } = useInboxCallsFilters();
  const directions = usePicklistValues({ picklistLogicRole: ACTIVITY_FIELDS_LOGIC_ROLE.DIRECTION });
  const sources = useGlobalPicklistValues({ logicRole: 'DATA_SOURCE' });

  const { isSmallDesktop } = useMediaQuery();

  const users = useGlobalPicklistValues({
    logicRole: 'USER',
  });

  const isAccountAdmin = useIsAccountAdmin();
  useEffect(() => () => resetAllFilters(), []);

  return (
    <SubhomeFilters>
      <SubhomeFilterGroup>
        <div className={styles.filter_relative_date}>
          <RelativeDatePicker
            width={isSmallDesktop ? 80 : 150}
            value={{
              start: dateFilter.start,
              end: dateFilter.end,
              type: 'custom',
            }}
            onChange={setDateFilter}
            placeholder="Date range"
            size="small"
          />
        </div>
        {isAccountAdmin && (
          <>
            <MultiSelect
              placeholder={activityUserFilter ? 'User' : 'Me'}
              size="small"
              variant="filters"
              onChange={setActivityUserFilter}
              value={activityUserFilter || []}
              selectAllOption
            >
              {users.map(user => (
                <CheckItem key={user.id} value={user.id}>
                  {user.value}
                </CheckItem>
              ))}
            </MultiSelect>
          </>
        )}
        <MultiSelect
          placeholder="Type"
          size="small"
          onChange={setDirectionFilter}
          value={directionFilter || []}
          variant="filters"
        >
          <CheckItem value="all">All</CheckItem>
          {directions.map(direction => (
            <CheckItem key={direction.id} value={direction.id}>
              {direction.value}
            </CheckItem>
          ))}
        </MultiSelect>
        <MultiSelect
          placeholder="Source"
          size="small"
          onChange={setSourceFilter}
          value={sourceFilter || []}
          variant="filters"
        >
          <CheckItem value="all">All</CheckItem>
          {sortBy(sources, 'value').map(source => (
            <CheckItem key={source.id} value={source.id}>
              {source.value}
            </CheckItem>
          ))}
        </MultiSelect>
        {!usingDefaultFilters && (
          <Button variant="clear" color="bloobirds" iconLeft="cross" onClick={resetAllFilters}>
            CLEAR
          </Button>
        )}
      </SubhomeFilterGroup>
      <SubhomeFilterGroup>
        <Text color="softPeanut" size="s" className={styles._filters__title}>
          Quick filters:
        </Text>
        <div className={styles._quick_filter_wrapper}>
          <Chip
            size="small"
            selected={showUnknownContactsFilter}
            onClick={setShowUnknownContactsFilter}
          >
            Show just unknown contacts
          </Chip>
          <Chip size="small" selected={showReportedFilter} onClick={setShowReportedFilter}>
            Show reported
          </Chip>
        </div>
      </SubhomeFilterGroup>
    </SubhomeFilters>
  );
};
