import { Dropdown, Icon, IconButton, Item, Text, useVisible } from '@bloobirds-it/flamingo-ui';
import clsx from 'clsx';
import React, { useEffect, useMemo } from 'react';
import { ConfigType, UserHomeConfig } from '../../../typings/home';
import styles from '../../../homePage.module.css';
import { getAvailableUserConfig, getUserSettingConfigType } from '../../../utils/homepage';
import { useUserHomepageSettings } from '../../../hooks/useUserHomepageSettings';
import { CONFIG_TYPE_BY_STAGE, defaultFilters, Filters, Stage } from '../utils/utils';
import { useHasBeenVisible } from '../../../../../hooks/useHasBeenVisible';

export const FilterDropdown = ({
  stage,
  updateFilters,
}: {
  stage: string;
  updateFilters: (param: Filters) => void;
}) => {
  const { ref, visible, setVisible } = useVisible();
  const hasBeenVisible = useHasBeenVisible(visible);
  const { availableSettings, userSettings, updateHomeSettings } = useUserHomepageSettings();
  const [filters, setFilters] = React.useState<Filters>(defaultFilters);

  const availableFilters = useMemo(() => {
    return {
      prospect: getAvailableUserConfig(availableSettings, ConfigType.TASKS_SECTION_FILTERS),
      sales: getAvailableUserConfig(availableSettings, ConfigType.TASKS_SECTION_FILTERS_SALES),
    };
  }, [availableSettings]);
  const userFilters = useMemo(() => {
    return {
      prospect: getUserSettingConfigType(userSettings, ConfigType.TASKS_SECTION_FILTERS),
      sales: getUserSettingConfigType(userSettings, ConfigType.TASKS_SECTION_FILTERS_SALES),
    };
  }, [userSettings]);

  useEffect(() => {
    const PROSPECT = userFilters.prospect ?? availableFilters.prospect;
    const SALES = userFilters.sales ?? availableFilters.sales;
    if (PROSPECT && SALES) {
      const initFilters: Filters = { PROSPECT, SALES };
      setFilters(initFilters);
      updateFilters(initFilters);
    }
  }, [availableSettings, userSettings]);

  const isProspectStage = stage === 'PROSPECT';
  const isSalesStage = stage === 'SALES';
  const isAllStage = stage === 'ALL';

  const updateFilterEnabled = (stage: Stage, filter: UserHomeConfig, enabled: boolean) => {
    const newFilters = {
      ...filters,
      [stage]: filters[stage].map(f => {
        let ret = f;
        if (f.enumName === filter.enumName) {
          ret = { ...f, enabled: enabled };
        }
        return ret;
      }),
    };
    setFilters(newFilters);
    updateFilters(newFilters);
  };

  useEffect(() => {
    if (hasBeenVisible && !visible) {
      switch (stage) {
        case 'PROSPECT':
          updateHomeSettings(filters.PROSPECT, CONFIG_TYPE_BY_STAGE.PROSPECT);
          break;
        case 'SALES':
          updateHomeSettings(filters.SALES, CONFIG_TYPE_BY_STAGE.SALES);
          break;
        case 'ALL':
          updateHomeSettings(filters.PROSPECT, CONFIG_TYPE_BY_STAGE.PROSPECT);
          updateHomeSettings(filters.SALES, CONFIG_TYPE_BY_STAGE.SALES);
      }
    }
  }, [visible, hasBeenVisible]);

  if (!availableSettings || !filters || !filters.PROSPECT || !filters.SALES) {
    return <></>;
  }

  return (
    <Dropdown
      ref={ref}
      visible={visible}
      anchor={
        <IconButton
          size={20}
          onClick={() => setVisible(!visible)}
          name="sliders"
          color="bloobirds"
        />
      }
    >
      {isAllStage && (
        <div className={styles._tasks_dropdown_title}>
          <Text size="s" color="peanut" className={styles.title_text}>
            Prospecting
          </Text>
        </div>
      )}
      {(isProspectStage || isAllStage) && (
        <div>
          {availableFilters?.prospect?.map(filter => {
            const enabled = filters.PROSPECT.find(f => f.enumName === filter.enumName)?.enabled;
            return (
              <Item
                value={filter.id}
                key={'dropdown-home-left-filter-prospecting-' + filter.id}
                className={clsx(styles._tasks_item, {
                  [styles.overdue_item]: filter?.enumName.includes('OVERDUE'),
                })}
                onClick={() => updateFilterEnabled('PROSPECT', filter, !enabled)}
              >
                <Text size="s" htmlTag="span" color={enabled ? 'bloobirds' : 'softPeanut'}>
                  {filter.name}
                </Text>
                <Icon name={enabled ? 'eye' : 'eyeOff'} size={16} />
              </Item>
            );
          })}
        </div>
      )}
      {isAllStage && (
        <div className={clsx(styles._tasks_dropdown_title, styles._tasks_dropdown_title_sales)}>
          <Text size="s" color="peanut" className={styles.title_text}>
            Sales
          </Text>
        </div>
      )}
      {(isSalesStage || isAllStage) && (
        <div>
          {availableFilters?.sales?.map(filter => {
            const enabled = filters.SALES.find(f => f.enumName === filter.enumName)?.enabled;
            return (
              <Item
                value={filter.id}
                key={'dropdown-home-left-filter-sales-' + filter.id}
                className={clsx(styles._tasks_item, {
                  [styles.overdue_item]: filter?.enumName.includes('OVERDUE'),
                })}
                onClick={() => updateFilterEnabled('SALES', filter, !enabled)}
              >
                <Text size="s" htmlTag="span" color={enabled ? 'bloobirds' : 'softPeanut'}>
                  {filter.name}
                </Text>
                <Icon name={enabled ? 'eye' : 'eyeOff'} size={16} />
              </Item>
            );
          })}
        </div>
      )}
    </Dropdown>
  );
};
