// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".bobjectForm-module___footer_buttons___4piMK {\n  display: flex;\n  gap: 8px;\n}\n\n.bobjectForm-module___save_buttons___ab3Vl > button:nth-child(1) {\n  margin-right: 16px;\n}\n", "",{"version":3,"sources":["webpack://./src/js/components/bobjectForm/bobjectForm.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,QAAQ;AACV;;AAEA;EACE,kBAAkB;AACpB","sourcesContent":["._footer_buttons {\n  display: flex;\n  gap: 8px;\n}\n\n._save_buttons > button:nth-child(1) {\n  margin-right: 16px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"_footer_buttons": "bobjectForm-module___footer_buttons___4piMK",
	"_save_buttons": "bobjectForm-module___save_buttons___ab3Vl"
};
export default ___CSS_LOADER_EXPORT___;
