// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".inactiveHandlingModal-module___modal_header___U8O1U {\n  box-sizing: border-box;\n  padding: 16px;\n}\n\n.inactiveHandlingModal-module___modal_content___iBv-w {\n  box-sizing: border-box;\n  padding: 32px 58px 0;\n}\n\n.inactiveHandlingModal-module___modal_footer___zUYx0 {\n  box-sizing: border-box;\n  padding: 0 32px 40px 32px;\n}\n\n.inactiveHandlingModal-module___modal_content___iBv-w > div > section > div {\n  display: flex;\n  flex-direction: column;\n}\n\n.inactiveHandlingModal-module___sections_container___NJVoP {\n  display: flex;\n  height: 272px;\n}\n", "",{"version":3,"sources":["webpack://./src/js/components/inactiveHandlingModal/inactiveHandlingModal.module.css"],"names":[],"mappings":"AAAA;EACE,sBAAsB;EACtB,aAAa;AACf;;AAEA;EACE,sBAAsB;EACtB,oBAAoB;AACtB;;AAEA;EACE,sBAAsB;EACtB,yBAAyB;AAC3B;;AAEA;EACE,aAAa;EACb,sBAAsB;AACxB;;AAEA;EACE,aAAa;EACb,aAAa;AACf","sourcesContent":["._modal_header {\n  box-sizing: border-box;\n  padding: 16px;\n}\n\n._modal_content {\n  box-sizing: border-box;\n  padding: 32px 58px 0;\n}\n\n._modal_footer {\n  box-sizing: border-box;\n  padding: 0 32px 40px 32px;\n}\n\n._modal_content > div > section > div {\n  display: flex;\n  flex-direction: column;\n}\n\n._sections_container {\n  display: flex;\n  height: 272px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"_modal_header": "inactiveHandlingModal-module___modal_header___U8O1U",
	"_modal_content": "inactiveHandlingModal-module___modal_content___iBv-w",
	"_modal_footer": "inactiveHandlingModal-module___modal_footer___zUYx0",
	"_sections_container": "inactiveHandlingModal-module___sections_container___NJVoP"
};
export default ___CSS_LOADER_EXPORT___;
