import React, { useCallback } from 'react';
import AccountSettingsLayout from '../../../layouts/accountSettingsLayout';
import { Tab, TabGroup } from '@bloobirds-it/flamingo-ui';
import { AircallIntegration } from './aircallIntegration/aircallIntegration';
import { TwilioIntegration } from './twilioIntegration/twilioIntegration';
import { useFeatureFlags } from '../../../hooks/useFeatureFlags';
import { useRouter } from '../../../hooks';
import { APP_ACCOUNT_DIALERS } from '../../../app/_constants/routes';
import SessionManagerFactory from '../../../misc/session';
import NoPermissionsPage from '../../noPermissionsPage';
import { JustcallIntegration } from './justCallIntegration/justcallIntegration';

const DialersPage = ({ tab }) => {
  const roleManager = SessionManagerFactory().getRoleManager();

  const { isFlagEnabled } = useFeatureFlags();
  const { history } = useRouter();
  const changeTab = useCallback(
    newTab => {
      history.push(`${APP_ACCOUNT_DIALERS}/${newTab.toLowerCase().replace(/\s+/g, '-')}`);
    },
    [history],
  );

  if (!roleManager.isAccountAdmin()) {
    return <NoPermissionsPage />;
  }

  return (
    <AccountSettingsLayout title="Dialer">
      <TabGroup value={tab} onClick={changeTab}>
        <Tab name="Twilio">
          <TwilioIntegration />
        </Tab>
        <Tab name="Aircall">
          <AircallIntegration />
        </Tab>
        {isFlagEnabled('JUSTCALL') ? (
          <Tab name="JustCall">
            <JustcallIntegration />
          </Tab>
        ) : (
          <></>
        )}
      </TabGroup>
    </AccountSettingsLayout>
  );
};

export default DialersPage;
