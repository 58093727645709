import React, { useCallback, useState } from 'react';
import CadencesLayout from '../../layouts/cadencesLayout/cadencesLayout';
import { Button, Tab, TabGroup } from '@bloobirds-it/flamingo-ui';
import useMediaQuery from '../../hooks/useMediaQuery';
import { APP_CADENCES_ANALYZE, APP_CADENCES_MANAGE, BobjectTypes } from '@bloobirds-it/types';
// @ts-ignore
import { useRouter } from '@bloobirds-it/hooks';
import { ManageTab } from './manageTab/manageTab';
import { AnalyzeTab } from './analyzeTab/analyzeTab';
import { CreateEditCadenceSettings } from './components/createEditCadenceSettings/createEditCadenceSettings';
import NoPermissionsPage from '../noPermissionsPage';
import { useUserSettings } from '../../components/userPermissions/hooks';
import { useCadenceV2Enabled } from '@bloobirds-it/hooks';
import { USER_PERMISSIONS } from '../../components/userPermissions/constants';
import styles from './cadencesPage.module.css';

export enum CadenceTabs {
  Manage = 'Manage',
  Analyze = 'Analyze',
}

const CadencesPageView = ({ tab }: { tab: CadenceTabs }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { isSmallDesktop } = useMediaQuery();
  const { history } = useRouter();
  const settings = useUserSettings();
  const accountId = settings?.account?.id;
  const hasCadencePermission = settings?.user?.permissions?.includes(
    USER_PERMISSIONS.VIEW_CADENCES,
  );
  const cadenceV2Enabled = useCadenceV2Enabled(accountId);

  const changeTab = useCallback(
    newTab => {
      let route;
      switch (newTab) {
        case CadenceTabs.Manage:
          route = APP_CADENCES_MANAGE;
          break;
        case CadenceTabs.Analyze:
          route = APP_CADENCES_ANALYZE;
          break;
        default:
          route = APP_CADENCES_MANAGE;
      }
      history.push(`${route}`);
    },
    [history],
  );

  if (!hasCadencePermission || !cadenceV2Enabled) {
    return <NoPermissionsPage />;
  }

  return (
    <>
      <CadencesLayout
        title="Cadences"
        leftAction={
          <Button
            dataTest="createCadence"
            iconLeft={isSmallDesktop ? 'plus' : undefined}
            color="bloobirds"
            onClick={() => setIsModalOpen(true)}
          >
            {!isSmallDesktop && 'Create cadence'}
          </Button>
        }
      >
        <div className={styles.tabGroupWrapper}>
          <TabGroup value={tab} onClick={changeTab} defaultValue="Manage">
            <Tab dataTest="cadencesManageTab" name="Manage" color="bloobirds">
              <ManageTab />
            </Tab>
            <Tab dataTest="cadencesAnalyzeTab" name="Analyze" color="bloobirds">
              <AnalyzeTab />
            </Tab>
          </TabGroup>
        </div>
      </CadencesLayout>
      {isModalOpen && (
        <CreateEditCadenceSettings
          onClose={() => setIsModalOpen(false)}
          bobjectType={BobjectTypes.Company}
        />
      )}
    </>
  );
};

export default CadencesPageView;
