import React, { useEffect, ReactNode } from 'react';
import {
  Button,
  CheckItem,
  Collapsible,
  Icon,
  Input,
  Item,
  Modal,
  ModalCloseIcon,
  ModalContent,
  ModalFooter,
  ModalHeader,
  MultiSelect,
  Radio,
  RadioGroup,
  Select,
  Spinner,
  Switch,
  Text,
} from '@bloobirds-it/flamingo-ui';
import { Controller, useForm } from 'react-hook-form';
import { isEmpty } from 'lodash';
import styles from './templateDecisionModal.module.css';
import { MessagingTemplate, Stage } from '@bloobirds-it/types';
import { toSentenceCase } from '../../../../../../utils/strings.utils';
import { usePlaybookSegmentation } from '@bloobirds-it/hooks';

export interface FormValuesTemplateDecisionModal {
  decision: string;
  name: string;
  stage: Stage | 'ALL';
  visibility?: boolean;
  segmentationValues: { [fieldId: string]: string[] };
}

interface TemplateDecisionModalProps {
  existingTemplate?: MessagingTemplate;
  onSave: (value: FormValuesTemplateDecisionModal) => void;
  onClose: () => void;
  comeFromAutoEmail?: boolean;
}

function TemplateDecisionModal({
  existingTemplate,
  onClose,
  onSave,
  comeFromAutoEmail = false,
}: TemplateDecisionModalProps) {
  const isEdit = !!existingTemplate?.id;

  const {
    control,
    watch,
    setValue,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<FormValuesTemplateDecisionModal>({
    defaultValues: {
      decision: 'update',
      stage: existingTemplate?.stage || 'PROSPECT',
      visibility: true,
      name: isEdit ? `${existingTemplate?.name} Copy` : undefined,
      segmentationValues: existingTemplate?.segmentationValues || {},
    },
  });
  const selectedStage = watch('stage');
  const decision = watch('decision');
  const segmentationValues = watch('segmentationValues');

  const { segmentationFields } = usePlaybookSegmentation(selectedStage);

  useEffect(() => {
    if (
      segmentationFields &&
      segmentationFields.length > 0 &&
      (!segmentationValues || isEmpty(segmentationValues))
    ) {
      setValue('segmentationValues', existingTemplate?.segmentationValues);
    }
  }, [segmentationValues, existingTemplate, segmentationFields]);

  useEffect(() => {
    if (!existingTemplate?.id) {
      setValue('decision', 'create');
    }
  }, [existingTemplate]);

  return (
    <Modal open onClose={onClose}>
      <form onSubmit={handleSubmit(onSave)}>
        <ModalHeader className={styles.header} color="veryLightBloobirds">
          <div className={styles.title}>
            <Icon color="softBloobirds" name="mail" />
            <Text size="l" color="peanut">
              {isEdit ? 'Update or create a new template' : 'Save as a template'}
            </Text>
          </div>
          <ModalCloseIcon color="softBloobirds" onClick={onClose} />
        </ModalHeader>
        <ModalContent>
          <Text size="m" weight="bold">
            {isEdit
              ? 'Do you want to update the existing template or create a new one?'
              : 'Add a name for your new template'}
          </Text>
          {isEdit ? (
            <>
              <Text className={styles.description} size="s">
                When a <b>template is updated</b>, any changes that have been made{' '}
                <b>will be applied to the selected template globally</b>. When{' '}
                <b>creating a new one</b>, a copy of this template will be generated, public for
                everyone. These changes <b>will be applied immediately in active cadences.</b>
              </Text>
              <Controller
                name="decision"
                control={control}
                render={({ value, onChange }) => (
                  <RadioGroup value={value} onChange={onChange}>
                    <Radio
                      expand
                      size="medium"
                      color="bloobirds"
                      backgroundColor="veryLightBloobirds"
                      value="update"
                    >
                      Update template for everyone
                    </Radio>
                    <Radio
                      expand
                      size="medium"
                      color="bloobirds"
                      backgroundColor="veryLightBloobirds"
                      value="create"
                    >
                      Save and create as new public template
                    </Radio>
                  </RadioGroup>
                )}
              />
            </>
          ) : (
            <Text className={styles.description} size="s">
              By <b>saving as new</b>, a new template will be created, public for everyone. These{' '}
              changes <b>will be applied immediately in active cadences.</b>
            </Text>
          )}
          <div className={styles.inputWrapper}>
            {(!isEdit || decision === 'create') && (
              <Controller
                name="name"
                control={control}
                render={({ value, onChange }) => (
                  <Input
                    value={value}
                    width="100%"
                    className={styles.input}
                    onChange={onChange}
                    placeholder="Template name"
                  />
                )}
              />
            )}
          </div>
          <Collapsible
            title={
              <div className={styles.moreOptionsTitle}>
                <Text size="l">More options</Text>
              </div>
            }
            expanded={true}
            color="bloobirds"
          >
            <div className={styles.moreContent}>
              <div className={styles.moreContentSection}>
                <div className={styles.textMeContentWrapper}>
                  <Text size="s">
                    <b>Stage</b>
                  </Text>
                </div>
                <div className={styles.textMeContentWrapper}>
                  <Text size="s">
                    Stage enables to easily use your templates on the company or opportunity view
                    depending on the sales stage you are working
                  </Text>
                </div>
                <Controller
                  name="stage"
                  control={control}
                  render={({ value, onChange }) => (
                    <div style={{ marginTop: 16 }}>
                      <Select
                        value={value}
                        width="100%"
                        onChange={onChange}
                        placeholder="Prospect stage"
                      >
                        <Item value="PROSPECT">Prospect stage</Item>
                        <Item value="SALES">Sales stage</Item>
                        <Item value="ALL">Prospect and Sales stage</Item>
                      </Select>
                    </div>
                  )}
                />
              </div>
              {!comeFromAutoEmail && (
                <div className={styles.moreContentSection}>
                  <div className={styles.textMeContentWrapper}>
                    <Text size="s">
                      <b>Visibility</b>
                    </Text>
                  </div>
                  <Controller
                    name="visibility"
                    control={control}
                    render={({ value, onChange }) => (
                      <div className={styles.visibility}>
                        <Switch onChange={onChange} checked={value} />
                        <div className={styles.visibilityText}>
                          <Text size="s">Visible for all team members? </Text>
                        </div>
                      </div>
                    )}
                  />
                </div>
              )}
              <div className={styles.moreContentSection}>
                <div className={styles.textMeContentWrapper}>
                  <Text size="s">
                    <b>Categorization</b>
                  </Text>
                </div>
                <Text size="s">
                  Categorizing enables you to easily filter your templates when contacting a lead.
                </Text>
                {segmentationFields &&
                  Object.entries(segmentationFields).map(([stage, messagingFilters]) => (
                    <div key={`segmantation-${stage}`} className={styles.segmentationBlock}>
                      {selectedStage === 'ALL' && (
                        <Text size="xs" color="softPeanut">
                          {toSentenceCase(stage.toLowerCase())}
                        </Text>
                      )}
                      {/*@ts-ignore*/}
                      {messagingFilters.map(filter => (
                        <Controller
                          control={control}
                          key={filter.id}
                          name={`segmentationValues.${stage}.${filter.id}`}
                          render={({ value, onChange }) => (
                            <div style={{ marginTop: 8 }}>
                              <MultiSelect
                                width="100%"
                                sortByChecked={false}
                                onChange={onChange}
                                placeholder={value?.length > 0 ? filter.name : `All ${filter.name}`}
                                value={value}
                              >
                                <Item value="">All {filter.name}</Item>
                                {filter.values.map(
                                  (option: {
                                    isEnabled: boolean;
                                    id: React.Key;
                                    name: string & ReactNode;
                                  }) => (
                                    <CheckItem
                                      hidden={!option.isEnabled}
                                      key={option.id}
                                      value={option.id}
                                      label={option.name}
                                    >
                                      {option.name}
                                    </CheckItem>
                                  ),
                                )}
                              </MultiSelect>
                            </div>
                          )}
                        />
                      ))}
                    </div>
                  ))}
              </div>
            </div>
          </Collapsible>
        </ModalContent>
        <ModalFooter>
          <Button variant="tertiary" color="softBloobirds" onClick={onClose}>
            Cancel
          </Button>
          <Button disabled={isSubmitting} variant="primary" color="bloobirds" type="submit">
            {isSubmitting ? <Spinner size={16} color="white" name="loadingCircle" /> : 'Continue'}
          </Button>
        </ModalFooter>
      </form>
    </Modal>
  );
}

export default TemplateDecisionModal;
