import React from 'react';

import { CadencePreview } from '@bloobirds-it/cadence';
import { Text } from '@bloobirds-it/flamingo-ui';

import { EntityCard } from '../../../../components/entityList/entityCard/entityCard';
import {
  EntityHeaderItem,
  EntityList,
  EntityListHeader,
} from '../../../../components/entityList/entityList';
import {
  CadenceErrorPage,
  CadenceListNoResults,
  CadenceListSkeleton,
} from '../cadenceListComponents/cadenceListComponents';
import { cadencesHeadersNames, objectsHeadersNames } from './analyzeCadenceList.constants';
import styles from './analyzeCadenceList.module.css';
import { AnalyzeCadenceTableRow, AnalyzeObjectsTableRow } from './analyzeCadenceList.typings';
import { AnalyzeCadenceCard } from './analyzeCards/analyzeCadenceCard';
import { AnalyzeObjectsCard } from './analyzeCards/analyzeObjectsCard';

export const AnalyzeCadencesList = ({
  cadenceId,
  orderedAnalyticsRows,
  totalRows,
  headers,
  refreshAnalyticsRows,
  sort,
  handleReorder,
  isLoading,
  analyticsListError,
}: {
  cadenceId: string;
  orderedAnalyticsRows: AnalyzeObjectsTableRow[] | AnalyzeCadenceTableRow[];
  headers: any;
  refreshAnalyticsRows: () => void;
  sort: { [category: string]: 'ASC' | 'DESC' };
  totalRows: number;
  handleReorder: (category: string) => void;
  isLoading: boolean;
  analyticsListError: boolean;
}) => {
  //TODO: This is temporay pagination must be done in BE
  const orderingEnabled = totalRows === orderedAnalyticsRows?.length;

  const tableHeaders = [
    ...(cadenceId
      ? objectsHeadersNames(orderingEnabled, orderedAnalyticsRows[0]?.bobjectType)
      : cadencesHeadersNames(orderingEnabled)),
    ...(headers?.map((item: any) => ({
      ...item,
      sortable: !cadenceId && orderingEnabled,
    })) || []),
  ];

  return (
    <>
      {cadenceId && (
        <div className={styles._cadence_preview_wrapper}>
          <Text>Cadence overview</Text>
          <div className={styles._cadence_table_preview_wrapper}>
            <CadencePreview cadenceId={cadenceId} fullWidth />
          </div>
        </div>
      )}
      {analyticsListError && <CadenceErrorPage />}
      {!analyticsListError && (
        <div>
          {cadenceId && (
            <Text className={styles._objects_table_title_wrapper}>Objects in cadence</Text>
          )}
          {isLoading ? (
            <CadenceListSkeleton />
          ) : orderedAnalyticsRows?.length > 0 ? (
            <EntityList>
              <>
                <EntityListHeader>
                  {tableHeaders.map(column => (
                    <EntityHeaderItem
                      label={column?.label}
                      key={column?.tooltip}
                      canBeSorted={column.sortable}
                      icon={column.icon}
                      color={column.color ? column.color : column.iconColor}
                      tooltip={column.tooltip}
                      // @ts-ignore
                      order={sort[column?.label] ? sort[column?.label] : null}
                      onClick={() => handleReorder(column.label.toLowerCase())}
                    />
                  ))}
                </EntityListHeader>
                {orderedAnalyticsRows.map((row: any, index) => (
                  <EntityCard key={'card-' + index + '-' + (row?.bobjectId || row?.cadenceId)}>
                    {cadenceId ? (
                      <AnalyzeObjectsCard row={row as AnalyzeObjectsTableRow} />
                    ) : (
                      <AnalyzeCadenceCard row={row as AnalyzeCadenceTableRow} />
                    )}
                  </EntityCard>
                ))}
              </>
            </EntityList>
          ) : (
            <CadenceListNoResults table={'Analytics'} />
          )}
        </div>
      )}
    </>
  );
};
