import useSWR from 'swr';
import { ServiceApi } from '../misc/api/service';

export const useTargetMarketsList = () => {
  const { data: targetMarkets } = useSWR('/targetMarket', () =>
    ServiceApi.request({
      url: '/service/view/targetMarket',
      method: 'GET',
    }),
  );

  return {
    targetMarkets,
  };
};
