import { useEffect, useState } from 'react';
import { atom, useRecoilState, useRecoilValue, useResetRecoilState } from 'recoil';
import { useSearchSubscription } from '@bloobirds-it/plover';
import SessionManagerFactory from '../../../../misc/session';
import { BOBJECT_TYPES, BobjectTypes } from '../../../../constants/bobject';
import {
  COMPANY_FIELDS_LOGIC_ROLE,
  COMPANY_STAGE_LOGIC_ROLE,
  COMPANY_STATUS_LOGIC_ROLE,
} from '../../../../constants/company';
import { requestFutureTasks, useProspectingItems } from '../useProspecting';
import { getValueFromLogicRole } from '../../../../utils/bobjects.utils';
import { TASK_FIELDS_LOGIC_ROLE } from '../../../../constants/task';
import { useFullSalesEnabled, useNewInactiveHandling } from '../../../../hooks/useFeatureFlags';
import { useCompany, useEntity } from '../../../../hooks';
import { COMPANY_COLUMNS } from '../useProspecting.constants';
import { SORT_FIELDS } from './inactive.constant';
import { SortValues } from '../../../../layouts/subhomeLayout/subhomeContent/newSubhomeFilters/subhomeFilters';
import { Bobject } from '../../../../typings/bobjects';

const SessionManager = SessionManagerFactory();

const DEFAULT_ORDER = 'lastAttemptOldest';

const sortAtom = atom({
  key: 'prospectCompanyInactiveSortAtom',
  default: { value: DEFAULT_ORDER, hasChanged: false },
});

const queryAtom = atom({
  key: 'prospectingCompanyInactiveQueryAtom',
  default: undefined,
});

/*END NEW ATOMS*/

function getQuery(hasSalesEnabled: boolean, isInactiveEnabled: boolean, bobjectFieldsEntity: any) {
  const assignedToField = bobjectFieldsEntity?.findByLogicRole(
    COMPANY_FIELDS_LOGIC_ROLE.ASSIGNED_TO,
  );
  const statusField = bobjectFieldsEntity?.findByLogicRole(COMPANY_FIELDS_LOGIC_ROLE.STATUS);
  const isInactive = bobjectFieldsEntity?.findByLogicRole(COMPANY_FIELDS_LOGIC_ROLE.IS_INACTIVE);

  const query = {
    [assignedToField?.id]: SessionManager.getUser()?.id,
    ...(hasSalesEnabled
      ? {
          [COMPANY_FIELDS_LOGIC_ROLE.STAGE]: [
            '__MATCH_EMPTY_ROWS__',
            COMPANY_STAGE_LOGIC_ROLE.PROSPECT,
          ],
        }
      : {}),
  };

  return !isInactiveEnabled
    ? {
        ...query,
        [statusField?.id]: [
          COMPANY_STATUS_LOGIC_ROLE.ON_PROSPECTION,
          COMPANY_STATUS_LOGIC_ROLE.READY_TO_PROSPECT,
          COMPANY_STATUS_LOGIC_ROLE.CONTACTED,
          COMPANY_STATUS_LOGIC_ROLE.ENGAGED,
        ],
      }
    : {
        ...query,
        [statusField?.id]: [
          COMPANY_STATUS_LOGIC_ROLE.ON_PROSPECTION,
          COMPANY_STATUS_LOGIC_ROLE.CONTACTED,
          COMPANY_STATUS_LOGIC_ROLE.ENGAGED,
          COMPANY_STATUS_LOGIC_ROLE.NURTURING,
          COMPANY_STATUS_LOGIC_ROLE.MEETING,
        ],
        [isInactive?.id]: COMPANY_FIELDS_LOGIC_ROLE.IS_INACTIVE + '__YES',
      };
}

export const useProspectingCompanyInactiveAggregation = () => {
  const isInactiveEnabled = useNewInactiveHandling();
  const hasSalesEnabled = useFullSalesEnabled();
  const bobjectFieldsEntity = useEntity('bobjectFields');
  const query = getQuery(hasSalesEnabled, isInactiveEnabled, bobjectFieldsEntity);

  const {
    data: { data: { contents: companies, totalMatching } = {} } = {},
  } = useSearchSubscription(
    {
      query,
      //@ts-ignore
      columns: COMPANY_COLUMNS,
      formFields: true,
      pageSize: 1000,
      injectReferences: false,
    },
    BOBJECT_TYPES.COMPANY,
  );
  const [filteredCompanies, setFilteredCompanies] = useState([]);
  const [futureTasks, setFutureTasks] = useState([]);

  useEffect(() => {
    if (!isInactiveEnabled) {
      requestFutureTasks(hasSalesEnabled, SessionManager?.getUser()?.id).then(response => {
        setFutureTasks(response);
      });
    }
  }, []);

  useEffect(() => {
    const companiesWithoutFutureTasks: Bobject[] = [];
    if (!isInactiveEnabled && futureTasks?.length > 0 && companies?.length > 0) {
      companies.forEach(company => {
        const hasFutureTasks = futureTasks.find(task => {
          const taskCompany = getValueFromLogicRole(task, TASK_FIELDS_LOGIC_ROLE.COMPANY);
          return taskCompany === company?.id?.value;
        });

        if (!hasFutureTasks) {
          companiesWithoutFutureTasks.push(company);
        }
      });
      setFilteredCompanies(companiesWithoutFutureTasks);
    } else if (companies?.length > 0 && (isInactiveEnabled || futureTasks?.length === 0)) {
      setFilteredCompanies(companies);
    } else {
      setFilteredCompanies(null);
    }
  }, [companies, futureTasks]);

  return isInactiveEnabled ? totalMatching : filteredCompanies?.length;
};

export const useProspectingInactiveCompany = () => {
  const bobjectFields = useEntity('bobjectFields');
  const [isLoaded, setIsLoaded] = useState(false);
  const salesFeatureEnabled = useFullSalesEnabled();
  const isInactiveEnabled = useNewInactiveHandling();
  const sort = SORT_FIELDS[useRecoilValue(sortAtom)?.value as keyof typeof SORT_FIELDS];
  const query: { [x: string]: any } = useRecoilValue(queryAtom);
  const assignedToId = bobjectFields?.findByLogicRole(COMPANY_FIELDS_LOGIC_ROLE.ASSIGNED_TO)?.id;

  const { items: companies, ...other } = useProspectingItems(
    query,
    sort,
    null,
    BobjectTypes.Company,
  );
  const assignedToQueryValue = query ? query[assignedToId] : SessionManager?.getUser().id;

  const [filteredCompanies, setFilteredCompanies] = useState([]);
  const [futureTasks, setFutureTasks] = useState([]);

  useEffect(() => {
    if (!isInactiveEnabled) {
      requestFutureTasks(salesFeatureEnabled, assignedToQueryValue).then(response =>
        setFutureTasks(response),
      );
    }
  }, [assignedToQueryValue]);

  useEffect(() => {
    const companiesWithoutFutureTasks: Bobject[] = [];
    if (!isInactiveEnabled && futureTasks.length > 0 && companies.length > 0) {
      companies.forEach(company => {
        const hasFutureTasks = futureTasks.find(task => {
          const taskCompany = getValueFromLogicRole(task, TASK_FIELDS_LOGIC_ROLE.COMPANY);
          return taskCompany === company?.id?.value;
        });

        if (!hasFutureTasks) {
          companiesWithoutFutureTasks.push(company);
        }
      });
      setFilteredCompanies(companiesWithoutFutureTasks);
      setIsLoaded(true);
    } else if (companies.length > 0 && (isInactiveEnabled || futureTasks.length === 0)) {
      setFilteredCompanies(companies);
      setIsLoaded(true);
    } else {
      setFilteredCompanies(null);
    }
  }, [companies, futureTasks]);

  return {
    ...other,
    items: isLoaded ? filteredCompanies : [],
    totalMatching: isLoaded ? filteredCompanies?.length : 0,
  };
};

// New Hooks
export const useProspectingInactiveAllItems = () => {
  const { fetchCompanies } = useCompany('inactive-tab');
  const query = useRecoilValue(queryAtom);
  const searchQuery = {
    query,
    formFields: true,
    pageSize: 1000,
    injectReferences: true,
  };
  const getAllItems = async () => {
    const response = await fetchCompanies(searchQuery);
    return response?.contents;
  };

  return { getAllItems };
};

export const useProspectingCompanyInactiveQuery = () => {
  const [query, setQuery] = useRecoilState<{ [x: string]: any }>(queryAtom);
  const hasSalesEnabled = useFullSalesEnabled();
  const isInactiveEnabled = useNewInactiveHandling();
  const bobjectFieldsEntity = useEntity('bobjectFields');

  const defaultQuery = getQuery(hasSalesEnabled, isInactiveEnabled, bobjectFieldsEntity);

  return {
    query,
    setQuery: (value: { [id: string]: any }) => {
      setQuery({ ...defaultQuery, ...value });
    },
    resetQuery: () => setQuery(defaultQuery),
  };
};

export const useProspectingCompanyInactiveSort = () => {
  const [sort, setSort] = useRecoilState(sortAtom);
  const resetSort = useResetRecoilState(sortAtom);
  return {
    sort,
    setSort: (value: SortValues) => {
      if (!value || value === DEFAULT_ORDER) {
        resetSort();
      } else {
        setSort({ value: value, hasChanged: true });
      }
    },
  };
};
