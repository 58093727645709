import { atom, useRecoilState } from 'recoil';
import { useNotifications } from './useNotifications';
import { NotificationsCategory } from '../typings/notifications';

const categoryAtom = atom({
  key: 'notificationBellCategory',
  default: 'UPDATES' as NotificationsCategory,
});

const useNotificationBell = () => {
  const [category, setCategory] = useRecoilState<NotificationsCategory>(categoryAtom);
  const methods = useNotifications({ category });

  return {
    category,
    setCategory,
    ...methods,
  };
};

export default useNotificationBell;
