import { ACTIVITY_FIELDS_LOGIC_ROLE } from './activity';
import {
  COMPANY_FIELDS_LOGIC_ROLE,
  COMPANY_SALES_STATUS_VALUES_LOGIC_ROLE,
  COMPANY_STAGE_LOGIC_ROLE,
  COMPANY_STATUS_LOGIC_ROLE,
} from './company';
import {
  LEAD_FIELDS_LOGIC_ROLE,
  LEAD_SALES_STATUS_VALUES_LOGIC_ROLE,
  LEAD_STAGE_LOGIC_ROLE,
  LEAD_STATUS_LOGIC_ROLE,
} from './lead';
import { OPPORTUNITY_FIELDS_LOGIC_ROLE, OPPORTUNITY_STATUS_LOGIC_ROLE } from './opportunity';
import { TASK_FIELDS_LOGIC_ROLE } from './task';
import { OPPORTUNITY_PRODUCT_FIELDS_LOGIC_ROLE } from './opportunityProduct';
import { PRODUCT_FIELDS_LOGIC_ROLE } from './product';

export enum BOBJECT_TYPES {
  ACTIVITY = 'Activity',
  COMPANY = 'Company',
  LEAD = 'Lead',
  OPPORTUNITY = 'Opportunity',
  TASK = 'Task',
  OPPORTUNITY_PRODUCT = 'OpportunityProduct',
  PRODUCT = 'Product',
}

export const FIELDS_LOGIC_ROLE = Object.freeze({
  [BOBJECT_TYPES.COMPANY]: COMPANY_FIELDS_LOGIC_ROLE,
  [BOBJECT_TYPES.LEAD]: LEAD_FIELDS_LOGIC_ROLE,
  [BOBJECT_TYPES.OPPORTUNITY]: OPPORTUNITY_FIELDS_LOGIC_ROLE,
  [BOBJECT_TYPES.TASK]: TASK_FIELDS_LOGIC_ROLE,
  [BOBJECT_TYPES.ACTIVITY]: ACTIVITY_FIELDS_LOGIC_ROLE,
  [BOBJECT_TYPES.OPPORTUNITY_PRODUCT]: OPPORTUNITY_PRODUCT_FIELDS_LOGIC_ROLE,
  [BOBJECT_TYPES.PRODUCT]: PRODUCT_FIELDS_LOGIC_ROLE,
});
export const fieldsLogicRole = (bobjectType: BobjectTypes) => FIELDS_LOGIC_ROLE[bobjectType];

export type CompanyOrLeadLR = typeof COMPANY_FIELDS_LOGIC_ROLE | typeof LEAD_FIELDS_LOGIC_ROLE;

export const SALES_STATUS_VALUES_LOGIC_ROLES = Object.freeze({
  [BOBJECT_TYPES.COMPANY]: COMPANY_SALES_STATUS_VALUES_LOGIC_ROLE,
  [BOBJECT_TYPES.LEAD]: LEAD_SALES_STATUS_VALUES_LOGIC_ROLE,
  [BOBJECT_TYPES.OPPORTUNITY]: OPPORTUNITY_STATUS_LOGIC_ROLE,
});

export const STATUS_VALUES_LOGIC_ROLES = Object.freeze({
  [BOBJECT_TYPES.COMPANY]: COMPANY_STATUS_LOGIC_ROLE,
  [BOBJECT_TYPES.LEAD]: LEAD_STATUS_LOGIC_ROLE,
  [BOBJECT_TYPES.OPPORTUNITY]: OPPORTUNITY_STATUS_LOGIC_ROLE,
});

export const STAGE_VALUES_LOGIC_ROLES = Object.freeze({
  [BOBJECT_TYPES.COMPANY]: COMPANY_STAGE_LOGIC_ROLE,
  [BOBJECT_TYPES.LEAD]: LEAD_STAGE_LOGIC_ROLE,
  [BOBJECT_TYPES.OPPORTUNITY]: OPPORTUNITY_STATUS_LOGIC_ROLE,
});

export enum BobjectTypes {
  Company = 'Company',
  Lead = 'Lead',
  Opportunity = 'Opportunity',
  Task = 'Task',
  Activity = 'Activity',
}

export function isBobjectType(type: any): boolean {
  return Object.values(BOBJECT_TYPES).includes(type);
}
