import { ColorType } from '@bloobirds-it/flamingo-ui';

export type BobjectType =
  | 'Company'
  | 'Lead'
  | 'Activity'
  | 'Task'
  | 'Opportunity'
  | 'Product'
  | 'OpportunityProduct';

export type MainBobjectTypes = 'Company' | 'Lead' | 'Opportunity';

export enum PluralBobjectTypes {
  Company = 'Companies',
  Lead = 'Leads',
  Activity = 'Activities',
  Task = 'Tasks',
  Opportunity = 'Opportunities',
  Product = 'Products',
  OpportunityProduct = 'Opportunity products',
}

export interface BobjectId {
  value: string;
  objectId: string;
  accountId: string;
  typeName: BobjectType;
}

export interface FieldValue {
  conditions: any[];
  deprecated: boolean;
  label: string;
  logicRole: string;
  parentFieldValueValue: any;
  value: string;
}

export interface BobjectField {
  bobjectFieldGroup?: any;
  duplicateValidation?: boolean;
  fieldValues?: FieldValue[];
  defaultValue?: string;
  defaultPicklistValue?: string;
  defaultGlobalPicklistValue?: string;
  id: string;
  label?: string;
  layoutReadOnly?: boolean;
  logicRole?: string;
  name: string;
  multiline?: boolean;
  readOnly?: boolean;
  referencedBobject?: Bobject;
  referencedBobjectType?: string;
  required?: boolean;
  text?: string;
  type: string;
  typeField?: boolean;
  value?: string;
  valueLogicRole?: string;
  valueBackgroundColor?: ColorType;
  valueTextColor?: ColorType;
  valueOutlineColor?: ColorType;
  fieldConditionsByField?: any[];
  fieldConditions?: any[];
  satisfiesFieldCrossCondition?: boolean;
  deprecated?: boolean;
}

export interface Bobject {
  id: BobjectId;
  fields: Array<BobjectField>;
  referencedBobject?: any;
  raw?: any;
}
