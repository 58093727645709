import React, { useEffect, useLayoutEffect, useMemo } from 'react';
import { useMachine } from '@xstate/react';
import { Modal, ModalCloseIcon, ModalHeader, ModalTitle } from '@bloobirds-it/flamingo-ui';
import {
  useAddToCalendar,
  useBobjectFormCreation,
  useCadenceControl,
  useCompany,
  useContactFlow,
  useLeads,
  useOpenContactFlow,
  useOpportunity,
  useRouter,
} from '../../hooks';
import { toTitleCase } from '../../utils/strings.utils';
import CallResult from './callResult/callResult';
import CallResultOpportunity from './callResultOpportunity/callResultOpportunity';
import ChangeSalesStatus from './changeSalesStatus/changeSalesStatus';
import ChangeStatus from './changeStatus/changeStatus';
import NoteAndQQ from './noteAndQQ/noteAndQQ';
import LoadingStep from './loadingStep/loadingStep';
import ConvertObject from './convertBobject/convertBobject';
import { COMPANY_FIELDS_LOGIC_ROLE, COMPANY_STAGE_LOGIC_ROLE } from '../../constants/company';
import { getFieldByLogicRole, getValueFromLogicRole, isCompany } from '../../utils/bobjects.utils';
import { isLeadPage, isOpportunityPage } from '../../utils/pages.utils';
import { EVENTS, STEPS, stepsMachine } from './contactFlowModal.machine';
import OpportunityControl from './opportunityControl/opportunityControl';
import { ACTIVITY_FIELDS_LOGIC_ROLE, ACTIVITY_MODE } from '../../constants/activity';
import { companyUrl, leadUrl } from '../../app/_constants/routes';
import {
  MinimizableModalType,
  useMinimizableModals,
} from '../../hooks/emails/useMinimizableModals';
import { STEPS_PROPS } from './contactFlow.constants';
import { useCalendarEnabled, useFullSalesEnabled } from '../../hooks/useFeatureFlags';
import { useContactBobjects } from '../../pages/contactPages/contactPageContext';
import { useSelectedOpportunity } from '../../hooks/useSelectedOpportunity';
import { Bobject } from '../../typings/bobjects';
import { LEAD_FIELDS_LOGIC_ROLE, LEAD_STAGE_LOGIC_ROLE } from '../../constants/lead';
import useSendToSales from '../../hooks/useSendToSales';
import {
  OPPORTUNITY_FIELDS_LOGIC_ROLE,
  OPPORTUNITY_STATUS_LOGIC_ROLE,
} from '../../constants/opportunity';
import { useSelectedLead } from '../../hooks/useSelectedLead';
import ScheduleNextStep from './scheduleNextStep/scheduleNextStep';

const OPPORTUNITY_CLOSED_STATUSES = [
  OPPORTUNITY_STATUS_LOGIC_ROLE.CLOSED_LOST,
  OPPORTUNITY_STATUS_LOGIC_ROLE.CLOSED_WON,
];

interface ChangeStatusStep {
  companyStatus: string;
  leadStatus: string;
}

export const getStatusOfBobject = (
  bobject: Bobject,
  changeStatusStepData: ChangeStatusStep,
  company: Bobject,
) => {
  const status = isCompany(bobject)
    ? toTitleCase(changeStatusStepData?.companyStatus)
    : toTitleCase(changeStatusStepData?.leadStatus);
  return status ? status : getFieldByLogicRole(company, COMPANY_FIELDS_LOGIC_ROLE.STATUS)?.text;
};

const checkOpenOpportunity = (opportunity: Bobject) => {
  let isClosed = false;
  if (opportunity) {
    const opportunityStatus = getFieldByLogicRole(opportunity, OPPORTUNITY_FIELDS_LOGIC_ROLE.STATUS)
      ?.valueLogicRole;

    isClosed = opportunityStatus ? OPPORTUNITY_CLOSED_STATUSES.includes(opportunityStatus) : true;
  }

  return isClosed;
};

const ContactFlowModal = ({ open, handleClose }: { open: boolean; handleClose: () => void }) => {
  const { activity: activityId, clear, step: contactFlowStep } = useOpenContactFlow();
  const { company } = useContactBobjects();
  const { selectedLead } = useSelectedLead();
  const { pathname } = useRouter();
  const { setAddToCalendarState, openAddToCalendarModal } = useAddToCalendar();
  const { openAddActivity, openAddOpportunity } = useBobjectFormCreation();
  const hasSalesEnabled = useFullSalesEnabled();
  const { openCadenceControl } = useCadenceControl();
  const { updateSelectedOpportunity, resetSelectedOpportunity } = useSelectedOpportunity();
  const { openMinimizableModal } = useMinimizableModals();
  const { updateSingleLead, leads, resetLeads, isLoaded: isLeadsLoaded } = useLeads('contactFlow');
  const { opportunity, getOpportunityById } = useOpportunity('contactFlow');
  const { resetCompany } = useCompany('contactFlow');
  const { setData: setConvertData } = useSendToSales();
  const isCalendarEnabled = useCalendarEnabled();

  const {
    activity,
    callResultStepData,
    changeStatusStepData,
    scheduleStepData,
    resetActivity,
    resetScheduleStepData,
    resetCallResultStepData,
    resetNoteStepData,
    resetChangeStatusStepData,
    setActivityId,
  } = useContactFlow();
  const lead = leads[0];
  const leadStage = lead && getFieldByLogicRole(lead, LEAD_FIELDS_LOGIC_ROLE.STAGE)?.valueLogicRole;
  const isLeadInSalesStage = leadStage === LEAD_STAGE_LOGIC_ROLE.SALES;
  const leadCompanyStage =
    company && getFieldByLogicRole(company, COMPANY_FIELDS_LOGIC_ROLE.STAGE)?.valueLogicRole;
  const isCompanyInSalesStage = leadCompanyStage === COMPANY_STAGE_LOGIC_ROLE.SALES;

  const hasLeads = !!lead;

  const closeModal = () => {
    clear();
    handleClose();
  };

  const handleOpenMinimizableModal = (type: MinimizableModalType) => {
    const companyName = company
      ? getValueFromLogicRole(company, COMPANY_FIELDS_LOGIC_ROLE.NAME)
      : '';
    openMinimizableModal({
      type,
      title: companyName && companyName !== '' ? companyName.slice(0, 10) : 'Untitled company',
      data: {
        mode: ACTIVITY_MODE.CREATE,
        company: {
          name: getValueFromLogicRole(company, COMPANY_FIELDS_LOGIC_ROLE.NAME),
          url: companyUrl(company),
          data: company,
        },
        lead: selectedLead && {
          name: getValueFromLogicRole(selectedLead, LEAD_FIELDS_LOGIC_ROLE.FULL_NAME),
          url: leadUrl(selectedLead),
          data: selectedLead,
        },
      },
    });
  };

  const [
    {
      value: step,
      context: { convertedLeads },
    },
    send,
  ] = useMachine(stepsMachine, {
    context: {
      hasSalesFeatureEnabled: hasSalesEnabled,
      handleClose: closeModal,
      handleOpenMinimizableModal,
      openAddActivity,
      openAddOpportunity,
      openCadenceControl,
      setConvertData,
      updateSelectedOpportunity,
      isCalendarEnabled,
    },
  });

  useEffect(() => {
    if (activity) {
      const leadId = getValueFromLogicRole(activity, ACTIVITY_FIELDS_LOGIC_ROLE.LEAD)?.split(
        '/',
      )[2];
      const opportunityId = getValueFromLogicRole(
        activity,
        ACTIVITY_FIELDS_LOGIC_ROLE.OPPORTUNITY,
      )?.split('/')[2];
      if (leadId && leadId !== 'undefined') {
        updateSingleLead(leadId);
      }
      if (opportunityId && opportunityId !== 'undefined') {
        getOpportunityById(opportunityId);
      }

      if (!leadId) {
        send(STEPS.CALL_RESULTS);
      }
    }
  }, [activity]);

  useLayoutEffect(() => {
    if (contactFlowStep && send) {
      send(contactFlowStep);
    }
  }, [send, contactFlowStep]);

  useEffect(() => {
    if (lead && send) {
      const hasAnOpenOpportunity = checkOpenOpportunity(opportunity);

      if ((isLeadInSalesStage && isCompanyInSalesStage) || hasAnOpenOpportunity) {
        send(STEPS.CALL_RESULTS_OPP);
      } else {
        send(STEPS.CALL_RESULTS);
      }
    }

    if (company && !hasLeads && isLeadsLoaded) {
      send(STEPS.CALL_RESULTS);
    }
  }, [send, lead, opportunity, company]);

  useEffect(
    () => () => {
      resetScheduleStepData();
      resetCallResultStepData();
      resetNoteStepData();
      resetChangeStatusStepData();
      resetActivity();
      resetLeads();
      resetCompany();
      if (!isOpportunityPage(pathname)) {
        resetSelectedOpportunity();
      }
    },
    [],
  );

  useEffect(() => {
    if (activityId) {
      setActivityId(Array.isArray(activityId) ? activityId[0] : activityId);
    }
  }, [activityId]);

  let otherProps = STEPS_PROPS[step];

  const getMainBobject = () => {
    if (isLeadPage(pathname)) {
      return lead;
    }

    if (isOpportunityPage(pathname)) {
      return opportunity;
    }

    return company;
  };

  // TODO: Better way
  if (step === STEPS.SCHEDULE_NEXT_STEPS) {
    const bobject = getMainBobject();
    const status = getStatusOfBobject(bobject, changeStatusStepData, company);

    otherProps = {
      ...otherProps,
      title: `
      Schedule next step for ${status || ''} ${isCompany(bobject) ? 'company' : 'lead'}`,
    };
  }

  if (step === STEPS.SALES_CHANGE_STATUS) {
    const bobject = lead || company;

    otherProps = {
      ...otherProps,
      title: `${isCompany(bobject) ? 'Company' : 'Lead'} status`,
    };
  }

  return useMemo(
    () => (
      <Modal open={open} onClose={closeModal} width={otherProps?.width}>
        <ModalHeader>
          <ModalTitle>{otherProps?.title}</ModalTitle>
          <ModalCloseIcon onClick={closeModal} />
        </ModalHeader>
        {step !== STEPS.INITIAL ? (
          <>
            {step === STEPS.CALL_RESULTS_OPP && (
              <CallResultOpportunity
                handleNext={correctContact => {
                  send(EVENTS.NEXT, {
                    isCorrectContact: correctContact,
                    isLeadInSalesStage,
                  });
                }}
              />
            )}
            {step === STEPS.CALL_RESULTS && (
              <CallResult
                hasLeads={hasLeads}
                handleNext={(selectedCallResult: string, needsClose: boolean) => {
                  if (needsClose) {
                    closeModal();
                  }
                  send(EVENTS.NEXT, { callResult: selectedCallResult });
                }}
              />
            )}
            {step === STEPS.SALES_CHANGE_STATUS && (
              <ChangeSalesStatus
                handleNext={(companyStatus, leadStatus) => {
                  send(EVENTS.NEXT, {
                    companyStatus,
                    leadStatus,
                    bobject: getMainBobject(),
                  });
                }}
                handleSkip={() => {
                  openCadenceControl({ bobjectToSet: getMainBobject() });
                  closeModal();
                }}
                handleBack={() => send(EVENTS.PREVIOUS)}
              />
            )}
            {step === STEPS.CHANGE_STATUS && (
              <ChangeStatus
                handleNext={(companyStatus: string, leadStatus: string) => {
                  send(EVENTS.NEXT, {
                    companyStatus,
                    leadStatus,
                    bobject: getMainBobject(),
                  });
                }}
                handleClose={closeModal}
                handleSkip={() => {
                  openCadenceControl({ bobjectToSet: getMainBobject() });
                  closeModal();
                }}
                handleBack={() => send(EVENTS.PREVIOUS)}
              />
            )}
            {step === STEPS.NOTES_AND_QQ && (
              <NoteAndQQ
                handleNext={() => send(EVENTS.NEXT)}
                handleBack={() => send(EVENTS.PREVIOUS)}
              />
            )}
            {step === STEPS.CONVERT_OBJECT && (
              <ConvertObject
                handleNext={(createOpportunity, leads) => {
                  if (createOpportunity) {
                    send(EVENTS.NEXT, { leads });
                  } else {
                    closeModal();
                  }
                }}
                handleBack={() => send(EVENTS.PREVIOUS)}
              />
            )}
            {step === STEPS.SCHEDULE_NEXT_STEPS && (
              <ScheduleNextStep
                handleClose={() => {
                  /*const calendarOptions = getCalendarOptions(
                    getMainBobject(),
                    scheduleStepData,
                    company,
                  );*/
                  // @ts-ignore
                  setAddToCalendarState(scheduleStepData);

                  openAddToCalendarModal();

                  closeModal();
                }}
                handleSkip={closeModal}
                handleBack={() => send(EVENTS.PREVIOUS)}
              />
            )}
            {step === STEPS.OPPORTUNITY_CONTROL && (
              <OpportunityControl
                leads={convertedLeads || []}
                handleBack={() => send(EVENTS.PREVIOUS)}
                handleClose={closeModal}
              />
            )}
          </>
        ) : (
          <LoadingStep />
        )}
      </Modal>
    ),
    [open, step, scheduleStepData, callResultStepData?.callResult, changeStatusStepData, hasLeads],
  );
};

export default ContactFlowModal;
