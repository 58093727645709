import React, { useEffect, useState } from 'react';
import {
  useActiveUser,
  useActivity,
  useEmailConnections,
  useEntity,
  useMediaQuery,
  usePicklistValues,
} from '../../hooks';
import {
  Button,
  Checkbox,
  ColorType,
  Icon,
  IconButton,
  Modal,
  ModalFooter,
  Spinner,
  Text,
  useToasts,
} from '@bloobirds-it/flamingo-ui';
import spacetime from 'spacetime';
import {
  Bobject,
  COMPANY_STAGE_LOGIC_ROLE,
  LEAD_FIELDS_LOGIC_ROLE,
  LEAD_STAGE_LOGIC_ROLE,
} from '@bloobirds-it/types';
import { FormProvider, useController, useForm } from 'react-hook-form';
import { api } from '../../utils/api';
import styles from './meetingModal.module.css';
import { TDateISODate } from '../../utils/dates.utils';
import { getValueFromLogicRole } from '../../utils/bobjects.utils';
import Calendar, { Invitee, InviteeCard } from './components/calendar/calendar';
import { MainInfoForm } from './components/mainInfoForm/mainInfoForm';
import {
  ACTIVITY_FIELDS_LOGIC_ROLE,
  ACTIVITY_TYPES_VALUES_LOGIC_ROLE,
} from '../../constants/activity';
import ActivityDetailsForm, {
  FormDataInterface,
} from './components/activityDetailsForm/activityDetailsForm';
import { useMinimizableModal } from '../../hooks/emails/useMinimizableModals';
import { CalendarsSelector } from './components/calendarsSelector/calendarsSelector';
import ChangeTimezoneModal from '../changeTimezoneModal/changeTimezoneModal';
import { NotesForm } from './components/notesForm/notesForm';
import { deserialize, serialize, useRichTextEditorPlugins } from '@bloobirds-it/rich-text-editor';
import { RemindeBeforeType, useCalendar } from './hooks/useCalendar';
import { BloobirdsCalendarsSelector } from './components/bloobirdsCalendarsSelector/bloobirdsCalendarsSelector';
import { ReminderForm } from './components/reminderForm/reminderForm';
import { ConferencingForm } from './components/conferencingForm/conferencingForm';
import { useUserSettings } from '../userPermissions/hooks';
import { SearchLeadsGuests } from './components/searchLeadsGuests/searchLeadsGuests';
import { InviteesDiscoveryTooltip } from '../discoveryTooltips/inviteesDiscoveryTooltip';
import { UserHelperKeys } from '../../constants/userHelperKeys';
import { useUserHelpers } from '../../hooks/useUserHelpers';
import { useConfirmDeleteModal } from '@bloobirds-it/bobjects';

export interface CalendarsWithColors {
  calendarId: string;
  color: ColorType;
  barColor: ColorType;
}

function stringifyArrays(obj: any) {
  const transformedObj = {};
  if (!transformedObj) {
    return null;
  }
  if (Object.keys(obj)?.length === 0) {
    return {};
  }
  for (const key in obj) {
    if (Array.isArray(obj[key])) {
      transformedObj[key] = JSON.stringify(obj[key]);
    } else {
      transformedObj[key] = obj[key];
    }
  }
  return transformedObj;
}

function getEmailFromCompany(company: Bobject) {
  const companyEmails = company
    ? company.fields.filter(field => field.value && field.type === 'EMAIL')
    : [];

  return companyEmails.length > 0 ? companyEmails[0] : undefined;
}

function MeetingModal(props: any) {
  const { id } = props;
  const { closeModal, minimize, data: formData, bobject } = useMinimizableModal<FormDataInterface>(
    id,
  );
  const isEditionModal = !!bobject;
  const [changeTimezoneModalVisible, setChangeTimezoneModalVisible] = useState<boolean>(false);
  const plugins = useRichTextEditorPlugins({
    images: false,
    replaceParagraphs: true,
  });
  const {
    setEventTypesSelected,
    eventsTypeSelected,
    invitees,
    setInvitees,
    resetInvitees,
    setDate,
    date,
    resetDate,
    calendarsAvailable,
    mutateCalendars,
    selectedTimezone,
    setSelectedTimezone,
    eventsPerDay,
    skipCalendarCreation,
    loading,
    setSkipCalendarCreation,
    setBannedEvent,
    meetingDuration,
  } = useCalendar();
  const parsedFormData = {
    title:
      formData && formData[ACTIVITY_FIELDS_LOGIC_ROLE.TITLE]
        ? formData[ACTIVITY_FIELDS_LOGIC_ROLE.TITLE]
        : null,
    dateTime:
      formData && formData[ACTIVITY_FIELDS_LOGIC_ROLE.TIME]
        ? new Date(formData[ACTIVITY_FIELDS_LOGIC_ROLE.TIME])
        : null,
    duration:
      formData && formData[ACTIVITY_FIELDS_LOGIC_ROLE.MEETING_DURATION]
        ? formData[ACTIVITY_FIELDS_LOGIC_ROLE.MEETING_DURATION]
        : meetingDuration || 60,
    calendarNotes:
      formData && formData[ACTIVITY_FIELDS_LOGIC_ROLE.CALENDAR_NOTE]
        ? deserialize(formData[ACTIVITY_FIELDS_LOGIC_ROLE.CALENDAR_NOTE], {
            format: 'HTML',
            plugins,
          })
        : null,
    ...formData,
    // @ts-ignore
    company: formData?.company?.data || formData?.company,
    // @ts-ignore
    lead: formData?.lead?.data || formData?.lead,
    // @ts-ignore
    opportunity: formData?.opportunity?.data || formData?.opportunity,
  };
  const activityTypes = usePicklistValues({ picklistLogicRole: ACTIVITY_FIELDS_LOGIC_ROLE.TYPE });
  const { activeUser } = useActiveUser();
  const methods = useForm({
    defaultValues: {
      ...parsedFormData,
    },
  });

  const { watch, control, getValues, formState, handleSubmit } = methods;
  useController({ control, name: ACTIVITY_FIELDS_LOGIC_ROLE.USER, defaultValue: activeUser?.id });
  useController({
    control,
    name: ACTIVITY_FIELDS_LOGIC_ROLE.TYPE,
    defaultValue: activityTypes?.find(
      (activityType: any) => activityType?.logicRole === ACTIVITY_TYPES_VALUES_LOGIC_ROLE.MEETING,
    )?.id,
  });
  const {
    settings: { openCalendarPopupAfterMeeting, calendarEventDecision },
  } = useUserSettings();
  const createAlwaysOnLinkedCalendar =
    calendarEventDecision === 'IMPERATIVE' && openCalendarPopupAfterMeeting;
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const users = useEntity('users');
  const { connections, mutate: mutateEmailConnections } = useEmailConnections();
  const { isValid, submitCount } = formState;
  const canSave = submitCount === 0 || isValid;
  const { createToast } = useToasts();
  const { isDesktop, isSmallDesktop, isMediumDesktop } = useMediaQuery();

  const lead = watch('lead');
  const company = watch('company');

  useEffect(() => {
    if (formData[ACTIVITY_FIELDS_LOGIC_ROLE.UNIQUE_NYLAS_ID]) {
      setBannedEvent(formData[ACTIVITY_FIELDS_LOGIC_ROLE.UNIQUE_NYLAS_ID]);
    }
    if (createAlwaysOnLinkedCalendar) setSkipCalendarCreation(false);
  }, []);
  const user = watch(ACTIVITY_FIELDS_LOGIC_ROLE.USER);
  const activityUser = users?.get(user as string);

  function handleClose() {
    resetInvitees();
    resetDate();
    closeModal();
  }
  const inviteesNotSynced =
    isEditionModal && !getValueFromLogicRole(bobject, ACTIVITY_FIELDS_LOGIC_ROLE.MEETING_INVITEES);

  useEffect(() => {
    if (formData[ACTIVITY_FIELDS_LOGIC_ROLE.MEETING_INVITEES]) {
      setInvitees(JSON.parse(formData[ACTIVITY_FIELDS_LOGIC_ROLE.MEETING_INVITEES]));
    } else {
      const newInvitees: Invitee[] = [];
      if (!!activeUser && !!connections) {
        const defaultConnection = connections.defaultConnection || connections.list[0]?.email;
        newInvitees.push({
          type: 'Organizer',
          email: activityUser ? activityUser?.email : defaultConnection,
          name: activityUser ? activityUser?.name : `${activeUser.name} (You)`,
        });
      }

      if (lead) {
        const leadEmail = getValueFromLogicRole(lead, 'LEAD__EMAIL');
        const leadName = getValueFromLogicRole(lead, 'LEAD__NAME');
        if (leadEmail) {
          newInvitees.push({ type: 'Lead', email: leadEmail, name: leadName });
        }
      }
      if (company) {
        const companyEmail = getEmailFromCompany(company);
        const companyName = getValueFromLogicRole(company, 'COMPANY__NAME');
        if (companyEmail) {
          newInvitees.push({ type: 'Company', email: companyEmail.value, name: companyName });
        }
      }
      if (invitees?.length === 0) {
        setInvitees(newInvitees);
      }
    }
  }, [connections, activeUser]);

  const removeInvitee = (email: string) => {
    setInvitees(currInvitees => currInvitees?.filter(invitee => invitee?.email !== email));
  };

  const { updateActivity } = useActivity(`calendarMeetingModalbobject${bobject?.id?.value}`);
  const onSubmit = (values: any) => {
    setIsSubmitting(true);
    const {
      company,
      lead,
      opportunity,
      duration,
      dateTime,
      title,
      calendarNotes,
      reminderTemplate,
      reminderBefore,
      reminderBeforeType,
      conferencingGoogleMeet,
      ...rest
    } = values;

    if (isEditionModal) {
      updateActivity(bobject?.id?.objectId, {
        ...rest,
        [ACTIVITY_FIELDS_LOGIC_ROLE.TIME]: dateTime,
        [ACTIVITY_FIELDS_LOGIC_ROLE.MEETING_DURATION]: duration,
        [ACTIVITY_FIELDS_LOGIC_ROLE.COMPANY]: company?.id?.value,
        [ACTIVITY_FIELDS_LOGIC_ROLE.LEAD]: lead?.id?.value,
        [ACTIVITY_FIELDS_LOGIC_ROLE.TITLE]: title,
        [ACTIVITY_FIELDS_LOGIC_ROLE.MEETING_INVITEES]: JSON.stringify(invitees),
        [ACTIVITY_FIELDS_LOGIC_ROLE.CALENDAR_NOTE]: serialize(calendarNotes, {
          format: 'AST',
          plugins,
        }),
      })
        .then(() => {
          createToast({
            type: 'success',
            message: 'Event updated successfully',
          });
          handleClose();
          setIsSubmitting(false);
        })
        .catch(() => {
          createToast({
            type: 'error',
            message: 'Something happened while creating the event, please check the connections',
          });
          setIsSubmitting(false);
        });
    } else {
      const reminderBeforeMuliplicator =
        reminderBeforeType === RemindeBeforeType.days
          ? 1440
          : reminderBeforeType === RemindeBeforeType.hours
          ? 60
          : 1;
      const data = {
        title,
        meetingDateTime: dateTime,
        meetingDuration: duration,
        company: company?.id?.value,
        lead: lead?.id?.value,
        opportunity:
          formData?.opportunity?.data?.id?.value ||
          formData?.opportunity?.id?.value ||
          opportunity?.id?.value,
        calendarId: calendarsAvailable?.data?.find(c => c.primary)?.id,
        accountId: calendarsAvailable?.data?.find(c => c.primary)?.accountId,
        invitees: invitees.map(i => i.email),
        inviteesDetails: invitees,
        otherFields: stringifyArrays(rest),
        reminderTemplateId: reminderTemplate,
        conferencingGoogleMeet,
        reminderTimeInMinutes: reminderBefore * reminderBeforeMuliplicator,
        skipCalendarEventCreation:
          connections?.list?.length === 0 ||
          !calendarsAvailable ||
          skipCalendarCreation ||
          !openCalendarPopupAfterMeeting,
        calendarNotes: serialize(calendarNotes, {
          format: 'AST',
          plugins,
        }),
      };
      api
        .post('/messaging/calendar/event', data)
        .then(() => {
          createToast({
            type: 'success',
            message: 'Event created successfully',
          });
          handleClose();
          setIsSubmitting(false);
        })
        .catch(() => {
          createToast({
            type: 'error',
            message: 'Something happened while creating the event, please check the connections',
          });
          setIsSubmitting(false);
        });
    }
  };

  const isoDate = spacetime(date).format('iso-short') as TDateISODate;
  const modalWidth = isDesktop ? 1400 : isMediumDesktop ? 1100 : isSmallDesktop ? 1000 : 700;
  const isLeadProspectingStage =
    !formData?.lead?.stage || formData?.lead?.stage === LEAD_STAGE_LOGIC_ROLE.PROSPECT;
  const isProspectingStage = isLeadProspectingStage
    ? true
    : !formData?.company?.stage || formData?.company?.stage === COMPANY_STAGE_LOGIC_ROLE.PROSPECT;

  const { openDeleteModal } = useConfirmDeleteModal();
  const handleDelete = () => {
    openDeleteModal(
      bobject,
      false,
      () => {},
      () => {
        createToast({ message: 'Meeting successfully deleted', type: 'success' });
        handleClose();
      },
    );
  };

  const [launchTooltip, setLaunchTooltip] = useState();
  const { has } = useUserHelpers();
  const defaultTooltipVisible =
    !has(UserHelperKeys.NEW_INVITEES_SYNC) && has(UserHelperKeys.NEW_MEETING_MODAL);

  useEffect(() => {
    setTimeout(() => {
      setLaunchTooltip(true);
    }, 2000);
  }, []);

  return (
    <Modal open={true} onClose={handleClose} width={modalWidth}>
      <FormProvider {...methods}>
        <div className={styles._header__container}>
          <div className={styles._header__date_picker}>
            <Text size="l" weight="regular">
              {spacetime(date).format('{month} {year}')}
            </Text>
            <IconButton
              name="chevronLeft"
              onClick={() => setDate(date => spacetime(date).subtract(1, 'week').toNativeDate())}
              size={16}
            />
            <IconButton
              name="chevronRight"
              onClick={() => setDate(date => spacetime(date).add(1, 'week').toNativeDate())}
              size={16}
            />
            <Button variant="secondary" size="small" onClick={() => setDate(new Date())}>
              Today
            </Button>
          </div>
          <div className={styles._header__spacer} />
          <div className={styles._header_right_actions}>
            {loading && <Spinner name="loadingCircle" size={16} />}
            <Text size="xs" color="softPeanut">
              Timezone: {selectedTimezone}{' '}
              <span
                className={styles._timezone_selector}
                onClick={() => setChangeTimezoneModalVisible(true)}
              >
                Change
              </span>
            </Text>
            {changeTimezoneModalVisible && (
              <ChangeTimezoneModal
                onChange={value => {
                  setSelectedTimezone(value);
                  setChangeTimezoneModalVisible(false);
                }}
                onClose={() => setChangeTimezoneModalVisible(false)}
                defaultTimezone={selectedTimezone}
              />
            )}
            <div className={styles._event_type_selector}>
              <div
                className={styles._event_type}
                style={{
                  backgroundColor:
                    eventsTypeSelected === 'bloobirds' ? 'var(--bloobirds)' : 'var(--white)',
                  borderTopLeftRadius: '4px',
                  borderBottomLeftRadius: '4px',
                }}
                onClick={() => setEventTypesSelected('bloobirds')}
              >
                <Text size="xs" color={eventsTypeSelected === 'bloobirds' ? 'white' : 'bloobirds'}>
                  Bloobirds
                </Text>
              </div>
              <div
                className={styles._event_type}
                style={{
                  backgroundColor:
                    eventsTypeSelected === 'nylas' ? 'var(--bloobirds)' : 'var(--white)',
                  borderTopRightRadius: '4px',
                  borderBottomRightRadius: '4px',
                }}
                onClick={() => setEventTypesSelected('nylas')}
              >
                <Icon
                  name="calendar"
                  size={12}
                  color={eventsTypeSelected === 'nylas' ? 'white' : 'bloobirds'}
                />
                <Text size="xs" color={eventsTypeSelected === 'nylas' ? 'white' : 'bloobirds'}>
                  Calendar
                </Text>
              </div>
            </div>
            <div className={styles._calendar_select}>
              {eventsTypeSelected === 'nylas' ? (
                <CalendarsSelector
                  connections={connections}
                  disabled={eventsTypeSelected === 'nylas' && connections?.list?.length === 0}
                />
              ) : (
                <BloobirdsCalendarsSelector />
              )}
            </div>
            <IconButton
              name="minus"
              size={24}
              onClick={() =>
                // @ts-ignore
                minimize({
                  data: getValues(),
                  title: getValues()?.title || 'Untitled event',
                  bobject,
                })
              }
            />
            <IconButton name="cross" size={24} onClick={handleClose} />
          </div>
        </div>
        <div className={styles._body}>
          <div className={styles._form_column}>
            <MainInfoForm prospectingStage={isProspectingStage} />
            <div className={styles._row_header}>
              <Text size="m">Guests</Text>
              {launchTooltip && (
                <InviteesDiscoveryTooltip defaultTooltipVisible={defaultTooltipVisible} />
              )}
            </div>
            {inviteesNotSynced && (
              <div className={styles.inviteesNotSynced}>
                <Text size="s" color="peanut">
                  Invitees would not be synced to your Calendar
                </Text>
              </div>
            )}
            {!inviteesNotSynced && (
              <div className={styles.searchLeads}>
                <SearchLeadsGuests
                  size={16}
                  handleSelect={leadSelected => {
                    if (leadSelected?.id?.value) {
                      const leadEmail = getValueFromLogicRole(
                        leadSelected,
                        LEAD_FIELDS_LOGIC_ROLE.EMAIL,
                      );
                      const leadName = getValueFromLogicRole(
                        leadSelected,
                        LEAD_FIELDS_LOGIC_ROLE.FULL_NAME,
                      );
                      if (!invitees?.find(invitee => invitee?.email === leadEmail)) {
                        setInvitees(curr => [
                          ...curr,
                          {
                            type: 'Lead',
                            email: leadEmail,
                            name: leadName,
                            leadId: leadSelected?.id?.value,
                          },
                        ]);
                      }
                    } else {
                      if (!invitees?.find(invitee => invitee?.email === leadSelected)) {
                        setInvitees(curr => [
                          ...curr,
                          {
                            type: null,
                            email: leadSelected as string,
                            name: null,
                            leadId: null,
                          },
                        ]);
                      }
                    }
                  }}
                  company={company}
                />
              </div>
            )}
            <div className={styles._invitees_container}>
              {invitees.map(i => (
                <InviteeCard
                  invitee={i}
                  key={i.email}
                  handleRemoveInvitee={removeInvitee}
                  readOnly={false}
                />
              ))}
            </div>
            {!isEditionModal && <ConferencingForm />}
            {!isEditionModal && <ReminderForm />}
            <NotesForm control={control} />
            <ActivityDetailsForm isEditionModal={isEditionModal} formData={{ company, lead }} />
          </div>
          <Calendar
            day={isoDate}
            mode="week"
            events={eventsPerDay}
            notConnected={eventsTypeSelected === 'nylas' && connections?.list?.length === 0}
            onCalendarReconnect={() => {
              mutateEmailConnections().then(() => {
                mutateCalendars();
              });
            }}
            selectedTimezone={selectedTimezone}
          />
        </div>
        <ModalFooter className={styles.footer}>
          <div>
            <Button variant="tertiary" onClick={handleClose}>
              Cancel
            </Button>
            {isEditionModal && (
              <Button variant="tertiary" color="tomato" onClick={handleDelete}>
                Delete
              </Button>
            )}
          </div>
          <div className={styles._footer_buttons_right}>
            {connections?.list?.length > 0 &&
              calendarsAvailable &&
              openCalendarPopupAfterMeeting &&
              !isEditionModal && (
                <Checkbox
                  size="small"
                  checked={
                    createAlwaysOnLinkedCalendar
                      ? createAlwaysOnLinkedCalendar
                      : !skipCalendarCreation
                  }
                  disabled={createAlwaysOnLinkedCalendar}
                  onClick={v => setSkipCalendarCreation(!v)}
                >
                  Create event in calendar
                </Checkbox>
              )}
            <Button
              disabled={isSubmitting || !canSave}
              variant="primary"
              onClick={() => {
                handleSubmit(values => onSubmit(values))();
              }}
            >
              {isSubmitting ? (
                <Spinner size={16} color="bloobirds" name="loadingCircle" />
              ) : (
                `${isEditionModal ? 'Save' : 'Create'}`
              )}
            </Button>
          </div>
        </ModalFooter>
      </FormProvider>
    </Modal>
  );
}

function WrappedModal(props: any) {
  return <MeetingModal {...props} />;
}

export default WrappedModal;
