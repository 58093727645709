import React, { useEffect, useState } from 'react';
import styles from '../../businessAssetsPage.module.css';
import { Text } from '@bloobirds-it/flamingo-ui';
import { SearchLogs } from '../../../../../../assets/svg';
import { emailAutomationHeaderNames } from './cadenceList.constants';
import {
  EntityHeaderItem,
  EntityList,
  EntityListHeader,
} from '../../../../../components/entityList/entityList';
import { EntityCard } from '../../../../../components/entityList/entityCard/entityCard';
import { CadenceCard } from './cadenceCard/cadenceCard';
import { CloneCadenceModal } from '../cloneCadenceModal/cloneCadenceModal';
import { useBobjectTypes } from '../../../../../hooks/useBobjectTypes';
import { useOrderedCadenceList } from './useCadenceList';

export const CadencesList = ({ searchValue, bobjectType }) => {
  const [isCloneModalOpen, setIsCloneModalOpen] = useState(false);
  const [cadenceToClone, setCadenceToClone] = useState();
  const bobjectTypes = useBobjectTypes();
  const bobjectTypeName = bobjectTypes?.get(bobjectType)?.name;
  const { orderedCadences, refreshCadences, sort, handleReorder } = useOrderedCadenceList(
    bobjectTypeName,
    searchValue,
  );
  useEffect(
    () => () => {
      refreshCadences();
    },
    [],
  );

  return (
    <>
      {orderedCadences?.length > 0 ? (
        <EntityList>
          {orderedCadences?.length > 0 ? (
            <>
              <EntityListHeader lastItemSticky>
                {emailAutomationHeaderNames.map(column => (
                  <EntityHeaderItem
                    label={column?.label}
                    key={column?.name}
                    canBeSorted={column.sortable}
                    order={sort[column?.name] ? sort[column?.name] : null}
                    onClick={() => handleReorder(column.name)}
                  />
                ))}
              </EntityListHeader>
              {orderedCadences.map(cadence => {
                return (
                  <EntityCard key={cadence?.id}>
                    <CadenceCard
                      key={cadence?.id}
                      cadence={cadence}
                      handleRefresh={refreshCadences}
                      setCadenceToClone={setCadenceToClone}
                      openCloneModal={() => setIsCloneModalOpen(true)}
                    />
                  </EntityCard>
                );
              })}
            </>
          ) : (
            <div className={styles._no_results__contents}>
              <SearchLogs className={styles._no_results__img} />
              <Text size="xl" weight="bold" align="center" color="softPeanut">
                No cadences for the current search
              </Text>
            </div>
          )}
        </EntityList>
      ) : (
        <div className={styles._no_results__contents}>
          <SearchLogs className={styles._no_results__img} />
          <Text size="xl" weight="bold" align="center" color="softPeanut">
            Still no cadences have been created
          </Text>
          <Text size="m" align="center" weight="regular" color="softPeanut">
            See how setting up your playbook correctly can help you in your sales process! See some
            examples to inspire you, then click the Create Target Market button to get started.
          </Text>
        </div>
      )}
      {isCloneModalOpen && (
        <CloneCadenceModal
          onClose={() => setIsCloneModalOpen(false)}
          cadence={cadenceToClone}
          refresh={refreshCadences}
        />
      )}
    </>
  );
};
