import React, { Fragment, useEffect, useRef, useState } from 'react';
import {
  IconButton,
  Portal,
  Text,
  Button,
  Tag,
  TagGroup,
  Spinner,
  RadioGroup,
  Radio,
  MultiSelect,
  CheckItem,
} from '@bloobirds-it/flamingo-ui';
import Draggable from 'react-draggable';
import { isPast, isToday } from 'date-fns';
import clsx from 'clsx';
import {
  useUserTasksFilters,
  useUserDateOverdueTasks,
  useUserDateTasks,
  useUserPausePeriods,
  useUserTasks,
  useAppCalendarVisibility,
} from '../../hooks/useUserTasks';
import Calendar from '../calendar/calendar';
import {
  TASK_FIELDS_LOGIC_ROLE,
  TASK_STATUS_VALUE_LOGIC_ROLE,
  TASK_TYPE,
} from '../../constants/task';
import { formatDate } from '../../utils/dates.utils';
import {
  getFieldByLogicRole,
  getTextFromLogicRole,
  getValueFromLogicRole,
} from '../../utils/bobjects.utils';
import useCalendar from '../../hooks/useCalendar';
import { SearchData } from '../../../assets/svg';
import styles from './appCalendar.module.css';
import TaskCard from './taskCard';
import { useActiveUser, useTaskNavigationStorage } from '../../hooks';
import { useFullSalesEnabled } from '../../hooks/useFeatureFlags';
import { TasksHookFamilies } from '../../typings/tasks';
import { useGlobalPicklistValues } from '../../hooks/usePicklistValues';
import { UserObject } from '../../typings/user';
import { useIsAccountAdmin } from '../../hooks/usePermissions';
import { sortBy } from 'lodash';

const DateGroupHeader = ({ text }: { text: string }) => (
  <header>
    <Text weight="medium" size="xs" inline>
      {text}
    </Text>
  </header>
);

const parseCalendarData = (data: any, showOverdue: boolean) => {
  const tasksDates = data?.map((task: any) => {
    const scheduledDate = getTextFromLogicRole(task, TASK_FIELDS_LOGIC_ROLE.SCHEDULED_DATETIME);
    const status = getFieldByLogicRole(task, TASK_FIELDS_LOGIC_ROLE.STATUS)?.valueLogicRole;
    if (
      isPast(new Date(scheduledDate)) &&
      [TASK_STATUS_VALUE_LOGIC_ROLE.TODO, TASK_STATUS_VALUE_LOGIC_ROLE.OVERDUE].includes(status) &&
      showOverdue
    ) {
      return formatDate(new Date(), 'yyyy-MM-dd');
    }
    return formatDate(new Date(scheduledDate), 'yyyy-MM-dd');
  });

  return tasksDates?.reduce((taskCounter: any, currentDate: string) => {
    if (typeof taskCounter[currentDate] !== 'undefined') {
      taskCounter[currentDate] += 1;
      return taskCounter;
    }
    taskCounter[currentDate] = 1;
    return taskCounter;
  }, {});
};

const AppCalendar = () => {
  const [showCalendar, setShowCalendar] = useState(false);
  const [showFilters, setShowFilters] = useState(false);
  const [timeMarkerPosition, setTimeMarkerPosition] = useState();
  const [isDragging, setIsDragging] = useState(false);
  const isAdminUser = useIsAccountAdmin();
  const activeUserId = useActiveUser()?.activeUser?.id;
  const { clientHeight: screenHeight } = document.body;
  const [height, setHeight] = useState(-(88 + 48));
  const { setSalesNavigation, startNavigation } = useTaskNavigationStorage();
  const { resetCalendar } = useCalendar();
  const { data: calendarData } = useUserTasks(TasksHookFamilies.Calendar);
  const { isOpen, closeCalendar, isExtended, setIsExtended } = useAppCalendarVisibility();
  const [selectedDay, setSelectedDay] = useState(new Date());
  const { tasks, isLoading: isLoadingTodayTasks, resetItems: resetTasks } = useUserDateTasks({
    date: selectedDay,
    family: TasksHookFamilies.Calendar,
  });
  const {
    tasks: overdueTasks,
    isLoading: isLoadingOverdue,
    resetItems: resetOverdueTasks,
  } = useUserDateOverdueTasks({
    date: selectedDay,
    family: TasksHookFamilies.Calendar,
  });
  const isLoading = isLoadingOverdue || isLoadingTodayTasks;
  const { periods } = useUserPausePeriods();
  const isFullSalesEnabled = useFullSalesEnabled();
  const {
    userFilter,
    prospectingTypesFilter,
    salesTypesFilter,
    tasksTypeFilter,
    setUserFilter,
    setDateFilter,
    setProspectingTypesFilter,
    setSalesTypesFilter,
    setTasksTypeFilter,
    resetAllFilters,
  } = useUserTasksFilters(TasksHookFamilies.Calendar);
  const ref = useRef(null);
  const typesFilter = tasksTypeFilter === 'PROSPECTING' ? prospectingTypesFilter : salesTypesFilter;
  const showOverdue = typesFilter.includes('OVERDUE') || typesFilter.length === 0;
  const users = useGlobalPicklistValues({
    logicRole: 'USER',
  })?.filter((user: UserObject) => user.enabled && user.id !== activeUserId);

  const getTimeMarkerPosition = (tasks: any) => {
    const shouldHaveMarker = tasks.map((task: any) => {
      const currentDateTime = new Date();
      const scheduledDatetime = getValueFromLogicRole(
        task,
        TASK_FIELDS_LOGIC_ROLE.SCHEDULED_DATETIME,
      );

      return scheduledDatetime && new Date(scheduledDatetime) < currentDateTime;
    });

    return shouldHaveMarker.lastIndexOf(true);
  };

  const handleStop = (event: MouseEvent) => {
    const { screenY } = event;
    const offset = screenY;

    if (isDragging) setIsDragging(false);
    if (!isExtended) {
      setHeight(screenHeight - offset);
    }
  };

  const handleDrag = () => {
    if (!isDragging) setIsDragging(true);
  };

  useEffect(() => {
    setTimeMarkerPosition(getTimeMarkerPosition(tasks));
    const intervalId = setInterval(
      () => setTimeMarkerPosition(getTimeMarkerPosition(tasks)),
      60000,
    );

    return () => intervalId && clearInterval(intervalId);
  }, [tasks]);

  useEffect(() => {
    setSalesNavigation(tasksTypeFilter === 'SALES');
  }, [tasksTypeFilter]);

  useEffect(() => {
    if (isExtended) {
      const offset = ref.current.getBoundingClientRect().y;
      setHeight(screenHeight - offset);
    }
  }, [isExtended]);

  useEffect(
    () => () => {
      resetAllFilters();
      resetCalendar();
      resetTasks();
      resetOverdueTasks();
    },
    [],
  );

  useEffect(() => {
    if (userFilter?.length === 0) setUserFilter(activeUserId);
  }, [userFilter]);

  return (
    isOpen && (
      <Portal>
        <Draggable onStop={handleStop} onDrag={handleDrag} handle="#handle" bounds="parent">
          <div
            aria-labelledby="calendar-app-modal"
            aria-describedby="Modal to manage my tasks"
            className={clsx(styles._container, {
              [styles._extended]: isExtended,
              [styles._no_extended]: !isExtended,
            })}
            style={{ height: isExtended ? `${height}px` : '' }}
          >
            <div ref={ref} className={styles._content}>
              <header className={styles._header}>
                <div className={styles._buttons}>
                  <IconButton
                    name="sliders"
                    selected={showFilters}
                    onClick={() => {
                      setShowCalendar(false);
                      setShowFilters(!showFilters);
                    }}
                  />
                  <IconButton
                    name="calendar"
                    selected={showCalendar}
                    onClick={() => {
                      setShowFilters(false);
                      setShowCalendar(!showCalendar);
                    }}
                  />
                  <div className={styles._actions}>
                    <span
                      id="handle"
                      className={clsx(styles._handle_icon, { [styles._dragging]: isDragging })}
                    >
                      <IconButton name="dragAndDrop" />
                    </span>
                    <IconButton
                      name={isExtended ? 'chevronClose' : 'chevronOpen'}
                      onClick={() => setIsExtended(!isExtended)}
                    />
                    <IconButton name="cross" onClick={closeCalendar} />
                  </div>
                </div>
              </header>
              {showFilters && (
                <div className={styles._filters_wrapper}>
                  {isFullSalesEnabled && (
                    <div className={styles._tasks_type_filter}>
                      <RadioGroup defaultValue={tasksTypeFilter} onChange={setTasksTypeFilter}>
                        <Radio value="ALL" size="small">
                          All tasks
                        </Radio>
                        <Radio value="PROSPECTING" size="small">
                          Prospecting tasks
                        </Radio>
                        <Radio value="SALES" size="small">
                          Sales tasks
                        </Radio>
                      </RadioGroup>
                    </div>
                  )}
                  {tasksTypeFilter !== 'SALES' ? (
                    <TagGroup
                      value={prospectingTypesFilter}
                      variant="secondary"
                      onChange={values => {
                        setProspectingTypesFilter(values);
                      }}
                    >
                      <Tag value={TASK_TYPE.PROSPECT_CADENCE}>ON CADENCE</Tag>
                      <Tag value={TASK_TYPE.NEXT_STEP}>SCHEDULED</Tag>
                      <Tag value={TASK_TYPE.CONTACT_BEFORE_MEETING}>MEETING</Tag>
                      <Tag value="OVERDUE">OVERDUE</Tag>
                      <Tag value="COMPLETED">COMPLETED</Tag>
                    </TagGroup>
                  ) : (
                    <TagGroup
                      value={salesTypesFilter}
                      variant="secondary"
                      onChange={values => {
                        setSalesTypesFilter(values);
                      }}
                    >
                      <Tag value={TASK_TYPE.NEXT_STEP}>APPOINTMENTS</Tag>
                      <Tag value={TASK_TYPE.PROSPECT_CADENCE}>FOLLOW UP</Tag>
                      <Tag value="OVERDUE">OVERDUE</Tag>
                      <Tag value="COMPLETED">COMPLETED</Tag>
                    </TagGroup>
                  )}
                </div>
              )}
              {showCalendar && (
                <div className={styles._calendar_wrapper}>
                  <Calendar
                    date={selectedDay}
                    onToday={() => {
                      setDateFilter(new Date());
                      setSelectedDay(new Date());
                    }}
                    onSelectedDay={date => {
                      setDateFilter(date);
                      setSelectedDay(new Date(date));
                    }}
                    onNextMonth={(month, year) => {
                      setDateFilter(new Date(year, month, 1));
                    }}
                    onPrevMonth={(month, year) => setDateFilter(new Date(year, month, 1))}
                    data={parseCalendarData(calendarData, showOverdue)}
                    markedDays={periods}
                  />
                </div>
              )}
              <div className={styles._tasks_wrapper}>
                <div className={styles._tasks_header}>
                  <Text size="m" color="softPeanut">
                    {formatDate(selectedDay, 'LLL do')}
                  </Text>
                  {(isToday(selectedDay) || isPast(selectedDay)) && (
                    <Button
                      iconLeft="play"
                      dataTest="Calendar-Tasks-Start"
                      disabled={[...tasks, ...overdueTasks].length === 0}
                      size="small"
                      onClick={() => {
                        const tasksToUse = [...tasks, ...overdueTasks];
                        startNavigation(
                          tasksToUse.filter(task => {
                            const status = getFieldByLogicRole(task, TASK_FIELDS_LOGIC_ROLE.STATUS)
                              ?.valueLogicRole;

                            return (
                              status === TASK_STATUS_VALUE_LOGIC_ROLE.TODO ||
                              status === TASK_STATUS_VALUE_LOGIC_ROLE.OVERDUE
                            );
                          }),
                        );
                      }}
                    >
                      Start tasks ({[...tasks, ...overdueTasks]?.length})
                    </Button>
                  )}
                </div>
                <div
                  className={clsx(styles._tasks_list, {
                    [styles._with_filters]: showFilters,
                    [styles._with_calendar]: showCalendar,
                  })}
                >
                  {isAdminUser && (
                    <MultiSelect
                      selectAllOption
                      size="small"
                      variant="filters"
                      className={styles.userSelect}
                      defaultValue={activeUserId}
                      value={userFilter}
                      onChange={setUserFilter}
                      dropdownProps={{ style: { maxHeight: '155px' } }}
                    >
                      {/* @ts-ignore */}
                      <CheckItem value={activeUserId}>Me</CheckItem>
                      {sortBy(users, 'value')?.map((user: { id: string; value: string }) => (
                        <CheckItem key={user.id} value={user.id}>
                          {user.value}
                        </CheckItem>
                      ))}
                    </MultiSelect>
                  )}
                  {isLoading && (
                    <div className={styles._isLoading}>
                      <Spinner name="loadingCircle" />
                    </div>
                  )}
                  {(tasks?.length > 0 || overdueTasks?.length > 0) && !isLoading && (
                    <>
                      {showOverdue && (
                        <ul className={styles._overdue_section}>
                          {overdueTasks?.map((task: any, index: number) => (
                            <Fragment key={task?.id?.value}>
                              {index === 0 && <DateGroupHeader text="Overdue" />}
                              <TaskCard task={task} />
                            </Fragment>
                          ))}
                        </ul>
                      )}
                      <ul className={styles._today_section}>
                        {tasks?.map((task: any, index: number) => (
                          <Fragment key={task?.id?.value}>
                            {index === 0 && (
                              <div className={styles.headerWrapper}>
                                <DateGroupHeader text="Tasks for today" />
                              </div>
                            )}
                            <TaskCard task={task} />
                            {index === timeMarkerPosition && isToday(new Date(selectedDay)) && (
                              <div className={styles._time_marker}>
                                <span className={styles._time_marker_bullet} />
                                <span className={styles._time_marker_line} />
                              </div>
                            )}
                          </Fragment>
                        ))}
                      </ul>
                    </>
                  )}
                  {!isLoading && tasks?.length === 0 && overdueTasks?.length === 0 && (
                    <>
                      <DateGroupHeader text="Tasks for today" />
                      <div className={styles._empty_list_container}>
                        <SearchData className={styles._empty_data__icon} />
                        <div className={styles._message_wrapper}>
                          <Text size="xs" align="center" color="softPeanut">
                            No tasks for today
                          </Text>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </Draggable>
      </Portal>
    )
  );
};

export default AppCalendar;
