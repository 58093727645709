import { ColorType, Label, Text, Tooltip } from '@bloobirds-it/flamingo-ui';
import React from 'react';
import { EntityCardItem } from '../../../../../components/entityList/entityCard/entityCard';
import styles from '../analyzeCadenceList.module.css';
import { AnalyzeObjectsTableRow, ObjectsInCadenceMetric } from '../analyzeCadenceList.typings';
import { cadenceStatusColors } from '../analyzeCadenceList.constants';
import { useBobjectDetails } from '../../../../../hooks';
import { useActiveUserSettings } from '@bloobirds-it/hooks';
import { BobjectTypes } from '@bloobirds-it/types';

const AnalyzeCardName = ({
  name,
  bobjectType,
  bobjectId,
}: //linkedInUrl,
//salesforceUrl,
{
  name: string;
  bobjectType: string;
  bobjectId: string;
}) => {
  const { openBobjectDetails } = useBobjectDetails();
  const { settings } = useActiveUserSettings();
  const parsedType = bobjectType?.charAt(0) + bobjectType?.slice(1)?.toLowerCase();

  /* const handleRedirect = (e: React.MouseEvent<HTMLElement, MouseEvent>, url: string) => {
     e.preventDefault();
     e.stopPropagation();
     window.open(url, '_blank');
   };*/

  return (
    <>
      <div
        onClick={() => {
          openBobjectDetails({
            id: `${settings?.account?.id}/${parsedType}/${bobjectId}`,
            showContactButton: true,
          });
        }}
        style={{ cursor: 'pointer' }}
      >
        <Text size="s" color="bloobirds" weight="bold">
          {name || ''}
        </Text>
      </div>
      {/*{(linkedInUrl || salesforceUrl) && (
        <div>
          {linkedInUrl && (
            <IconButton name="linkedin" onClick={e => handleRedirect(e, linkedInUrl)} />
          )}
          {salesforceUrl && (
            <IconButton name="salesforce" onClick={e => handleRedirect(e, salesforceUrl)} />
          )}
        </div>
      )}*/}
    </>
  );
};
export const AnalyzeObjectsCard = ({ row }: { row: AnalyzeObjectsTableRow }) => (
  <>
    {['LEAD', 'OPPORTUNITY'].includes(row?.bobjectType) && (
      <EntityCardItem>
        <AnalyzeCardName
          name={row?.name}
          bobjectType={row?.bobjectType}
          bobjectId={row?.bobjectId}
        />
      </EntityCardItem>
    )}
    <EntityCardItem size="medium">
      <AnalyzeCardName
        name={row?.companyName}
        bobjectType={BobjectTypes.Company}
        bobjectId={row?.companyId}
      />
    </EntityCardItem>
    <EntityCardItem>
      <Label
        color={cadenceStatusColors[row?.status]?.backgroundColor as ColorType}
        textColor={cadenceStatusColors[row?.status]?.textColor as ColorType}
        uppercase={false}
        overrideStyle={{ width: '90px', fontWeight: 'bold' }}
      >
        {row?.status}
      </Label>
    </EntityCardItem>
    <EntityCardItem>
      {row?.startDate ? row?.startDate?.charAt(0) + row?.startDate?.slice(1)?.toLowerCase() : ''}
    </EntityCardItem>
    {row?.metrics?.map((metric: ObjectsInCadenceMetric) => (
      <EntityCardItem key={metric?.tooltip}>
        <Tooltip title={metric?.tooltip} position="top">
          <Text size="s" color={metric?.color} className={styles._metric_value}>
            {metric?.value}
          </Text>
        </Tooltip>
      </EntityCardItem>
    ))}
  </>
);
