// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".nextStep-module___section_title__wrapper___wOAFR {\n  margin-top: 28px;\n  margin-bottom: 20px;\n}\n\n.nextStep-module___message__wrapper___UUuBd {\n  display: flex;\n  align-items: center;\n  margin-left: 7px;\n}\n\n.nextStep-module___message__wrapper___UUuBd > div {\n  margin-right: 23px;\n}\n\n.nextStep-module___actions__wrapper___lTZmf {\n  margin-bottom: 28px;\n}\n\n.nextStep-module___actions__wrapper___lTZmf > div > div {\n  margin-right: 8px;\n}\n\n.nextStep-module___buttons__wrapper___6M1FK {\n  display: flex;\n  width: 100%;\n  justify-content: flex-end;\n  padding-right: 16px;\n}\n\n.nextStep-module___buttons__wrapper___6M1FK > button:nth-child(2) {\n  margin-left: auto;\n}\n", "",{"version":3,"sources":["webpack://./src/js/components/cadenceControlModal/nextStep/nextStep.module.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,gBAAgB;AAClB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,aAAa;EACb,WAAW;EACX,yBAAyB;EACzB,mBAAmB;AACrB;;AAEA;EACE,iBAAiB;AACnB","sourcesContent":["._section_title__wrapper {\n  margin-top: 28px;\n  margin-bottom: 20px;\n}\n\n._message__wrapper {\n  display: flex;\n  align-items: center;\n  margin-left: 7px;\n}\n\n._message__wrapper > div {\n  margin-right: 23px;\n}\n\n._actions__wrapper {\n  margin-bottom: 28px;\n}\n\n._actions__wrapper > div > div {\n  margin-right: 8px;\n}\n\n._buttons__wrapper {\n  display: flex;\n  width: 100%;\n  justify-content: flex-end;\n  padding-right: 16px;\n}\n\n._buttons__wrapper > button:nth-child(2) {\n  margin-left: auto;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"_section_title__wrapper": "nextStep-module___section_title__wrapper___wOAFR",
	"_message__wrapper": "nextStep-module___message__wrapper___UUuBd",
	"_actions__wrapper": "nextStep-module___actions__wrapper___lTZmf",
	"_buttons__wrapper": "nextStep-module___buttons__wrapper___6M1FK"
};
export default ___CSS_LOADER_EXPORT___;
