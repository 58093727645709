import { CadenceStatus } from './analyzeCadenceList.typings';

export const cadencesHeadersNames = (orderingEnabled: boolean) => [
  {
    label: 'Name',
    name: 'name',
    sortable: orderingEnabled,
  },
  {
    label: '',
    name: '',
    sortable: false,
  },
  {
    label: 'Type',
    name: 'type',
    sortable: orderingEnabled,
  },
];

export const objectsHeadersNames = (
  orderingEnabled: boolean,
  bobjectType: 'COMPANY' | 'LEAD' | 'OPPORTUNITY',
) =>
  [
    ['LEAD', 'OPPORTUNITY'].includes(bobjectType)
      ? {
          label: bobjectType,
          name: 'name',
          sortable: orderingEnabled,
        }
      : undefined,
    {
      label: 'Company',
      name: 'companyName',
      sortable: orderingEnabled,
    },
    {
      label: 'Status',
      name: 'status',
      sortable: orderingEnabled,
    },
    {
      label: 'Started',
      name: 'startDate',
      sortable: orderingEnabled,
    },
  ].filter(Boolean);

export const cadenceStatusColors = {
  [CadenceStatus.Active]: { textColor: 'extraCall', backgroundColor: 'verySoftMelon' },
  [CadenceStatus.Stopped]: { textColor: 'tomato', backgroundColor: 'verySoftTomato' },
  [CadenceStatus.Ended]: { textColor: 'peanut', backgroundColor: 'verySoftPeanut' },
};
