import React, { useEffect, useMemo, useState } from 'react';
import {
  ColorType,
  ExpandableBox,
  ExpandableStep,
  IconButton,
  IconType,
  SubStep,
  Text,
} from '@bloobirds-it/flamingo-ui';
import { atom, useRecoilState, useRecoilValue } from 'recoil';
import { isBefore } from 'date-fns';
import { recoilPersist } from 'recoil-persist';
import { isObject } from 'lodash';
import styles from './quickStartGuide.module.css';
import { getQuickStartGuideBlocks } from './guideDefinition.constants';
import { useUserSettings } from '../../../../components/userPermissions/hooks';
import { useEntity, useRouter } from '../../../../hooks';
import { UserHelperKeys, UserHelperTooltipsKeys } from '../../../../constants/userHelperKeys';
import { api } from '../../../../utils/api';
import { COMPANY_FIELDS_LOGIC_ROLE } from '../../../../constants/company';
import { LEAD_FIELDS_LOGIC_ROLE } from '../../../../constants/lead';
import { getTextFromLogicRole } from '../../../../utils/bobjects.utils';
import { bobjectUrl } from '../../../../app/_constants/routes';
import { BobjectTypes } from '../../../../constants/bobject';
import { useUserHelpers } from '../../../../hooks/useUserHelpers';
import { WelcomeTooltip } from '../../../../components/discoveryTooltips/welcomeTooltips/welcomeTooltip';
import { useQuickStartGuideCompletedAggregation } from '../../../../hooks/useQuickStartGuide';
import { useCadences } from '../../../../hooks/useCadences';

const { persistAtom } = recoilPersist();

const tooltipTextDictionary = {
  [UserHelperKeys.LAUNCH_YOUR_FIRST_CADENCE]: 'This step requires a cadence to be completed',
  [UserHelperKeys.SEND_YOUR_FIRST_EMAIL]:
    'This step requires a company or lead assigned to you to be completed',
  [UserHelperKeys.START_TASK_FROM_CADENCE]:
    'This step requires an active cadence, start one to complete it',
  [UserHelperKeys.MARK_AS_DONE_ATTEMPT]: 'This step requires a pending task done be completed',
};

interface QSGInfoAtomType {
  openedBlocks: string[];
  contactableBobjects: {
    Company: { url: string; creationDateTime: string };
    Lead: { url: string; creationDateTime: string };
  };
}

const QSGInfoAtom = atom<QSGInfoAtomType>({
  key: 'QSGInfo',
  //@ts-ignore
  default: { openedBlocks: ['PREPARE_PIPELINE'], contactableBobjects: {} },
  effects: [persistAtom],
});

function getTourKeys(key: UserHelperKeys) {
  switch (key) {
    case UserHelperKeys.TAKE_TOUR_PROSPECT_TAB:
      // eslint-disable-next-line max-len
      return `${UserHelperTooltipsKeys.COMPANIES_AND_LEADS_TOOLTIP},${UserHelperTooltipsKeys.ON_CADENCE_FILTERS},${UserHelperTooltipsKeys.ON_CADENCE_DISCOVERY_TOOLTIP},${UserHelperTooltipsKeys.START_TO_FINISH},${UserHelperTooltipsKeys.START_TASKS},`;
    case UserHelperKeys.TAKE_TOUR_ON_INBOX:
      // eslint-disable-next-line max-len
      return `${UserHelperTooltipsKeys.INBOX_BULK_ACTIONS},${UserHelperTooltipsKeys.INBOX_INDIVIDUAL_ACTIONS},${UserHelperTooltipsKeys.INBOX_FILTERS},${UserHelperTooltipsKeys.INBOX_LEFT_SIDE_NAVIGATION},${UserHelperTooltipsKeys.INBOX_NO_TASKS},`;
    case UserHelperKeys.TAKE_TOUR_ON_OUTBOX:
      // eslint-disable-next-line max-len
      return `${UserHelperTooltipsKeys.OUTBOX_AUTOMATED_EMAILS},${UserHelperTooltipsKeys.OUTBOX_SCHEDULED_ACTIONS},${UserHelperTooltipsKeys.OUTBOX_SCHEDULED_EMAILS},${UserHelperTooltipsKeys.OUTBOX_AUTOMATED_BULK},${UserHelperTooltipsKeys.OUTBOX_SCHEDULED_PAGE},${UserHelperTooltipsKeys.OUTBOX_SCHEDULED_NO_EMAILS},${UserHelperTooltipsKeys.OUTBOX_SCHEDULED_NO_EMAILS},`;
  }
}

const Footer = () => (
  <div className={styles.hint_container}>
    <div className={styles.emoji}>🤓</div>
    <Text size="s" align="center">
      Check out Academy courses for best practices and level up content from Bloobirds Experts{' '}
    </Text>
    <div
      className={styles.academy_link}
      onClick={() => window.open('https://academy.bloobirds.com', '_blank')}
    >
      <Text size="s" color="bloobirds" align="center">
        https://academy.bloobirds.com
      </Text>
      <IconButton name="arrowRight" color="bloobirds" size={20} />
    </div>
  </div>
);

type SubStepProps = {
  label: string;
  key: UserHelperKeys;
  linkYoutube?: string;
  linkHowTo?: string;
  linkTour?: string;
  linkNavigation?: string;
};

type StepProps = {
  goals: SubStepProps[];
  title: string;
  icon: IconType;
  iconColor: ColorType;
  defaultOpened?: boolean;
  onClick?: () => void;
  toggleFiltersVisible?: () => void;
};

function getNavigation(
  linkNavigation: string,
  contactableBobjects: {
    Company: { url: string; creationDateTime: string };
    Lead: { url: string; creationDateTime: string };
  },
  key: UserHelperKeys,
) {
  if (!linkNavigation && contactableBobjects) {
    const contactableCompany = contactableBobjects?.Company;
    const contactableLead = contactableBobjects?.Lead;
    if (!contactableCompany?.url && contactableLead?.url) return contactableLead.url;
    if (contactableCompany?.url && !contactableLead?.url) return contactableCompany.url;
    const wasCompanyCreatedFirst = isBefore(
      new Date(contactableCompany?.creationDateTime),
      new Date(contactableLead?.creationDateTime),
    );

    return `${
      wasCompanyCreatedFirst ? contactableCompany?.url : contactableLead?.url
    }?fromGuide=${key}`;
  }
  return linkNavigation;
}

function getIsCompleted(
  userHelpers: Record<UserHelperKeys, Date>,
  key: UserHelperKeys,
  conditions: { [helperKey: string]: boolean },
) {
  //DEFAULT COMPLETE
  if (userHelpers && userHelpers[key]) return true;
  //PREVIOUS COMPLETION
  return !!conditions[key];
}

function getIsDisabled(key: UserHelperKeys, conditions: { [helperKey: string]: boolean }) {
  return {
    isDisabled: !!conditions[key],
    tooltipText: tooltipTextDictionary[key],
  };
}

const Step = ({ goals, defaultOpened, ...props }: StepProps) => {
  const { cadences } = useCadences([BobjectTypes.Company, BobjectTypes.Lead]);
  const { userHelpers }: { userHelpers: Record<UserHelperKeys, Date> } = useUserSettings();
  const { user } = useUserSettings();
  const { has, reset } = useUserHelpers();
  const { history } = useRouter();
  const savedHelpersNumber = isObject(userHelpers) && Object.keys(userHelpers)?.length;
  const numberOfTotalGoals = goals?.length;
  const completedTasks = useMemo(
    () =>
      goals.reduce(
        (acc, { key }) =>
          acc +
          (getIsCompleted(userHelpers, key, {
            [UserHelperKeys.SET_UP_REMINDERS]: user.remaindersEnabled,
          })
            ? 1
            : 0),
        0,
      ),
    [goals.length, savedHelpersNumber],
  );
  const progressPercentage = (completedTasks / numberOfTotalGoals) * 100;
  const hasTargetMarkets = useEntity('targetMarkets')?.all()?.length !== 0;
  const hasBuyerPersonas = useEntity('idealCustomerProfiles')?.all()?.length !== 0;
  const emptyEntities = [
    !hasTargetMarkets && UserHelperKeys.CHECK_OUT_YOUR_TARGET_MARKETS,
    !hasBuyerPersonas && UserHelperKeys.CHECK_OUT_YOUR_BUYER_PERSONAS,
  ];
  const { contactableBobjects } = useRecoilValue(QSGInfoAtom);

  function handleCardClick(key: UserHelperKeys, linkNavigationFunction: string) {
    if (key === UserHelperKeys.ENABLE_KPI_METRICS) return props.toggleFiltersVisible();
    if (key === UserHelperKeys.DOWNLOAD_CHROME_EXTENSION)
      return window.open(linkNavigationFunction, '_blank');
    if (key === UserHelperKeys.CREATE_FIRST_LIST)
      reset(
        `${UserHelperTooltipsKeys.CREATE_FIRST_LIST_FILTERS_COLUMNS},${UserHelperTooltipsKeys.SAVE_LIST}`,
      );
    if (linkNavigationFunction) {
      if (linkNavigationFunction.includes('app')) {
        history.push(linkNavigationFunction);
      } else {
        window.location.href = linkNavigationFunction;
      }
    }
    if (key.includes('TOUR')) reset(getTourKeys(key));
  }

  return (
    <ExpandableStep
      progressPercentage={progressPercentage}
      defaultOpened={defaultOpened && progressPercentage !== 100}
      {...props}
    >
      {goals.map(goal => {
        const { label, key, linkYoutube, linkHowTo, linkTour, linkNavigation } = goal;
        const isCompleted = getIsCompleted(userHelpers, key, {
          [UserHelperKeys.SET_UP_REMINDERS]: user.remaindersEnabled,
        });
        if (emptyEntities.includes(goal.key)) return;
        const linkNavigationFunction = getNavigation(linkNavigation, contactableBobjects, key);

        const { isDisabled, tooltipText } = getIsDisabled(key, {
          [UserHelperKeys.LAUNCH_YOUR_FIRST_CADENCE]: !cadences,
          [UserHelperKeys.SEND_YOUR_FIRST_EMAIL]:
            Object.values(contactableBobjects).filter(bobject => bobject.url !== '').length === 0,
          [UserHelperKeys.START_TASK_FROM_CADENCE]: !has(UserHelperKeys.LAUNCH_YOUR_FIRST_CADENCE),
          [UserHelperKeys.MARK_AS_DONE_ATTEMPT]: !has(UserHelperKeys.LAUNCH_YOUR_FIRST_CADENCE),
        });

        return (
          <SubStep
            key={key}
            isDisabled={isDisabled}
            completed={isCompleted}
            tooltipText={tooltipText}
            title={label}
            linkHowTo={linkHowTo}
            linkTour={linkTour}
            linkYoutube={linkYoutube}
            linkNavigation={linkNavigationFunction}
            onClick={() => {
              handleCardClick(key, linkNavigationFunction);
            }}
          />
        );
      })}
    </ExpandableStep>
  );
};

export const QuickStartGuide = ({ toggleFiltersVisible }: { toggleFiltersVisible: () => void }) => {
  const [tasksTotal, setTasksTotal] = useState(0);
  const [{ openedBlocks, contactableBobjects }, setQSGInfo] = useRecoilState(QSGInfoAtom);
  const completedGoals = useQuickStartGuideCompletedAggregation();
  const settings = useUserSettings();
  const { has } = useUserHelpers();
  const quickStartGuideBlocks = getQuickStartGuideBlocks({
    LINKEDIN_SALES: { hasExtension: has(UserHelperKeys.DOWNLOAD_CHROME_EXTENSION) },
  });

  useEffect(() => {
    if (Object.values(contactableBobjects).filter(bobject => bobject.url !== '').length === 0) {
      const searchQuery = {
        page: 0,
        formFields: true,
        pageSize: 1,
        injectReferences: true,
        sort: [] as Array<string>,
      };
      const userId = settings?.user?.id;
      api
        .post(`/bobjects/${settings?.account.id}/Company/search`, {
          query: {
            [COMPANY_FIELDS_LOGIC_ROLE.ASSIGNED_TO]: userId,
          },
          ...searchQuery,
        })
        .then(responseCompany => {
          const company = responseCompany?.data?.contents[0];
          api
            .post(`/bobjects/${settings?.account.id}/Lead/search`, {
              query: {
                [LEAD_FIELDS_LOGIC_ROLE.ASSIGNED_TO]: userId,
              },
              ...searchQuery,
            })
            .then(responseLead => {
              const lead = responseLead?.data?.contents[0];
              setQSGInfo({
                openedBlocks: openedBlocks,
                contactableBobjects: {
                  [BobjectTypes.Company]: {
                    url: bobjectUrl(company),
                    creationDateTime: getTextFromLogicRole(
                      company,
                      COMPANY_FIELDS_LOGIC_ROLE.CREATION_DATETIME,
                    ),
                  },
                  [BobjectTypes.Lead]: {
                    url: bobjectUrl(lead),
                    creationDateTime: getTextFromLogicRole(
                      lead,
                      LEAD_FIELDS_LOGIC_ROLE.CREATION_DATETIME,
                    ),
                  },
                },
              });
            });
        });
    }
  }, []);

  function handleStepClick(key: string) {
    const openedBlockInfo = openedBlocks.includes(key)
      ? openedBlocks.filter((block: string) => block !== key)
      : [...openedBlocks, key];
    setQSGInfo({
      openedBlocks: openedBlockInfo,
      contactableBobjects,
    });
  }

  useEffect(() => {
    const totalGoals = quickStartGuideBlocks.reduce((acc, block) => {
      return acc + block.goals.length;
    }, 0);

    setTasksTotal(totalGoals);
  }, [quickStartGuideBlocks]);

  return (
    <>
      <ExpandableBox
        defaultOpened={true}
        title="Your quick start guide 🚀"
        subtitle={
          <>
            <WelcomeTooltip defaultTooltipVisible />
            Start prospecting in less than <b>10 minutes!</b>
          </>
        }
        progressPercentage={(completedGoals / tasksTotal) * 100}
        progressText={`${completedGoals} / ${tasksTotal} tasks completed`}
      >
        <div className={styles.container}>
          {quickStartGuideBlocks.map(block => (
            <Step
              key={block.key}
              defaultOpened={openedBlocks?.includes(block?.key)}
              onClick={() => handleStepClick(block.key)}
              {...(block.key === 'MESSAGE_PROGRESS' ? { toggleFiltersVisible } : {})}
              {...block}
            />
          ))}
          <Footer />
        </div>
      </ExpandableBox>
    </>
  );
};
