// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".emailSettingsForm-module__subtitle___-bg\\+5 {\n  display: flex;\n  gap: 4px;\n  align-items: center;\n  margin-top: 4px;\n  margin-bottom: 24px;\n}\n\n.emailSettingsForm-module__input___PrXn1 {\n  display: flex;\n  gap: 12px;\n  align-items: center;\n}\n\n.emailSettingsForm-module__section___iXUoZ {\n  margin-bottom: 56px;\n}\n", "",{"version":3,"sources":["webpack://./src/js/pages/accountSettingsPages/emailSettingsPage/emailSettingsForm/emailSettingsForm.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,QAAQ;EACR,mBAAmB;EACnB,eAAe;EACf,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,SAAS;EACT,mBAAmB;AACrB;;AAEA;EACE,mBAAmB;AACrB","sourcesContent":[".subtitle {\n  display: flex;\n  gap: 4px;\n  align-items: center;\n  margin-top: 4px;\n  margin-bottom: 24px;\n}\n\n.input {\n  display: flex;\n  gap: 12px;\n  align-items: center;\n}\n\n.section {\n  margin-bottom: 56px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"subtitle": "emailSettingsForm-module__subtitle___-bg+5",
	"input": "emailSettingsForm-module__input___PrXn1",
	"section": "emailSettingsForm-module__section___iXUoZ"
};
export default ___CSS_LOADER_EXPORT___;
