import { Checkbox, Icon, Input, Item, Select, Text } from '@bloobirds-it/flamingo-ui';
import styles from '../../../styles/generalSettingsPage.module.css';
import React from 'react';
import {
  AccountSettingsTabContent,
  AccountSettingsTab,
} from '../../../../../../layouts/accountSettingsLayout/accountSettingsTab/accountSettingsTab';
import {
  AccountSettingsSection,
  AccountSettingsSectionContent,
  AccountSettingsSectionSubtitle,
  AccountSettingsSectionTitle,
} from '../../../../../../layouts/accountSettingsLayout/accountSettingsSection/accountSettingsSection';
import { Controller } from 'react-hook-form';

export const ContactBeforeMeeting = () => {
  return (
    <AccountSettingsTab>
      <AccountSettingsTabContent>
        <AccountSettingsSection>
          <AccountSettingsSectionTitle>Contact before meeting task</AccountSettingsSectionTitle>
          <AccountSettingsSectionSubtitle>
            The user who creates a meeting will receive a task to confirm the attendance of those
            invited to the meeting.
          </AccountSettingsSectionSubtitle>
          <AccountSettingsSectionContent>
            <div className={styles._checkbox_wrapper}>
              <Controller
                name="contactBeforeMeetingWarning"
                render={({ onChange, value }) => (
                  <Checkbox onClick={onChange} checked={value}>
                    <div>
                      <Text size="s">Enable the “contact before meeting” task </Text>
                    </div>
                  </Checkbox>
                )}
              />
            </div>
          </AccountSettingsSectionContent>
          <AccountSettingsSectionContent>
            <div className={styles._select_wrapper}>
              <Text color="softPeanut" size="m" weight="bold">
                Schedule task
              </Text>
              <Icon name="arrowRight" color="softPeanut" />
              <div className={styles._schedule_input}>
                <Controller
                  name="contactBeforeMeetingTime"
                  render={({ onChange, value }) => (
                    <Input
                      onChange={onChange}
                      value={value}
                      defaultValue={30}
                      type="number"
                      width="65px"
                    />
                  )}
                />
              </div>
              <Controller
                name="contactBeforeMeetingTimeRange"
                render={({ onChange, value }) => (
                  <Select
                    placeholder="Time"
                    defaultValue="MINUTES"
                    dataTest={'inboundLeadNotification'}
                    width="214px"
                    onChange={onChange}
                    value={value}
                  >
                    <Item value="MINUTES">Minutes</Item>
                    <Item value="HOURS">Hours</Item>
                    <Item value="DAYS">Days</Item>
                  </Select>
                )}
              />
              <div className={styles._schedule_text}>
                <Text color="softPeanut" size="m" weight="bold">
                  before the meeting
                </Text>
              </div>
            </div>
          </AccountSettingsSectionContent>
          <AccountSettingsSectionContent>
            <div className={styles._checkbox_wrapper}>
              <Controller
                name="contactBeforeMeetingOnWeekdays"
                render={({ onChange, value }) => {
                  return (
                    <Checkbox onClick={v => onChange(!v)} checked={!value} size="small">
                      <div>
                        <Text size="s">I want the scheduled task to be generated on weekends</Text>
                      </div>
                    </Checkbox>
                  );
                }}
              />
            </div>
          </AccountSettingsSectionContent>
        </AccountSettingsSection>
      </AccountSettingsTabContent>
    </AccountSettingsTab>
  );
};
