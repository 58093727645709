import React from 'react';
import MessagingTemplateActionsView from './messagingTemplateActions.view';

const MessagingTemplateActions = ({ creationConfig, children }) => (
  <MessagingTemplateActionsView
    searchPlaceholder={creationConfig.searchPlaceholder}
    handleOnCreationClick={creationConfig.onClickAction}
    newEntityButtonName={creationConfig.actionName}
  >
    {children}
  </MessagingTemplateActionsView>
);

export default MessagingTemplateActions;
