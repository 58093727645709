import React, { useCallback } from 'react';
import NoPermissionsPage from '../../noPermissionsPage';
import { Button, ColorType, IconType, Tab, TabGroup } from '@bloobirds-it/flamingo-ui';
import { useIsAccountAdmin } from '../../../hooks/usePermissions';
import AccountSettingsLayout from '../../../layouts/accountSettingsLayout';
import TargetMarketTab from './targetMarketTab/targetMarketTab';
import BuyerPersonaTab from './buyerPersonaTab/buyerPersonaTab';
import ScenarioTab from './scenarioTab/scenarioTab';
import { useRouter } from '../../../hooks';
import {
  APP_PLAYBOOK_BUYER_PERSONAS,
  APP_PLAYBOOK_CADENCES,
  APP_PLAYBOOK_CUSTOM_TASKS,
  APP_PLAYBOOK_SCENARIOS,
  APP_PLAYBOOK_TARGET_MARKET,
} from '../../../app/_constants/routes';
import CadenceTab from './cadenceTab/cadenceTab';
import { useNewCadenceTableEnabled } from '../../../hooks/useFeatureFlags';
import { useActiveUserSettings, useCadenceV2Enabled } from '@bloobirds-it/hooks';
import { USER_PERMISSIONS } from '../../../components/userPermissions/constants';
import CustomTaskTab from './customTaskTab/customTaskTab';

const BusinessAssetsPage = ({ tab }: { tab: string }) => {
  const isAccountAdmin = useIsAccountAdmin();
  const { settings } = useActiveUserSettings();
  const cadenceV2Enabled = useCadenceV2Enabled(settings?.account?.id);
  const hasNewCadenceTableFlag = useNewCadenceTableEnabled();
  const canViewCadences = settings?.user?.permissions.includes(USER_PERMISSIONS.VIEW_CADENCES);
  const canViewCadenceTab = canViewCadences && !cadenceV2Enabled;
  const canViewCustomTaskTab = canViewCadences && hasNewCadenceTableFlag;
  const { history } = useRouter();
  const changeTab = useCallback(
    newTab => {
      let route = '';
      switch (newTab) {
        case 'Target markets':
          route = APP_PLAYBOOK_TARGET_MARKET;
          break;
        case 'Buyer personas (ICP)':
          route = APP_PLAYBOOK_BUYER_PERSONAS;
          break;
        case 'Scenarios':
          route = APP_PLAYBOOK_SCENARIOS;
          break;
        case 'Cadences':
          route = APP_PLAYBOOK_CADENCES;
          break;
        case 'Custom Tasks':
          route = APP_PLAYBOOK_CUSTOM_TASKS;
          break;
        default:
          route = APP_PLAYBOOK_TARGET_MARKET;
      }
      history.push(`${route}`);
    },
    [history],
  );

  const tabsToDisplay: {
    dataTest: string;
    iconLeft: IconType;
    name: string;
    color: ColorType;
    tab: JSX.Element;
  }[] = [
    {
      dataTest: 'playbookTargetMarketTab',
      iconLeft: 'company',
      name: 'Target markets',
      color: 'purple',
      tab: <TargetMarketTab />,
    },
    {
      dataTest: 'playbookBuyerPersonaTab',
      iconLeft: 'person',
      name: 'Buyer personas (ICP)',
      color: 'purple',
      tab: <BuyerPersonaTab />,
    },
    {
      dataTest: 'playbookScenarioTab',
      iconLeft: 'compass',
      name: 'Scenarios',
      color: 'purple',
      tab: <ScenarioTab />,
    },
    canViewCadenceTab && {
      dataTest: 'playbookCadenceTab',
      iconLeft: 'cadence',
      name: 'Cadences',
      color: 'purple',
      tab: <CadenceTab />,
    },
    canViewCustomTaskTab && {
      dataTest: 'playbookCustomTasksTab',
      iconLeft: 'taskAction',
      name: 'Custom Tasks',
      color: 'purple',
      tab: <CustomTaskTab />,
    },
  ];

  if (!isAccountAdmin && !canViewCadences) {
    return <NoPermissionsPage />;
  }

  return (
    <AccountSettingsLayout
      title="Business assets"
      subtitle="Configure your playbook strategy by creating your business assets: target markets, buyer personas, scenarios and cadences"
      actionChildren={
        <div style={{ marginLeft: 12 }}>
          <Button
            variant="clear"
            iconLeft="questionCircle"
            uppercase={false}
            onClick={() => window.open('https://youtu.be/QzrF_9OA_2k', '_blank')}
            color="purple"
          >
            Guide to understand Business assets
          </Button>
        </div>
      }
    >
      {isAccountAdmin ? (
        <TabGroup value={tab} onClick={changeTab}>
          {tabsToDisplay.filter(Boolean).map(({ dataTest, name, iconLeft, color, tab }, idx) => (
            <Tab
              key={name + '-' + idx}
              name={name}
              color={color}
              dataTest={dataTest}
              iconLeft={iconLeft}
            >
              {tab}
            </Tab>
          ))}
        </TabGroup>
      ) : (
        canViewCadences && (
          <TabGroup value={tab} onClick={changeTab}>
            <Tab iconLeft="cadence" name="Cadences" color="purple">
              <CadenceTab />
            </Tab>
          </TabGroup>
        )
      )}
    </AccountSettingsLayout>
  );
};

export default BusinessAssetsPage;
