import useSWR, { SWRResponse } from 'swr';
import { useToasts } from '@bloobirds-it/flamingo-ui';
import { isObject } from 'lodash';
import { api } from '../utils/api';
import { getQuickStartGuideBlocks } from '../pages/homePage/components/quickStartGuide/guideDefinition.constants';
import { useRouter } from './useRouter';
import { APP_TASKS } from '../app/_constants/routes';
import SessionManagerFactory from '../misc/session';
import { useIsAccountAdmin } from './usePermissions';
import { useQuickStartEnabled } from './useQuickStartGuide';
import { getAdminQuickStartGuideBlocks } from '../pages/homePage/components/quickStartGuide/adminGuideDefinition.constants';
import { CustomUserHelperKeys, UserHelperKeys, UserHelperTooltipsKeys } from '@bloobirds-it/types';

export type HelperKey = {
  [key in UserHelperKeys]: string;
};

export interface HelpersResponse {
  helpers: HelperKey;
}

const SessionManager = SessionManagerFactory();

export const useGetUserHelpers = () => {
  const { data }: SWRResponse<HelpersResponse> = useSWR<HelpersResponse>(
    `/utils/helpers/${SessionManager.getUser()?.id}`,
    () => api.get('/utils/users/helpers').then(res => res.data),
  );
  return { helpers: data?.helpers };
};

export const useUserHelpers = () => {
  const { data, mutate }: SWRResponse<HelpersResponse> = useSWR<HelpersResponse>(
    `/utils/helpers/${SessionManager.getUser()?.id}`,
    () => api.get('/utils/users/helpers').then(res => res.data),
  );
  const { createToast } = useToasts();
  const { history } = useRouter();
  const isQuickStartGuideEnabled = useQuickStartEnabled();

  const has = (key: UserHelperKeys | UserHelperTooltipsKeys) => {
    return isObject(data?.helpers) && Object.prototype.hasOwnProperty.call(data?.helpers, key);
  };

  const get = (key: UserHelperKeys | UserHelperTooltipsKeys | CustomUserHelperKeys) => {
    return isObject(data?.helpers) && data?.helpers[key];
  };

  const deleteHelper = (key: UserHelperKeys | UserHelperTooltipsKeys | CustomUserHelperKeys) => {
    return api.delete('utils/users/helpers?helperKeys=' + key);
  };
  const quickStartGuideBlocks = getQuickStartGuideBlocks();
  const adminQuickStartGuideBlocks = getAdminQuickStartGuideBlocks();
  const isAccountAdmin = useIsAccountAdmin();

  function saveCustom(data: { [x: string]: string }) {
    api.post('/utils/users/helpers/custom', data).then(() => mutate());
  }

  const save = (key: UserHelperKeys | UserHelperTooltipsKeys) => {
    if (!has(key)) {
      api.post('/utils/users/helpers/' + key).then(() => {
        // TODO: Filter also by the users that have to see this quick start guide
        const keys = isAccountAdmin
          ? adminQuickStartGuideBlocks.flatMap(guide => guide.goals)
          : quickStartGuideBlocks.flatMap(guide => guide.goals);
        const keySelected = keys.find(k => k.key === key);
        if (keySelected && isQuickStartGuideEnabled) {
          createToast({
            message: 'Goal completed: ' + keySelected?.label,
            type: 'reminder',
            icon: 'check',
            iconColor: 'extraCall',
            onClick: () => history.push(`${APP_TASKS}`),
          });
        }
        mutate();
      });
    }
  };

  const reset = (key?: UserHelperKeys) => {
    api
      .delete(
        '/utils/users/helpers?helperKeys=' +
          (key
            ? key
            : Object.keys(data?.helpers).filter(
                helper => ![/*'COMPLETE_WELCOME_SCREEN'*/ 'delete'].includes(helper),
              )),
      )
      .then(() => mutate());
  };

  return {
    helpers: data?.helpers,
    mutate,
    save,
    saveCustom,
    has,
    get,
    deleteHelper,
    reset,
  };
};
