import {
  useUserSettings,
  useUserSettingsReload,
} from '../../../../../components/userPermissions/hooks';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import React, { useState } from 'react';
import { api } from '../../../../../utils/api';
import {
  AccountSettingsTab,
  AccountSettingsTabContent,
  AccountSettingsTabTitle,
} from '../../../../../layouts/accountSettingsLayout/accountSettingsTab/accountSettingsTab';
import {
  AccountSettingsSectionContent,
  AccountSettingsSectionSubtitle,
  AccountSettingsSectionTitle,
} from '../../../../../layouts/accountSettingsLayout/accountSettingsSection/accountSettingsSection';
import styles from '../../styles/generalSettingsPage.module.css';
import { Button, Checkbox, Icon, Text, useToasts } from '@bloobirds-it/flamingo-ui';
import { AssignmentsTooltip } from '../../../../../components/discoveryTooltips/generalSettingsTourTooltips/assignmentsTooltip';
import { useQuickStartEnabled } from '../../../../../hooks/useQuickStartGuide';

export const AssignationCard = ({ defaultValue }) => {
  const settings = useUserSettings();
  const reloadUserSettings = useUserSettingsReload();
  const [hasChanges, setHasChanges] = useState(false);
  const methods = useForm({ defaultValues: defaultValue });
  const { createToast } = useToasts();

  const hasQSGEnabled = useQuickStartEnabled();

  const handleSave = values => {
    setHasChanges(false);
    api.patch(`/entities/accounts/${settings.account.id}`, values).then(() => {
      reloadUserSettings();
      createToast({ message: 'Account updated successfully!', type: 'success' });
    });
  };

  return (
    <FormProvider {...methods}>
      <AccountSettingsTab>
        <AccountSettingsTabTitle icon="personAdd">
          <div style={{ display: 'flex', height: 24 }}>
            <Text>Assignment</Text>
            {hasQSGEnabled && <AssignmentsTooltip defaultTooltipVisible />}
          </div>
        </AccountSettingsTabTitle>
        <AccountSettingsTabContent />
        <AccountSettingsTabContent>
          <AccountSettingsTab>
            <AccountSettingsSectionTitle>Assignment propagation</AccountSettingsSectionTitle>
            <AccountSettingsSectionSubtitle>
              Here you can decide whether the assignation could be propagated between leads and
              companies.
            </AccountSettingsSectionSubtitle>
            <AccountSettingsSectionContent>
              <div className={styles._checkbox_wrapper}>
                <Controller
                  name="propagateAssignedFromLeadToCompany"
                  render={({ value, onChange }) => (
                    <Checkbox
                      checked={value}
                      onClick={v => {
                        onChange(v);
                        setHasChanges(true);
                      }}
                    >
                      <div>
                        <Text size="s">
                          <Icon name="person" />
                          <Icon name="arrowRight" />
                          <Icon name="company" /> Enable Owner (Assigned To) propagation from a lead
                          to its related company
                        </Text>
                      </div>
                    </Checkbox>
                  )}
                />
              </div>
              <div className={styles._checkbox_wrapper}>
                <Controller
                  name="propagateAssignedFromCompanyToLead"
                  render={({ value, onChange }) => (
                    <Checkbox
                      checked={value}
                      onClick={v => {
                        onChange(v);
                        setHasChanges(true);
                      }}
                    >
                      <div>
                        <Text size="s">
                          <Icon name="company" /> <Icon name="arrowRight" /> <Icon name="people" />{' '}
                          Enable Owner (Assigned To) propagation from a company to its related leads
                        </Text>
                      </div>
                    </Checkbox>
                  )}
                />
              </div>
            </AccountSettingsSectionContent>
          </AccountSettingsTab>
        </AccountSettingsTabContent>
        <AccountSettingsTabContent>
          <Button disabled={!hasChanges} onClick={methods.handleSubmit(handleSave)}>
            SAVE
          </Button>
        </AccountSettingsTabContent>
      </AccountSettingsTab>
    </FormProvider>
  );
};
