import { Button, Icon, Input, Item, Select, Text, useToasts } from '@bloobirds-it/flamingo-ui';
import React, { useState } from 'react';
import { ServiceApi } from '../../../misc/api/service';
import { ColorPicker } from '../../../components/colorPicker/index';
import TimeZoneChangeModal from './timeZoneChangeModal.view';
import styles from './personalDetails.module.css';
import { useTimeZones } from '../../../hooks/useTimeZones';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useUserSettingsReload } from '../../../components/userPermissions/hooks';

const PersonalDetails = ({ userSettings }) => {
  const originalTimeZoneValue = userSettings.timeZone;
  const methods = useForm({ defaultValues: userSettings });
  const { isDirty } = methods.formState;
  const [open, setOpen] = useState(false);
  const timeZones = useTimeZones();
  const { createToast } = useToasts();
  const userSettingsReload = useUserSettingsReload();

  const onSubmit = () => {
    if (methods.getValues().timeZone !== originalTimeZoneValue) {
      setOpen(true);
    } else {
      ServiceApi.request({
        url: '/service/users/me',
        method: 'POST',
        body: { ...methods.getValues() },
      })
        .then(() => {
          createToast({ type: 'success', message: 'Your settings have been updated!' });
          userSettingsReload();
        })
        .catch(() => {
          createToast({
            type: 'error',
            message: 'There was an error saving your personal settings!',
          });
          setOpen(false);
        });
    }
  };

  const timeZoneSubmit = () => {
    ServiceApi.request({
      url: '/service/users/me',
      method: 'POST',
      body: { ...methods.getValues() },
    })
      .then(() => {
        createToast({ type: 'success', message: 'Your settings have been updated!' });
        setOpen(false);
        userSettingsReload();
      })
      .catch(() => {
        createToast({
          type: 'error',
          message: 'There was an error saving your personal settings!',
        });
        setOpen(false);
      });
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <FormProvider {...methods}>
        <div className={styles._container} data-intercom="user-settings-page-personal-details">
          <div className={styles._content}>
            <div className={styles._sectionVertical}>
              <div className={styles._timezone_title}>
                <Text color="softPeanut" size="m">
                  Personal details
                </Text>
              </div>
              <Controller
                name="name"
                render={({ onChange, value }) => (
                  <Input
                    id="personal-details-name-field"
                    width="100%"
                    label="Name"
                    onChange={onChange}
                    value={value}
                    variant="outlined"
                  />
                )}
              />
              <div className={styles._sectionHorizontal}>
                <Controller
                  name="shortname"
                  render={({ onChange, value }) => (
                    <Input onChange={onChange} value={value} label="Shortname" variant="outlined" />
                  )}
                />
                <Controller
                  name="color"
                  render={({ onChange, value }) => (
                    <ColorPicker
                      onChange={onChange}
                      value={value}
                      label="Color"
                      variant="outlined"
                    />
                  )}
                />
              </div>
              <div>
                <div className={styles._timezone_title}>
                  <Text color="softPeanut" size="m">
                    Time Zone
                  </Text>
                  <div>
                    <Icon size="20" name={'infoFilled'} />
                  </div>
                </div>
                <Controller
                  name="timeZone"
                  render={({ onChange, value }) => (
                    <Select
                      onChange={onChange}
                      value={value}
                      width="100%"
                      placeholder="Time Zone"
                      autocomplete
                    >
                      {timeZones?.map(tz => (
                        <Item label={tz.name} key={tz.location} value={tz.location}>
                          {tz.name}
                        </Item>
                      ))}
                    </Select>
                  )}
                />
              </div>
            </div>
            <div>
              <Button
                variant="primary"
                expand
                disabled={!isDirty}
                onClick={methods.handleSubmit(onSubmit)}
              >
                save changes
              </Button>
            </div>
          </div>
        </div>
        <TimeZoneChangeModal
          open={open}
          close={handleClose}
          name={methods.getValues().timeZone}
          save={methods.handleSubmit(timeZoneSubmit)}
        />
      </FormProvider>
    </>
  );
};

export default PersonalDetails;
