import React from 'react';
import { Icon, Text } from '@bloobirds-it/flamingo-ui';
import styles from '../generalSearchBar.module.css';

export function FirstTimeSearch() {
  return (
    <div className={styles.firstTime}>
      <div className={styles.firstTimeTitle}>
        <Text color="peanut" weight={'bold'}>
          Search what you need
        </Text>
      </div>
      <div className={styles.firstTimeBody}>
        <Text color="peanut" size={'m'} className={styles.firstTimeBodyTitle}>
          As precise as you want
        </Text>
        <div className={styles.firstTimeBodyIconText}>
          <Icon name="search" size={24} color="bloobirds" className={styles.firstTimeBodyIcon} />
          <Text color="softPeanut" size={'s'}>
            A normal search will display results that contains the terms searched
          </Text>
        </div>
        {/*
        <div className={styles.firstTimeBodyIconText}>
          <Icon name="quote" size={24} color="bloobirds" className={styles.firstTimeBodyIcon} />
          <Text color="softPeanut" size={'s'}>
            Using quotes on a search will display exact results
          </Text>
        </div>
        */}
        <Text color="peanut" size={'m'} className={styles.firstTimeBodyTitle}>
          Filter for more results
        </Text>
        <div className={styles.firstTimeBodyIconText}>
          <Icon name="filter" size={24} color="bloobirds" className={styles.firstTimeBodyIcon} />
          <Text color="softPeanut" size={'s'}>
            Use filters to focus on a type of result
          </Text>
        </div>
        {/*
          <div className={styles.firstTimeBodyIconText}>
            <Icon name="list1" size={24} color="bloobirds" className={styles.firstTimeBodyIcon} />
            <Text color="softPeanut" size={'s'}>
              “Show more” will display all matching results
            </Text>
          </div>
        */}
      </div>
    </div>
  );
}

export function FirstTimeSearchCompressed() {
  return (
    <div className={styles.firstTime}>
      <div className={styles.firstTimeTitle}>
        <Text color="peanut" weight={'bold'}>
          Search what you need
        </Text>
      </div>
      <div className={styles.firstTimeBody}>
        <Text color="peanut" size={'m'} className={styles.firstTimeBodyTitle}>
          As precise as you want
        </Text>
        <div className={styles.firstTimeBodyIconText}>
          <Icon name="quote" size={24} color="bloobirds" className={styles.firstTimeBodyIcon} />
          <Text color="softPeanut" size={'s'}>
            Using quotes on a search will display exact results
          </Text>
        </div>
        <Text color="peanut" size={'m'} className={styles.firstTimeBodyTitle}>
          Filter for more results
        </Text>
        <div className={styles.firstTimeBodyIconText}>
          <Icon name="filter" size={24} color="bloobirds" className={styles.firstTimeBodyIcon} />
          <Text color="softPeanut" size={'s'}>
            Use filters to focus on a type of result
          </Text>
        </div>
      </div>
    </div>
  );
}
