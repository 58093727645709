import React, { useEffect } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import {
  Icon,
  IconButton,
  Input,
  Item,
  Section,
  Select,
  Text,
  Tooltip,
} from '@bloobirds-it/flamingo-ui';
import { truncate } from 'lodash';
import { useMessagingTemplates } from '../../../../hooks/useMessagingTemplates';
import { TEMPLATE_TYPES } from '../../../../utils/templates.utils';
import { MessagingTemplate } from '../../../../typings/messaging';
import { useActiveUser } from '../../../../hooks';
import { RemindeBeforeType, ReminderBefore, useCalendar } from '../../hooks/useCalendar';
import { ACTIVITY_FIELDS_LOGIC_ROLE } from '../../../../constants/activity';
import styles from './reminderForm.module.css';

export const ReminderForm = () => {
  const {
    reminderTemplate,
    setReminderBefore,
    reminderBefore,
    setReminderTemplate,
    showReminder,
    setShowReminder,
  } = useCalendar();
  const { control, errors, setValue } = useFormContext();
  const { messagingTemplates } = useMessagingTemplates({
    onlyMine: false,
    visibility: null,
    name: null,
    segmentationValues: {},
    type: TEMPLATE_TYPES.EMAIL,
    page: 0,
    size: 200,
  });
  const { activeUser } = useActiveUser();
  const privateTemplates = messagingTemplates.filter(
    (template: MessagingTemplate) => template?.createdBy === activeUser?.id,
  );
  const publicTemplates = messagingTemplates.filter(
    (template: MessagingTemplate) => template.visibility === 'PUBLIC',
  );
  const {
    field: { value: reminderTemplateValue, onChange: templateOnChange },
  } = useController({
    control,
    name: 'reminderTemplate',
    rules: {
      required: !!showReminder,
    },
    defaultValue: reminderTemplate,
  });
  const {
    field: { value: reminderBeforeValue, onChange: reminderBeforeOnChange },
  } = useController({
    control,
    name: 'reminderBefore',
    defaultValue: reminderBefore?.value,
    rules: {
      required: true,
    },
  });
  const {
    field: { value: reminderBeforeTypeValue, onChange: reminderBeforeTypeOnChange },
  } = useController({
    control,
    name: 'reminderBeforeType',
    defaultValue: reminderBefore?.type,
    rules: {
      required: true,
    },
  });
  const errorTemplate = errors && errors['reminderTemplate'] && 'This field is required';

  useEffect(() => {
    if (reminderTemplate && !reminderTemplateValue) {
      setValue('reminderTemplate', reminderTemplate);
    }
  }, [reminderTemplate, reminderTemplateValue]);

  useEffect(() => {
    if (
      messagingTemplates &&
      reminderTemplateValue &&
      !messagingTemplates?.find(template => template?.id === reminderTemplateValue)
    ) {
      setValue('reminderTemplate', null);
    }
  }, [reminderTemplateValue, messagingTemplates]);

  return (
    <div>
      <div className={styles._reminder_container}>
        <div className={styles._title}>
          <Icon name="bell" />
          <Text size="s">Add a notification email</Text>
          <Tooltip
            title="Email notification will be sent from your email to the lead assigned to the meeting"
            position="top"
          >
            <Icon name="infoFilled" size={16} />
          </Tooltip>
        </div>
        <IconButton name="plus" onClick={() => setShowReminder(true)} />
      </div>
      {showReminder && (
        <div className={styles._reminder_form_container}>
          <Select
            borderless={false}
            size="small"
            width="150px"
            error={errorTemplate}
            value={reminderTemplateValue}
            placeholder="Email Template*"
            onChange={templateId => {
              setReminderTemplate(templateId);
              templateOnChange(templateId);
            }}
          >
            <Section id="my-templates">My templates</Section>
            {privateTemplates.map((messagingTemplate: any) => (
              <Item key={messagingTemplate.id} section="my-templates" value={messagingTemplate.id}>
                {truncate(messagingTemplate.name, { length: 32 })}
              </Item>
            ))}
            <Section id="team-templates">Team templates</Section>
            {publicTemplates.map((messagingTemplate: any) => (
              <Item
                key={messagingTemplate.id}
                section="team-templates"
                value={messagingTemplate.id}
              >
                {truncate(messagingTemplate.name, { length: 32 })}
              </Item>
            ))}
          </Select>
          <Input
            size="small"
            type="number"
            width="50px"
            value={reminderBeforeValue}
            onChange={(v: string) => {
              setReminderBefore((prevStatus: ReminderBefore) => {
                return {
                  type: prevStatus.type,
                  value: v,
                };
              });
              reminderBeforeOnChange(v);
            }}
          />
          <Select
            size="small"
            width="60px"
            borderless={false}
            value={reminderBeforeTypeValue}
            onChange={(type: RemindeBeforeType) => {
              setReminderBefore((prevStatus: ReminderBefore) => {
                return {
                  type,
                  value: prevStatus.value,
                };
              });
              reminderBeforeTypeOnChange(type);
            }}
          >
            <Item value={RemindeBeforeType.minutes}>Minutes</Item>
            <Item value={RemindeBeforeType.hours}>Hours</Item>
            <Item value={RemindeBeforeType.days}>Days</Item>
          </Select>
          <IconButton
            name="cross"
            onClick={() => {
              setReminderBefore({
                type: RemindeBeforeType.minutes,
                value: 30,
              });
              setReminderTemplate(null);
              setShowReminder(false);
            }}
          />
        </div>
      )}
    </div>
  );
};
