import {
  COMPANY_FIELDS_LOGIC_ROLE,
  COMPANY_STAGE_LOGIC_ROLE,
  COMPANY_STATUS_LOGIC_ROLE,
} from '../../../constants/company';
import {
  LEAD_FIELDS_LOGIC_ROLE,
  LEAD_STAGE_LOGIC_ROLE,
  LEAD_STATUS_LOGIC_ROLE,
} from '../../../constants/lead';
import { TASK_ACTION, TASK_FIELDS_LOGIC_ROLE } from '../../../constants/task';
import { ACTIVITY_FIELDS_LOGIC_ROLE } from '../../../constants/activity';
import { BOBJECT_TYPES } from '../../../constants/bobject';

export const companyStatusesMinusNurturing = [
  COMPANY_STATUS_LOGIC_ROLE.ON_PROSPECTION,
  COMPANY_STATUS_LOGIC_ROLE.NEW,
  COMPANY_STATUS_LOGIC_ROLE.DISCARDED,
  COMPANY_STATUS_LOGIC_ROLE.DELIVERED,
  COMPANY_STATUS_LOGIC_ROLE.ENGAGED,
  COMPANY_STATUS_LOGIC_ROLE.CONTACTED,
  COMPANY_STATUS_LOGIC_ROLE.MEETING,
  COMPANY_STATUS_LOGIC_ROLE.ON_HOLD,
];

export const leadStatusesMinusNurturing = [
  LEAD_STATUS_LOGIC_ROLE.ON_PROSPECTION,
  LEAD_STATUS_LOGIC_ROLE.NEW,
  LEAD_STATUS_LOGIC_ROLE.DISCARDED,
  LEAD_STATUS_LOGIC_ROLE.DELIVERED,
  LEAD_STATUS_LOGIC_ROLE.ENGAGED,
  LEAD_STATUS_LOGIC_ROLE.CONTACTED,
  LEAD_STATUS_LOGIC_ROLE.MEETING,
  LEAD_STATUS_LOGIC_ROLE.ON_HOLD,
];

export const COMPANY_COLUMNS = [
  COMPANY_FIELDS_LOGIC_ROLE.HIGH_PRIORITY,
  COMPANY_FIELDS_LOGIC_ROLE.NAME,
  COMPANY_FIELDS_LOGIC_ROLE.NUMBER_OF_LEADS,
  COMPANY_FIELDS_LOGIC_ROLE.TARGET_MARKET,
  COMPANY_FIELDS_LOGIC_ROLE.STATUS,
  COMPANY_FIELDS_LOGIC_ROLE.STAGE,
  COMPANY_FIELDS_LOGIC_ROLE.SOURCE,
  COMPANY_FIELDS_LOGIC_ROLE.SALES_STATUS,
  COMPANY_FIELDS_LOGIC_ROLE.ASSIGNED_TO,
  COMPANY_FIELDS_LOGIC_ROLE.MR_RATING,
  COMPANY_FIELDS_LOGIC_ROLE.ASSIGNED_DATE,
  COMPANY_FIELDS_LOGIC_ROLE.UPDATE_DATETIME,
  COMPANY_FIELDS_LOGIC_ROLE.CADENCE,
];

export const LEAD_COLUMNS = [
  LEAD_FIELDS_LOGIC_ROLE.HIGH_PRIORITY,
  LEAD_FIELDS_LOGIC_ROLE.FULL_NAME,
  LEAD_FIELDS_LOGIC_ROLE.NAME,
  LEAD_FIELDS_LOGIC_ROLE.EMAIL,
  LEAD_FIELDS_LOGIC_ROLE.STATUS,
  LEAD_FIELDS_LOGIC_ROLE.SOURCE,
  LEAD_FIELDS_LOGIC_ROLE.STAGE,
  LEAD_FIELDS_LOGIC_ROLE.SALES_STATUS,
  LEAD_FIELDS_LOGIC_ROLE.ASSIGNED_TO,
  LEAD_FIELDS_LOGIC_ROLE.MR_RATING,
  LEAD_FIELDS_LOGIC_ROLE.ASSIGNED_DATE,
  LEAD_FIELDS_LOGIC_ROLE.ICP,
  LEAD_FIELDS_LOGIC_ROLE.COMPANY,
  LEAD_FIELDS_LOGIC_ROLE.UPDATE_DATETIME,
  LEAD_FIELDS_LOGIC_ROLE.ATTEMPTS_LAST_DAY,
  LEAD_FIELDS_LOGIC_ROLE.CADENCE,
];

export const LEAD_REFERENCED_COLUMNS = [
  COMPANY_FIELDS_LOGIC_ROLE.NAME,
  LEAD_FIELDS_LOGIC_ROLE.STAGE,
  COMPANY_FIELDS_LOGIC_ROLE.STATUS,
];

export const TASK_COLUMNS = [
  TASK_FIELDS_LOGIC_ROLE.TITLE,
  TASK_FIELDS_LOGIC_ROLE.STATUS,
  TASK_FIELDS_LOGIC_ROLE.COMPANY,
  TASK_FIELDS_LOGIC_ROLE.LEAD,
  TASK_FIELDS_LOGIC_ROLE.OPPORTUNITY,
  TASK_FIELDS_LOGIC_ROLE.ASSIGNED_TO,
  TASK_FIELDS_LOGIC_ROLE.SCHEDULED_DATE,
  TASK_FIELDS_LOGIC_ROLE.SCHEDULED_DATETIME,
  TASK_FIELDS_LOGIC_ROLE.TASK_TYPE,
  TASK_FIELDS_LOGIC_ROLE.IS_AUTOMATED,
  TASK_FIELDS_LOGIC_ROLE.IS_AUTOMATED_EMAIL,
  TASK_FIELDS_LOGIC_ROLE.DESCRIPTION,
  TASK_ACTION.CALL,
  TASK_ACTION.EMAIL,
  TASK_ACTION.LINKEDIN_MESSAGE,
  TASK_FIELDS_LOGIC_ROLE.CADENCE,
  TASK_FIELDS_LOGIC_ROLE.MEETING_ACTIVITY,
  TASK_FIELDS_LOGIC_ROLE.STEP_ID,
  TASK_FIELDS_LOGIC_ROLE.CUSTOM_TASK,
  TASK_FIELDS_LOGIC_ROLE.SCHEDULED_MODE,
];
export const TASK_REFERENCED_COLUMNS = [
  COMPANY_FIELDS_LOGIC_ROLE.NAME,
  COMPANY_FIELDS_LOGIC_ROLE.EMAIL,
  COMPANY_FIELDS_LOGIC_ROLE.STATUS,
  COMPANY_FIELDS_LOGIC_ROLE.SOURCE,
  COMPANY_FIELDS_LOGIC_ROLE.STAGE,
  COMPANY_FIELDS_LOGIC_ROLE.TIME_ZONE,
  COMPANY_FIELDS_LOGIC_ROLE.COUNTRY,
  COMPANY_FIELDS_LOGIC_ROLE.CADENCE,
  COMPANY_FIELDS_LOGIC_ROLE.ATTEMPTS_LAST_DAY,
  COMPANY_FIELDS_LOGIC_ROLE.HIGH_PRIORITY,
  COMPANY_FIELDS_LOGIC_ROLE.NURTURING_REASONS,
  COMPANY_FIELDS_LOGIC_ROLE.LINKEDIN_URL,
  LEAD_FIELDS_LOGIC_ROLE.FULL_NAME,
  LEAD_FIELDS_LOGIC_ROLE.EMAIL,
  LEAD_FIELDS_LOGIC_ROLE.STATUS,
  LEAD_FIELDS_LOGIC_ROLE.SOURCE,
  LEAD_FIELDS_LOGIC_ROLE.STAGE,
  LEAD_FIELDS_LOGIC_ROLE.CADENCE,
  LEAD_FIELDS_LOGIC_ROLE.ATTEMPTS_LAST_DAY,
  LEAD_FIELDS_LOGIC_ROLE.HIGH_PRIORITY,
  LEAD_FIELDS_LOGIC_ROLE.NURTURING_REASONS,
  LEAD_FIELDS_LOGIC_ROLE.LINKEDIN_URL,
  ACTIVITY_FIELDS_LOGIC_ROLE.TIME,
];

//TODO this could probably be simplified
export const subqueriesByBobjectType = {
  [BOBJECT_TYPES.COMPANY]: {
    [TASK_FIELDS_LOGIC_ROLE.COMPANY]: {
      query: {
        [COMPANY_FIELDS_LOGIC_ROLE.STAGE]: [
          '__MATCH_EMPTY_ROWS__',
          COMPANY_STAGE_LOGIC_ROLE.PROSPECT,
        ],
        [COMPANY_FIELDS_LOGIC_ROLE.CREATION_DATETIME]: ['__MATCH_FULL_ROWS__'],
      },
      searchMode: 'SUBQUERY__SEARCH',
    },
    [TASK_FIELDS_LOGIC_ROLE.LEAD]: ['__MATCH_EMPTY_ROWS__'],
  },
  [BOBJECT_TYPES.LEAD]: {
    [TASK_FIELDS_LOGIC_ROLE.LEAD]: {
      query: {
        [LEAD_FIELDS_LOGIC_ROLE.CREATION_DATETIME]: ['__MATCH_FULL_ROWS__'],
        [LEAD_FIELDS_LOGIC_ROLE.STAGE]: ['__MATCH_EMPTY_ROWS__', LEAD_STAGE_LOGIC_ROLE.PROSPECT],
      },
      searchMode: 'SUBQUERY__SEARCH',
    },
  },
};

export const getSubqueriesByBobjectType = (hasNurturingFlag: boolean) => ({
  [BOBJECT_TYPES.COMPANY]: {
    [TASK_FIELDS_LOGIC_ROLE.COMPANY]: {
      query: {
        [COMPANY_FIELDS_LOGIC_ROLE.STAGE]: [
          '__MATCH_EMPTY_ROWS__',
          COMPANY_STAGE_LOGIC_ROLE.PROSPECT,
        ],
        [COMPANY_FIELDS_LOGIC_ROLE.CREATION_DATETIME]: ['__MATCH_FULL_ROWS__'],
        ...(hasNurturingFlag
          ? { [COMPANY_FIELDS_LOGIC_ROLE.STATUS]: companyStatusesMinusNurturing }
          : {}),
      },
      searchMode: 'SUBQUERY__SEARCH',
    },
    [TASK_FIELDS_LOGIC_ROLE.LEAD]: ['__MATCH_EMPTY_ROWS__'],
  },
  [BOBJECT_TYPES.LEAD]: {
    [TASK_FIELDS_LOGIC_ROLE.LEAD]: {
      query: {
        [LEAD_FIELDS_LOGIC_ROLE.CREATION_DATETIME]: ['__MATCH_FULL_ROWS__'],
        [LEAD_FIELDS_LOGIC_ROLE.STAGE]: ['__MATCH_EMPTY_ROWS__', LEAD_STAGE_LOGIC_ROLE.PROSPECT],
        ...(hasNurturingFlag
          ? { [LEAD_FIELDS_LOGIC_ROLE.STATUS]: leadStatusesMinusNurturing }
          : {}),
      },
      searchMode: 'SUBQUERY__SEARCH',
    },
  },
});

export const DEFAULT_SUBQUERY = [
  {
    [TASK_FIELDS_LOGIC_ROLE.COMPANY]: {
      query: {
        [COMPANY_FIELDS_LOGIC_ROLE.STAGE]: [
          '__MATCH_EMPTY_ROWS__',
          COMPANY_STAGE_LOGIC_ROLE.PROSPECT,
        ],
        [COMPANY_FIELDS_LOGIC_ROLE.CREATION_DATETIME]: ['__MATCH_FULL_ROWS__'],
      },
      searchMode: 'SUBQUERY__SEARCH',
    },
    [TASK_FIELDS_LOGIC_ROLE.LEAD]: ['__MATCH_EMPTY_ROWS__'],
  },
  {
    [TASK_FIELDS_LOGIC_ROLE.LEAD]: {
      query: {
        [LEAD_FIELDS_LOGIC_ROLE.CREATION_DATETIME]: ['__MATCH_FULL_ROWS__'],
        [LEAD_FIELDS_LOGIC_ROLE.STAGE]: ['__MATCH_EMPTY_ROWS__', LEAD_STAGE_LOGIC_ROLE.PROSPECT],
      },
      searchMode: 'SUBQUERY__SEARCH',
    },
  },
];
