import React from 'react';
import { Text } from '@bloobirds-it/flamingo-ui';
import styles from './messagingTab.module.css';
import { toTitleCase } from '../../../../../utils/strings.utils';
import EmailButton from '../../../../../components/emailButton';
import { TEMPLATE_TYPES } from '../../../../../utils/templates.utils';
import MessagingTemplateCollection from '../../../../../layouts/messagingSectionLayout/messagingTemplateCollection/messagingTemplateCollection.container';
import BannerPlaybook from './bannerPlaybook';
import MessagingSearchBar from '../../../../../components/messagingTemplates/messagingSearchBar/messagingSearchBar';
import MessagingMineSwitch from '../../../../../components/messagingTemplates/messagingMineSwitch/messagingMineSwitch';
import { TYPES } from '../../../../../constants/templates';
import MessagingVisibilitySwitch from '../../../../../components/messagingTemplates/messagingVisibilitySwitch/messagingVisibilitySwitch';
import MessagingOfficialFilterSwitch from '../../../../../components/messagingTemplates/messagingOfficialFilterSwitch/messagingOfficialFilterSwitch';
import MessagingBattlecardsFilterSwitch from '../../../../../components/messagingTemplates/messagingBattlecardsFilterSwitch/messagingBattlecardsFilterSwitch';

const MessagingTab = ({ type }) => (
  <div>
    <BannerPlaybook />
    <header className={styles.header}>
      <Text className={styles.title} htmlTag="h4" size="l" color="peanut">
        {toTitleCase(type)} templates
      </Text>
      <div className={styles.actions}>
        <div className={styles.switches}>
          <MessagingMineSwitch />
          <MessagingOfficialFilterSwitch />
          {type === TYPES.PITCH && <MessagingBattlecardsFilterSwitch />}
          <MessagingVisibilitySwitch />
        </div>
        <MessagingSearchBar placeholder="Search" />
      </div>
      {type === TYPES.EMAIL && (
        <div className={styles.emailButton}>
          <EmailButton isFromBB templateBody="" isBlankEmail />
        </div>
      )}
    </header>
    <MessagingTemplateCollection type="CONTACT_VIEW" templateType={TEMPLATE_TYPES[type]} />
  </div>
);

export default MessagingTab;
