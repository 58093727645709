// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".messagingTemplateCollection-module___container___utYPt {\n  width: 100%;\n  max-width: 1280px;\n}\n\n@media (max-width: 1680px) {\n  .messagingTemplateCollection-module___container___utYPt {\n    width: 100%;\n    max-width: none;\n  }\n}\n", "",{"version":3,"sources":["webpack://./src/js/layouts/messagingSectionLayout/messagingTemplateCollection/messagingTemplateCollection.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,iBAAiB;AACnB;;AAEA;EACE;IACE,WAAW;IACX,eAAe;EACjB;AACF","sourcesContent":["._container {\n  width: 100%;\n  max-width: 1280px;\n}\n\n@media (max-width: 1680px) {\n  ._container {\n    width: 100%;\n    max-width: none;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"_container": "messagingTemplateCollection-module___container___utYPt"
};
export default ___CSS_LOADER_EXPORT___;
