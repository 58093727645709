import React from 'react';
import { useAggregationSubscription } from '@bloobirds-it/plover';
import { TIME_WINDOW } from '../typings/home';
import {
  ACTIVITY_FIELDS_LOGIC_ROLE,
  ACTIVITY_TYPES_VALUES_LOGIC_ROLE,
  DIRECTION_VALUES_LOGIC_ROLE,
} from '../../../constants/activity';
import { getRangeByType } from '../../dashboardPages/utils/getDashboardTimeRange';
import { BOBJECT_TYPES } from '../../../constants/bobject';
import { aggregationToStackedChartData } from '../../../utils/aggregations.utils';

export const useTeamActivitiesChart = (timeWindow: TIME_WINDOW) => {
  const query = getRangeByType(timeWindow);

  const activitiesRequest = React.useMemo(
    () => ({
      query: {
        [ACTIVITY_FIELDS_LOGIC_ROLE.TIME]: {
          query: {
            gte: query?.start,
            lte: query?.end,
          },
          searchMode: 'RANGE__SEARCH',
        },
        [ACTIVITY_FIELDS_LOGIC_ROLE.TYPE]: [
          ACTIVITY_TYPES_VALUES_LOGIC_ROLE.CALL,
          ACTIVITY_TYPES_VALUES_LOGIC_ROLE.EMAIL,
          ACTIVITY_TYPES_VALUES_LOGIC_ROLE.LINKEDIN,
        ],
        [ACTIVITY_FIELDS_LOGIC_ROLE.DIRECTION]: [DIRECTION_VALUES_LOGIC_ROLE.OUTGOING],
      },
      aggregations: [ACTIVITY_FIELDS_LOGIC_ROLE.USER, ACTIVITY_FIELDS_LOGIC_ROLE.TYPE],
      formFields: true,
      page: 0,
      pageSize: 5000,
    }),
    [timeWindow],
  );
  const { data } = useAggregationSubscription(activitiesRequest, BOBJECT_TYPES.ACTIVITY);

  return {
    chartData: aggregationToStackedChartData({ data: data?.data?.contents }),
    data: data?.data?.contents,
    values: data?.data?.contents?.flatMap((x: { fieldDataList: any }) => x.fieldDataList),
  };
};
