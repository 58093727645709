import React, { useEffect, useState } from 'react';

import { CheckboxListItem } from './checkboxListItem';
import { Text } from '@bloobirds-it/flamingo-ui';
import styles from '../../styles/usersPage.module.css';
import { useNewCadenceTableEnabled } from '../../../../../hooks/useFeatureFlags';

export const PermissionsModalSection = ({
  sections,
  sectionTitle,
  selectAllHandle,
  value: values,
  updateValue,
}: {
  sections: any;
  sectionTitle: string;
  selectAllHandle?: string;
  value: string[];
  updateValue: (value: string[]) => void;
}) => {
  const entriesIds = sections && Object.entries(sections)?.map(item => item[1]?.id);
  const [selectAll, setSelectAll] = useState(values?.length === entriesIds?.length);
  const isNewCadenceTableEnabled = useNewCadenceTableEnabled();

  useEffect(() => {
    setSelectAll(values?.length === entriesIds?.length);
  }, [values]);

  const sectionNames = Object.freeze({
    EDIT_ALL: 'Edit all',
    DOWNLOAD_LIST: 'Download lists',
    VIEW_INBOUND_TAB: 'Inbound tab',
    VIEW_INBOX: 'Inbox tab',
    VIEW_OUTBOX_TAB: 'Outbox tab',
    VIEW_ASSIGN_TAB: 'Assign tab',
    VIEW_PROSPECT_TAB: 'Prospect tab',
    VIEW_SALES_TAB: 'Sales tab',
    VIEW_DASHBOARDS_TAB: 'Dashboards',
    VIEW_ADD_QC_TAB: 'Add QC tab',
    VIEW_CADENCES: isNewCadenceTableEnabled ? 'Create cadences and custom tasks' : 'Cadences tab',
    BULK_ACTIONS: 'Bulk Actions',
  });

  return (
    <div className={styles._section}>
      <div className={styles._last_section}>
        <div className={styles._section_header}>
          <Text size="m" color="peanut" weight="bold">
            {sectionTitle}
          </Text>
          <Text size="m" color="peanut" weight="bold">
            Allow
          </Text>
        </div>
        {selectAllHandle && (
          <CheckboxListItem
            size="small"
            value={selectAll}
            onChange={value => {
              updateValue(
                value
                  ? Object.entries(sections)?.map(field => {
                      return field[1]?.id;
                    })
                  : [],
              );
            }}
          >
            {selectAllHandle}
          </CheckboxListItem>
        )}
        {sections?.map((option: any) => {
          const fieldId = option?.id;
          return (
            <CheckboxListItem
              size="small"
              key={fieldId}
              value={values?.includes(fieldId)}
              onChange={value => {
                updateValue(
                  value && !values?.includes(fieldId)
                    ? [...values, fieldId]
                    : values.filter(v => v !== fieldId),
                );
              }}
            >
              {sectionNames[option?.enumName]}
            </CheckboxListItem>
          );
        })}
      </div>
    </div>
  );
};
