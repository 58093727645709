// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".reminderForm-module___reminder_container___YtAdd {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  margin-bottom: 8px;\n}\n\n.reminderForm-module___title___u88xD {\n  display: flex;\n  align-items: center;\n}\n\n.reminderForm-module___title___u88xD > * {\n  margin-right: 4px;\n}\n\n.reminderForm-module___reminder_form_container___5sFZS {\n  display: flex;\n  align-items: flex-start;\n}\n\n.reminderForm-module___reminder_form_container___5sFZS > * {\n  margin-right: 6px;\n}\n\n.reminderForm-module___reminder_form_container___5sFZS > *:last-child {\n  margin-right: 0;\n}\n", "",{"version":3,"sources":["webpack://./src/js/components/newMeetingModal/components/reminderForm/reminderForm.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,8BAA8B;EAC9B,kBAAkB;AACpB;;AAEA;EACE,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,aAAa;EACb,uBAAuB;AACzB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,eAAe;AACjB","sourcesContent":["._reminder_container {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  margin-bottom: 8px;\n}\n\n._title {\n  display: flex;\n  align-items: center;\n}\n\n._title > * {\n  margin-right: 4px;\n}\n\n._reminder_form_container {\n  display: flex;\n  align-items: flex-start;\n}\n\n._reminder_form_container > * {\n  margin-right: 6px;\n}\n\n._reminder_form_container > *:last-child {\n  margin-right: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"_reminder_container": "reminderForm-module___reminder_container___YtAdd",
	"_title": "reminderForm-module___title___u88xD",
	"_reminder_form_container": "reminderForm-module___reminder_form_container___5sFZS"
};
export default ___CSS_LOADER_EXPORT___;
