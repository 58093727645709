import { atom, useRecoilState, useResetRecoilState } from 'recoil';
import useSWR, { SWRResponse } from 'swr';
import spacetime from 'spacetime';
import React, { useEffect, useMemo } from 'react';
import { useSearchSubscription } from '@bloobirds-it/plover';
import { ColorType } from '@bloobirds-it/flamingo-ui';
import { recoilPersist } from 'recoil-persist';
import { EventsType, Invitee } from '../components/calendar/calendar';
import { api } from '../../../utils/api';
import { keepPreviousResponse } from '../../../utils/swr.utils';
import { getUserTimeZone } from '../../../utils/dates.utils';
import { ACTIVITY_FIELDS_LOGIC_ROLE, ACTIVITY_TYPES } from '../../../constants/activity';
import { BOBJECT_TYPES } from '../../../constants/bobject';
import { injectReferencesSearchProcess } from '../../../misc/api/bobject';
import { useActiveUser, useEmailConnections, useEntity } from '../../../hooks';
import { parseEvents } from '../utils/calendar.utils';

const { persistAtom } = recoilPersist();

const primaryCalendarColor: ColorType = 'verySoftBloobirds';

const primaryBarColor: ColorType = 'softBloobirds';

const colorsPalette: ColorType[] = [
  'softTomato',
  'softBanana',
  'softGrape',
  'softGray',
  'softTangerine',
  'lightestCall',
];

const secondaryColorsPalette: ColorType[] = [
  'tomato',
  'banana',
  'grape',
  'gray',
  'tangerine',
  'extraCall',
];

export interface CalendarData {
  id: string;
  name: string;
  description?: any;
  location?: any;
  timezone: string;
  readOnly: boolean;
  primary: boolean;
  accountId: string;
  jobStatusId?: any;
}

export enum RemindeBeforeType {
  minutes = 'minutes',
  hours = 'hours',
  days = 'days',
}

export interface ReminderBefore {
  type: RemindeBeforeType;
  value: number;
}

const calendarSelectedAtom = atom({
  key: 'calendarSelectedAtom',
  default: null,
  effects: [persistAtom],
});

const accountSelectedAtom = atom({
  key: 'accountSelectedAtom',
  default: null,
  effects: [persistAtom],
});

const eventsTypeSelectedAtom = atom({
  key: 'eventsTypeSelectedAtom',
  default: 'nylas' as EventsType,
  effects: [persistAtom],
});

const inviteesAtom = atom({
  key: 'inviteesAtom',
  default: [],
});

const dateSelectedAtom = atom({
  key: 'daySelectedAtom',
  default: new Date(),
});

const selectedTimezoneAtom = atom({
  key: 'selectedTimezoneAtom',
  default: getUserTimeZone(),
});

// These are the users selected when seeing bloobirds events
const usersSelectedAtom = atom({
  key: 'usersSelectedAtom',
  default: [],
  effects: [persistAtom],
});

// These are the account executives selected when seeing bloobirds events
const accountExecutivesSelectedAtom = atom({
  key: 'accountExecutivesSelectedAtom',
  default: [],
  effects: [persistAtom],
});

const skipEventCalendarCreationAtom = atom({
  key: 'skipEventCalendarCreationAtom',
  default: false,
  effects: [persistAtom],
});

const loadingAtom = atom({
  key: 'loadingMeetingModalAtom',
  default: false,
});

// In case this is an update, we will ban this event in the calendar so we only show the new placeholder
const bannedEventAtom = atom({
  key: 'bannedEventAtom',
  default: null,
});

const showReminderAtom = atom({
  key: 'showReminderAtom',
  default: false,
  effects: [persistAtom],
});

const reminderTemplateAtom = atom({
  key: 'reminderTemplateAtom',
  default: null,
  effects: [persistAtom],
});

const reminderBeforeAtom = atom({
  key: 'reminderBeforeAtom',
  default: {
    type: RemindeBeforeType.minutes,
    value: 30,
  } as ReminderBefore,
  effects: [persistAtom],
});

const conferencingInGoogleMeetAtom = atom({
  key: 'conferencingGoogleMeet',
  default: true,
  effects: [persistAtom],
});

const meetingDurationAtom = atom({
  key: 'meetingDurationAtomIncalendar',
  default: 60,
  effects: [persistAtom],
});

export const useCalendar = () => {
  const [calendarSelected, setSelectedCalendar] = useRecoilState(calendarSelectedAtom);
  const [accountSelected, setAccountSelected] = useRecoilState(accountSelectedAtom);
  const [eventsTypeSelected, setEventTypesSelected] = useRecoilState<EventsType>(
    eventsTypeSelectedAtom,
  );
  const [invitees, setInvitees] = useRecoilState<Invitee[]>(inviteesAtom);
  const [selectedTimezone, setSelectedTimezone] = useRecoilState(selectedTimezoneAtom);
  const [date, setDate] = useRecoilState<Date>(dateSelectedAtom);
  const [usersSelected, setUsersSelected] = useRecoilState(usersSelectedAtom);
  const [skipCalendarCreation, setSkipCalendarCreation] = useRecoilState(
    skipEventCalendarCreationAtom,
  );
  const [loading, setLoading] = useRecoilState(loadingAtom);
  const [bannedEvent, setBannedEvent] = useRecoilState(bannedEventAtom);
  const resetBannedAtom = useResetRecoilState(bannedEventAtom);
  const resetInvitees = useResetRecoilState(inviteesAtom);
  const resetDate = useResetRecoilState(dateSelectedAtom);
  const [reminderTemplate, setReminderTemplate] = useRecoilState(reminderTemplateAtom);
  const [reminderBefore, setReminderBefore] = useRecoilState(reminderBeforeAtom);
  const [showReminder, setShowReminder] = useRecoilState(showReminderAtom);
  const [meetingDuration, setMeetingDuration] = useRecoilState(meetingDurationAtom);
  const [conferencingGoogleMeet, setConferencingGoogleMeet] = useRecoilState(
    conferencingInGoogleMeetAtom,
  );
  useEffect(
    () => () => {
      resetBannedAtom();
    },
    [],
  );
  useEffect(() => {
    if (activeUser && usersSelected?.length === 0) {
      setUsersSelected([activeUser?.id]);
    }
  }, []);
  const [accountExecutivesSelected, setAccountExecutivesSelected] = useRecoilState(
    accountExecutivesSelectedAtom,
  );
  const { activeUser } = useActiveUser();
  const users = useEntity('users');
  const { connections } = useEmailConnections();

  const calendarIdsSelected = calendarSelected?.join(',');
  const {
    data: { data: events } = {},
    isValidating: isNylasValidating,
    error: eventsError,
  } = useSWR(
    `/messaging/calendar/events?start=${spacetime(date)
      .startOf('week')
      .format('iso-utc')}&end=${spacetime(date)
      .endOf('week')
      .format('iso-utc')}&calendar=${calendarIdsSelected}${
      accountSelected ? '&account=' + accountSelected : ''
    }`,
    url => api.get(url),
    { use: [keepPreviousResponse] },
  );
  const { data: calendarsAvailable, mutate: mutateCalendars, error: calendarsError } = useSWR<
    SWRResponse<CalendarData[]>
  >(`/messaging/calendar${accountSelected ? '?account=' + accountSelected : ''}`, url =>
    api.get(url),
  );

  useEffect(() => {
    if (eventsError || calendarsError) {
      setAccountSelected(null);
      setSelectedCalendar(null);
    }
  }, [eventsError, calendarsError]);

  useEffect(() => {
    calendarSelected?.forEach((calendarId: string) => {
      if (
        calendarsAvailable?.data?.length > 0 &&
        !calendarsAvailable?.data?.find(c => c?.id === calendarId)
      ) {
        setSelectedCalendar((curr: any) => curr?.filter((c: string) => c !== calendarId));
      }
    });
  }, [calendarsAvailable]);

  const meetingsRequest = React.useMemo(
    eventsTypeSelected === 'bloobirds'
      ? () => {
          const queries = [];
          if (usersSelected) {
            queries.push({
              [ACTIVITY_FIELDS_LOGIC_ROLE.USER]: usersSelected,
            });
            queries.push({
              [ACTIVITY_FIELDS_LOGIC_ROLE.MEETING_ASSIGNED_TO]: usersSelected,
            });
          }
          if (accountExecutivesSelected && accountExecutivesSelected?.length > 0) {
            queries.push({
              [ACTIVITY_FIELDS_LOGIC_ROLE.ACCOUNT_EXECUTIVE]: accountExecutivesSelected,
            });
          }
          return {
            query: {
              [ACTIVITY_FIELDS_LOGIC_ROLE.TIME]: {
                query: {
                  gte: spacetime(date).startOf('week').format('iso-utc'),
                  lte: spacetime(date).endOf('week').format('iso-utc'),
                },
                searchMode: 'RANGE__SEARCH',
              },
              [ACTIVITY_FIELDS_LOGIC_ROLE.TYPE]: ACTIVITY_TYPES.MEETING,
            },
            queries,
            formFields: true,
            page: 0,
            pageSize: 5000,
            injectReferences: true,
          };
        }
      : () => null,
    [date, usersSelected, accountExecutivesSelected, eventsTypeSelected],
  );
  const { data, isValidating: isBloobirdsValidating } = useSearchSubscription(
    meetingsRequest,
    BOBJECT_TYPES.ACTIVITY,
  );
  const eventsWithReferences = data ? injectReferencesSearchProcess(data?.data)?.contents : null;

  const calendarsWithColor = calendarsAvailable?.data?.map((calendar, index) => {
    if (calendar?.primary) {
      return {
        calendarId: calendar?.id,
        color: primaryCalendarColor,
        barColor: primaryBarColor,
      };
    } else {
      return {
        calendarId: calendar?.id,
        color: colorsPalette[index % colorsPalette?.length],
        barColor: secondaryColorsPalette[index % colorsPalette?.length],
      };
    }
  });

  const eventsPerDay = useMemo(() => {
    return parseEvents(
      eventsTypeSelected === 'nylas' ? events : eventsWithReferences,
      eventsTypeSelected,
      users,
      selectedTimezone,
      calendarsWithColor,
      bannedEvent,
    );
  }, [events?.length, eventsWithReferences?.length, bannedEvent]);

  useEffect(() => {
    if (!accountSelected && connections && connections?.list?.length > 0) {
      setAccountSelected(
        connections?.list?.find((connection: any) => connection?.default)?.id ||
          connections?.list[0]?.id,
      );
    }
  }, [connections]);

  useEffect(() => {
    if (!calendarSelected && calendarsAvailable && calendarsAvailable?.data?.length > 0) {
      setSelectedCalendar([calendarsAvailable?.data?.find(c => c.primary)?.id]);
    }
  }, [calendarsAvailable]);

  useEffect(() => {
    eventsTypeSelected === 'nylas'
      ? setLoading(isNylasValidating)
      : setLoading(isBloobirdsValidating);
  }, [eventsTypeSelected, isNylasValidating, isBloobirdsValidating]);

  return {
    calendarSelected,
    setSelectedCalendar,
    accountSelected,
    setAccountSelected: (value: string) => {
      setSelectedCalendar(undefined);
      setAccountSelected(value);
    },
    eventsTypeSelected,
    setEventTypesSelected,
    invitees,
    setInvitees,
    resetInvitees,
    date,
    setDate,
    resetDate,
    selectedTimezone,
    setSelectedTimezone,
    usersSelected,
    setUsersSelected,
    accountExecutivesSelected,
    setAccountExecutivesSelected,
    skipCalendarCreation,
    setSkipCalendarCreation,
    bannedEvent,
    setBannedEvent,
    reminderBefore,
    setReminderBefore,
    reminderTemplate,
    setReminderTemplate,
    showReminder,
    setShowReminder,
    conferencingGoogleMeet,
    setConferencingGoogleMeet,
    meetingDuration,
    setMeetingDuration,
    calendarsAvailable,
    mutateCalendars,
    events,
    eventsPerDay,
    calendarsWithColor,
    isNylasValidating,
    isBloobirdsValidating,
    loading,
  };
};
