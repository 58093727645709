import {
  Button,
  Dropdown,
  Icon,
  IconButton,
  Item,
  Switch,
  Text,
  Tooltip,
  useToasts,
  useVisible,
} from '@bloobirds-it/flamingo-ui';
import React, { useEffect, useState } from 'react';
import styles from '../cadenceList.module.css';
import spacetime from 'spacetime';
import { useBobjectTypes } from '../../../../../hooks/useBobjectTypes';
import SessionManagerFactory from '../../../../../misc/session';
import { CADENCE_EDIT_PERMISSIONS } from '../../createEditCadenceSettings/createEditCadenceSettings';
import { api } from '../../../../../utils/api';
import { ConfirmDeleteModalLayout } from '../../../../../layouts/confirmDeleteModalLayout/confirmDeleteModalLayout';
import { EntityCardItem } from '../../../../../components/entityList/entityCard/entityCard';
import { APP_CADENCES_ANALYZE, cadenceEditUrl, cadenceEditUrlV2 } from '@bloobirds-it/types';
import { useCadenceV2Enabled, useRouter } from '@bloobirds-it/hooks';
import { useManageCadenceList } from '../useManageCadenceList';
import { useGlobalPicklistValues } from '../../../../../hooks/usePicklistValues';

export const CadenceCard = ({
  cadence,
  handleRefresh,
  setCadenceToClone,
  openCloneModal,
}: {
  cadence: any;
  handleRefresh: () => void;
  setCadenceToClone: (x: any) => void;
  openCloneModal: () => void;
}) => {
  const bobjectTypes = useBobjectTypes();
  const bobjectType = bobjectTypes?.get(cadence.bobjectType);
  const { delete: deleteCadence, getUsesCadence, markAsDefault } = useManageCadenceList();
  const [isDeleting, setIsDeleting] = useState(false);
  const [isEnabled, setIsEnabled] = useState(cadence?.enabled);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [numberOfUses, setNumberOfUses] = useState<number>();
  const { createToast } = useToasts();
  const SessionManager = SessionManagerFactory();
  const isDefaultCadence = cadence.defaultCadence;
  const [infoDefaultCadence, setInfoDefaultCadence] = useState(isDefaultCadence);
  const { history } = useRouter();
  const { ref, visible, setVisible } = useVisible(false);
  const cadenceVisibilityLabel =
    cadence?.editMode === CADENCE_EDIT_PERMISSIONS.EVERYONE ? 'Public' : 'Private';
  const canEditCadence =
    SessionManager.getRoleManager()?.isAccountAdmin() ||
    SessionManager.getUser()?.id === cadence?.owner ||
    cadence?.editMode === CADENCE_EDIT_PERMISSIONS.EVERYONE;
  const cadenceV2Enabled = useCadenceV2Enabled(SessionManager.getAccount()?.id);
  const bobjectTypesInterface = useBobjectTypes();
  const cadenceBobjectType = bobjectTypesInterface?.findBy('id')(cadence?.bobjectType)?.name;
  const users = useGlobalPicklistValues({
    logicRole: 'USER',
  })?.filter(user => user.enabled);
  const cadenceAuthor = users.filter(u => u.id === cadence?.ownerId)[0]?.value;

  useEffect(() => {
    setInfoDefaultCadence(isDefaultCadence);
  }, [isDefaultCadence]);

  useEffect(() => {
    setIsEnabled(cadence?.enabled);
  }, [cadence]);

  const handleToggleEnabled = async () => {
    await api.put(`/messaging/cadences/${cadence.id}/${isEnabled ? 'disable' : 'enable'}`);
    setIsEnabled(!isEnabled);
  };

  const handleDeleteCadence = () => {
    setIsDeleting(true);
    deleteCadence(cadence.id).then(() => {
      createToast({ message: 'Cadence succesfully deleted!', type: 'success' });
      setIsDeleting(false);
      setConfirmModalOpen(false);
      handleRefresh();
    });
  };

  const markCadenceAsDefault = () => {
    markAsDefault(cadence.id).then(() => {
      handleRefresh();
    });
  };

  const handleDelete = () => {
    getUsesCadence(cadence.id, bobjectType?.name).then(r => {
      setNumberOfUses(r);
      setConfirmModalOpen(true);
    });
  };

  return (
    <>
      {confirmModalOpen && (
        <ConfirmDeleteModalLayout
          icon="company"
          assetLabel="Cadence"
          isDeleting={isDeleting}
          disable={numberOfUses > 0}
          handleDelete={handleDeleteCadence}
          handleClose={() => setConfirmModalOpen(false)}
          colorSchema={{
            verySoft: 'veryLightBloobirds',
            light: 'softBloobirds',
          }}
        >
          {numberOfUses === 0 && (
            <div className={styles.confirmation_body}>
              <Text size="s" className={styles.confirmation_info}>
                You are going to delete permanently the Cadence &quot;{cadence?.name}&quot;
              </Text>
              <Text size="s" weight="bold">
                Are you sure you want to continue?
              </Text>
            </div>
          )}
          {numberOfUses > 0 && (
            <div className={styles.confirmation_body}>
              <Text size="s" className={styles.confirmation_info}>
                &quot;{cadence?.name}&quot; cadence is being used in {numberOfUses}{' '}
                {bobjectType?.name}
              </Text>
              <Text size="s" weight="bold">
                If you want to delete the cadence, reassign them to another cadence.
              </Text>
            </div>
          )}
        </ConfirmDeleteModalLayout>
      )}
      <EntityCardItem size="medium">
        <div
          style={{ cursor: 'pointer' }}
          onClick={() =>
            history.push(
              `${APP_CADENCES_ANALYZE}?id=${cadence.id}&name=${cadence.name}&bobjectType=${bobjectType?.name}`,
            )
          }
        >
          <Text size="s" color="bloobirds" weight="bold">
            {cadence?.name}
          </Text>
        </div>
      </EntityCardItem>
      <EntityCardItem>
        <Tooltip title="Default Cadence" position="top">
          {infoDefaultCadence && <Icon name="starChecked" color="softBanana" size={16} />}
        </Tooltip>
      </EntityCardItem>
      <EntityCardItem>{cadenceBobjectType}</EntityCardItem>
      {/*<EntityCardItem>{cadence?.statistics.prospectCount}</EntityCardItem>
      <EntityCardItem>{cadence?.statistics.activeCount}</EntityCardItem>*/}
      <EntityCardItem>{cadence?.statistics?.totalDays}</EntityCardItem>
      <EntityCardItem>{cadence?.statistics?.totalSteps}</EntityCardItem>
      <EntityCardItem>
        {Math.round(cadence?.statistics?.automatedPercentage * 100 || 0)}%
      </EntityCardItem>
      <EntityCardItem>{cadence?.statistics.optOutCount}</EntityCardItem>
      <EntityCardItem>
        {cadence?.creationDatetime
          ? spacetime(cadence?.creationDatetime).format('nice-short')
          : '-'}
      </EntityCardItem>
      <EntityCardItem>{cadenceAuthor ? cadenceAuthor : '[userName](deleted)'}</EntityCardItem>
      <EntityCardItem>{cadenceVisibilityLabel}</EntityCardItem>
      <EntityCardItem size="small">
        <span className={styles._status__span}>
          <Tooltip
            title={isDefaultCadence ? 'The default cadence can not be disabled' : ''}
            position="top"
          >
            <Switch
              checked={isEnabled}
              disabled={isDefaultCadence}
              color="bloobirds"
              onChange={handleToggleEnabled}
            />
          </Tooltip>
          <Button
            color="bloobirds"
            variant="secondary"
            size="small"
            iconRight={canEditCadence ? 'edit' : 'eye'}
            onClick={() => {
              history.push(
                `${
                  cadenceV2Enabled ? cadenceEditUrlV2(cadence.id) : cadenceEditUrl(cadence.id)
                }&name=${cadence.name}&bobjectType=${bobjectType?.name}`,
              );
            }}
            uppercase={false}
            className={styles._edit_view_button}
          >
            {canEditCadence ? 'Edit' : 'View'}
          </Button>
          <Dropdown
            ref={ref}
            visible={visible}
            anchor={
              <IconButton
                name="moreOpenholes"
                color="bloobirds"
                dataTest="moreDropdown"
                onClick={() => setVisible(!visible)}
              />
            }
          >
            {!isDefaultCadence && (
              <Item
                onClick={() => {
                  setVisible(!visible);
                  markCadenceAsDefault();
                }}
                disabled={!canEditCadence}
                dataTest="updateLeadStatusOption"
                icon={isDefaultCadence ? 'starChecked' : 'starUnchecked'}
              >
                Mark as default
              </Item>
            )}
            <Item
              onClick={() => {
                setVisible(!visible);
                setCadenceToClone(cadence);
                openCloneModal();
              }}
              dataTest="logActivityOption"
              icon="copy"
            >
              Clone
            </Item>
            {!isDefaultCadence ? (
              <Item
                disabled={!canEditCadence}
                onClick={() => {
                  setVisible(!visible);
                  handleDelete();
                }}
                icon="trashEmpty"
              >
                Delete
              </Item>
            ) : (
              <Tooltip title="Default cadence cannot be deleted" position="top">
                <Item disabled icon="trashEmpty">
                  Delete
                </Item>
              </Tooltip>
            )}
          </Dropdown>
        </span>
      </EntityCardItem>
    </>
  );
};
