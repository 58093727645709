import React from 'react';
import PropTypes from 'prop-types';
import {
  Dropdown,
  Icon,
  IconButton,
  Item,
  Text,
  Tooltip,
  useVisible,
} from '@bloobirds-it/flamingo-ui';
import mixpanel from 'mixpanel-browser';
import { useSWRConfig } from 'swr';
import {
  APP_ACCOUNT_GENERAL_SETTINGS,
  APP_MANAGEMENT_USER,
  APP_PLAYBOOK_MESSAGING_PITCH,
  APP_PLAYBOOK_TARGET_MARKET,
} from '../../../app/_constants/routes';
import { useDialerVisibility, useMediaQuery, useRouter } from '../../../hooks';
import NotificationBell from './notificationBell';
import styles from './headerActions.module.css';
import { HelpButton } from './helpButton/helpButton';
import { useTableContext } from '../../bobjectTable/context/bobjectTable.context';
import { bobjectTableActions } from '../../bobjectTable/context/bobjectTable.types';
import { useAppCalendarVisibility } from '../../../hooks/useUserTasks';
import { useOldReportingEnabled } from '../../../hooks/useFeatureFlags';
import SessionManagerFactory from '../../../misc/session';
import { useLogin } from '../../../hooks/useLogin';
import { SearchBarButton } from './searchBarButton/searchBarButton';
import clsx from 'clsx';

const HeaderActions = props => {
  const { userName } = props;
  const { logout } = useLogin();
  const roleManager = SessionManagerFactory().getRoleManager();
  const router = useRouter();
  const { dispatch } = useTableContext();
  const { openCalendar, isOpen: isOpenCalendar, closeCalendar } = useAppCalendarVisibility();
  const isOldReportingEnabled = useOldReportingEnabled();
  const { cache } = useSWRConfig();

  const { ref, visible, setVisible } = useVisible(false);
  const { isOpen: isDialerOpen } = useDialerVisibility();
  const { windowDimensions, isSmallDesktop } = useMediaQuery();
  const screenWithSpace = windowDimensions.width > 1650;

  const handleClick = () => {
    setVisible(!visible);
  };

  const handleClose = () => {
    if (visible) {
      setVisible(false);
    }
  };

  const resetTable = () => dispatch({ type: bobjectTableActions.BOBJECT_TABLE_RESET });

  const handleLogout = () => {
    router.history.replace({ search: '' });
    logout({ callback: resetTable });
    cache.clear();
    mixpanel.reset();
  };

  return (
    <div className={styles._container} onClick={handleClose}>
      <SearchBarButton shortPlaceholder={(isDialerOpen && !screenWithSpace) || isSmallDesktop} />
      <div
        className={clsx(styles._button_wrapper, {
          [styles._button_wrapper_dialer_open]:
            (isDialerOpen && !screenWithSpace) || isSmallDesktop,
        })}
        data-test="Button-HeaderMyPlaybook"
        onClick={e => {
          const url = roleManager.isAccountAdmin()
            ? APP_PLAYBOOK_TARGET_MARKET
            : APP_PLAYBOOK_MESSAGING_PITCH;
          router.history.push(url, { event: e });
        }}
      >
        <Icon name="magic" color="purple" size={16} />
        {(!isDialerOpen || screenWithSpace) && !isSmallDesktop && (
          <Text size="s" color="purple" htmlTag="span">
            My Playbook
          </Text>
        )}
      </div>
      <NotificationBell />
      <div className={styles._calendar_icon_wrapper}>
        <IconButton
          dataTest="HeaderCalendar"
          name="event"
          onClick={event => {
            if (!isOpenCalendar) {
              openCalendar();
            } else {
              closeCalendar();
            }
          }}
          size={20}
          color="peanut"
        />
      </div>
      <Dropdown
        ref={ref}
        arrow={false}
        visible={visible}
        anchor={
          <div
            className={styles._user_button}
            style={{ margin: isOldReportingEnabled ? '0 24px' : '0 0 0 24px' }}
            data-test={'Button-HeaderUserName'}
            data-intercom="nav-action-user"
            onClick={() => {
              handleClick();
            }}
          >
            <div className={styles._user_button_circle} />
            {userName}
            <div className={styles._user_button_icon_container}>
              <Icon name={visible ? 'chevronUp' : 'chevronDown'} size={12} color="verySoftPeanut" />
            </div>
          </div>
        }
      >
        <Item
          dataTest={'HeaderUserNameUserSettings'}
          onClick={(value, e) => router.push(APP_MANAGEMENT_USER, { event: e })}
        >
          <span
            data-test={'DropdownItem-HeaderUserSettings'}
            data-intercom="nav-action-user-user-settings"
          >
            User Settings
          </span>
        </Item>
        {roleManager.isAccountAdmin() && (
          <Item
            dataTest={'HeaderUserNameAccountSettings'}
            onClick={(value, e) => router.push(APP_ACCOUNT_GENERAL_SETTINGS, { event: e })}
          >
            <span
              data-test={'DropdownItem-dropdownAccountSettings'}
              data-intercom="nav-action-user-account-settings"
            >
              Account Settings
            </span>
          </Item>
        )}
        <Item dataTest={'HeaderUserNameLogOut'} onClick={handleLogout}>
          Log Out
        </Item>
      </Dropdown>
      {isOldReportingEnabled && (
        <Tooltip title="Open Dashboards">
          <a
            className={styles._dashboard_button}
            target="_blank"
            rel="noopener noreferrer"
            onClick={() => {
              window.open('https://reporting.bloobirds.com/superset/welcome', '_blank');
              mixpanel.track('OLD_DASHBOARDS_OPENED');
            }}
          >
            <Icon name="barchart" color="peanut" size={20} />
          </a>
        </Tooltip>
      )}
      <HelpButton />
    </div>
  );
};

HeaderActions.propTypes = {
  userName: PropTypes.string,
};

HeaderActions.defaultProps = {
  userName: '',
};

export { HeaderActions };
