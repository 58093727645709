import React from 'react';
import { IconButton, IconType, Text } from '@bloobirds-it/flamingo-ui';
import { capitalize } from 'lodash';
import classNames from 'clsx';
// @ts-ignore
import {
  MinimizableModalType,
  useActiveUserId,
  useDataModel,
  useMeetingLinks,
  useMinimizableModal,
  useMinimizableModals,
} from '@bloobirds-it/hooks';
import styles from './minimizableModals.module.css';
import BobjectFormModal from './bobjectFormModal';
import { useSmartEmailEnabled } from '../../hooks/useFeatureFlags';
// @ts-ignore
import { EmailModal, SmartEmailModal } from '@bloobirds-it/email';
import { Bobject, BobjectTypes } from '@bloobirds-it/types';
import { useUserSettings } from '../userPermissions/hooks';
import MeetingModal from '../newMeetingModal/meetingModal';
import { useEmailConnections, useRouter } from '../../hooks';
import { APP_MANAGEMENT_USER, APP_TASKS_OUTBOX, bobjectUrl } from '../../app/_constants/routes';
// @ts-ignore
import { ConfirmCloseModal } from '@bloobirds-it/confirm-close-modal';

const icons: Record<MinimizableModalType, IconType> = {
  email: 'mail',
  meeting: 'calendar',
  note: 'noteAction',
  task: 'taskAction',
  calendarMeeting: 'calendar',
};

const GenericModal = ({ type, id }: { type: MinimizableModalType; id: string }) => {
  const isSmartEmailEnabled = useSmartEmailEnabled();
  const { isLoading: areMeetingLinksLoading } = useMeetingLinks();
  const settings = useUserSettings();
  const userId = settings.user.id;
  const activeUserId = useActiveUserId();
  const {
    data: { lead, opportunity, company, leads, pageBobjectType, mode },
    closeModal,
  } = useMinimizableModal(id);
  const { connections, mutate: mutateConnections } = useEmailConnections();
  const { history } = useRouter();
  const dataModel = useDataModel();

  switch (type) {
    case 'email':
      if (areMeetingLinksLoading || !activeUserId) {
        return null;
      } else {
        return isSmartEmailEnabled ? (
          <SmartEmailModal
            id={id}
            user={{ id: userId }}
            accountId={settings?.account?.id}
            connections={connections}
            mutateConnections={mutateConnections}
            emailSettingsRedirect={() => {
              closeModal();
              window.open(APP_MANAGEMENT_USER, '_blank');
            }}
            scheduleEmailRedirect={() => {
              history.push(`${APP_TASKS_OUTBOX}/scheduledEmails`);
            }}
            handleRedirect={() => {}}
            statusActivityRedirect={(bobject: Bobject) => {
              history.push(bobjectUrl(bobject));
              closeModal();
            }}
            bobjectsInfo={{
              activeBobject: pageBobjectType === BobjectTypes.Company ? company : lead,
              company,
              lead,
              leads,
              opportunity,
              pageBobjectType,
            }}
            dataModel={dataModel}
            mode={mode}
          />
        ) : (
          <EmailModal
            id={id}
            user={{ id: userId }}
            accountId={settings?.account?.id}
            emailSettingsRedirect={() => {
              closeModal();
              window.open(APP_MANAGEMENT_USER, '_blank');
            }}
            scheduleEmailRedirect={() => {
              history.push(`${APP_TASKS_OUTBOX}/scheduledEmails`);
            }}
            handleRedirect={() => {}}
            bobjectsInfo={{
              activeBobject: pageBobjectType === BobjectTypes.Company ? company : lead,
              company,
              lead,
              leads,
              opportunity,
              pageBobjectType,
            }}
            dataModel={dataModel}
          />
        );
      }
    case 'meeting':
    case 'note':
      return <BobjectFormModal id={id} bobjectType="Activity" type={capitalize(type)} />;
    case 'task':
      return <BobjectFormModal id={id} bobjectType="Task" type={capitalize(type)} />;
    case 'calendarMeeting':
      return <MeetingModal id={id} accountId={settings?.account?.id} />;
    default:
      return null;
  }
};

const MinimizedModal = ({ id }: { id: string }) => {
  const { maximize, type, title, openConfirmModal } = useMinimizableModal(id);

  return (
    <div className={classNames(styles._minimizedModal__container, styles[type])} onClick={maximize}>
      <IconButton name={icons[type]} color="white" size={16} onClick={maximize} />
      <Text color="white" size="s" weight="medium" ellipsis={24}>
        {title}
      </Text>
      <IconButton name="maximize" color="white" size={16} onClick={maximize} />
      <IconButton
        name="cross"
        color="white"
        size={18}
        onClick={event => {
          event.stopPropagation();
          event.preventDefault();
          openConfirmModal();
        }}
      />
    </div>
  );
};

const MinimizableModal = ({ id }: { id: string }) => {
  const { open, type } = useMinimizableModal(id);

  if (open) {
    return <GenericModal type={type} id={id} />;
  }

  return <MinimizedModal id={id} />;
};

const MinimizableModals = () => {
  const { minimizableModals, confirmationModal } = useMinimizableModals();

  return (
    <div className={styles._container}>
      {confirmationModal.open && <ConfirmCloseModal />}
      {minimizableModals.map(({ id }) => (
        <MinimizableModal id={id} key={id} />
      ))}
    </div>
  );
};

export default MinimizableModals;
