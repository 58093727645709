import React from 'react';
import PropTypes from 'prop-types';
import SyncSettingsTabOutbound from './outbound/index';
import { TabGroup, Tab, Icon } from '@bloobirds-it/flamingo-ui';
import SyncSettingsTabInbound from './inbound/syncSettingsTabInbound';
import styles from './syncSettingsTab.module.css';
import {
  useInboundHubspotEnabled,
  useInboundSalesforceEnabled,
} from '../../../hooks/useFeatureFlags';
import { CRM, CRM_DISPLAY_NAME } from '../../../constants/integrations';
import SyncSettingsTabEmbedded from './embedded/syncSettingsTabEmbedded';

const SyncSettingsTab = ({
  activityTrigger,
  leadTrigger,
  companyTrigger,
  meetingTrigger,
  salesforceUsers,
  dealsPipeline,
  crm,
  disconnectIntegration,
  activeIntegration,
  opportunityTrigger,
}) => {
  const displayCrm = CRM_DISPLAY_NAME[crm];
  const tabTitle = inbound => (
    <div style={{ display: 'flex' }}>
      <p style={{ margin: '0 2px' }}>{inbound ? displayCrm : 'Bloobirds'}</p>
      <div>
        <Icon name="arrowRight" color="peanut" size="16" />
      </div>
      <p style={{ margin: ' 0 2px' }}>{inbound ? 'Bloobirds' : displayCrm}</p>
    </div>
  );

  const isActiveHubspotInbound = useInboundHubspotEnabled();
  const isActiveSalesforceInbound = useInboundSalesforceEnabled();
  const isSalesforce = crm === CRM.SALESFORCE;
  const isHubspot = crm === CRM.HUBSPOT;

  return (
    <div className={styles._container}>
      <TabGroup>
        <Tab name={tabTitle(false)} variant="secondary" color="peanut">
          <SyncSettingsTabOutbound
            activeIntegration={activeIntegration}
            activityTrigger={activityTrigger}
            companyTrigger={companyTrigger}
            disconnectIntegration={disconnectIntegration}
            dealsPipeline={dealsPipeline}
            crm={crm}
            leadTrigger={leadTrigger}
            meetingTrigger={meetingTrigger}
            salesforceUsers={salesforceUsers}
            opportunityTrigger={opportunityTrigger}
          />
        </Tab>

        <Tab
          name={tabTitle(true)}
          variant="secondary"
          color="peanut"
          disabled={
            (!isActiveHubspotInbound && isHubspot) || (!isActiveSalesforceInbound && isSalesforce)
          }
        >
          <SyncSettingsTabInbound
            crm={crm}
            isActiveHubspotInbound={isActiveHubspotInbound}
            isActiveSalesforceInbound={isActiveSalesforceInbound}
          />
        </Tab>
        {isActiveSalesforceInbound && isSalesforce ? (
          <Tab name="Embedded Integrations" variant="secondary" color="peanut">
            <SyncSettingsTabEmbedded />
          </Tab>
        ) : (
          <></>
        )}
      </TabGroup>
    </div>
  );
};
SyncSettingsTab.propTypes = {
  activeIntegration: PropTypes.func,
  activityTrigger: PropTypes.object,
  companyTrigger: PropTypes.object,
  crm: PropTypes.string,
  dealsPipeline: PropTypes.object,
  disconnectIntegration: PropTypes.func,
  leadTrigger: PropTypes.object,
  meetingTrigger: PropTypes.object,
  salesforceUsers: PropTypes.array,
};
export default SyncSettingsTab;
