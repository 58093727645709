// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".rescheduleTaskModal-modules___date__wrapper___qB5yB {\n  display: flex;\n  justify-content: center;\n  margin-top: 30px;\n  margin-bottom: 50px;\n}\n", "",{"version":3,"sources":["webpack://./src/js/components/rescheduleTaskModal/rescheduleTaskModal.modules.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,uBAAuB;EACvB,gBAAgB;EAChB,mBAAmB;AACrB","sourcesContent":["._date__wrapper {\n  display: flex;\n  justify-content: center;\n  margin-top: 30px;\n  margin-bottom: 50px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"_date__wrapper": "rescheduleTaskModal-modules___date__wrapper___qB5yB"
};
export default ___CSS_LOADER_EXPORT___;
