import { Item, Select, Text } from '@bloobirds-it/flamingo-ui';
import { truncate } from 'lodash';
import React from 'react';
import styles from './messagingTemplatesBar.module.css';
import useAutomationEmailTemplates from '../../../../../../../hooks/useAutomationEmailTemplates';
import { BobjectType } from '../../../../../../../typings/bobjects';

interface MessagingTemplatesSelectProps {
  value?: string;
  onChange: (id: string) => void;
  bobjectType: BobjectType;
}

function MessagingTemplatesBar({ value, onChange, bobjectType }: MessagingTemplatesSelectProps) {
  const { messagingTemplates } = useAutomationEmailTemplates({ bobjectType });

  return (
    <div className={styles.container}>
      <div className={styles.select}>
        <div className={styles.label}>
          <Text size="xs" color="softPeanut">
            Template:
          </Text>
        </div>
        <Select
          autocomplete
          borderless={false}
          size="small"
          width="256px"
          placeholder="Email Template"
          value={value}
          onChange={onChange}
        >
          {messagingTemplates.map((messagingTemplate: any) => (
            <Item
              key={messagingTemplate.id}
              label={messagingTemplate.name}
              value={messagingTemplate.id}
            >
              {truncate(messagingTemplate.name, { length: 32 })}
            </Item>
          ))}
        </Select>
      </div>
    </div>
  );
}

export default MessagingTemplatesBar;
