import React from 'react';

import { ColorType, Icon, Text, Tooltip } from '@bloobirds-it/flamingo-ui';
import { useRouter } from '@bloobirds-it/hooks';
import { APP_CADENCES_ANALYZE } from '@bloobirds-it/types';

import { EntityCardItem } from '../../../../../components/entityList/entityCard/entityCard';
import { AnalyzeCadenceTableRow, CadenceTableKPI } from '../analyzeCadenceList.typings';

export const AnalyzeCadenceCard = ({ row }: { row: AnalyzeCadenceTableRow }) => {
  const { history } = useRouter();

  return (
    <>
      <EntityCardItem>
        <div
          style={{ cursor: 'pointer' }}
          onClick={() =>
            history.push(
              `${APP_CADENCES_ANALYZE}?id=${row?.cadenceId}&name=${row?.name}&bobjectType=${
                row?.type?.charAt(0) + row?.type?.slice(1)?.toLowerCase()
              }`,
            )
          }
        >
          <Text size="s" color="bloobirds" weight="bold">
            {row?.name}
          </Text>
        </div>
      </EntityCardItem>
      <EntityCardItem>
        <Tooltip title="Default Cadence" position="top">
          {row?.defaultCadence && <Icon name="starChecked" color="softBanana" size={16} />}
        </Tooltip>
      </EntityCardItem>
      <EntityCardItem>{row?.type?.charAt(0) + row?.type?.slice(1)?.toLowerCase()}</EntityCardItem>
      {row?.kpis?.map((kpi: CadenceTableKPI) => (
        <EntityCardItem key={kpi.label}>
          <Text size="s" weight="bold" color={kpi.iconColor as ColorType} align="center">
            {kpi?.value + (kpi?.type === 'PERCENTAGE' && '%')}
          </Text>
        </EntityCardItem>
      ))}
    </>
  );
};
