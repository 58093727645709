import {
  Button,
  Input,
  Modal,
  ModalContent,
  ModalFooter,
  Text,
  useToasts,
} from '@bloobirds-it/flamingo-ui';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import styles from './twilioConfigModal.module.css';
import React from 'react';
import {
  useUserSettings,
  useUserSettingsReload,
} from '../../../../../components/userPermissions/hooks';
import { useEntity } from '../../../../../hooks';
import { api } from '../../../../../utils/api';
import { useUserHelpers } from '../../../../../hooks/useUserHelpers';
import { UserHelperKeys } from '../../../../../constants/userHelperKeys';

export const TwilioConfigModal = ({ setModalVisible, isTwilioInstalled }) => {
  const settings = useUserSettings();
  const reloadUserSettings = useUserSettingsReload();
  const methods = useForm({ defaultValues: { ...settings.account } });
  const { createToast } = useToasts();
  const dialerTypes = useEntity('dialerTypes');
  const { save } = useUserHelpers();

  const handleUpdateAccount = () => {
    const currentDialerTypes = settings.account?.dialerTypes?.map(
      type => `dialerTypes/${dialerTypes.find(t => t.enumName === type)?.id}`,
    );
    const values = {
      ...methods.getValues(),
      dialerTypes: [
        `dialerTypes/${dialerTypes.find(type => type.enumName === 'BLOOBIRDS_DIALER')?.id}`,
        currentDialerTypes.length > 0 ? currentDialerTypes.join(',') : null,
      ],
    };

    api
      .patch(
        `/entities/accounts/${settings.account.id}`,
        isTwilioInstalled ? methods.getValues() : values,
      )
      .then(() => {
        createToast({ message: 'Twilio settings updated!', type: 'success' });
        reloadUserSettings();
        setModalVisible(false);
      });
    save(UserHelperKeys.CHOOSE_DIALER);
  };

  return (
    <Modal
      open
      onClose={() => setModalVisible(false)}
      title="Set up your Twilio Account"
      width={600}
    >
      <ModalContent>
        <Text htmlTag="h5" size="s" color="softPeanut" className={styles._howTo__text}>
          See how to create an set up a Twilio account
          <span
            className={styles._link__button}
            onClick={() =>
              window.open(
                'https://support.bloobirds.com/hc/en-us/articles/360011451399-Set-up-your-Twilio-account',
                '_blank',
              )
            }
          >
            {' '}
            here
          </span>
        </Text>
        <FormProvider {...methods}>
          <div className={styles._form__container}>
            <Controller
              name="twilioApplicationSid"
              rules={{
                required: 'This field is required',
              }}
              render={({ onChange, value }) => (
                <Input
                  error={methods.errors.twilioApplicationSid?.message}
                  value={value}
                  onChange={onChange}
                  placeholder="Twilio application sid *"
                  width={375}
                  className={styles._input__container}
                />
              )}
            />
            <Controller
              name="twilioAccountSid"
              rules={{
                required: 'This field is required',
              }}
              render={({ onChange, value }) => (
                <Input
                  error={methods.errors.twilioAccountSid?.message}
                  value={value}
                  onChange={onChange}
                  placeholder="Twilio account sid *"
                  width={375}
                  className={styles._input__container}
                />
              )}
            />
            <Controller
              name="twilioAuthToken"
              rules={{
                required: 'This field is required',
              }}
              render={({ onChange, value }) => (
                <Input
                  error={methods.errors.twilioAuthToken?.message}
                  value={value}
                  onChange={onChange}
                  placeholder="Twilio auth token *"
                  width={375}
                  className={styles._input__container}
                />
              )}
            />
          </div>
        </FormProvider>
      </ModalContent>
      <ModalFooter>
        <Button variant="clear" onClick={() => setModalVisible(false)}>
          CANCEL
        </Button>
        <Button onClick={methods.handleSubmit(handleUpdateAccount)}>SAVE</Button>
      </ModalFooter>
    </Modal>
  );
};
