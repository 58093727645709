// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".reportingDelay-module__alert__container___O-mVN {\n  width: 100%;\n  height: 40px;\n  display: flex;\n  align-items: center;\n  box-sizing: border-box;\n  color: var(--peanut);\n  font-family: var(--fontFamily);\n  font-size: 16px;\n  border-radius: 4px;\n  line-height: 20px;\n  border: 1px solid rgba(255, 189, 25, 0.4);\n  padding: 0 16px;\n  background-color: rgba(255, 232, 179, 0.4);\n}\n\n.reportingDelay-module__text__container___j0\\+W1 {\n  flex-grow: 1;\n}\n\n.reportingDelay-module__text__container___j0\\+W1 > p:first-child {\n  margin-right: 5px;\n}\n\n.reportingDelay-module__cross_icon___YDtXL {\n  margin-right: 50px;\n}\n", "",{"version":3,"sources":["webpack://./src/js/pages/dashboardPages/v1/reportingDelay/reportingDelay.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,YAAY;EACZ,aAAa;EACb,mBAAmB;EACnB,sBAAsB;EACtB,oBAAoB;EACpB,8BAA8B;EAC9B,eAAe;EACf,kBAAkB;EAClB,iBAAiB;EACjB,yCAAyC;EACzC,eAAe;EACf,0CAA0C;AAC5C;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,kBAAkB;AACpB","sourcesContent":[".alert__container {\n  width: 100%;\n  height: 40px;\n  display: flex;\n  align-items: center;\n  box-sizing: border-box;\n  color: var(--peanut);\n  font-family: var(--fontFamily);\n  font-size: 16px;\n  border-radius: 4px;\n  line-height: 20px;\n  border: 1px solid rgba(255, 189, 25, 0.4);\n  padding: 0 16px;\n  background-color: rgba(255, 232, 179, 0.4);\n}\n\n.text__container {\n  flex-grow: 1;\n}\n\n.text__container > p:first-child {\n  margin-right: 5px;\n}\n\n.cross_icon {\n  margin-right: 50px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"alert__container": "reportingDelay-module__alert__container___O-mVN",
	"text__container": "reportingDelay-module__text__container___j0+W1",
	"cross_icon": "reportingDelay-module__cross_icon___YDtXL"
};
export default ___CSS_LOADER_EXPORT___;
