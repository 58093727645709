import { atom, useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { useEffect } from 'react';
import { useEntity } from './entities/useEntity';
import { useActiveUser } from './useActiveUser';
import { getFieldTextById } from '../utils/bobjects.utils';
import { useBobjectTypes } from './useBobjectTypes';

const emailVariablesAtom = atom({
  key: 'emailVariables-A',
  default: {},
});

const emailVariablesValuesAtom = atom({
  key: 'emailVariablesValues-A',
  default: {},
});

const getVariableValue = ({ lead, user, company, opportunity, variable }) => {
  switch (variable.type) {
    case 'company':
      return getFieldTextById(company, variable.id);
    case 'lead':
      return getFieldTextById(lead, variable.id);
    case 'opportunity':
      return getFieldTextById(opportunity, variable.id);
    case 'user':
      return user.name;
    default:
      throw new Error(`Unsupported variable of type: '${variable.type}'`);
  }
};

const parseEmailVariables = (bobjectFields, bobjectTypes) => {
  let variablesParsed = {};
  bobjectFields.forEach(field => {
    const logicRole = field.logicRole;
    const types = bobjectTypes?.all().find(x => x.id === field.bobjectType);
    const type = types.name.toLowerCase();

    const obj = {
      [type]: [
        ...(variablesParsed[type] || []),
        {
          id: field.id,
          type,
          name: field.name,
          logicRole,
        },
      ],
    };
    variablesParsed = {
      ...variablesParsed,
      ...obj,
      SDR: [
        {
          id: 'user',
          name: 'Name',
          type: 'user',
          logicRole: 'USER__NAME',
        },
      ],
    };
  });

  return variablesParsed;
};

export const useEmailVariables = () => {
  const [emailVariables, setEmailVariables] = useRecoilState(emailVariablesAtom);
  const templatesBobjectFields = useEntity('bobjectFields');
  const bobjectTypes = useBobjectTypes();

  useEffect(() => {
    if (templatesBobjectFields && bobjectTypes) {
      const variables = parseEmailVariables(
        templatesBobjectFields?.all().filter(entity => entity.templateVariable),
        bobjectTypes,
      );
      setEmailVariables(variables);
    }
  }, [templatesBobjectFields, bobjectTypes?.all()?.length]);

  return emailVariables;
};

export const useEmailVariableValue = () => useRecoilValue(emailVariablesValuesAtom);

export const useSetEmailVariablesValues = () => {
  const emailVariables = useEmailVariables();
  const setEmailVariablesValues = useSetRecoilState(emailVariablesValuesAtom);
  const { activeUser: user } = useActiveUser();
  return ({ company, lead, opportunity }) => {
    const values = {};
    Object.entries(emailVariables).forEach(([key, group]) => {
      values[key] = group.map(variable => {
        const value = getVariableValue({ lead, user, company, opportunity, variable });
        return { ...variable, value };
      });
    });
    setEmailVariablesValues(values);
  };
};
