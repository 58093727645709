export const headerNames = [
  {
    label: 'Name',
  },
  {
    label: 'Cadence by default',
  },
  {
    label: 'Description',
  },
  {
    label: 'Segmentation',
  },
  {
    label: 'Status',
  },
];
