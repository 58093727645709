// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".deleteCadenceStepModal-module__title___xaLXN {\n  display: flex;\n  align-items: center;\n}\n\n.deleteCadenceStepModal-module__title___xaLXN > svg {\n  margin-right: 8px;\n}\n\n.deleteCadenceStepModal-module__content___sAYYE {\n  margin-bottom: 16px;\n  margin-top: 8px;\n}\n\n.deleteCadenceStepModal-module__content___sAYYE > p {\n  margin-bottom: 16px;\n  line-height: 21px;\n}\n\n.deleteCadenceStepModal-module__buttons___8dzC4 {\n  margin-left: auto;\n}\n\n.deleteCadenceStepModal-module__buttons___8dzC4 > button:first-child {\n  margin-right: 16px;\n}\n", "",{"version":3,"sources":["webpack://./src/js/pages/playbookPages/businessAssetsPage/cadenceTab/deleteCadenceStepModal/deleteCadenceStepModal.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,mBAAmB;EACnB,eAAe;AACjB;;AAEA;EACE,mBAAmB;EACnB,iBAAiB;AACnB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,kBAAkB;AACpB","sourcesContent":[".title {\n  display: flex;\n  align-items: center;\n}\n\n.title > svg {\n  margin-right: 8px;\n}\n\n.content {\n  margin-bottom: 16px;\n  margin-top: 8px;\n}\n\n.content > p {\n  margin-bottom: 16px;\n  line-height: 21px;\n}\n\n.buttons {\n  margin-left: auto;\n}\n\n.buttons > button:first-child {\n  margin-right: 16px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"title": "deleteCadenceStepModal-module__title___xaLXN",
	"content": "deleteCadenceStepModal-module__content___sAYYE",
	"buttons": "deleteCadenceStepModal-module__buttons___8dzC4"
};
export default ___CSS_LOADER_EXPORT___;
