import useSWR from 'swr';
import { api } from '../../../../utils/api';
import { keepPreviousResponse } from '../../../../utils/swr.utils';
import React, { useEffect, useState } from 'react';
import { useActiveUser } from '../../../../hooks';
import {
  CircularBadge,
  Dropdown,
  Icon,
  Item,
  SearchInput,
  Text,
  useVisible,
} from '@bloobirds-it/flamingo-ui';
import useDebounce from '../../../../hooks/useDebounce';
import styles from './searchLeadsGuests.module.css';
import { isSizeNumber } from '../../../../utils/styles.utils';
import { Bobject, LEAD_FIELDS_LOGIC_ROLE } from '@bloobirds-it/types';
import { getValueFromLogicRole, isEmail } from '@bloobirds-it/utils';

export const SearchLeadsGuests = ({
  size = 16,
  handleSelect,
  company,
}: {
  size: number | string;
  handleSelect: (lead: Bobject | string) => void;
  company?: Bobject;
}) => {
  const [searchQuery, setSearchQuery] = useState<string>();
  const { activeAccount } = useActiveUser();
  const [focused, setFocused] = React.useState(false);
  const onFocus = () => setFocused(true);
  const onBlur = () => setFocused(false);
  const { ref, visible, setVisible } = useVisible(false);
  const debounceSearchValue = useDebounce(searchQuery, 200);
  const isValidEmail = isEmail(debounceSearchValue);
  const [error, setError] = useState<string>();
  const query = company
    ? {
        [LEAD_FIELDS_LOGIC_ROLE.COMPANY]: [company?.id?.value],
      }
    : {};
  let queries: any = [];
  if (debounceSearchValue) {
    queries = [
      {
        [LEAD_FIELDS_LOGIC_ROLE.EMAIL]: {
          query: [debounceSearchValue],
          searchMode: 'AUTOCOMPLETE__SEARCH',
        },
      },
      {
        [LEAD_FIELDS_LOGIC_ROLE.FULL_NAME]: {
          query: [debounceSearchValue],
          searchMode: 'AUTOCOMPLETE__SEARCH',
        },
        [LEAD_FIELDS_LOGIC_ROLE.EMAIL]: ['__MATCH_FULL_ROWS__'],
      },
    ];
  } else {
    queries = [
      {
        [LEAD_FIELDS_LOGIC_ROLE.EMAIL]: ['__MATCH_FULL_ROWS__'],
      },
    ];
  }

  const { data: response, mutate, isValidating } = useSWR(
    ['searchLeadGuestsMeetings', debounceSearchValue],
    () => {
      return api.post(`/bobjects/${activeAccount.id}/Lead/search`, {
        query,
        queries,
        formFields: true,
        pageSize: 50,
      });
    },
    { use: [keepPreviousResponse] },
  );
  const leads: Bobject[] = response?.data?.contents;

  useEffect(() => {
    setVisible(focused || (debounceSearchValue && leads.length > 0));
  }, [focused, response]);

  const handleKeyPress = (e: any) => {
    if (e.key === 'Enter') {
      if (debounceSearchValue) {
        if (isValidEmail) {
          handleSelect(debounceSearchValue);
          setFocused(false);
          setSearchQuery(null);
        } else {
          setError('Invalid email');
        }
      }
    }
  };

  useEffect(() => {
    if (error && isValidEmail) {
      setError(null);
    }
  }, [debounceSearchValue]);

  return (
    <div ref={ref}>
      <Dropdown
        width="100%"
        visible={visible && leads?.length > 0}
        fallbackPositions={['bottom-start', 'bottom-end', 'top-end']}
        arrow={false}
        anchor={
          <div>
            <SearchInput
              width="100%"
              placeholder="Add other email or search lead"
              value={searchQuery}
              onChange={setSearchQuery}
              onKeyPress={handleKeyPress}
              error={error}
              size="small"
              name="lead"
              onFocus={onFocus}
              onBlur={onBlur}
            />
          </div>
        }
      >
        {leads?.length > 0 && (
          <div
            className={styles._item_wrapper}
            style={{
              width: '300px',
            }}
          >
            <>
              {leads?.map((option: Bobject) => {
                const name = getValueFromLogicRole(option, LEAD_FIELDS_LOGIC_ROLE.FULL_NAME);
                const email = getValueFromLogicRole(option, LEAD_FIELDS_LOGIC_ROLE.EMAIL);
                return (
                  <Item
                    onMouseDown={() => {
                      handleSelect(option);
                      //setFocused(false);
                    }}
                    key={option.id?.value}
                    value={option.id?.value}
                    className={styles.item}
                  >
                    <>
                      <CircularBadge
                        size="small"
                        style={{
                          backgroundColor: 'var(--softPeanut)',
                          color: 'var(--white)',
                          borderColor: 'var(--white)',
                          flexShrink: 0,
                          ...(isSizeNumber(size)
                            ? {
                                width: isSizeNumber(size) && size,
                                height: isSizeNumber(size) && size,
                                fontSize: isSizeNumber(size) && size && `${size / 2} px`,
                              }
                            : {}),
                        }}
                      >
                        ?
                      </CircularBadge>
                      <div className={styles._lead__info}>
                        <Text
                          color="peanut"
                          size={size === 'medium' ? 'm' : 's'}
                          weight="medium"
                          ellipsis={30}
                        >
                          {name}
                        </Text>
                        <Text
                          color="softPeanut"
                          size={size === 'medium' ? 's' : 'xs'}
                          inline
                          className={styles._lead__company}
                        >
                          {email && (
                            <>
                              <Icon
                                size={16}
                                name="mail"
                                color="softPeanut"
                                className={styles._company__icon}
                              />
                              {email}
                            </>
                          )}
                        </Text>
                      </div>
                    </>
                  </Item>
                );
              })}
            </>
          </div>
        )}
      </Dropdown>
    </div>
  );
};
