import React, { useState } from 'react';
import AccountSettingsTab from '../../../../layouts/accountSettingsLayout/accountSettingsTab';
import { Disconnected } from '../../../../../assets/svg';
import styles from './aircall.module.css';
import { Button, Text } from '@bloobirds-it/flamingo-ui';
import { ServiceApi } from '../../../../misc/api/service';

const AircallAuth = () => {
  const [isFetchingInstallUrl, setIsFetchingInstallUrl] = useState(false);

  const fetchAndOpenAircallUrl = () => {
    setIsFetchingInstallUrl(true);
    ServiceApi.request({
      url: '/aircall/generateUrl',
      method: 'GET',
    }).then(payload => {
      window.location.replace(payload.url);
    });
  };

  return (
    <AccountSettingsTab>
      <div className={styles._auth_container}>
        <div className={styles._ellipse}>
          <Disconnected />
        </div>
        <Text
          className={styles._auth_header}
          size="xxl"
          weight="medium"
          color="peanut"
          align="center"
        >
          Aircall is not connected
        </Text>
        <Text
          className={styles._auth_text}
          size="m"
          weight="regular"
          color="softPeanut"
          align="center"
        >
          Connecting your Aircall will allow you to call in Bloobirds by the Aircall dialer and
          synchronise the phone activity with your Bloobirds data.
        </Text>
        <Text size="m" weight="regular" color="softPeanut" align="center">
          Do you wish to connect your Aircall account?
        </Text>
        <Button
          className={styles._auth_button}
          disabled={isFetchingInstallUrl}
          onClick={fetchAndOpenAircallUrl}
          iconLeft="settings"
        >
          CONNECT AIRCALL
        </Button>
      </div>
    </AccountSettingsTab>
  );
};

export default AircallAuth;
