import React from 'react';
import MessagingTemplatesLayout from '../../../layouts/messagingSectionLayout';
import MessagingTemplateCollection from '../../../layouts/messagingSectionLayout/messagingTemplateCollection';
import { APP_PLAYBOOK_MESSAGING_EMAIL_FORM } from '../../../app/_constants/routes';
import { FORM_MODES, TEMPLATE_TYPES } from '../../../utils/templates.utils';
import { useRouter } from '../../../hooks';
import MessagingOfficialFilterSwitch from '../../../components/messagingTemplates/messagingOfficialFilterSwitch/messagingOfficialFilterSwitch';
import MessagingMineSwitch from '../../../components/messagingTemplates/messagingMineSwitch/messagingMineSwitch';

const EmailTemplatesPage = () => {
  const { history } = useRouter();
  const config = {
    actionName: 'Create template',
    onClickAction: (e: any) =>
      history.push(`${APP_PLAYBOOK_MESSAGING_EMAIL_FORM}?mode=${FORM_MODES.CREATION}`, {
        event: e,
      }),
    searchPlaceholder: 'Search',
  };

  return (
    <MessagingTemplatesLayout
      dataIntercom="account-settings-email-template-page"
      body={<MessagingTemplateCollection templateType={TEMPLATE_TYPES.EMAIL} />}
      actions={
        <>
          <MessagingOfficialFilterSwitch />
          <MessagingMineSwitch />
        </>
      }
      id="EMAIL_MESSAGING_TEMPLATES"
      title="Email Templates"
      createConfig={config}
      pluralEntityName="Email templates"
    />
  );
};

export default EmailTemplatesPage;
