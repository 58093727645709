import React from 'react';
import MessagingSectionLayoutView from './messagingSectionLayout.view';
import PropTypes from 'prop-types';

const MessagingSectionLayoutContainer = ({
  actions,
  body,
  id,
  title,
  dataIntercom,
  createConfig,
  pluralEntityName,
}) => (
  <MessagingSectionLayoutView
    actions={actions}
    dataIntercom={dataIntercom}
    body={body}
    createConfig={createConfig}
    id={id}
    pluralEntityName={pluralEntityName}
    title={title}
  />
);

MessagingSectionLayoutContainer.defaultProps = {
  active: true,
  actions: PropTypes.node,
};

export default MessagingSectionLayoutContainer;
