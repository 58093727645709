import { IconButton, Input, Text } from '@bloobirds-it/flamingo-ui';
import React, { useEffect, useState } from 'react';
import { NylasAlias } from '../../../typings/connections';
import { useEmailConnections, useHover } from '../../../hooks';
import styles from './aliasName.module.css';
import { isEmail } from '../../../misc/utils';

export const AliasName = ({ alias }: { alias: NylasAlias }) => {
  const [isEdition, setIsEdition] = useState<boolean>();
  const [emailAlias, setEmailAlias] = useState<string>(alias?.emailAlias);
  const [ref, isHover] = useHover();
  const { removeAlias, updateAlias } = useEmailConnections();
  const [error, setError] = useState<boolean>(false);
  useEffect(() => {
    if (emailAlias?.length > 0 && !isEmail(emailAlias)) {
      setError(true);
    } else {
      setError(false);
    }
  }, [emailAlias]);

  const handleUpdateAlias = () => {
    updateAlias(alias.id, {
      emailAlias: emailAlias,
    });
    setIsEdition(false);
  };

  const handleRemoveAlias = () => {
    removeAlias(alias.id);
  };

  return (
    <div ref={ref} className={styles.container}>
      {isEdition ? (
        <>
          <Input
            value={emailAlias}
            onChange={setEmailAlias}
            size="small"
            error={error && 'This is not a valid email'}
          />
          <IconButton name="check" size={16} onClick={handleUpdateAlias} />
        </>
      ) : (
        <Text key={alias?.id} size="xs">
          {alias?.emailAlias}
        </Text>
      )}
      {isHover && (
        <>
          {!isEdition && <IconButton name="edit" size={16} onClick={() => setIsEdition(true)} />}
          <IconButton name="cross" color="tomato" size={16} onClick={handleRemoveAlias} />
        </>
      )}
    </div>
  );
};
