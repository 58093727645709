// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".leadTableActions-module___actions__container___eoHqV {\n  background-color: var(--white);\n  height: 64px;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  flex-shrink: 0;\n  border-radius: 0 0 4px 4px;\n  border-top: 1px solid var(--veryLightBloobirds);\n  box-shadow: 0 -1px 3px rgba(25, 145, 255, 0.09);\n}\n\n.leadTableActions-module___actions__container___eoHqV > div {\n  margin-right: 4px;\n}\n\n.leadTableActions-module___phone_item___4WGLA > * {\n  margin-left: 20px;\n}\n\n.leadTableActions-module__linkedinIcons___VKO5k > * {\n  margin: 8px;\n}\n\n.leadTableActions-module__fakeTooltipClass___XcMt4 {\n  height: 32px;\n}\n\n.leadTableActions-module__discovery_tooltip___B\\+g0y {\n  margin-left: -24px;\n  margin-top: -29px;\n}\n", "",{"version":3,"sources":["webpack://./src/js/pages/contactPages/leadTableActions/leadTableActions.module.css"],"names":[],"mappings":"AAAA;EACE,8BAA8B;EAC9B,YAAY;EACZ,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,cAAc;EACd,0BAA0B;EAC1B,+CAA+C;EAC/C,+CAA+C;AACjD;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,kBAAkB;EAClB,iBAAiB;AACnB","sourcesContent":["._actions__container {\n  background-color: var(--white);\n  height: 64px;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  flex-shrink: 0;\n  border-radius: 0 0 4px 4px;\n  border-top: 1px solid var(--veryLightBloobirds);\n  box-shadow: 0 -1px 3px rgba(25, 145, 255, 0.09);\n}\n\n._actions__container > div {\n  margin-right: 4px;\n}\n\n._phone_item > * {\n  margin-left: 20px;\n}\n\n.linkedinIcons > * {\n  margin: 8px;\n}\n\n.fakeTooltipClass {\n  height: 32px;\n}\n\n.discovery_tooltip {\n  margin-left: -24px;\n  margin-top: -29px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"_actions__container": "leadTableActions-module___actions__container___eoHqV",
	"_phone_item": "leadTableActions-module___phone_item___4WGLA",
	"linkedinIcons": "leadTableActions-module__linkedinIcons___VKO5k",
	"fakeTooltipClass": "leadTableActions-module__fakeTooltipClass___XcMt4",
	"discovery_tooltip": "leadTableActions-module__discovery_tooltip___B+g0y"
};
export default ___CSS_LOADER_EXPORT___;
