/* eslint-disable */

/* eslint-disable */

// source: proto/message.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

var jspb = require('google-protobuf');
var goog = jspb;
var proto = {};

goog.exportSymbol('BobjectId', null, proto);
goog.exportSymbol('Direction', null, proto);
goog.exportSymbol('FormBobject', null, proto);
goog.exportSymbol('FormBobjectField', null, proto);
goog.exportSymbol('RawBobject', null, proto);
goog.exportSymbol('SearchRequest', null, proto);
goog.exportSymbol('SearchRequest.SearchRequestFieldQuery', null, proto);
goog.exportSymbol('SearchRequest.SearchRequestSorting', null, proto);
goog.exportSymbol('SearchResponseFormBobject', null, proto);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.BobjectId = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.BobjectId, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.BobjectId.displayName = 'proto.BobjectId';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.RawBobject = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.RawBobject, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.RawBobject.displayName = 'proto.RawBobject';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.FormBobjectField = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.FormBobjectField, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.FormBobjectField.displayName = 'proto.FormBobjectField';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.FormBobject = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.FormBobject.repeatedFields_, null);
};
goog.inherits(proto.FormBobject, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.FormBobject.displayName = 'proto.FormBobject';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.SearchResponseFormBobject = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.SearchResponseFormBobject.repeatedFields_, null);
};
goog.inherits(proto.SearchResponseFormBobject, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.SearchResponseFormBobject.displayName = 'proto.SearchResponseFormBobject';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.SearchRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.SearchRequest.repeatedFields_, null);
};
goog.inherits(proto.SearchRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.SearchRequest.displayName = 'proto.SearchRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.SearchRequest.SearchRequestSorting = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.SearchRequest.SearchRequestSorting, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.SearchRequest.SearchRequestSorting.displayName = 'proto.SearchRequest.SearchRequestSorting';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.SearchRequest.SearchRequestFieldQuery = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.SearchRequest.SearchRequestFieldQuery.repeatedFields_, null);
};
goog.inherits(proto.SearchRequest.SearchRequestFieldQuery, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.SearchRequest.SearchRequestFieldQuery.displayName = 'proto.SearchRequest.SearchRequestFieldQuery';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.BobjectId.prototype.toObject = function(opt_includeInstance) {
  return proto.BobjectId.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.BobjectId} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.BobjectId.toObject = function(includeInstance, msg) {
  var f, obj = {
    value: jspb.Message.getFieldWithDefault(msg, 1, ""),
    typeName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    accountId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    objectId: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.BobjectId}
 */
proto.BobjectId.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.BobjectId;
  return proto.BobjectId.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.BobjectId} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.BobjectId}
 */
proto.BobjectId.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setValue(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTypeName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setObjectId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.BobjectId.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.BobjectId.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.BobjectId} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.BobjectId.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getValue();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTypeName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAccountId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getObjectId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string value = 1;
 * @return {string}
 */
proto.BobjectId.prototype.getValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.BobjectId} returns this
 */
proto.BobjectId.prototype.setValue = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string type_name = 2;
 * @return {string}
 */
proto.BobjectId.prototype.getTypeName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.BobjectId} returns this
 */
proto.BobjectId.prototype.setTypeName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string account_id = 3;
 * @return {string}
 */
proto.BobjectId.prototype.getAccountId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.BobjectId} returns this
 */
proto.BobjectId.prototype.setAccountId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string object_id = 4;
 * @return {string}
 */
proto.BobjectId.prototype.getObjectId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.BobjectId} returns this
 */
proto.BobjectId.prototype.setObjectId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.RawBobject.prototype.toObject = function(opt_includeInstance) {
  return proto.RawBobject.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.RawBobject} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.RawBobject.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = msg.getId()) && proto.BobjectId.toObject(includeInstance, f),
    contentsMap: (f = msg.getContentsMap()) ? f.toObject(includeInstance, undefined) : []
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.RawBobject}
 */
proto.RawBobject.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.RawBobject;
  return proto.RawBobject.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.RawBobject} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.RawBobject}
 */
proto.RawBobject.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.BobjectId;
      reader.readMessage(value,proto.BobjectId.deserializeBinaryFromReader);
      msg.setId(value);
      break;
    case 2:
      var value = msg.getContentsMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readString, null, "", "");
         });
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.RawBobject.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.RawBobject.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.RawBobject} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.RawBobject.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.BobjectId.serializeBinaryToWriter
    );
  }
  f = message.getContentsMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(2, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeString);
  }
};


/**
 * optional BobjectId id = 1;
 * @return {?proto.BobjectId}
 */
proto.RawBobject.prototype.getId = function() {
  return /** @type{?proto.BobjectId} */ (
    jspb.Message.getWrapperField(this, proto.BobjectId, 1));
};


/**
 * @param {?proto.BobjectId|undefined} value
 * @return {!proto.RawBobject} returns this
*/
proto.RawBobject.prototype.setId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.RawBobject} returns this
 */
proto.RawBobject.prototype.clearId = function() {
  return this.setId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.RawBobject.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * map<string, string> contents = 2;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,string>}
 */
proto.RawBobject.prototype.getContentsMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,string>} */ (
      jspb.Message.getMapField(this, 2, opt_noLazyCreate,
      null));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.RawBobject} returns this
 */
proto.RawBobject.prototype.clearContentsMap = function() {
  this.getContentsMap().clear();
  return this;};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.FormBobjectField.prototype.toObject = function(opt_includeInstance) {
  return proto.FormBobjectField.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.FormBobjectField} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.FormBobjectField.toObject = function(includeInstance, msg) {
  var f, obj = {
    text: jspb.Message.getFieldWithDefault(msg, 1, ""),
    value: jspb.Message.getFieldWithDefault(msg, 2, ""),
    name: jspb.Message.getFieldWithDefault(msg, 3, ""),
    label: jspb.Message.getFieldWithDefault(msg, 4, ""),
    logicRole: jspb.Message.getFieldWithDefault(msg, 5, ""),
    icon: jspb.Message.getFieldWithDefault(msg, 6, ""),
    ordering: jspb.Message.getFieldWithDefault(msg, 7, 0),
    valueLogicRole: jspb.Message.getFieldWithDefault(msg, 8, ""),
    valueTextColor: jspb.Message.getFieldWithDefault(msg, 9, ""),
    valueBackgroundColor: jspb.Message.getFieldWithDefault(msg, 10, ""),
    valueOutlineColor: jspb.Message.getFieldWithDefault(msg, 11, ""),
    valueScore: jspb.Message.getFieldWithDefault(msg, 12, 0),
    groupName: jspb.Message.getFieldWithDefault(msg, 13, ""),
    groupOrdering: jspb.Message.getFieldWithDefault(msg, 14, 0),
    groupIcon: jspb.Message.getFieldWithDefault(msg, 15, ""),
    groupDetailDisplay: jspb.Message.getFieldWithDefault(msg, 16, ""),
    referencedBobjectType: jspb.Message.getFieldWithDefault(msg, 17, ""),
    dateFormatType: jspb.Message.getFieldWithDefault(msg, 18, ""),
    dateFormatAbsolute: jspb.Message.getFieldWithDefault(msg, 19, ""),
    type: jspb.Message.getFieldWithDefault(msg, 20, ""),
    required: jspb.Message.getBooleanFieldWithDefault(msg, 21, false),
    inboundField: jspb.Message.getBooleanFieldWithDefault(msg, 22, false),
    typeField: jspb.Message.getBooleanFieldWithDefault(msg, 23, false),
    valueOrdering: jspb.Message.getFieldWithDefault(msg, 24, 0),
    listsOrdering: jspb.Message.getFieldWithDefault(msg, 25, 0),
    requiredColumnLists: jspb.Message.getBooleanFieldWithDefault(msg, 26, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.FormBobjectField}
 */
proto.FormBobjectField.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.FormBobjectField;
  return proto.FormBobjectField.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.FormBobjectField} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.FormBobjectField}
 */
proto.FormBobjectField.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setText(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setValue(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setLabel(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setLogicRole(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setIcon(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOrdering(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setValueLogicRole(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setValueTextColor(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setValueBackgroundColor(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setValueOutlineColor(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setValueScore(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setGroupName(value);
      break;
    case 14:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setGroupOrdering(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setGroupIcon(value);
      break;
    case 16:
      var value = /** @type {string} */ (reader.readString());
      msg.setGroupDetailDisplay(value);
      break;
    case 17:
      var value = /** @type {string} */ (reader.readString());
      msg.setReferencedBobjectType(value);
      break;
    case 18:
      var value = /** @type {string} */ (reader.readString());
      msg.setDateFormatType(value);
      break;
    case 19:
      var value = /** @type {string} */ (reader.readString());
      msg.setDateFormatAbsolute(value);
      break;
    case 20:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    case 21:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setRequired(value);
      break;
    case 22:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setInboundField(value);
      break;
    case 23:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setTypeField(value);
      break;
    case 24:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setValueOrdering(value);
      break;
    case 25:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setListsOrdering(value);
      break;
    case 26:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setRequiredColumnLists(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.FormBobjectField.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.FormBobjectField.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.FormBobjectField} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.FormBobjectField.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getText();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getValue();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getLabel();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getLogicRole();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getIcon();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getOrdering();
  if (f !== 0) {
    writer.writeInt32(
      7,
      f
    );
  }
  f = message.getValueLogicRole();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getValueTextColor();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getValueBackgroundColor();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getValueOutlineColor();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getValueScore();
  if (f !== 0) {
    writer.writeInt32(
      12,
      f
    );
  }
  f = message.getGroupName();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getGroupOrdering();
  if (f !== 0) {
    writer.writeInt32(
      14,
      f
    );
  }
  f = message.getGroupIcon();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
  f = message.getGroupDetailDisplay();
  if (f.length > 0) {
    writer.writeString(
      16,
      f
    );
  }
  f = message.getReferencedBobjectType();
  if (f.length > 0) {
    writer.writeString(
      17,
      f
    );
  }
  f = message.getDateFormatType();
  if (f.length > 0) {
    writer.writeString(
      18,
      f
    );
  }
  f = message.getDateFormatAbsolute();
  if (f.length > 0) {
    writer.writeString(
      19,
      f
    );
  }
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      20,
      f
    );
  }
  f = message.getRequired();
  if (f) {
    writer.writeBool(
      21,
      f
    );
  }
  f = message.getInboundField();
  if (f) {
    writer.writeBool(
      22,
      f
    );
  }
  f = message.getTypeField();
  if (f) {
    writer.writeBool(
      23,
      f
    );
  }
  f = message.getValueOrdering();
  if (f !== 0) {
    writer.writeInt32(
      24,
      f
    );
  }
  f = message.getListsOrdering();
  if (f !== 0) {
    writer.writeInt32(
      25,
      f
    );
  }
  f = message.getRequiredColumnLists();
  if (f) {
    writer.writeBool(
      26,
      f
    );
  }
};


/**
 * optional string text = 1;
 * @return {string}
 */
proto.FormBobjectField.prototype.getText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.FormBobjectField} returns this
 */
proto.FormBobjectField.prototype.setText = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string value = 2;
 * @return {string}
 */
proto.FormBobjectField.prototype.getValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.FormBobjectField} returns this
 */
proto.FormBobjectField.prototype.setValue = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string name = 3;
 * @return {string}
 */
proto.FormBobjectField.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.FormBobjectField} returns this
 */
proto.FormBobjectField.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string label = 4;
 * @return {string}
 */
proto.FormBobjectField.prototype.getLabel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.FormBobjectField} returns this
 */
proto.FormBobjectField.prototype.setLabel = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string logic_role = 5;
 * @return {string}
 */
proto.FormBobjectField.prototype.getLogicRole = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.FormBobjectField} returns this
 */
proto.FormBobjectField.prototype.setLogicRole = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string icon = 6;
 * @return {string}
 */
proto.FormBobjectField.prototype.getIcon = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.FormBobjectField} returns this
 */
proto.FormBobjectField.prototype.setIcon = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional int32 ordering = 7;
 * @return {number}
 */
proto.FormBobjectField.prototype.getOrdering = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.FormBobjectField} returns this
 */
proto.FormBobjectField.prototype.setOrdering = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional string value_logic_role = 8;
 * @return {string}
 */
proto.FormBobjectField.prototype.getValueLogicRole = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.FormBobjectField} returns this
 */
proto.FormBobjectField.prototype.setValueLogicRole = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string value_text_color = 9;
 * @return {string}
 */
proto.FormBobjectField.prototype.getValueTextColor = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.FormBobjectField} returns this
 */
proto.FormBobjectField.prototype.setValueTextColor = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string value_background_color = 10;
 * @return {string}
 */
proto.FormBobjectField.prototype.getValueBackgroundColor = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.FormBobjectField} returns this
 */
proto.FormBobjectField.prototype.setValueBackgroundColor = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string value_outline_color = 11;
 * @return {string}
 */
proto.FormBobjectField.prototype.getValueOutlineColor = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.FormBobjectField} returns this
 */
proto.FormBobjectField.prototype.setValueOutlineColor = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional int32 value_score = 12;
 * @return {number}
 */
proto.FormBobjectField.prototype.getValueScore = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {number} value
 * @return {!proto.FormBobjectField} returns this
 */
proto.FormBobjectField.prototype.setValueScore = function(value) {
  return jspb.Message.setProto3IntField(this, 12, value);
};


/**
 * optional string group_name = 13;
 * @return {string}
 */
proto.FormBobjectField.prototype.getGroupName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.FormBobjectField} returns this
 */
proto.FormBobjectField.prototype.setGroupName = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional int32 group_ordering = 14;
 * @return {number}
 */
proto.FormBobjectField.prototype.getGroupOrdering = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 14, 0));
};


/**
 * @param {number} value
 * @return {!proto.FormBobjectField} returns this
 */
proto.FormBobjectField.prototype.setGroupOrdering = function(value) {
  return jspb.Message.setProto3IntField(this, 14, value);
};


/**
 * optional string group_icon = 15;
 * @return {string}
 */
proto.FormBobjectField.prototype.getGroupIcon = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.FormBobjectField} returns this
 */
proto.FormBobjectField.prototype.setGroupIcon = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};


/**
 * optional string group_detail_display = 16;
 * @return {string}
 */
proto.FormBobjectField.prototype.getGroupDetailDisplay = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 16, ""));
};


/**
 * @param {string} value
 * @return {!proto.FormBobjectField} returns this
 */
proto.FormBobjectField.prototype.setGroupDetailDisplay = function(value) {
  return jspb.Message.setProto3StringField(this, 16, value);
};


/**
 * optional string referenced_bobject_type = 17;
 * @return {string}
 */
proto.FormBobjectField.prototype.getReferencedBobjectType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 17, ""));
};


/**
 * @param {string} value
 * @return {!proto.FormBobjectField} returns this
 */
proto.FormBobjectField.prototype.setReferencedBobjectType = function(value) {
  return jspb.Message.setProto3StringField(this, 17, value);
};


/**
 * optional string date_format_type = 18;
 * @return {string}
 */
proto.FormBobjectField.prototype.getDateFormatType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 18, ""));
};


/**
 * @param {string} value
 * @return {!proto.FormBobjectField} returns this
 */
proto.FormBobjectField.prototype.setDateFormatType = function(value) {
  return jspb.Message.setProto3StringField(this, 18, value);
};


/**
 * optional string date_format_absolute = 19;
 * @return {string}
 */
proto.FormBobjectField.prototype.getDateFormatAbsolute = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 19, ""));
};


/**
 * @param {string} value
 * @return {!proto.FormBobjectField} returns this
 */
proto.FormBobjectField.prototype.setDateFormatAbsolute = function(value) {
  return jspb.Message.setProto3StringField(this, 19, value);
};


/**
 * optional string type = 20;
 * @return {string}
 */
proto.FormBobjectField.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 20, ""));
};


/**
 * @param {string} value
 * @return {!proto.FormBobjectField} returns this
 */
proto.FormBobjectField.prototype.setType = function(value) {
  return jspb.Message.setProto3StringField(this, 20, value);
};


/**
 * optional bool required = 21;
 * @return {boolean}
 */
proto.FormBobjectField.prototype.getRequired = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 21, false));
};


/**
 * @param {boolean} value
 * @return {!proto.FormBobjectField} returns this
 */
proto.FormBobjectField.prototype.setRequired = function(value) {
  return jspb.Message.setProto3BooleanField(this, 21, value);
};


/**
 * optional bool inbound_field = 22;
 * @return {boolean}
 */
proto.FormBobjectField.prototype.getInboundField = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 22, false));
};


/**
 * @param {boolean} value
 * @return {!proto.FormBobjectField} returns this
 */
proto.FormBobjectField.prototype.setInboundField = function(value) {
  return jspb.Message.setProto3BooleanField(this, 22, value);
};


/**
 * optional bool type_field = 23;
 * @return {boolean}
 */
proto.FormBobjectField.prototype.getTypeField = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 23, false));
};


/**
 * @param {boolean} value
 * @return {!proto.FormBobjectField} returns this
 */
proto.FormBobjectField.prototype.setTypeField = function(value) {
  return jspb.Message.setProto3BooleanField(this, 23, value);
};


/**
 * optional int32 value_ordering = 24;
 * @return {number}
 */
proto.FormBobjectField.prototype.getValueOrdering = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 24, 0));
};


/**
 * @param {number} value
 * @return {!proto.FormBobjectField} returns this
 */
proto.FormBobjectField.prototype.setValueOrdering = function(value) {
  return jspb.Message.setProto3IntField(this, 24, value);
};


/**
 * optional int32 lists_ordering = 25;
 * @return {number}
 */
proto.FormBobjectField.prototype.getListsOrdering = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 25, 0));
};


/**
 * @param {number} value
 * @return {!proto.FormBobjectField} returns this
 */
proto.FormBobjectField.prototype.setListsOrdering = function(value) {
  return jspb.Message.setProto3IntField(this, 25, value);
};


/**
 * optional bool required_column_lists = 26;
 * @return {boolean}
 */
proto.FormBobjectField.prototype.getRequiredColumnLists = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 26, false));
};


/**
 * @param {boolean} value
 * @return {!proto.FormBobjectField} returns this
 */
proto.FormBobjectField.prototype.setRequiredColumnLists = function(value) {
  return jspb.Message.setProto3BooleanField(this, 26, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.FormBobject.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.FormBobject.prototype.toObject = function(opt_includeInstance) {
  return proto.FormBobject.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.FormBobject} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.FormBobject.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = msg.getId()) && proto.BobjectId.toObject(includeInstance, f),
    raw: (f = msg.getRaw()) && proto.RawBobject.toObject(includeInstance, f),
    fieldsList: jspb.Message.toObjectList(msg.getFieldsList(),
    proto.FormBobjectField.toObject, includeInstance),
    referencedBobjectsMap: (f = msg.getReferencedBobjectsMap()) ? f.toObject(includeInstance, proto.FormBobject.toObject) : []
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.FormBobject}
 */
proto.FormBobject.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.FormBobject;
  return proto.FormBobject.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.FormBobject} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.FormBobject}
 */
proto.FormBobject.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.BobjectId;
      reader.readMessage(value,proto.BobjectId.deserializeBinaryFromReader);
      msg.setId(value);
      break;
    case 2:
      var value = new proto.RawBobject;
      reader.readMessage(value,proto.RawBobject.deserializeBinaryFromReader);
      msg.setRaw(value);
      break;
    case 3:
      var value = new proto.FormBobjectField;
      reader.readMessage(value,proto.FormBobjectField.deserializeBinaryFromReader);
      msg.addFields(value);
      break;
    case 4:
      var value = msg.getReferencedBobjectsMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readMessage, proto.FormBobject.deserializeBinaryFromReader, "", new proto.FormBobject());
         });
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.FormBobject.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.FormBobject.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.FormBobject} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.FormBobject.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.BobjectId.serializeBinaryToWriter
    );
  }
  f = message.getRaw();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.RawBobject.serializeBinaryToWriter
    );
  }
  f = message.getFieldsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.FormBobjectField.serializeBinaryToWriter
    );
  }
  f = message.getReferencedBobjectsMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(4, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeMessage, proto.FormBobject.serializeBinaryToWriter);
  }
};


/**
 * optional BobjectId id = 1;
 * @return {?proto.BobjectId}
 */
proto.FormBobject.prototype.getId = function() {
  return /** @type{?proto.BobjectId} */ (
    jspb.Message.getWrapperField(this, proto.BobjectId, 1));
};


/**
 * @param {?proto.BobjectId|undefined} value
 * @return {!proto.FormBobject} returns this
*/
proto.FormBobject.prototype.setId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.FormBobject} returns this
 */
proto.FormBobject.prototype.clearId = function() {
  return this.setId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.FormBobject.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional RawBobject raw = 2;
 * @return {?proto.RawBobject}
 */
proto.FormBobject.prototype.getRaw = function() {
  return /** @type{?proto.RawBobject} */ (
    jspb.Message.getWrapperField(this, proto.RawBobject, 2));
};


/**
 * @param {?proto.RawBobject|undefined} value
 * @return {!proto.FormBobject} returns this
*/
proto.FormBobject.prototype.setRaw = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.FormBobject} returns this
 */
proto.FormBobject.prototype.clearRaw = function() {
  return this.setRaw(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.FormBobject.prototype.hasRaw = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * repeated FormBobjectField fields = 3;
 * @return {!Array<!proto.FormBobjectField>}
 */
proto.FormBobject.prototype.getFieldsList = function() {
  return /** @type{!Array<!proto.FormBobjectField>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.FormBobjectField, 3));
};


/**
 * @param {!Array<!proto.FormBobjectField>} value
 * @return {!proto.FormBobject} returns this
*/
proto.FormBobject.prototype.setFieldsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.FormBobjectField=} opt_value
 * @param {number=} opt_index
 * @return {!proto.FormBobjectField}
 */
proto.FormBobject.prototype.addFields = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.FormBobjectField, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.FormBobject} returns this
 */
proto.FormBobject.prototype.clearFieldsList = function() {
  return this.setFieldsList([]);
};


/**
 * map<string, FormBobject> referenced_bobjects = 4;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,!proto.FormBobject>}
 */
proto.FormBobject.prototype.getReferencedBobjectsMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,!proto.FormBobject>} */ (
      jspb.Message.getMapField(this, 4, opt_noLazyCreate,
      proto.FormBobject));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.FormBobject} returns this
 */
proto.FormBobject.prototype.clearReferencedBobjectsMap = function() {
  this.getReferencedBobjectsMap().clear();
  return this;};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.SearchResponseFormBobject.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.SearchResponseFormBobject.prototype.toObject = function(opt_includeInstance) {
  return proto.SearchResponseFormBobject.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.SearchResponseFormBobject} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.SearchResponseFormBobject.toObject = function(includeInstance, msg) {
  var f, obj = {
    page: jspb.Message.getFieldWithDefault(msg, 1, 0),
    totalMatching: jspb.Message.getFieldWithDefault(msg, 2, 0),
    contentsList: jspb.Message.toObjectList(msg.getContentsList(),
    proto.FormBobject.toObject, includeInstance),
    referencedBobjectsMap: (f = msg.getReferencedBobjectsMap()) ? f.toObject(includeInstance, proto.FormBobject.toObject) : []
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.SearchResponseFormBobject}
 */
proto.SearchResponseFormBobject.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.SearchResponseFormBobject;
  return proto.SearchResponseFormBobject.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.SearchResponseFormBobject} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.SearchResponseFormBobject}
 */
proto.SearchResponseFormBobject.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPage(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTotalMatching(value);
      break;
    case 3:
      var value = new proto.FormBobject;
      reader.readMessage(value,proto.FormBobject.deserializeBinaryFromReader);
      msg.addContents(value);
      break;
    case 4:
      var value = msg.getReferencedBobjectsMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readMessage, proto.FormBobject.deserializeBinaryFromReader, "", new proto.FormBobject());
         });
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.SearchResponseFormBobject.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.SearchResponseFormBobject.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.SearchResponseFormBobject} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.SearchResponseFormBobject.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPage();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getTotalMatching();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getContentsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.FormBobject.serializeBinaryToWriter
    );
  }
  f = message.getReferencedBobjectsMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(4, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeMessage, proto.FormBobject.serializeBinaryToWriter);
  }
};


/**
 * optional int32 page = 1;
 * @return {number}
 */
proto.SearchResponseFormBobject.prototype.getPage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.SearchResponseFormBobject} returns this
 */
proto.SearchResponseFormBobject.prototype.setPage = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 total_matching = 2;
 * @return {number}
 */
proto.SearchResponseFormBobject.prototype.getTotalMatching = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.SearchResponseFormBobject} returns this
 */
proto.SearchResponseFormBobject.prototype.setTotalMatching = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * repeated FormBobject contents = 3;
 * @return {!Array<!proto.FormBobject>}
 */
proto.SearchResponseFormBobject.prototype.getContentsList = function() {
  return /** @type{!Array<!proto.FormBobject>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.FormBobject, 3));
};


/**
 * @param {!Array<!proto.FormBobject>} value
 * @return {!proto.SearchResponseFormBobject} returns this
*/
proto.SearchResponseFormBobject.prototype.setContentsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.FormBobject=} opt_value
 * @param {number=} opt_index
 * @return {!proto.FormBobject}
 */
proto.SearchResponseFormBobject.prototype.addContents = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.FormBobject, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.SearchResponseFormBobject} returns this
 */
proto.SearchResponseFormBobject.prototype.clearContentsList = function() {
  return this.setContentsList([]);
};


/**
 * map<string, FormBobject> referenced_bobjects = 4;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,!proto.FormBobject>}
 */
proto.SearchResponseFormBobject.prototype.getReferencedBobjectsMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,!proto.FormBobject>} */ (
      jspb.Message.getMapField(this, 4, opt_noLazyCreate,
      proto.FormBobject));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.SearchResponseFormBobject} returns this
 */
proto.SearchResponseFormBobject.prototype.clearReferencedBobjectsMap = function() {
  this.getReferencedBobjectsMap().clear();
  return this;};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.SearchRequest.repeatedFields_ = [1,2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.SearchRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.SearchRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.SearchRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.SearchRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    queryList: jspb.Message.toObjectList(msg.getQueryList(),
    proto.SearchRequest.SearchRequestFieldQuery.toObject, includeInstance),
    sortList: jspb.Message.toObjectList(msg.getSortList(),
    proto.SearchRequest.SearchRequestSorting.toObject, includeInstance),
    page: jspb.Message.getFieldWithDefault(msg, 3, 0),
    pageSize: jspb.Message.getFieldWithDefault(msg, 4, 0),
    injectReferences: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    formFields: jspb.Message.getBooleanFieldWithDefault(msg, 6, false),
    simpleFields: jspb.Message.getBooleanFieldWithDefault(msg, 7, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.SearchRequest}
 */
proto.SearchRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.SearchRequest;
  return proto.SearchRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.SearchRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.SearchRequest}
 */
proto.SearchRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.SearchRequest.SearchRequestFieldQuery;
      reader.readMessage(value,proto.SearchRequest.SearchRequestFieldQuery.deserializeBinaryFromReader);
      msg.addQuery(value);
      break;
    case 2:
      var value = new proto.SearchRequest.SearchRequestSorting;
      reader.readMessage(value,proto.SearchRequest.SearchRequestSorting.deserializeBinaryFromReader);
      msg.addSort(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPage(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPageSize(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setInjectReferences(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setFormFields(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSimpleFields(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.SearchRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.SearchRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.SearchRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.SearchRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getQueryList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.SearchRequest.SearchRequestFieldQuery.serializeBinaryToWriter
    );
  }
  f = message.getSortList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.SearchRequest.SearchRequestSorting.serializeBinaryToWriter
    );
  }
  f = message.getPage();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getPageSize();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getInjectReferences();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getFormFields();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
  f = message.getSimpleFields();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.SearchRequest.SearchRequestSorting.prototype.toObject = function(opt_includeInstance) {
  return proto.SearchRequest.SearchRequestSorting.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.SearchRequest.SearchRequestSorting} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.SearchRequest.SearchRequestSorting.toObject = function(includeInstance, msg) {
  var f, obj = {
    field: jspb.Message.getFieldWithDefault(msg, 1, ""),
    direction: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.SearchRequest.SearchRequestSorting}
 */
proto.SearchRequest.SearchRequestSorting.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.SearchRequest.SearchRequestSorting;
  return proto.SearchRequest.SearchRequestSorting.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.SearchRequest.SearchRequestSorting} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.SearchRequest.SearchRequestSorting}
 */
proto.SearchRequest.SearchRequestSorting.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setField(value);
      break;
    case 2:
      var value = /** @type {!proto.Direction} */ (reader.readEnum());
      msg.setDirection(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.SearchRequest.SearchRequestSorting.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.SearchRequest.SearchRequestSorting.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.SearchRequest.SearchRequestSorting} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.SearchRequest.SearchRequestSorting.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getField();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDirection();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * optional string field = 1;
 * @return {string}
 */
proto.SearchRequest.SearchRequestSorting.prototype.getField = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.SearchRequest.SearchRequestSorting} returns this
 */
proto.SearchRequest.SearchRequestSorting.prototype.setField = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional Direction direction = 2;
 * @return {!proto.Direction}
 */
proto.SearchRequest.SearchRequestSorting.prototype.getDirection = function() {
  return /** @type {!proto.Direction} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.Direction} value
 * @return {!proto.SearchRequest.SearchRequestSorting} returns this
 */
proto.SearchRequest.SearchRequestSorting.prototype.setDirection = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.SearchRequest.SearchRequestFieldQuery.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.SearchRequest.SearchRequestFieldQuery.prototype.toObject = function(opt_includeInstance) {
  return proto.SearchRequest.SearchRequestFieldQuery.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.SearchRequest.SearchRequestFieldQuery} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.SearchRequest.SearchRequestFieldQuery.toObject = function(includeInstance, msg) {
  var f, obj = {
    field: jspb.Message.getFieldWithDefault(msg, 1, ""),
    valuesList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f,
    searchMode: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.SearchRequest.SearchRequestFieldQuery}
 */
proto.SearchRequest.SearchRequestFieldQuery.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.SearchRequest.SearchRequestFieldQuery;
  return proto.SearchRequest.SearchRequestFieldQuery.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.SearchRequest.SearchRequestFieldQuery} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.SearchRequest.SearchRequestFieldQuery}
 */
proto.SearchRequest.SearchRequestFieldQuery.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setField(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.addValues(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setSearchMode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.SearchRequest.SearchRequestFieldQuery.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.SearchRequest.SearchRequestFieldQuery.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.SearchRequest.SearchRequestFieldQuery} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.SearchRequest.SearchRequestFieldQuery.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getField();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getValuesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      2,
      f
    );
  }
  f = message.getSearchMode();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string field = 1;
 * @return {string}
 */
proto.SearchRequest.SearchRequestFieldQuery.prototype.getField = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.SearchRequest.SearchRequestFieldQuery} returns this
 */
proto.SearchRequest.SearchRequestFieldQuery.prototype.setField = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated string values = 2;
 * @return {!Array<string>}
 */
proto.SearchRequest.SearchRequestFieldQuery.prototype.getValuesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.SearchRequest.SearchRequestFieldQuery} returns this
 */
proto.SearchRequest.SearchRequestFieldQuery.prototype.setValuesList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.SearchRequest.SearchRequestFieldQuery} returns this
 */
proto.SearchRequest.SearchRequestFieldQuery.prototype.addValues = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.SearchRequest.SearchRequestFieldQuery} returns this
 */
proto.SearchRequest.SearchRequestFieldQuery.prototype.clearValuesList = function() {
  return this.setValuesList([]);
};


/**
 * optional string search_mode = 3;
 * @return {string}
 */
proto.SearchRequest.SearchRequestFieldQuery.prototype.getSearchMode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.SearchRequest.SearchRequestFieldQuery} returns this
 */
proto.SearchRequest.SearchRequestFieldQuery.prototype.setSearchMode = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated SearchRequestFieldQuery query = 1;
 * @return {!Array<!proto.SearchRequest.SearchRequestFieldQuery>}
 */
proto.SearchRequest.prototype.getQueryList = function() {
  return /** @type{!Array<!proto.SearchRequest.SearchRequestFieldQuery>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.SearchRequest.SearchRequestFieldQuery, 1));
};


/**
 * @param {!Array<!proto.SearchRequest.SearchRequestFieldQuery>} value
 * @return {!proto.SearchRequest} returns this
*/
proto.SearchRequest.prototype.setQueryList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.SearchRequest.SearchRequestFieldQuery=} opt_value
 * @param {number=} opt_index
 * @return {!proto.SearchRequest.SearchRequestFieldQuery}
 */
proto.SearchRequest.prototype.addQuery = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.SearchRequest.SearchRequestFieldQuery, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.SearchRequest} returns this
 */
proto.SearchRequest.prototype.clearQueryList = function() {
  return this.setQueryList([]);
};


/**
 * repeated SearchRequestSorting sort = 2;
 * @return {!Array<!proto.SearchRequest.SearchRequestSorting>}
 */
proto.SearchRequest.prototype.getSortList = function() {
  return /** @type{!Array<!proto.SearchRequest.SearchRequestSorting>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.SearchRequest.SearchRequestSorting, 2));
};


/**
 * @param {!Array<!proto.SearchRequest.SearchRequestSorting>} value
 * @return {!proto.SearchRequest} returns this
*/
proto.SearchRequest.prototype.setSortList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.SearchRequest.SearchRequestSorting=} opt_value
 * @param {number=} opt_index
 * @return {!proto.SearchRequest.SearchRequestSorting}
 */
proto.SearchRequest.prototype.addSort = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.SearchRequest.SearchRequestSorting, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.SearchRequest} returns this
 */
proto.SearchRequest.prototype.clearSortList = function() {
  return this.setSortList([]);
};


/**
 * optional int32 page = 3;
 * @return {number}
 */
proto.SearchRequest.prototype.getPage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.SearchRequest} returns this
 */
proto.SearchRequest.prototype.setPage = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int32 page_size = 4;
 * @return {number}
 */
proto.SearchRequest.prototype.getPageSize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.SearchRequest} returns this
 */
proto.SearchRequest.prototype.setPageSize = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional bool inject_references = 5;
 * @return {boolean}
 */
proto.SearchRequest.prototype.getInjectReferences = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.SearchRequest} returns this
 */
proto.SearchRequest.prototype.setInjectReferences = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional bool form_fields = 6;
 * @return {boolean}
 */
proto.SearchRequest.prototype.getFormFields = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.SearchRequest} returns this
 */
proto.SearchRequest.prototype.setFormFields = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};


/**
 * optional bool simple_fields = 7;
 * @return {boolean}
 */
proto.SearchRequest.prototype.getSimpleFields = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.SearchRequest} returns this
 */
proto.SearchRequest.prototype.setSimpleFields = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};


/**
 * @enum {number}
 */
proto.Direction = {
  ASC: 0,
  DESC: 1
};

goog.object.extend(exports, proto);
