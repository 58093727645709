import React, { useState } from 'react';
import {
  Card,
  CardRight,
  CardBody,
  CardHoverButtons,
  CardButton,
  CardLeft,
  CardHeader,
  Icon,
  Text,
  Spinner,
} from '@bloobirds-it/flamingo-ui';
import styles from './pauseCadenceCard.module.css';
import { usePausePeriods, usePausePeriodsModal } from '@bloobirds-it/hooks';
import { useActiveUser } from '../../../../hooks';
import { formatDateAsText } from '@bloobirds-it/utils';

const PauseCadenceCard = ({ completed, ...period }: any) => {
  const { activeUser } = useActiveUser();
  const { cancelPeriod, isSubmitting } = usePausePeriods({ userId: activeUser.id });
  const [confirmation, setConfirmation] = useState(false);
  const { openEditPauseModal } = usePausePeriodsModal();

  return (
    <div className={styles._card__container}>
      <Card completed={completed} width={650}>
        <CardHeader>
          <CardLeft>
            <Icon name="pauseOutlined" color={period.finished ? 'verySoftPeanut' : 'banana'} />
          </CardLeft>
          <CardBody>
            <Text ellipsis={52} size="s">
              {period.pauseName}
            </Text>
          </CardBody>
          <CardRight>
            <Text dataTest={'Text-pauseCadenceDates'} size="s" color="gray">
              From{' '}
              {formatDateAsText(
                new Date(
                  new Date(period.startDate).getTime() +
                    new Date(period.startDate).getTimezoneOffset() * 60000,
                ),
                '{date} {month} {year}',
              )}{' '}
              to{' '}
              {formatDateAsText(
                new Date(
                  new Date(period.endDate).getTime() +
                    new Date(period.endDate).getTimezoneOffset() * 60000,
                ),
                '{date} {month} {year}',
              )}
            </Text>
          </CardRight>
          <CardHoverButtons>
            <>
              <CardButton
                variant="secondary"
                onClick={() => openEditPauseModal(period)}
                disabled={completed}
              >
                Edit
              </CardButton>
              {!confirmation ? (
                <CardButton
                  dataTest={'pauseCadenceCancel'}
                  disabled={completed}
                  onClick={() => setConfirmation(true)}
                >
                  Cancel
                </CardButton>
              ) : (
                <CardButton
                  dataTest={'pauseCadenceSure'}
                  disabled={isSubmitting || completed}
                  color="tangerine"
                  onClick={() => cancelPeriod(period.id)}
                >
                  {!isSubmitting ? <>Sure?</> : <Spinner name="loadingCircle" size={15} />}
                </CardButton>
              )}
            </>
          </CardHoverButtons>
        </CardHeader>
      </Card>
    </div>
  );
};

export default PauseCadenceCard;
