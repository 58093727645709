import React from 'react';
import AccountSettingsLayout from '../../../layouts/accountSettingsLayout';
import SessionManagerFactory from '../../../misc/session';
import NoPermissionsPage from '../../noPermissionsPage';
import { MeetingCard } from './components/meetingCard/meetingCard';
import { LeadCard } from './components/leadCard/leadCard';
import { useUserSettings } from '../../../components/userPermissions/hooks';
import { AssignationCard } from './components/assignationCard/assignationCard';

const AccountSettingsPage = () => {
  const roleManager = SessionManagerFactory().getRoleManager();
  const accountName = SessionManagerFactory().getAccount().name;

  if (!roleManager.isAccountAdmin()) {
    return <NoPermissionsPage />;
  }
  const settings = useUserSettings();
  const { settings: configOptions } = settings;
  const meetingDefault = {
    meetingFieldsRequiredEnabled: configOptions.meetingFieldsRequiredEnabled,
    contactBeforeMeetingTime: configOptions.contactBeforeMeetingTimeRange?.time,
    contactBeforeMeetingTimeRange: configOptions.contactBeforeMeetingTimeRange?.timeRange,
    contactBeforeMeetingWarning: configOptions.contactBeforeMeetingWarning,
    contactBeforeMeetingOnWeekdays: configOptions.contactBeforeMeetingOnWeekdays,
    calendarEventDecision: configOptions.calendarEventDecision,
    openCalendarPopupAfterMeeting: configOptions.openCalendarPopupAfterMeeting,
    calendarLinksType: configOptions.calendarLinksType,
    createMeetingAfterCalendarEvent: configOptions.createMeetingAfterCalendarEvent,
  };
  const leadCardDefault = configOptions.leadEmailMatching;
  const assignationCardDefault = {
    propagateAssignedFromCompanyToLead: configOptions.propagateAssignedFromCompanyToLeadEnabled,
    propagateAssignedFromLeadToCompany: configOptions.propagateAssignedFromLeadToCompanyEnabled,
  };
  return (
    <AccountSettingsLayout
      title={`General Settings for ${accountName}`}
      subtitle="These defaults will be applied to the entire account."
    >
      <AssignationCard defaultValue={assignationCardDefault} />
      <MeetingCard value={meetingDefault} />
      <LeadCard value={leadCardDefault} />
    </AccountSettingsLayout>
  );
};

export default AccountSettingsPage;
