import { useAggregationSubscription } from '@bloobirds-it/plover';
import {
  atom,
  useRecoilState,
  useRecoilValue,
  useResetRecoilState,
  useSetRecoilState,
} from 'recoil';
import { useMemo } from 'react';
import { BobjectTypes } from '../../../../constants/bobject';
import {
  TASK_ACTION,
  TASK_ACTION_VALUE,
  TASK_FIELDS_LOGIC_ROLE,
  TASK_STATUS_VALUE_LOGIC_ROLE,
  TASK_TYPE,
} from '../../../../constants/task';
import SessionManagerFactory from '../../../../misc/session';
import { useEntity } from '../../../../hooks';
import { getTaskAggregationQuery, useProspectingItems } from '../useProspecting';
import { useUserSettings } from '../../../../components/userPermissions/hooks';
import { useFullSalesEnabled, useProspectingNurturingTab } from '../../../../hooks/useFeatureFlags';
import { api } from '../../../../utils/api';
import { SORT_FIELDS } from './onCadence.constant';
import { parsedDateValueToRelativeDates } from '../../../../utils/subhomeFilters.utils';
import { DEFAULT_SUBQUERY, getSubqueriesByBobjectType } from '../useProspecting.constants';
import { SortValues } from '../../../../layouts/subhomeLayout/subhomeContent/newSubhomeFilters/subhomeFilters';
import { MainBobjectTypes } from '../../../../hooks/useSubhomeFilters';
import { keepPreviousResponse } from '../../../../utils/swr.utils';

const SessionManager = SessionManagerFactory();

const DEFAULT_ORDER = 'select';

const queryAtom = atom({
  key: 'prospectingOnCadenceQueryAtom',
  default: undefined,
});

const sortAtom = atom({
  key: 'prospectOnCadenceSortAtom',
  default: { value: DEFAULT_ORDER, hasChanged: false },
});

const subQueriesAtom = atom({
  key: 'prospectOnCadenceSubQueriesAtom',
  default: DEFAULT_SUBQUERY,
});

const pageAtom = atom({
  key: 'prospectingOnCadencePage',
  default: 1,
});

const hasNextPageAtom = atom({
  key: 'prospectingOnCadenceHasNextPage',
  default: true,
});

export const useProspectingOnCadencePage = () => {
  const [hasNextPage, setHasNextPage] = useRecoilState(hasNextPageAtom);
  const [page, setPage] = useRecoilState(pageAtom);

  const loadNextPage = () => {
    setPage(page + 1);
  };

  return {
    page,
    hasNextPage,
    loadNextPage,
    setHasNextPage,
  };
};

export const useProspectingOnCadenceAggregation = () => {
  const { data } = useAggregationSubscription(
    getTaskAggregationQuery([TASK_TYPE.PROSPECT_CADENCE], {
      [TASK_ACTION.AUTOMATED_EMAIL]: [TASK_ACTION_VALUE.AUTOMATED_EMAIL_NO, '__MATCH_EMPTY_ROWS__'],
    }),
    BobjectTypes.Task,
  );
  return data?.data?.contents[0]?.value;
};

export const useProspectingTasksOnCadence = () => {
  const hasSalesEnabled = useFullSalesEnabled();
  const sort = SORT_FIELDS[useRecoilValue(sortAtom)?.value as keyof typeof SORT_FIELDS];
  const query = useRecoilValue(queryAtom);
  const currentSubQueries = useRecoilValue(subQueriesAtom);

  return {
    ...useProspectingItems(
      query,
      sort,
      pageAtom,
      BobjectTypes.Task,
      hasSalesEnabled ? currentSubQueries : [{}],
      [keepPreviousResponse],
    ),
    ...{ haveFiltersBeenChanged: !!query },
  };
};

export const useProspectingOnCadenceAllItems = () => {
  const settings = useUserSettings();
  const hasSalesEnabled = useFullSalesEnabled();
  const accountId = settings.account.id;
  const query = useRecoilValue(queryAtom);
  const queries = useRecoilValue(subQueriesAtom);
  const getAllItems = () => {
    const searchQuery = {
      query,
      queries: hasSalesEnabled ? queries : [{}],
      page: 0,
      formFields: true,
      pageSize: 1000,
      injectReferences: true,
      sort: [] as any[],
    };
    return api.post(`/bobjects/${accountId}/Task/search`, searchQuery);
  };
  return { getAllItems };
};

export const useProspectingOnCadenceQuery = () => {
  const isFullSalesEnabled = useFullSalesEnabled();
  const [query, setQuery] = useRecoilState(queryAtom);
  const setORsValues = useSetRecoilState(subQueriesAtom);
  const bobjectFieldsEntity = useEntity('bobjectFields');
  const hasNurturingTab = useProspectingNurturingTab();

  const assignedToField = bobjectFieldsEntity?.findByLogicRole(TASK_FIELDS_LOGIC_ROLE.ASSIGNED_TO);
  const statusField = bobjectFieldsEntity?.findByLogicRole(TASK_FIELDS_LOGIC_ROLE.STATUS);
  const taskTypeField = bobjectFieldsEntity?.findByLogicRole(TASK_FIELDS_LOGIC_ROLE.TASK_TYPE);
  const automatedEmailField = bobjectFieldsEntity?.findByLogicRole(TASK_ACTION.AUTOMATED_EMAIL);
  const opportunityField = bobjectFieldsEntity?.findByLogicRole(TASK_FIELDS_LOGIC_ROLE.OPPORTUNITY);
  const defaultQuery = {
    [assignedToField?.id]: SessionManager?.getUser()?.id,
    [statusField?.id]: [TASK_STATUS_VALUE_LOGIC_ROLE.TODO, TASK_STATUS_VALUE_LOGIC_ROLE.OVERDUE],
    [taskTypeField?.id]: [TASK_TYPE.PROSPECT_CADENCE],
    [automatedEmailField?.id]: [TASK_ACTION_VALUE.AUTOMATED_EMAIL_NO, '__MATCH_EMPTY_ROWS__'],
    ...(isFullSalesEnabled ? { [opportunityField?.id]: ['__MATCH_EMPTY_ROWS__'] } : {}),
  };

  function handleOrsBobjectTypeChange(bobjectType: MainBobjectTypes[]) {
    const parsedOrs = [];
    const subqueriesByBobjectType = getSubqueriesByBobjectType(hasNurturingTab);
    if (bobjectType?.length === 0 || !bobjectType) {
      parsedOrs.push(...Object.values(subqueriesByBobjectType));
    } else {
      bobjectType?.forEach((value: MainBobjectTypes) => {
        parsedOrs.push(subqueriesByBobjectType[value as BobjectTypes.Company | BobjectTypes.Lead]);
      });
    }

    setORsValues(parsedOrs);
  }

  return {
    query,
    setQuery: (value: { [id: string]: any }) => {
      setQuery({ ...defaultQuery, ...value });
    },
    resetQuery: () => setQuery(defaultQuery),
    setSubqueryBobjectType: (value: MainBobjectTypes[]) => handleOrsBobjectTypeChange(value),
  };
};

export const useProspectingOnCadenceSort = () => {
  const [sort, setSort] = useRecoilState(sortAtom);
  const resetSort = useResetRecoilState(sortAtom);
  return {
    sort,
    setSort: (value: SortValues | 'select') => {
      if (!value || value === DEFAULT_ORDER) {
        resetSort();
      } else {
        setSort({ value, hasChanged: true });
      }
    },
  };
};

export const useProspectingOnCadenceFooter = () => {
  const [query] = useRecoilState(queryAtom);
  const bobjectFieldsEntity = useEntity('bobjectFields');

  const dateFilter = useMemo(() => {
    const scheduledDateField = bobjectFieldsEntity?.findByLogicRole(
      TASK_FIELDS_LOGIC_ROLE.SCHEDULED_DATETIME,
    );
    const dateFilterValue = query && query[scheduledDateField?.id];
    return parsedDateValueToRelativeDates(dateFilterValue?.query);
  }, [bobjectFieldsEntity, query]);

  return { dateFilter };
};
