import React from 'react';
import classnames from 'clsx';
import styles from './entityCard.module.css';
import PropTypes from 'prop-types';
import { Text } from '@bloobirds-it/flamingo-ui';

export const EntityCard = React.forwardRef(
  (
    {
      className,
      children,
      ...props
    }: {
      className?: any;
      children: JSX.Element | JSX.Element[];
    },
    ref,
  ) => (
    <tr
      className={classnames(className, styles._entity__row)}
      //@ts-ignore
      ref={ref}
      {...props}
    >
      {children}
    </tr>
  ),
);

export const EntityCardItem = ({
  className,
  children,
  ellipsis,
  size,
  wrap,
  flexEnd,
  bold,
}: {
  className?: any;
  children: any;
  ellipsis?: number;
  size?: string;
  wrap?: boolean;
  flexEnd?: boolean;
  bold?: boolean;
}) => {
  const isString = React.Children?.toArray(children)?.every(child => typeof child === 'string');
  return (
    <td className={classnames(className, styles[`_card__cell_${size}`])}>
      {isString ? (
        <Text inline size="s" color="peanut" weight={bold ? 'bold' : 'regular'} ellipsis={ellipsis}>
          {children}
        </Text>
      ) : (
        <div
          className={classnames(styles._card__item, {
            [styles._card__item__wrap]: wrap,
            [styles._card__cell_flexEnd]: flexEnd,
          })}
        >
          {children}
        </div>
      )}
    </td>
  );
};

EntityCardItem.propTypes = {
  className: PropTypes.string,
  ellipsis: PropTypes.number,
  size: 'small' || 'medium',
  wrap: PropTypes.bool,
};

EntityCardItem.defaultProps = {
  size: 'medium',
  wrap: false,
  flexEnd: false,
};
