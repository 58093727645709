import React from 'react';
import { Spinner, Text } from '@bloobirds-it/flamingo-ui';
import styles from './homePage.module.css';
import { LeftContent } from './pages/leftContent/leftContent';
import LeftContentSales from './pages/leftContentSalesConversion/leftContent';
import { RightContent } from './pages/rightContent/rightContent';
import { CenterContent } from './pages/centerContent/centerContent';
import { useDocumentTitle } from '../../hooks';
import { useUserSettings } from '../../components/userPermissions/hooks';
import { useUserHomepageSettings } from './hooks/useUserHomepageSettings';
import UndoToast from '../../components/undoToast/undoToast';
import { useFullSalesEnabled } from '../../hooks/useFeatureFlags';

export const HomePage = () => {
  const settings = useUserSettings();
  const username = settings?.user?.name;
  const { availableSettings, userSettings } = useUserHomepageSettings();
  useDocumentTitle('Welcome, ' + username + '!');
  const hasSalesEnabled = useFullSalesEnabled();

  return (
    <div className={styles.home__container}>
      <div className={styles.title__container}>
        <Text size="xl">Welcome, {username}!</Text>
      </div>
      <div className={styles.content__container}>
        {availableSettings && userSettings ? (
          <>
            {hasSalesEnabled ? <LeftContentSales /> : <LeftContent />}
            <CenterContent />
            <RightContent />
          </>
        ) : (
          <div className={styles.spinnerDots}>
            <Spinner name="dots" size={50} />
          </div>
        )}
      </div>
      <UndoToast />
    </div>
  );
};
