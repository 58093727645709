// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".meetingResultModal-module___section__wrapper___12fpW {\n  margin-bottom: 24px;\n}\n\n.meetingResultModal-module___section_title__wrapper___IeXaP {\n  margin-bottom: 16px;\n}\n\n.meetingResultModal-module___labels__wrapper___-MCmK {\n  display: flex;\n  flex-direction: column;\n  flex-wrap: wrap;\n  align-content: space-around;\n}\n\n.meetingResultModal-module___label__content___AG7lM {\n  display: flex;\n  flex-direction: column;\n  width: 225px;\n  margin-bottom: 12px;\n}\n\n.meetingResultModal-module___label__content___AG7lM > div {\n  margin-bottom: 12px;\n}\n\n.meetingResultModal-module___buttons__wrapper___8SDQr {\n  display: flex;\n  width: 100%;\n}\n\n.meetingResultModal-module___buttons__wrapper___8SDQr > button:nth-child(2) {\n  margin-left: auto;\n  margin-right: 16px;\n}\n", "",{"version":3,"sources":["webpack://./src/js/layouts/subhomeLayout/subhomeContent/subhomeCard/subhomeCardButtons/modals/meetingResultModal.module.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;AACrB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,eAAe;EACf,2BAA2B;AAC7B;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,YAAY;EACZ,mBAAmB;AACrB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,WAAW;AACb;;AAEA;EACE,iBAAiB;EACjB,kBAAkB;AACpB","sourcesContent":["._section__wrapper {\n  margin-bottom: 24px;\n}\n\n._section_title__wrapper {\n  margin-bottom: 16px;\n}\n\n._labels__wrapper {\n  display: flex;\n  flex-direction: column;\n  flex-wrap: wrap;\n  align-content: space-around;\n}\n\n._label__content {\n  display: flex;\n  flex-direction: column;\n  width: 225px;\n  margin-bottom: 12px;\n}\n\n._label__content > div {\n  margin-bottom: 12px;\n}\n\n._buttons__wrapper {\n  display: flex;\n  width: 100%;\n}\n\n._buttons__wrapper > button:nth-child(2) {\n  margin-left: auto;\n  margin-right: 16px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"_section__wrapper": "meetingResultModal-module___section__wrapper___12fpW",
	"_section_title__wrapper": "meetingResultModal-module___section_title__wrapper___IeXaP",
	"_labels__wrapper": "meetingResultModal-module___labels__wrapper___-MCmK",
	"_label__content": "meetingResultModal-module___label__content___AG7lM",
	"_buttons__wrapper": "meetingResultModal-module___buttons__wrapper___8SDQr"
};
export default ___CSS_LOADER_EXPORT___;
