// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".meetingSettings-module__noLinks___mH5Gq{\n  display: flex;\n  align-items: center;\n  flex-direction: column;\n  width: 500px;\n  margin: 16px auto;\n}\n", "",{"version":3,"sources":["webpack://./src/js/pages/userSettingsPages/meetingSettings/meetingSettings.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,sBAAsB;EACtB,YAAY;EACZ,iBAAiB;AACnB","sourcesContent":[".noLinks{\n  display: flex;\n  align-items: center;\n  flex-direction: column;\n  width: 500px;\n  margin: 16px auto;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"noLinks": "meetingSettings-module__noLinks___mH5Gq"
};
export default ___CSS_LOADER_EXPORT___;
