import React from 'react';
import { useLocation } from 'react-router';
import { Button } from '@bloobirds-it/flamingo-ui';
import styles from './messagingTemplateCollection.module.css';
import {
  APP_PLAYBOOK_MESSAGING_EMAIL_FORM,
  APP_PLAYBOOK_MESSAGING_LINKEDIN_FORM,
  APP_PLAYBOOK_MESSAGING_PITCH_FORM,
  APP_PLAYBOOK_MESSAGING_QQ_FORM,
  APP_PLAYBOOK_MESSAGING_SNIPPET_FORM,
} from '../../../app/_constants/routes';
import { TEMPLATE_TYPES } from '../../../utils/templates.utils';
import MessagingTemplateCard from '../messagingTemplateCard';
import EmailButton from '../../../components/emailButton';
import { copyToClipboard } from '../../../misc/utils';
import { convertHtmlToString } from '../../../utils/email.utils';
import { useRouter } from '../../../hooks';
import { useSelectedLead } from '../../../hooks/useSelectedLead';
import { getFieldByLogicRole, getValueFromLogicRole } from '../../../utils/bobjects.utils';
import { LEAD_FIELDS_LOGIC_ROLE } from '../../../constants/lead';

const TEMPLATE_FORMS_ROUTES = Object.freeze({
  [TEMPLATE_TYPES.EMAIL]: APP_PLAYBOOK_MESSAGING_EMAIL_FORM,
  [TEMPLATE_TYPES.LINKEDIN]: APP_PLAYBOOK_MESSAGING_LINKEDIN_FORM,
  [TEMPLATE_TYPES.PITCH]: APP_PLAYBOOK_MESSAGING_PITCH_FORM,
  [TEMPLATE_TYPES.SNIPPET]: APP_PLAYBOOK_MESSAGING_SNIPPET_FORM,
  [TEMPLATE_TYPES.QUALIFYING_QUESTION]: APP_PLAYBOOK_MESSAGING_QQ_FORM,
});

const TEMPLATE_CARD_ACTIONS = {
  [TEMPLATE_TYPES.EMAIL]: template => (
    <>
      <EmailButton template={template} />
      <EmailButton template={template} isFromBB />
    </>
  ),
  [TEMPLATE_TYPES.LINKEDIN]: (template, selectedLead) => {
    const linkedInLeadLink = getValueFromLogicRole(
      selectedLead,
      LEAD_FIELDS_LOGIC_ROLE.LINKEDIN_URL,
    );
    const salesNavLink = getValueFromLogicRole(selectedLead, LEAD_FIELDS_LOGIC_ROLE.SALES_NAV_URL);
    const selectedLeadName = getValueFromLogicRole(
      selectedLead,
      LEAD_FIELDS_LOGIC_ROLE.FULL_NAME,
      true,
    );
    const company = getFieldByLogicRole(selectedLead, LEAD_FIELDS_LOGIC_ROLE.COMPANY)
      ?.referencedBobject;
    return (
      <>
        <Button
          size="small"
          onClick={() => {
            if (linkedInLeadLink) {
              window.open(
                linkedInLeadLink + '?bb-messaging-tab-open&templateId=' + template?.id,
                '_blank',
              );
            } else {
              window.open(
                'https://www.linkedin.com/messaging/thread/new/?bbFullName=' +
                  selectedLeadName +
                  '&templateId=' +
                  template?.id +
                  '&leadId=' +
                  selectedLead?.id?.objectId +
                  '&companyId=' +
                  company?.id?.objectId,
                '_blank',
              );
            }
          }}
          iconLeft="linkedin"
          uppercase
        >
          Open in Linkedin
        </Button>
        {salesNavLink && (
          <Button
            size="small"
            onClick={() => {
              window.open(
                salesNavLink + '?bb-messaging-tab-open&templateId=' + template?.id,
                '_blank',
              );
            }}
            iconLeft="compass"
            uppercase
          >
            Open in Sales Navigator
          </Button>
        )}
      </>
    );
  },
  [TEMPLATE_TYPES.SNIPPET]: () => <></>,
  [TEMPLATE_TYPES.PITCH]: () => <></>,
  [TEMPLATE_TYPES.QUALIFYING_QUESTION]: () => <></>,
};

const MessagingTemplateCollectionView = ({ templateCollection, templateType, type }) => {
  const { history } = useRouter();
  const location = useLocation();
  const { selectedLead } = useSelectedLead();

  return (
    <div className={styles._container} data-intercom={`contact-view-messaging-${type}`}>
      {templateCollection.map(template => (
        <MessagingTemplateCard
          key={template.id}
          templateName={template.name}
          templateSubject={template.previewSubject}
          enabled={template.enabled}
          templateFormat={template.format}
          type={type}
          templateBody={template.previewContent || template.question}
          templateType={templateType}
          templateStatistics={template.templateStatistics}
          lastUpdated={template.updateDatetime}
          template={template}
          actions={TEMPLATE_CARD_ACTIONS[templateType](template, selectedLead)}
          onClone={() => {
            const url = `${TEMPLATE_FORMS_ROUTES[templateType]}?mode=CLONE&id=${template.id}&from=${location.pathname}`;
            history.push(url);
          }}
          onCopy={() => {
            const plain = convertHtmlToString(template.previewContent);
            copyToClipboard({ html: template.previewContent, plain });
          }}
          onEdit={e => {
            const url = `${TEMPLATE_FORMS_ROUTES[templateType]}?mode=EDITION&id=${template.id}&from=${location.pathname}`;
            history.push(url, { event: e });
          }}
        />
      ))}
    </div>
  );
};

MessagingTemplateCollectionView.defaultProps = {
  templateCollection: [],
  type: 'TEMPLATE_MANAGEMENT',
};

export default MessagingTemplateCollectionView;
