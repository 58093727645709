import { useMemo, useState } from 'react';
import { atom, useRecoilState } from 'recoil';
import { useCadences } from '@bloobirds-it/cadence';
import { CadenceObject } from '../../../../../typings/cadence';
import { useUserSettings } from '../../../../../components/userPermissions/hooks';
import { BobjectTypes } from '@bloobirds-it/types';

const cadenceOrderAtom = atom({
  key: 'cadenceListOrderAtom',
  default: {},
});

export const useOrderedCadenceList = (
  bobjectType: BobjectTypes | BobjectTypes[],
  searchValue: string,
) => {
  const settings = useUserSettings();
  const { cadences, refreshCadences } = useCadences(bobjectType, settings?.account?.id);
  const [sort, setSort] = useRecoilState(cadenceOrderAtom);
  const [orderingField, setOrderingField] = useState('');

  const orderedCadences = useMemo(() => {
    if (cadences && cadences.length) {
      let filtered = cadences;

      const availableSortingFields = filtered?.length && [
        ...Object.keys(filtered[0]),
        ...Object.keys(filtered[0]?.statistics),
      ];
      const isStatisticField =
        filtered?.length && Object.keys(filtered[0]?.statistics).includes(orderingField);

      if (searchValue) {
        filtered = filtered?.filter((cadence: CadenceObject) =>
          cadence.name.toLowerCase().includes(searchValue.toLowerCase()),
        );
      }
      if (availableSortingFields?.includes(orderingField) && orderingField) {
        return filtered?.sort((a: any, b: any) => {
          if (!a[orderingField]) a[orderingField] = '';
          if (!b[orderingField]) b[orderingField] = '';
          if (sort[orderingField] === 'ASC') {
            return isStatisticField
              ? a.statistics[orderingField] - b.statistics[orderingField]
              : a[orderingField].localeCompare(b[orderingField]);
          } else {
            return isStatisticField
              ? b.statistics[orderingField] - a.statistics[orderingField]
              : b[orderingField].localeCompare(a[orderingField]);
          }
        });
      } else
        return filtered?.sort((a: any, b: any) => {
          return b?.lastEntityUpdate.localeCompare(a?.lastEntityUpdate);
        });
    }
    return [];
  }, [searchValue, orderingField, sort, cadences]);

  const handleReorder = (category: string) => {
    if (sort[category]) {
      setSort({
        [category]: sort[category] === 'ASC' ? 'DESC' : 'ASC',
      });
    } else {
      setSort({ [category]: 'ASC' });
    }
    setOrderingField(category);
  };

  return {
    orderedCadences,
    handleReorder,
    sort,
    refreshCadences,
    setOrderingField,
  };
};
