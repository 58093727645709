import useSWR from 'swr';
import { ServiceApi } from '../misc/api/service';

export const useBuyerPersonasList = () => {
  const { data: buyerPersonas } = useSWR('/idealCustomerProfiles', () =>
    ServiceApi.request({
      url: '/service/view/idealCustomerProfile',
      method: 'GET',
    }),
  );

  return {
    buyerPersonas,
  };
};
