import React, { useCallback, useEffect, useRef, useState } from 'react';
import { EntityCardItem } from '../../../../../components/entityList/entityCard/entityCard';
import {
  Button,
  Dropdown,
  Icon,
  IconButton,
  Input,
  Switch,
  Tag,
  Text,
  useVisible,
} from '@bloobirds-it/flamingo-ui';
import styles from '../../businessAssetsPage.module.css';
import { PickableIcon, pickableIcons } from '../customTasks.constants';
import { useCustomTaskCreation } from '../hooks/useCustomTaskCreation';
import { api } from '../../../../../utils/api';
import { CustomTaskFieldsModal } from '../customTaskFieldsModal/customTaskFieldsModal';
import { useEntity } from '../../../../../hooks';
import { CustomTask, CustomTaskField } from '@bloobirds-it/types';

interface CustomTaskCardProps {
  customTask: CustomTask;
  isNew?: boolean;
  clickOutside?: boolean;
  onCardUpdated: (customTask: CustomTask) => void;
}

interface IconPickerProps {
  icon: PickableIcon;
  onSelectIcon: (icon: PickableIcon) => void;
  hasError: boolean;
}

const IconPicker = ({ icon, onSelectIcon, hasError }: IconPickerProps) => {
  const [selectedIcon, setSelectedIcon] = useState<PickableIcon>(icon);
  const { ref, visible, setVisible } = useVisible(false);

  const handleSelected = (iconPick: PickableIcon) => {
    setSelectedIcon(iconPick);
    if (onSelectIcon) {
      onSelectIcon(iconPick);
    }
    setVisible(false);
  };
  return (
    <Dropdown
      ref={ref}
      visible={visible}
      anchor={
        <div
          className={styles.iconPickerButton}
          style={hasError ? { border: '1px solid var(--tomato)' } : undefined}
        >
          <IconButton
            name={selectedIcon?.name ?? 'questionCircle'}
            color={selectedIcon?.color ?? 'bloobirds'}
            onClick={() => setVisible(true)}
            size={24}
          />
        </div>
      }
    >
      <div className={styles.iconPicker}>
        {pickableIcons.map(pic => (
          <IconButton
            name={pic.name}
            color={pic.color}
            key={pic.name}
            onClick={() => {
              handleSelected({ ...pic });
            }}
          />
        ))}
      </div>
    </Dropdown>
  );
};

export const CustomTaskCard = ({
  customTask,
  isNew = false,
  onCardUpdated,
  clickOutside,
}: CustomTaskCardProps) => {
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [edit, setEdit] = useState<boolean>(isNew);
  const [task, setTask] = useState<CustomTask>(customTask);
  const [error, setError] = useState<{ name?: string; description?: string; icon?: boolean }>({});
  const [showHiddenFields, setShowHiddenFields] = useState(false);
  const { setCustomTaskCreation } = useCustomTaskCreation();
  const handleToggleEnabled = (value: boolean) => {
    updateTask('enabled', value);
    enableTask(task.id, value);
  };

  useEffect(() => {
    if (!isNew && edit) {
      setEdit(false);
    }
  }, [clickOutside]);
  const refName = useRef<HTMLInputElement>();
  const refDesc = useRef<HTMLInputElement>();
  const revertChanges = useCallback(() => {
    if (isNew) {
      setCustomTaskCreation(false);
    }
    setTask(customTask);
    setError({});
    setEdit(false);
    refName?.current?.blur();
    refDesc?.current?.blur();
  }, [refName, customTask]);

  const updateTask = (key: keyof CustomTask, value: any) => {
    setTask({ ...task, [key]: value });
  };

  const handleFieldsUpdate = (fields: CustomTaskField[]) => {
    saveCard({
      ...task,
      fields: fields.map((f, index) => ({ ...f, ordering: fields.length - index - 1 })),
    });
  };

  const saveCard = async (taskToSave: CustomTask) => {
    let updatedTask: CustomTask;
    if (!taskToSave.name || !taskToSave.description || taskToSave.icon === 'questionCircle') {
      setError({
        name: taskToSave.name ? undefined : 'The task must have a name',
        description: taskToSave.description ? undefined : 'The task must have a description',
        icon: taskToSave.icon === 'questionCircle',
      });
    } else {
      if (taskToSave.id) {
        const { data } = await api.patch<CustomTask>(
          `/utils/customTask/${taskToSave.id}`,
          {
            ...taskToSave,
            fields: taskToSave.fields ?? [],
          },
          { params: { id: taskToSave.id } },
        );
        updatedTask = data;
      } else {
        const { data } = await api.post<CustomTask>('/utils/customTask', {
          ...taskToSave,
          ordering: 0,
          fields: taskToSave.fields ?? [],
        });

        updatedTask = data;
      }

      setEdit(false);
      setTask({ ...updatedTask });
      setError({});
      onCardUpdated(updatedTask);
    }
  };

  const enableTask = (customTaskId: string, enabled: boolean) => {
    const url = `/utils/customTask/${enabled ? 'enable' : 'disable'}/${customTaskId}`;

    api.patch<CustomTask>(url, {}, { params: { id: customTaskId } }).then(response => {
      setTask({ ...response.data });
      onCardUpdated(response.data);
    });
  };

  const bobjectFields = useEntity('bobjectFields');

  return (
    <>
      <>
        <EntityCardItem>
          <Icon name="dragAndDrop" size={24} color="softPeanut" />
          {edit ? (
            <IconPicker
              icon={{ name: customTask.icon, color: customTask.iconColor }}
              onSelectIcon={pick => {
                setTask({ ...task, icon: pick.name, iconColor: pick.color });
              }}
              hasError={error.icon}
            />
          ) : (
            <IconButton
              name={task.icon}
              color={task.iconColor}
              size={24}
              onClick={() => setEdit(true)}
            />
          )}
          <Input
            innerRef={refName}
            size="small"
            width="300px"
            placeholder="Set your task type name"
            transparent={!edit}
            value={task.name}
            onChange={value => updateTask('name', value)}
            onFocus={() => setEdit(true)}
            error={error.name}
          />
        </EntityCardItem>
        <EntityCardItem>
          <Input
            innerRef={refDesc}
            size="small"
            width="450px"
            placeholder="Briefly describe what the task is about"
            transparent={!edit}
            value={task.description}
            onChange={value => updateTask('description', value)}
            onFocus={() => setEdit(true)}
            error={error.description}
          />
        </EntityCardItem>
        <EntityCardItem>
          {(!customTask?.fields || customTask?.fields?.length === 0 || edit) && (
            <Button
              variant="clear"
              iconLeft="add"
              uppercase={false}
              onClick={() => setOpenModal(true)}
            >
              {!customTask?.fields || customTask?.fields?.length === 0 ? 'Add' : 'Edit'}
            </Button>
          )}

          <div className={styles.customTaskFields}>
            {task.fields
              .slice(0, showHiddenFields ? task.fields.length : 2)
              .sort((a, b) => b.ordering - a.ordering)
              .map(field => (
                <Tag uppercase={false} key={field.bobjectFieldId}>
                  <Text size="s" ellipsis={35}>
                    {bobjectFields?.get(field.bobjectFieldId).name}
                  </Text>
                  {field.required && <Icon name="required" size={16} />}
                </Tag>
              ))}
            <span className={styles.taskFieldsCounter} onClick={() => setShowHiddenFields(true)}>
              {!showHiddenFields && task.fields.length > 2 && `+${task.fields.slice(2).length}`}
            </span>
          </div>
        </EntityCardItem>
        <EntityCardItem size="small">
          <span className={styles._status__span}>
            {edit ? (
              <>
                <span className={styles._status__span}>
                  <Button color="purple" size="small" onClick={() => saveCard(task)}>
                    save
                  </Button>
                  <IconButton color="purple" onClick={revertChanges} name="undoRevert" size={24} />
                </span>
              </>
            ) : (
              <>
                <Switch checked={task.enabled} color="purple" onChange={handleToggleEnabled} />
                <IconButton
                  color="purple"
                  onClick={() => {
                    setEdit(true);
                  }}
                  name={'edit'}
                  size={24}
                />
              </>
            )}
          </span>
        </EntityCardItem>
      </>
      {openModal && (
        <CustomTaskFieldsModal
          onClose={() => setOpenModal(false)}
          customTaskFields={task.fields}
          onSave={handleFieldsUpdate}
        />
      )}
    </>
  );
};
