import useSWRImmutable from 'swr/immutable';
import SessionManagerFactory from '../misc/session';

const SessionManager = SessionManagerFactory();

export const useFeatureFlags = () => {
  const account = SessionManager.getAccount();
  const { data: featureFlags } = useSWRImmutable(`/featureFlags/feature/account/${account?.id}`);
  const isFlagEnabled = (flag: string): boolean => {
    const featureFlag = featureFlags?.features?.find(
      (feature: { featureName?: string; featureType?: string }) =>
        (feature.featureName || feature.featureType) === flag,
    );
    return featureFlag ? featureFlag.active : false;
  };

  return { featureFlags, isFlagEnabled };
};

export const useInboundHubspotEnabled = () => {
  const { isFlagEnabled } = useFeatureFlags();
  return isFlagEnabled('INBOUND_HUBSPOT');
};

export const useInboundSalesforceEnabled = () => {
  return true;
};

export const useFullSalesEnabled = () => {
  const { isFlagEnabled } = useFeatureFlags();
  return isFlagEnabled('SALES');
};

export const useSalesDashboardEnabled = () => {
  const { isFlagEnabled } = useFeatureFlags();
  return isFlagEnabled('DASHBOARD_SALES');
};

export const useOldReportingEnabled = () => {
  const { isFlagEnabled } = useFeatureFlags();
  return isFlagEnabled('OLD_REPORTING');
};

export const useSendFromMailEnabled = () => {
  const { isFlagEnabled } = useFeatureFlags();
  return isFlagEnabled('SEND_FROM_MAIL');
};

export const useDynamicsEnabled = () => {
  const { isFlagEnabled } = useFeatureFlags();
  return isFlagEnabled('DYNAMICS');
};

export const useVtigerEnabled = () => {
  const { isFlagEnabled } = useFeatureFlags();
  return isFlagEnabled('VTIGER');
};

export const useProductsEnabled = () => {
  const { isFlagEnabled } = useFeatureFlags();
  return isFlagEnabled('PRODUCTS');
};

export const useNewDashboardEnabled = () => {
  const { isFlagEnabled } = useFeatureFlags();
  return isFlagEnabled('NEW_DASHBOARDS');
};

export const useQuickStartEnabled = () => {
  return true;
};

export const useRefactorQuickFilterEnabled = () => {
  return true;
};

export const useIntegrationApp = () => {
  const { isFlagEnabled } = useFeatureFlags();
  return isFlagEnabled('INTEGRATION_APP');
};

export const useBulkActionsEnabled = () => {
  return true;
};

export const useSetCadenceEnabled = () => {
  const { isFlagEnabled } = useFeatureFlags();
  return isFlagEnabled('SET_CADENCE');
};

export const useCalendarEnabled = () => {
  return true;
};

export const useNewMeetingTab = () => {
  const { isFlagEnabled } = useFeatureFlags();
  return isFlagEnabled('MEETING_SALES_TAB');
};

export const useOnboardingFlow = () => {
  return true;
};

export const useProspectingNurturingTab = () => {
  const { isFlagEnabled } = useFeatureFlags();
  return isFlagEnabled('NURTURING_PROSPECT_TAB');
};

export const useSalesNurturingTab = () => {
  const { isFlagEnabled } = useFeatureFlags();
  return isFlagEnabled('NURTURING_SALES_TAB');
};

export const useMultiPicklistEnabled = () => {
  return true;
};
export const useSalesforceOauthEnabled = () => {
  const { isFlagEnabled } = useFeatureFlags();
  return isFlagEnabled('SALESFORCE_OAUTH');
};

export const useNewInactiveHandling = () => {
  return true;
};

export const useAutoMarkAsDoneEnabled = () => {
  return true;
};

export const useGlobalSearchBar = () => {
  return true;
};

export const useSmartEmailEnabled = () => {
  const { isFlagEnabled } = useFeatureFlags();
  return isFlagEnabled('SMART_EMAIL');
};

export const useMeetingLinksEnabled = () => {
  return true;
};

export const useNewCadenceTableEnabled = () => {
  const { isFlagEnabled } = useFeatureFlags();
  return isFlagEnabled('NEW_CADENCE_TABLE');
};

export const useSnippetsEnabled = () => {
  const { isFlagEnabled } = useFeatureFlags();
  return isFlagEnabled('SNIPPETS');
};

export const useCreateReceivedMeeting = () => {
  const { isFlagEnabled } = useFeatureFlags();
  return isFlagEnabled('CREATE_RECEIVED_MEETING');
};
