// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".messagingTemplateFooterActions-module__footer___RgSX4 {\n  background-color: var(--white);\n  padding: 20px 56px;\n  display: flex;\n  justify-content: space-between;\n  position: fixed;\n  bottom: 0;\n  left: 0;\n  width: 100%;\n  box-sizing: border-box;\n}\n\n.messagingTemplateFooterActions-module__buttons___q4Zno > *:first-child {\n  margin-right: 28px;\n}\n\n.messagingTemplateFooterActions-module__buttons___q4Zno {\n  display: flex;\n  gap: 8px;\n}\n", "",{"version":3,"sources":["webpack://./src/js/components/messagingTemplates/messagingTemplateFooterActions/messagingTemplateFooterActions.module.css"],"names":[],"mappings":"AAAA;EACE,8BAA8B;EAC9B,kBAAkB;EAClB,aAAa;EACb,8BAA8B;EAC9B,eAAe;EACf,SAAS;EACT,OAAO;EACP,WAAW;EACX,sBAAsB;AACxB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,aAAa;EACb,QAAQ;AACV","sourcesContent":[".footer {\n  background-color: var(--white);\n  padding: 20px 56px;\n  display: flex;\n  justify-content: space-between;\n  position: fixed;\n  bottom: 0;\n  left: 0;\n  width: 100%;\n  box-sizing: border-box;\n}\n\n.buttons > *:first-child {\n  margin-right: 28px;\n}\n\n.buttons {\n  display: flex;\n  gap: 8px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"footer": "messagingTemplateFooterActions-module__footer___RgSX4",
	"buttons": "messagingTemplateFooterActions-module__buttons___q4Zno"
};
export default ___CSS_LOADER_EXPORT___;
