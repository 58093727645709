import React from 'react';
import mixpanel from 'mixpanel-browser';
import { CardButton } from '@bloobirds-it/flamingo-ui';
import { MIXPANEL_EVENTS } from '../../../../../constants/mixpanel';
import { Bobject } from '../../../../../typings/bobjects';
import { useInactiveHandlingModal } from '../../../../../components/inactiveHandlingModal/useInactiveHandlingModal';
import { BobjectTypes } from '../../../../../constants/bobject';
import { isSalesPage } from '../../../../../utils/pages.utils';

export const CardNextStepButton = ({
  isSmallDesktop,
  bobject,
}: {
  isSmallDesktop?: boolean;
  bobject?: Bobject;
}) => {
  const { setModalState } = useInactiveHandlingModal();
  const subhomeStage = isSalesPage(window.location.href) ? 'SALES' : 'PROSPECTING';
  return (
    <CardButton
      dataTest="Subhome-QuickStart"
      iconLeft="bookmark"
      onClick={event => {
        mixpanel.track(
          MIXPANEL_EVENTS[
            `NEXT_STEP_ACTION_CLICKED_ON_INACTIVE_${subhomeStage}_${
              bobject?.id?.typeName?.toUpperCase() as Uppercase<BobjectTypes.Company>
            }_TAB`
          ],
        );
        event.preventDefault();
        event.stopPropagation();
        setModalState({ visible: true, bobject });
      }}
    >
      {!isSmallDesktop && 'Next Step'}
    </CardButton>
  );
};
