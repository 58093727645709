import React, { useCallback, useState } from 'react';
import {
  Icon,
  Text,
  Card,
  CardHoverButtons,
  CardBody,
  CardButton,
  CardLeft,
  CardRight,
  CardHeader,
  CardContent,
} from '@bloobirds-it/flamingo-ui';
import { useEmailConnections, usePhoneConnections } from '../../hooks';
import { formatDistance } from '../../utils/dates.utils';
import DisconnectModal from './disconnectModal';
import styles from './connectionCard.module.css';
import { GoogleSvg, MicrosoftSvg } from '../../../assets/svg';
import { AddAliasModal } from '../addAliasModal/addAliasModal';
import { AliasName } from './aliasName/aliasName';

function getDate(createdAt) {
  if (createdAt && typeof createdAt === 'string') {
    return new Date(createdAt);
  } else {
    return new Date(
      createdAt.year,
      createdAt.monthValue - 1,
      createdAt.dayOfMonth,
      createdAt.hour,
      createdAt.minute,
      createdAt.second,
    );
  }
}

const ConnectionCard = ({ data, isNylas, isDefault, type = 'email' }) => {
  const isEmail = type === 'email';
  const hookType = isEmail ? useEmailConnections : usePhoneConnections;
  const { updateDefaultConnection, disconnectConnection } = hookType();
  const [aliasModalOpen, setAliasModalOpen] = useState();
  const [openModal, setOpenModal] = useState(false);
  const { createdAt, email, phoneNumber: phone, id: connectionId, syncState } = data;
  const isStopped = syncState === 'stopped' || syncState === 'invalid';
  const date = createdAt && isEmail ? getDate(createdAt) : new Date(data?.creationDatetime);

  date.setHours(date.getHours() - new Date().getTimezoneOffset() / 60);

  const getConnectionIcon = useCallback(() => {
    const emailIcon =
      data?.provider === 'gmail' ? (
        <GoogleSvg />
      ) : data?.provider === 'eas' ? (
        <MicrosoftSvg />
      ) : null;
    const icon = !isNylas ? <GoogleSvg /> : emailIcon || <Icon name="mail" color="tangerine" />;

    return !isEmail ? <Icon name="phone" color="melon" /> : icon;
  }, [type]);
  return (
    <>
      <div className={styles._card__container}>
        <Card width={650}>
          <CardHeader>
            <CardLeft>{getConnectionIcon()}</CardLeft>
            <CardBody>
              <Text size="s" color="peanut" inline>
                {isEmail ? email : phone}
              </Text>
              {isDefault && <Icon name="starChecked" color="softBanana" size={16} />}
            </CardBody>
            {isEmail && isStopped ? (
              <CardRight>
                <Text size="xs" color="softTomato" inline align="right">
                  Requires to be reconnected
                </Text>
              </CardRight>
            ) : (
              <>
                {date && (
                  <CardRight>
                    <Text size="s" color="softPeanut" inline align="right">
                      {`Added ${formatDistance(date, new Date())} ago`}
                    </Text>
                  </CardRight>
                )}
              </>
            )}
            <CardHoverButtons>
              {isEmail && (
                <CardButton variant="clear" size="small" onClick={() => setAliasModalOpen(true)}>
                  Add Alias
                </CardButton>
              )}
              <CardButton
                variant="secondary"
                size="small"
                onClick={() => {
                  const connection = isEmail ? email : connectionId;
                  updateDefaultConnection(connection);
                }}
              >
                Set as defaults
              </CardButton>
              <CardButton size="small" onClick={() => setOpenModal(true)}>
                Disconnect
              </CardButton>
            </CardHoverButtons>
          </CardHeader>
          {data?.nylasAliases?.length > 0 ? (
            <CardContent>
              <div className={styles._alias_container}>
                <Text size="s" color="softPeanut">
                  Aliases
                </Text>
                <div className={styles._alias_list}>
                  {data?.nylasAliases?.map(alias => (
                    <AliasName key={alias?.id} alias={alias} />
                  ))}
                </div>
              </div>
            </CardContent>
          ) : (
            <></>
          )}
        </Card>
      </div>
      {aliasModalOpen && (
        <AddAliasModal nylasAccount={data} onClose={() => setAliasModalOpen(false)} />
      )}
      {openModal && (
        <DisconnectModal
          open
          type={type}
          handleClose={() => setOpenModal(false)}
          handleConfirm={() => disconnectConnection(connectionId, isNylas)}
          connection={isEmail ? email : phone}
        />
      )}
    </>
  );
};

export default ConnectionCard;
