import spacetime from 'spacetime';
import {
  addDays,
  addMinutes as addMinutesFn,
  addMonths,
  addSeconds,
  differenceInCalendarDays,
  differenceInDays,
  eachDayOfInterval,
  endOfMonth,
  endOfWeek,
  format,
  formatDistance as formatDistanceFn,
  formatDistanceToNow as formatDistanceToNowFn,
  getDate,
  getDay,
  getWeeksInMonth as getWeeksInMonthFn,
  isAfter,
  isBefore,
  isSameDay as isSameDayFn,
  isWeekend as isWeekendFn,
  isYesterday,
  startOfDay,
  startOfMonth,
  startOfWeek,
  subDays,
  subMinutes as subMinutesFn,
  subMonths,
} from 'date-fns';


// eslint-disable-next-line prettier/prettier
type TYear         = `${number}${number}${number}${number}`;
type TMonth        = `${number}${number}`;
type TDay          = `${number}${number}`;
type THours        = `${number}${number}`;
type TMinutes      = `${number}${number}`;
type TSeconds      = `${number}${number}`;
type TMilliseconds = `${number}${number}${number}`;

/**
 * Represent a string like `2021-01-08`
 *
 * @deprecated use the one in @bloobirds-it/utils
 */
export type TDateISODate = `${TYear}-${TMonth}-${TDay}`;

/**
 * Represent a string like `14:42:34.678`
 *
 * @deprecated use the one in @bloobirds-it/utils
 */
export type TDateISOTime = `${THours}:${TMinutes}:${TSeconds}.${TMilliseconds}`;

/**
 * Represent a string like `2021-01-08T14:42:34.678Z` (format: ISO 8601).
 *
 * It is not possible to type more precisely (list every possible values for months, hours etc) as
 * it would result in a warning from TypeScript:
 *   "Expression produces a union type that is too complex to represent. ts(2590)
 *
 * @deprecated use the one in @bloobirds-it/utils
 */
export type TDateISO = `${TDateISODate}T${TDateISOTime}Z`;

/**
 * @deprecated use getISOShortFormattedDate instead (from general utils)
 */
export const getSimpleDate = (date: number | Date) => {
  if (!date) {
    throw new Error('Date parameter is required');
  }
  return format(date, 'yyyy-LL-dd');
};

/**
 * @deprecated use the one in @bloobirds-it/utils
 */
export const getDateRange = ({
  startingDate,
  pastRange = 15,
  futureRange = 3,
  includeToday = true,
}) => {
  const pastDays = Array.from({ length: pastRange }, (v, k) => k + 1).map(i =>
    subDays(startingDate, i),
  );
  const thisDay = includeToday ? [startingDate] : [];
  const futureDays = Array.from({ length: futureRange }, (v, k) => k + 1).map(i =>
    addDays(startingDate, i),
  );
  return pastDays.concat(thisDay, futureDays).map(d => getSimpleDate(d));
};

/**
 * @deprecated use the one in @bloobirds-it/utils
 */
export const getRangeBetweenDates = (startDate: string, endDate: string, formatPattern = 'yyyy-MM-dd') => {
  const days = differenceInDays(new Date(endDate), new Date(startDate));

  return Array(days + 1).map((x,i) =>
    format(addDays(new Date(startDate), i), formatPattern),
  );
};

/**
 * @deprecated use the one in @bloobirds-it/utils
 */
export const getWeeksInMonth = (date: number | Date, dirtyOptions: { locale?: Locale; weekStartsOn?: 0 | 1 | 2 | 5 | 3 | 4 | 6; }) => getWeeksInMonthFn(date, dirtyOptions);

/**
 * @deprecated use the one in @bloobirds-it/utils
 */
const getTimeZoneLocationPosition = (completeTimeZone: string) =>
  completeTimeZone.split(' ', 3).join(' ').length;

/**
 * @deprecated use the one in @bloobirds-it/utils
 */
const getWeekOfMonth = (date: number | Date, dirtyOptions: { weekStartsOn?: number; }) => {
  const options = dirtyOptions || {};
  const weekStartsOn = options.weekStartsOn == null ? 0 : options.weekStartsOn;

  // Test if weekStartsOn is between 0 and 6 _and_ is not NaN
  if (!(weekStartsOn >= 0 && weekStartsOn <= 6)) {
    throw new RangeError('weekStartsOn must be between 0 and 6 inclusively');
  }

  const currentDayOfMonth = getDate(date);
  if (Number.isNaN(currentDayOfMonth)) {
    return currentDayOfMonth;
  }

  const startWeekDay = getDay(startOfMonth(date));
  let lastDayOfFirstWeek;

  if (startWeekDay >= weekStartsOn) {
    lastDayOfFirstWeek = weekStartsOn + 7 - startWeekDay;
  } else {
    lastDayOfFirstWeek = weekStartsOn - startWeekDay;
  }

  let weekNumber = 1;

  if (currentDayOfMonth > lastDayOfFirstWeek) {
    const remainingDaysAfterFirstWeek = currentDayOfMonth - lastDayOfFirstWeek;
    weekNumber += Math.ceil(remainingDaysAfterFirstWeek / 7);
  }
  return weekNumber;
};

/**
 * @deprecated use the one in @bloobirds-it/utils
 */
export const getDayOfWeekStartingFromMonday = (date: number | Date) =>
  getDay(date) - 1 === -1 ? 6 : getDay(date) - 1;

/**
 * @deprecated use the one in @bloobirds-it/utils
 */
export const getDayAndWeekNumberFromDate = ({ date }: { date: number | Date }) => {
  const numbers = { dayNumber: 0, weekNumber: 0 };
  numbers.dayNumber = getDayOfWeekStartingFromMonday(date);
  numbers.weekNumber = getWeekOfMonth(date, { weekStartsOn: 1 }) - 1;
  return numbers;
};

export const isToday = (date: number | Date) => isSameDayFn(date, new Date());
export const isTomorrow = (date: number | Date) => isSameDayFn(date, addDays(new Date(), 1));
export const isBeforeToday = (date: number | Date) => isBefore(date, addMinutes(startOfDay(new Date()), 1));
export const isAfterToday = (date: number | Date) => isAfter(date, startOfDay(new Date()));

/**
 * @deprecated use the one in @bloobirds-it/utils
 */
export const isAfterTomorrow = (date: number | Date) => isAfter(date, addDays(startOfDay(new Date()), 1));
/**
 * @deprecated use the one in @bloobirds-it/utils
 */
export const isWeekend = (date: number | Date) => isWeekendFn(date);
/**
 * @deprecated use the one in @bloobirds-it/utils
 */
const isSameDay = (date1: number | Date, date2: number | Date) => isSameDayFn(date1, date2);

/**
 * @deprecated use the one in @bloobirds-it/utils
 */
export const intervalDaysOfMonth = ({ date }: { date: number | Date }) =>
  eachDayOfInterval({
    start: startOfMonth(date),
    end: endOfMonth(date),
  });

/**
 * @deprecated use the one in @bloobirds-it/utils
 */
export const lastWeekOfPrevMonth = ({ date }: { date: number | Date }) => {
  const start = startOfWeek(startOfMonth(date), { weekStartsOn: 1 });
  const end = subDays(startOfMonth(date), 1);
  return isBefore(end, start)
    ? []
    : eachDayOfInterval({
        start,
        end,
      });
};

/**
 * @deprecated use the one in @bloobirds-it/utils
 */
export const firstWeekOfNextMonth = ({ date }: { date: number | Date }) => {
  const start = addDays(endOfMonth(date), 1);
  const end = endOfWeek(endOfMonth(date), { weekStartsOn: 1 });
  return isAfter(start, end)
    ? []
    : eachDayOfInterval({
        start,
        end,
      });
};

/**
 * @deprecated use the one in @bloobirds-it/utils
 */
export { addDays, getDaysInMonth, differenceInDays, subDays, startOfDay, endOfDay } from 'date-fns';
/**
 * @deprecated use the one in @bloobirds-it/utils
 */
export const isAfterDate = (dateToCompare: number | Date, date: number | Date) => isAfter(dateToCompare, date);
/**
 * @deprecated use the one in @bloobirds-it/utils
 */
export const isSameDayDate = (dateLeft: number | Date, dateRight: number | Date) => isSameDay(dateLeft, dateRight);
/**
 * @deprecated use the one in @bloobirds-it/utils
 */
export const addMonth = (date: number | Date, numberOfMonth = 1) => addMonths(date, numberOfMonth);
/**
 * @deprecated use the one in @bloobirds-it/utils
 */
export const addMinutes = (date: number, minutes: number) => addMinutesFn(date, minutes);
/**
 * @deprecated use the one in @bloobirds-it/utils
 */
export const subMinutes = (date: number, minutes: number) => subMinutesFn(date, minutes);
/**
 * @deprecated use the one in @bloobirds-it/utils
 */
export const subMonth = (date: number, numberOfMonth = 1) => subMonths(date, numberOfMonth);
/**
 * @deprecated use the one in @bloobirds-it/utils
 */
export const formatDate = (date: any, formatString: string) => {
  if (!date) {
    throw new Error('date parameter is required');
  }

  return format(date, formatString);
};
/**
 * @deprecated use the one in @bloobirds-it/utils
 */
export const formatDistance = (date1: Date | number, date2: Date | number) => {
  if (!date1 || !date2) {
    throw new Error('date parameter is required');
  }
  return formatDistanceFn(date1, date2);
};

/**
 * @deprecated use the one in @bloobirds-it/utils
 */
export const formatDistanceToNow = (date: number | Date) => {
  if (!date) {
    throw new Error('date parameter is required');
  }
  return formatDistanceToNowFn(date);
};

/**
 * @deprecated use the one in @bloobirds-it/utils
 */
export const formatSecondToElapsedTime = (seconds: number) => {
  const helperDate = addSeconds(new Date(1970, 0, 1), seconds);
  return format(helperDate, 'HH:mm:ss');
};

/**
 * @deprecated use the one in @bloobirds-it/utils
 */
export const today = () =>
  new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), 0, 0, 0);

/**
 * @deprecated use the one in @bloobirds-it/utils
 */
export const transformCalendarEventDate = (dateTime: Date, applyTZOffset = true) => {
  const day = dateTime.getDate();
  let hour = dateTime.getHours();
  const min = dateTime.getMinutes();
  const month = dateTime.getMonth() + 1;
  const year = dateTime.getFullYear();

  if (applyTZOffset) {
    hour += dateTime.getTimezoneOffset() / 60;
  }

  const monthStr = `0${month}`.slice(-2);
  const hourStr = `0${hour}`.slice(-2);
  const minStr = `0${min}`.slice(-2);
  const dayStr = `0${day}`.slice(-2);
  return {
    year,
    monthStr,
    hourStr,
    minStr,
    dayStr,
  };
};

/**
 * @deprecated use the one in @bloobirds-it/utils
 */
export const parseTimeToDatetime = (timeToParse: string) => {
  const unitTime = timeToParse?.substr(-1);
  const time = timeToParse?.substr(0, timeToParse.length - 1);
  const todayTime = new Date().getTime();
  let convert = 60 * 1000;

  if (unitTime === 'M') {
    return addMonths(new Date(), time);
  }

  if (unitTime === 'h') {
    convert = 60 * 60 * 1000;
  }
  if (unitTime === 'd') {
    convert = 24 * 60 * 60 * 1000;
  }
  return new Date(todayTime + convert * time);
};

/**
 * @deprecated use the one in @bloobirds-it/utils
 */
export const getDateFormatted = (date: string) => {
  if (date?.indexOf('T') > 0) {
    return `${date
      .split('T')[0]
      .split('-')
      .map(x => {
        if (x.length <= 1) {
          return (x = `0${x}`);
        }
        return x;
      })
      .join('-')}T${date
      .split('T')[1]
      .split(':')
      .map(x => {
        if (x.length <= 1) {
          return (x = `0${x}`);
        }
        return x;
      })
      .join(':')}`;
  }
  return date
    .split('-')
    .map(x => {
      if (x.length <= 1) {
        return (x = `0${x}`);
      }
      return x;
    })
    .join('-');
};

/**
 * @deprecated use the one in @bloobirds-it/utils
 */
export const formatDateAsText = (
  text,
  patternFormat = '{month} {date-ordinal}, {year}',
  timeZone = getUserTimeZone(),
) => (text ? spacetime(text, 'UTC').goto(timeZone).format(patternFormat) : 'never');

export const generateDatePrefix = (date: Date, diffDatePrefix = false) => {
  if (!date) {
    return '-';
  }
  if (isToday(date)) {
    return 'Today ';
  }
  if (isYesterday(date)) {
    return 'Yesterday ';
  }
  if (isTomorrow(date)) {
    return 'Tomorrow ';
  }
  if (diffDatePrefix) {
    const diffDays = differenceInCalendarDays(date, new Date());
    if (diffDays > 30 || diffDays < -30) {
      const patternFormat = `{month-short} {date}${diffDays < -365 || diffDays > 365 ? ' {year}' : ''}`;
      return formatDateAsText(date, patternFormat);
    }
    return diffDays > 0 ? `In ${diffDays} days` : `${-diffDays} days ago`;
  }
  return '';
};

/**
 * @deprecated use the one in @bloobirds-it/utils
 */
export const getDateTimestampString = (date: Date) =>
  new Date(date.getFullYear(), date.getMonth(), date.getDay()).getTime().toString();

/**
 * @deprecated use the one in @bloobirds-it/utils
 */
export const getLocationFromCompleteTimeZone = (timezone: string) => {
  const positionValue = getTimeZoneLocationPosition(timezone);
  return timezone.substring(positionValue + 1).replace(' ', '_');
};

/**
 * @deprecated use the one in @bloobirds-it/utils
 */
export const getUTCFromCompleteTimeZone = (timeZone: string) => {
  const positionValue = getTimeZoneLocationPosition(timeZone);
  return timeZone.substring(0, positionValue);
};

/**
 * @deprecated use the one in @bloobirds-it/utils
 */
export const convertLocationToHourMinutes = (timeZoneLocation: string) => {
  const timeZoneFormatter = Intl.DateTimeFormat([], {
    timeZone: timeZoneLocation,
    hour: '2-digit',
    minute: '2-digit',
  });
  return timeZoneFormatter.format(new Date());
};

/**
 * @deprecated use parseTimeToDatetime instead
 */
export const getUTCDate = (date: string | number | Date) =>
  new Date(new Date(date).getTime() - new Date(date).getTimezoneOffset() * 60000);

/**
 * @deprecated use the one in @bloobirds-it/utils
 */
export const getRoundedDateTime = (minutes: number, d: Date = new Date()) => {
  const ms = 1000 * 60 * minutes; // convert minutes to ms
  const date = new Date(Math.round(d.getTime() / ms) * ms);
  return date.toISOString();
};

/**
 * @deprecated use the one in @bloobirds-it/utils
 */
export const getDifferenceInMinutes = ({ startDate = Date.now(), endDate }: { startDate: string | number | Date, endDate: string | number | Date }) => {
  const diff = new Date(endDate).getTime() - new Date(startDate).getTime();
  return Math.ceil(diff / 60000);
};

/**
 * @deprecated use the one in @bloobirds-it/utils
 */
export const addHoursToStringDate = (date: string) => (date?.includes('T') ? date : `${date}T00:00:00.000`);

/**
 * @deprecated use the one in @bloobirds-it/utils
 */
export const isDifferentYearThanCurrent = (date: string | number | Date) =>
  new Date().getFullYear() !== new Date(date).getFullYear();

/**
 * @deprecated use the one in @bloobirds-it/utils
 */
export const getUserTimeZone = () => Intl.DateTimeFormat().resolvedOptions().timeZone;

/**
 * It returns the corrected day for a given UTC date. If the date is in full ISO format (e.g. 2020-01-01T00:00:00.000Z) it trims the time part.
 * Example: If a task is set for 2020-01-01T00:00:00.000Z, the returned date will be 2020-01-01T00:00:00.000Z on the local timezone of the user.
 * If a task is set for 2020-01-01 the returned date will be 2020-01-01T00:00:00.000Z on the local timezone of the user.
 *
 * @param {string} date
 * @param {string} timeZone if it's not declared not it would take the user computer timezone
 * @returns {Date} the corrected js date
 *
 * @deprecated use the one in @bloobirds-it/utils
 */
export function parseUTCDateToLocal(date: string, timeZone = getUserTimeZone()) {
  const regexDateWithTime = /[0-9]{4}-[0-9]{2}-[0-9]{2}T[0-9]{2}:[0-9]{2}:[0-9]{2}(.[0-9]{3})?(z|Z)/g;
  if (date?.match(regexDateWithTime)) {
    const utcDate = spacetime(date, 'UTC');
    date = utcDate.format('iso-short');
  }
  const s = spacetime(date, timeZone);
  return s.toNativeDate();
}

export function parseUTCDateTimeToLocal(date: string | Date, timeZone = getUserTimeZone()) {
  const s = spacetime(date, timeZone);
  return s.toNativeDate();
}

/**
 * It returns the string formatted day (YYYY-MM-DD) for a given UTC date. If the date is in full ISO format (e.g. 2020-01-01T00:00:00.000Z) it trims the time part.
 *
 * @param {string} date
 * @param {string} timeZone
 * @returns {String} the ISO short formatted date (YYYY-MM-DD)
 *
 * @deprecated use the one in @bloobirds-it/utils
 */
export function getISOShortFormattedDate(date: string, timeZone: string) {
  const s = parseUTCDateToLocal(date, timeZone);
  return spacetime(s, timeZone).format('iso-short');
}
