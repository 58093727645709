import { Checkbox, Dropdown, Icon, Text, useVisible } from '@bloobirds-it/flamingo-ui';
import React from 'react';
import styles from '../calendarsSelector/calendarsSelector.module.css';
import { useActiveUser, useEntity } from '../../../../hooks';
import { ACTIVITY_FIELDS_LOGIC_ROLE } from '../../../../constants/activity';
import { useFullSalesEnabled } from '../../../../hooks/useFeatureFlags';
import { useCalendar } from '../../hooks/useCalendar';

export const BloobirdsCalendarsSelector = () => {
  const {
    usersSelected,
    setUsersSelected,
    accountExecutivesSelected,
    setAccountExecutivesSelected,
  } = useCalendar();
  const { ref, visible, setVisible } = useVisible();
  const isFullSalesEnabled = useFullSalesEnabled();
  const { activeUser } = useActiveUser();
  const users = useEntity('users')?.all();
  const sortedUsers = users?.reduce((acc, user) => {
    if (user?.id === activeUser?.id) {
      return [user, ...acc];
    }
    return [...acc, user];
  }, []);
  const activityAccountExecutiveField = useEntity('bobjectFields')?.findByLogicRole(
    ACTIVITY_FIELDS_LOGIC_ROLE.ACCOUNT_EXECUTIVE,
  );
  const totalCalendarsSelected = [...accountExecutivesSelected, ...usersSelected];
  const accountExecutivePicklistValues = useEntity('bobjectPicklistFieldValues')
    ?.all()
    ?.filter(
      ae =>
        ae?.bobjectGlobalPicklist === activityAccountExecutiveField?.bobjectGlobalPicklist &&
        ae?.enabled,
    );
  const handleSelectUser = (user: any, value: boolean) => {
    if (value) {
      setUsersSelected((curr: any) => [...curr, user?.id]);
    } else {
      setUsersSelected(usersSelected?.filter((id: string) => id !== user?.id));
    }
  };

  const handleSelectAccountExecutive = (ae: any, value: boolean) => {
    if (value) {
      setAccountExecutivesSelected((curr: any) => [...curr, ae?.id]);
    } else {
      setAccountExecutivesSelected(
        accountExecutivesSelected?.filter((id: string) => id !== ae?.id),
      );
    }
  };

  return (
    <Dropdown
      anchor={
        <div className={styles._select_anchor} onClick={() => setVisible(true)}>
          <span style={{ display: 'flex' }}>
            <span className={styles._icon_container}>
              <Icon name="calendar" size={12} color="softPeanut" />
            </span>
            <Text size="xs" color="peanut" className={styles._select_text}>
              {totalCalendarsSelected?.length} calendar
              {totalCalendarsSelected?.length === 1 ? '' : 's'} selected
            </Text>
          </span>
          <span style={{ marginRight: '4px' }}>
            <Icon name="chevronDown" size={12} color="softPeanut" />
          </span>
        </div>
      }
      visible={visible}
      ref={ref}
    >
      <div className={styles._calendars_container}>
        {users?.length > 0 && (
          <>
            <Text size="m" color="peanut">
              Users
            </Text>
            <div className={styles._calendars_list}>
              {sortedUsers?.map(user => {
                const isSelfUser = activeUser?.id === user?.id;
                return (
                  <Checkbox
                    key={user?.id}
                    size="small"
                    disabled={isSelfUser}
                    checked={!!usersSelected?.find((id: string) => id === user?.id)}
                    onClick={v => handleSelectUser(user, v)}
                  >
                    {user?.name} {isSelfUser && '(You)'}
                  </Checkbox>
                );
              })}
            </div>
          </>
        )}
        {!isFullSalesEnabled && accountExecutivePicklistValues?.length > 0 && (
          <>
            <Text size="m" color="peanut">
              Account Executives
            </Text>
            <div className={styles._calendars_list}>
              {accountExecutivePicklistValues?.map((ae: any) => (
                <Checkbox
                  key={ae?.id}
                  size="small"
                  checked={!!accountExecutivesSelected?.find((id: string) => id === ae?.id)}
                  onClick={v => handleSelectAccountExecutive(ae, v)}
                >
                  {ae?.value}
                </Checkbox>
              ))}
            </div>
          </>
        )}
      </div>
    </Dropdown>
  );
};
