import { DiscoveryTooltip } from '@bloobirds-it/flamingo-ui';
import React from 'react';
import styles from './welcomeTooltips.module.css';
import { UserHelperTooltipsKeys } from '../../../constants/userHelperKeys';
import { useUserHelpers } from '../../../hooks/useUserHelpers';
import tasksImage from '../../../../assets/tooltipImages/tasks.png';
import { useQuickStartEnabled } from '../../../hooks/useQuickStartGuide';

export const TasksTooltip = ({ defaultTooltipVisible }: { defaultTooltipVisible: boolean }) => {
  const { save, has } = useUserHelpers();
  const hasQSGEnabled = useQuickStartEnabled();
  const shouldBeDisplayed =
    has(UserHelperTooltipsKeys.TAB_BAR) && !has(UserHelperTooltipsKeys.TASKS) && hasQSGEnabled;

  return (
    <span className={styles._tasks_anchor}>
      {shouldBeDisplayed ? (
        <DiscoveryTooltip
          title="Your tasks for the day will appear here."
          visible={defaultTooltipVisible}
        >
          <DiscoveryTooltip.TooltipImage className={styles._tasks_image}>
            <img src={tasksImage} width={200} height={140} alt={'calendar'} />
          </DiscoveryTooltip.TooltipImage>
          <DiscoveryTooltip.TooltipFooter description="Use filters to choose the tasks you want to see, then press  ▶️ START TASKS, to begin working on your selected companies or leads. ">
            <DiscoveryTooltip.TooltipButton
              variant="secondary"
              isMainButton={true}
              size="small"
              onClick={() => save(UserHelperTooltipsKeys.TASKS)}
            >
              Ok
            </DiscoveryTooltip.TooltipButton>
          </DiscoveryTooltip.TooltipFooter>
        </DiscoveryTooltip>
      ) : (
        <div className={styles._anchor_placeholder} />
      )}
    </span>
  );
};
