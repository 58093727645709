import {
  Checkbox,
  Dropdown,
  Icon,
  IconButton,
  Item,
  Switch,
  Text,
  Tooltip,
  useToasts,
  useVisible,
} from '@bloobirds-it/flamingo-ui';
import React, { useCallback, useEffect, useState } from 'react';
import styles from '../../../businessAssetsPage.module.css';
import { EntityCardItem } from '../../../../../../components/entityList/entityCard/entityCard';
import { ConfirmDeleteModalLayout } from '../../../../../../layouts/confirmDeleteModalLayout/confirmDeleteModalLayout';
import { cadenceEditUrl } from '../../../../../../app/_constants/routes';
import { useRouter } from '../../../../../../hooks';
import { useCadences } from '@bloobirds-it/cadence';
import { useBobjectTypes } from '../../../../../../hooks/useBobjectTypes';
import { CADENCE_EDIT_PERMISSIONS } from '../../createEditCadenceSettings/createEditCadenceSettings';
import SessionManagerFactory from '../../../../../../misc/session';
import { api } from '../../../../../../utils/api';
import spacetime from 'spacetime';
import { useCadenceV2Enabled } from '@bloobirds-it/hooks';
import { cadenceEditUrlV2 } from '@bloobirds-it/types';
import { useUserSettings } from '../../../../../../components/userPermissions/hooks';

export const CadenceCard = ({ cadence, handleRefresh, setCadenceToClone, openCloneModal }) => {
  const bobjectTypes = useBobjectTypes();
  const bobjectType = bobjectTypes?.get(cadence.bobjectType);
  const settings = useUserSettings();
  const { markAsDefault, getUsesCadence, delete: deleteCadence } = useCadences(
    bobjectType?.name,
    settings?.account?.id,
  );
  const [isDeleting, setIsDeleting] = useState();
  const [isEnabled, setIsEnabled] = useState(cadence?.enabled);
  const [confirmModalOpen, setConfirmModalOpen] = useState();
  const [numberOfUses, setNumberOfUses] = useState(false);
  const { createToast } = useToasts();
  const SessionManager = SessionManagerFactory();
  const isDefaultCadence = cadence.defaultCadence;
  const [infoDefaultCadence, setInfoDefaultCadence] = useState(isDefaultCadence);
  const { history } = useRouter();
  const { ref, visible, setVisible } = useVisible(false);
  const cadenceVisibilityLabel =
    cadence?.editMode === CADENCE_EDIT_PERMISSIONS.EVERYONE ? 'Public' : 'Private';
  const canEditCadence =
    SessionManager.getRoleManager()?.isAccountAdmin() ||
    SessionManager.getUser()?.id === cadence?.owner ||
    cadence?.editMode === CADENCE_EDIT_PERMISSIONS.EVERYONE;
  const cadenceV2Enabled = useCadenceV2Enabled(SessionManager.getAccount()?.id);

  const [allowDelete, setAllowDelete] = useState(false);

  const handleAllowDelete = () => {
    setAllowDelete(!allowDelete);
  };

  useEffect(() => {
    setAllowDelete(numberOfUses === 0);
  }, [numberOfUses]);

  useEffect(() => {
    setInfoDefaultCadence(isDefaultCadence);
  }, [isDefaultCadence]);

  useEffect(() => {
    setIsEnabled(cadence?.enabled);
  }, [cadence]);

  const handleToggleEnabled = async () => {
    await api.put(`/messaging/cadences/${cadence.id}/${isEnabled ? 'disable' : 'enable'}`);
    setIsEnabled(!isEnabled);
  };

  const handleDeleteCadence = () => {
    setIsDeleting(true);
    deleteCadence(cadence.id).then(() => {
      createToast({ message: 'Cadence succesfully deleted!', type: 'success' });
      setIsDeleting(false);
      setConfirmModalOpen(false);
      handleRefresh();
    });
  };

  const markCadenceAsDefault = () => {
    markAsDefault(cadence.id).then(() => {
      handleRefresh();
    });
  };

  const handleDelete = () => {
    getUsesCadence(cadence.id, bobjectType?.name).then(r => {
      setNumberOfUses(r);
      setConfirmModalOpen(true);
    });
  };

  return (
    <>
      {confirmModalOpen && (
        <ConfirmDeleteModalLayout
          icon="company"
          assetLabel="Cadence"
          isDeleting={isDeleting}
          disable={!allowDelete}
          handleDelete={handleDeleteCadence}
          handleClose={() => setConfirmModalOpen(false)}
          colorSchema={{
            verySoft: 'verySoftPurple',
            light: 'lightPurple',
          }}
        >
          {numberOfUses === 0 && (
            <div className={styles.confirmation_body}>
              <Text size="s" className={styles.confirmation_info}>
                You are going to delete permanently the Cadence &quot;{cadence?.name}&quot;
              </Text>
              <Text size="s" weight="bold">
                Are you sure you want to continue?
              </Text>
            </div>
          )}
          {numberOfUses > 0 && (
            <div className={styles.confirmation_body}>
              <Text size="s" className={styles.confirmation_info}>
                &quot;{cadence?.name}&quot; cadence is being used in {numberOfUses}{' '}
                {bobjectType?.name}
              </Text>
              <Text size="s" weight="bold">
                Deleting a cadence has the following consequences:
              </Text>
              <ul>
                <li>
                  <Text size="s">
                    Objects with this cadence in progress will keep it, but the cadence cannot be
                    started again.
                  </Text>
                </li>
                <li>
                  <Text size="s">Deleted cadences cannot be initialized.</Text>
                </li>
                <li>
                  <Text size="s">
                    <Text size="s">You will stop seeing this cadence in the cadence list.</Text>
                  </Text>
                </li>
              </ul>
              <Text size="s" weight="bold">
                This action cannot be undone. Do you to want to delete this cadence?
              </Text>
              <div className={styles.delete_checkbox}>
                <Checkbox size="small" checked={allowDelete} onClick={handleAllowDelete} expand>
                  I would like to delete this cadence
                </Checkbox>
              </div>
            </div>
          )}
        </ConfirmDeleteModalLayout>
      )}
      <EntityCardItem size="medium">{cadence?.name}</EntityCardItem>
      <EntityCardItem>
        <Tooltip title="Default Cadence" position="top">
          {infoDefaultCadence && <Icon name="starChecked" color="softBanana" size={16} />}
        </Tooltip>
      </EntityCardItem>
      <EntityCardItem>{cadence?.statistics?.totalSteps}</EntityCardItem>
      <EntityCardItem>{cadence?.statistics?.totalDays}</EntityCardItem>
      <EntityCardItem>{cadence?.statistics.prospectCount}</EntityCardItem>
      <EntityCardItem>{cadence?.statistics.activeCount}</EntityCardItem>
      <EntityCardItem>
        {Math.round(cadence?.statistics?.automatedPercentage * 100 || 0)}%
      </EntityCardItem>
      <EntityCardItem>{cadence?.statistics.optOutCount}</EntityCardItem>
      <EntityCardItem>
        {cadence?.creationDatetime
          ? spacetime(cadence?.creationDatetime).format('nice-short')
          : '-'}
      </EntityCardItem>
      <EntityCardItem>
        {cadence?.lastEntityUpdate
          ? spacetime(cadence?.lastEntityUpdate).format('nice-short')
          : '-'}
      </EntityCardItem>
      <EntityCardItem>{cadence?.createdBy}</EntityCardItem>
      <EntityCardItem>{cadenceVisibilityLabel}</EntityCardItem>
      <EntityCardItem size="small">
        <span className={styles._status__span}>
          <Tooltip
            title={isDefaultCadence ? 'The default cadence can not be disabled' : ''}
            position="top"
          >
            <Switch
              checked={isEnabled}
              disabled={isDefaultCadence}
              color="purple"
              onChange={handleToggleEnabled}
            />
          </Tooltip>
          <IconButton
            color="purple"
            onClick={() => {
              history.push(
                `${
                  cadenceV2Enabled ? cadenceEditUrlV2(cadence.id) : cadenceEditUrl(cadence.id)
                }&name=${cadence.name}&bobjectType=${bobjectType?.name}`,
              );
            }}
            name={canEditCadence ? 'edit' : 'eye'}
            size={24}
          />
          <Dropdown
            ref={ref}
            visible={visible}
            anchor={
              <IconButton
                name="moreOpenholes"
                color="purple"
                dataTest="moreDropdown"
                onClick={() => setVisible(!visible)}
              />
            }
          >
            {!isDefaultCadence && (
              <Item
                onClick={() => {
                  setVisible(!visible);
                  markCadenceAsDefault();
                }}
                disabled={!canEditCadence}
                dataTest="updateLeadStatusOption"
                icon={isDefaultCadence ? 'starChecked' : 'starUnchecked'}
              >
                Mark as default
              </Item>
            )}
            <Item
              onClick={() => {
                setVisible(!visible);
                setCadenceToClone(cadence);
                openCloneModal();
              }}
              dataTest="logActivityOption"
              icon="copy"
            >
              Clone
            </Item>
            {!isDefaultCadence ? (
              <Item
                disabled={!canEditCadence}
                onClick={() => {
                  setVisible(!visible);
                  handleDelete();
                }}
                icon="trashEmpty"
              >
                Delete
              </Item>
            ) : (
              <Tooltip title="Default cadence cannot be deleted" position="top">
                <Item disabled icon="trashEmpty">
                  Delete
                </Item>
              </Tooltip>
            )}
          </Dropdown>
        </span>
      </EntityCardItem>
    </>
  );
};
