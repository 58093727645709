import React, { useMemo } from 'react';
import { Tab, TabGroup } from '@bloobirds-it/flamingo-ui';
import NoPermissionsPage from '../../noPermissionsPage';
import SessionManagerFactory from '../../../misc/session';
import AccountSettingsLayout from '../../../layouts/accountSettingsLayout';
import { BOBJECT_TYPES } from '../../../constants/bobject';
import ViewTabTemplate from './viewTabTemplate';
import { useFullSalesEnabled } from '../../../hooks/useFeatureFlags';

const ViewsPage = () => {
  const roleManager = SessionManagerFactory().getRoleManager();
  const salesFeatureEnabled = useFullSalesEnabled();
  const viewsTabs = useMemo(() => {
    const tabs = [
      <Tab name="Company" key="company-tab">
        <ViewTabTemplate bobjectType={BOBJECT_TYPES.COMPANY} />
      </Tab>,
    ];

    tabs.push(
      <Tab name="Lead">
        <ViewTabTemplate bobjectType={BOBJECT_TYPES.LEAD} />
      </Tab>,
    );

    if (salesFeatureEnabled) {
      tabs.push(
        <Tab name="Opportunity">
          <ViewTabTemplate bobjectType={BOBJECT_TYPES.OPPORTUNITY} />
        </Tab>,
      );
    }
    return tabs;
  }, [salesFeatureEnabled]);

  return roleManager.isAccountAdmin() ? (
    <AccountSettingsLayout title="Views" defaultTab="Company">
      <TabGroup>{viewsTabs}</TabGroup>
    </AccountSettingsLayout>
  ) : (
    <NoPermissionsPage />
  );
};

export default ViewsPage;
