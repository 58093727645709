import React from 'react';
import { BarChart, Icon, Text } from '@bloobirds-it/flamingo-ui';
import { TIME_WINDOW } from '../../typings/home';
import { useTeamMeetingsChart } from '../../hooks/useTeamMeetingsChart';
import styles from '../../homePage.module.css';
import { useUserSettings } from '../../../../components/userPermissions/hooks';
import { DataScope } from '../../../dashboardPages/v1/panels/shared/DataScope';

export const TeamMeetings = ({ timeWindow }: { timeWindow: TIME_WINDOW }) => {
  const { data, chartData } = useTeamMeetingsChart(timeWindow);
  const settings = useUserSettings();

  return (
    <>
      {data?.length > 0 ? (
        <div className={styles.chart_container}>
          <DataScope max={8} data={chartData}>
            {({ visibleData }: any) => {
              const getDataKeyColor = (dataKey: any, index: string | number) => {
                const myUser = data?.find((d: any) => {
                  return d?.fieldDataList[0]?.text === visibleData[index]?.group;
                });
                return myUser?.fieldDataList[0]?.value === settings?.user?.id
                  ? 'var(--bloobirds)'
                  : 'var(--verySoftBloobirds)';
              };
              return (
                <BarChart
                  dataKeys={['value']}
                  groupKey="group"
                  onBarClick={undefined}
                  points={visibleData}
                  getDataKeyColor={getDataKeyColor}
                />
              );
            }}
          </DataScope>
        </div>
      ) : (
        <div className={styles.noData}>
          <Icon name="search" color="softPeanut" />
          <Text size="s" color="softPeanut">
            No data
          </Text>
        </div>
      )}
    </>
  );
};
