import React, { useState } from 'react';
import AccountSettingsTab from '../../../../layouts/accountSettingsLayout/accountSettingsTab';
import styles from './aircall.module.css';
import { useEntity } from '../../../../hooks';
import {
  Button,
  Icon,
  Item,
  Select,
  Snackbar,
  Spinner,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tag,
  Text,
  Tooltip,
} from '@bloobirds-it/flamingo-ui';
import { CallsApi } from '../../../../misc/api/calls';
import { mutate } from 'swr';
import { RestApi } from '../../../../misc/api/rest';
import {
  AccountSettingsTabContent,
  AccountSettingsTabHeader,
  AccountSettingsTabHeaderLeft,
  AccountSettingsTableContainer,
  AccountSettingsTabSubtitle,
  AccountSettingsTabTitle,
} from '../../../../layouts/accountSettingsLayout/accountSettingsTab/accountSettingsTab';
import { UserHelperKeys } from '../../../../constants/userHelperKeys';
import { useUserHelpers } from '../../../../hooks/useUserHelpers';

const PhoneNumberRow = ({ phone }) => (
  <TableRow>
    <TableCell>{phone?.name}</TableCell>
    <TableCell>{phone?.phoneNumber}</TableCell>
    <TableCell>{phone?.aircallRecordingEnabled ? <Tag>Yes</Tag> : <Tag>No</Tag>}</TableCell>
    <TableCell>{phone?.phoneIntegrated ? <Tag>Yes</Tag> : <Tag>No</Tag>}</TableCell>
  </TableRow>
);

const UserRow = ({ aircallUser, userList }) => {
  const { save } = useUserHelpers();

  const updateAircallUser = userId => {
    RestApi.patch({
      entity: 'aircallUsers',
      id: aircallUser?.id,
      body: {
        bloobirdsUser: `/users/${userId}`,
      },
    }).then(() => mutate('/entity/aircallUsers'));
    save(UserHelperKeys.CHOOSE_DIALER);
  };

  return (
    <TableRow>
      <TableCell>{aircallUser?.aircallUserName}</TableCell>
      <TableCell>{aircallUser?.aircallUserEmail}</TableCell>
      <TableCell>
        <Select
          placeholder="Bloobirds user"
          size="small"
          borderless={false}
          width={200}
          onChange={updateAircallUser}
          value={aircallUser?.bloobirdsUser}
        >
          {userList &&
            userList.map(user => (
              <Item key={user?.id} dataTest={user?.value} value={user?.id}>
                {user?.value}
              </Item>
            ))}
        </Select>
      </TableCell>
      <TableCell>{aircallUser?.syncContactsEnabled ? 'Yes' : 'No'}</TableCell>
    </TableRow>
  );
};

const AircallConfig = () => {
  const aircallToken = useEntity('aircallTokens')?.all()[0];
  const phoneNumbers = useEntity('phoneNumbers')?.filterBy('type', 'AIRCALL_NUMBER');
  const aircallUsers = useEntity('aircallUsers')?.all();
  const bobjectGlobalPicklist = useEntity('bobjectGlobalPicklists');
  const userGlobalPicklist = bobjectGlobalPicklist?.findByLogicRole('USER');
  const bobjectPicklistFieldValues = useEntity('bobjectPicklistFieldValues');
  const userList = bobjectPicklistFieldValues
    ?.filterBy('bobjectGlobalPicklist', userGlobalPicklist?.id)
    ?.filter(user => user?.enabled);
  const [isRefreshing, setIsRefreshing] = useState();
  const [isRefreshingUsers, setIsRefreshingUsers] = useState();
  const numbersNotInIntegration = phoneNumbers?.filter(n => !n.phoneIntegrated)?.length;

  const refreshNumbers = () => {
    setIsRefreshing(true);
    CallsApi.get({ path: 'aircall/sync/numbers' });
  };

  const handleFinishRefreshingNumbers = () => {
    mutate('/entity/phoneNumbers');
    setIsRefreshing(false);
  };

  const refreshUsers = () => {
    setIsRefreshingUsers(true);
    CallsApi.get({ path: 'aircall/sync/users' });
  };

  const handleFinishRefreshingUsers = () => {
    mutate('/entity/aircallUsers');
    setIsRefreshingUsers(false);
  };

  return (
    <div className={styles._config__container}>
      <AccountSettingsTab title="Aircall account" icon="person">
        <AccountSettingsTabHeader>
          <AccountSettingsTabHeaderLeft>
            <AccountSettingsTabTitle icon="person">Aircall account</AccountSettingsTabTitle>
          </AccountSettingsTabHeaderLeft>
        </AccountSettingsTabHeader>
        <AccountSettingsTabContent>
          <div className={styles._config__account}>
            <Text className={styles._config__account_name} size="m" color="softPeanut">
              Bloobirds production
            </Text>
            <Text className={styles._config__account_status_text} size="s" color="softPeanut">
              Your integration is currently {aircallToken?.enabled ? 'active' : 'inactive'}
            </Text>
            <Button
              variant="tertiary"
              color="tomato"
              onClick={() => window.open('https://dashboard-v2.aircall.io/integrations', '_blank')}
            >
              Delete
            </Button>
          </div>
        </AccountSettingsTabContent>
      </AccountSettingsTab>
      <AccountSettingsTab>
        <AccountSettingsTabHeader>
          <AccountSettingsTabHeaderLeft>
            <AccountSettingsTabTitle icon="person">
              <div className={styles._users_title}>
                Aircall Users
                <Tooltip
                  title="Aircall users are automatically synced each time they are deleted or created. If you are not seeing some of them, you can try to refresh them!"
                  position="top"
                >
                  <Icon name="info" size={15} className={styles._info__icon} />
                </Tooltip>
                <Button
                  variant="secondary"
                  iconLeft="refresh"
                  size="small"
                  className={styles._refresh__button}
                  onClick={refreshUsers}
                >
                  REFRESH
                </Button>
              </div>
              <Snackbar
                variant="actions"
                position="bottom"
                visible={isRefreshingUsers}
                onClose={handleFinishRefreshingUsers}
              >
                <Text size="s" color="white">
                  Refreshing users...
                </Text>
              </Snackbar>
            </AccountSettingsTabTitle>
            <AccountSettingsTabSubtitle>
              Match your Bloobirds Users with the Aircall users to match calls between systems.
            </AccountSettingsTabSubtitle>
          </AccountSettingsTabHeaderLeft>
        </AccountSettingsTabHeader>
        <AccountSettingsTabContent>
          <AccountSettingsTableContainer>
            <Table>
              <TableHead>
                <TableCell>Aircall User Name</TableCell>
                <TableCell>Aircall User Email</TableCell>
                <TableCell>Bloobirds User</TableCell>
                <TableCell>Sync contacts from Bloobirds</TableCell>
              </TableHead>
              <TableBody>
                {aircallUsers
                  ?.sort((a, b) => {
                    if (a.aircallUserName < b.aircallUserName) {
                      return -1;
                    }
                    if (a.aircallUserName > b.aircallUserName) {
                      return 1;
                    }
                    return 0;
                  })
                  .map(aircallUser => (
                    <UserRow key={aircallUser?.id} aircallUser={aircallUser} userList={userList} />
                  ))}
              </TableBody>
            </Table>
          </AccountSettingsTableContainer>
        </AccountSettingsTabContent>
      </AccountSettingsTab>
      <AccountSettingsTab>
        <AccountSettingsTabHeader>
          <AccountSettingsTabHeaderLeft>
            <AccountSettingsTabTitle icon="phone">
              Connected numbers{' '}
              <Button
                variant="secondary"
                iconLeft="refresh"
                size="small"
                className={styles._refresh__button}
                onClick={refreshNumbers}
              >
                {isRefreshing ? (
                  <Spinner name="loadingCircle" color="bloobirds" size={10} />
                ) : (
                  'REFRESH'
                )}
              </Button>
              <Snackbar
                variant="actions"
                position="bottom"
                visible={isRefreshing}
                onClose={handleFinishRefreshingNumbers}
              >
                <Text size="s" color="white">
                  Refreshing numbers...
                </Text>
              </Snackbar>
            </AccountSettingsTabTitle>
          </AccountSettingsTabHeaderLeft>
        </AccountSettingsTabHeader>
        <AccountSettingsTabContent>
          {numbersNotInIntegration > 0 && (
            <div className={styles._warning_banner}>
              <div className={styles._warning_icon}>
                <Icon size={40} name="alertTriangle" color="banana" />
              </div>
              <div className={styles._warning_text}>
                <Text size="m">
                  You have {numbersNotInIntegration}{' '}
                  {numbersNotInIntegration === 1 ? 'number' : 'numbers'} not added in the
                  integration. Remember to add all your numbers on the integration to avoid the
                  calls not being registered on Bloobirds. To add more numbers, go to your{' '}
                  <a
                    href="https://dashboard-v2.aircall.io/integrations"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Aircall dashboard
                  </a>
                  . This can also be added in integration success email or similar push
                </Text>
              </div>
            </div>
          )}
          <AccountSettingsTableContainer>
            <Table>
              <TableHead>
                <TableCell>Phone name</TableCell>
                <TableCell>Phone number</TableCell>
                <TableCell>Recording enabled</TableCell>
                <TableCell>Phone added to the integration</TableCell>
              </TableHead>
              <TableBody>
                {phoneNumbers?.map(phone => (
                  <PhoneNumberRow key={phone.id} phone={phone} userList={userList} />
                ))}
              </TableBody>
            </Table>
          </AccountSettingsTableContainer>
        </AccountSettingsTabContent>
      </AccountSettingsTab>
    </div>
  );
};

export default AircallConfig;
