import React from 'react';
import { useDocumentTitle } from '../../hooks/useDocumentTitle';
import SideBar from './sidebar/sidebar';
import Routes from './playbookPage.routes';
import styles from './playbookPage.module.css';

const PlaybookPage = () => {
  useDocumentTitle('My Playbook');
  return (
    <div className={styles._container}>
      <SideBar />
      <div className={styles._content__container}>
        <Routes />
      </div>
    </div>
  );
};

export default PlaybookPage;
