export const USER_PERMISSIONS = Object.seal({
  VIEW_INBOUND_TAB: 'VIEW_INBOUND_TAB',
  VIEW_INBOX: 'VIEW_INBOX',
  VIEW_ADD_QC_TAB: 'VIEW_ADD_QC_TAB',
  VIEW_ASSIGN_TAB: 'VIEW_ASSIGN_TAB',
  VIEW_ADD_LEADS_TAB: 'VIEW_ADD_LEADS_TAB',
  VIEW_PROSPECT_TAB: 'VIEW_PROSPECT_TAB',
  VIEW_SCHEDULED_TAB: 'VIEW_SCHEDULED_TAB',
  VIEW_MEETING_TAB: 'VIEW_MEETING_TAB',
  VIEW_SALES_TAB: 'VIEW_SALES_TAB',
  EDIT_ALL: 'EDIT_ALL',
  VIEW_DASHBOARDS_TAB: 'VIEW_DASHBOARDS_TAB',
  VIEW_OUTBOX_TAB: 'VIEW_OUTBOX_TAB',
  BULK_ACTIONS: 'BULK_ACTIONS',
  VIEW_CADENCES: 'VIEW_CADENCES',
});
