import React from 'react';
import { DiscoveryTooltip } from '@bloobirds-it/flamingo-ui';
import styles from './outboxTooltips.module.css';
import { useUserHelpers } from '../../../hooks/useUserHelpers';
import { UserHelperTooltipsKeys } from '../../../constants/userHelperKeys';
// @ts-ignore
import AutomatedEmails from '../../../../assets/tooltipImages/outboxDiscovery/automatedEmails.png';
import { useHasQueryParam } from '../../../hooks/useHasQueryParam';

export const AutomatedEmailsTooltip = ({
  defaultTooltipVisible,
}: {
  defaultTooltipVisible: boolean;
}) => {
  const { save, has } = useUserHelpers();
  const isTour = !useHasQueryParam('fromGuide');

  const shouldBeVisible = !has(UserHelperTooltipsKeys.OUTBOX_AUTOMATED_EMAILS) && isTour;
  return (
    <div className={styles._automated_emails_wrapper}>
      {shouldBeVisible && (
        <DiscoveryTooltip title="Automated emails" visible={defaultTooltipVisible} isPersistent>
          <DiscoveryTooltip.TooltipImage className={styles._image}>
            <img src={AutomatedEmails} width={280} alt="Outbox automated emails DT" />
          </DiscoveryTooltip.TooltipImage>
          <DiscoveryTooltip.TooltipFooter description="Here you’ll find your automated emails generated by cadences. You can filter and reschedule, send or cancel them ✨">
            <DiscoveryTooltip.TooltipButton
              variant="secondary"
              className={styles._primary_button}
              isMainButton
              size="small"
              onClick={() => {
                save(UserHelperTooltipsKeys.OUTBOX_AUTOMATED_EMAILS);
              }}
            >
              Ok
            </DiscoveryTooltip.TooltipButton>
          </DiscoveryTooltip.TooltipFooter>
        </DiscoveryTooltip>
      )}
    </div>
  );
};
