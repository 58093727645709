import React from 'react';
import ConvertToSalesStep from '../../../pages/contactPages/sendToSalesModal/convertToSalesStep/convertToSalesStep';
import { Bobject } from '../../../typings/bobjects';

interface ConvertBobjectProps {
  handleBack: () => void;
  handleNext: (createOpportunity: boolean, leads?: Array<Bobject>) => void;
}

const ConvertObject = ({ handleBack, handleNext }: ConvertBobjectProps) => {
  return (
    <>
      <ConvertToSalesStep onBack={handleBack} onNext={handleNext} />
    </>
  );
};

export default ConvertObject;
