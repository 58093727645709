import { Checkbox, Icon, Radio, RadioGroup, Text, Tooltip } from '@bloobirds-it/flamingo-ui';
import styles from '../../../styles/generalSettingsPage.module.css';
import React, { useState } from 'react';
import {
  AccountSettingsTab,
  AccountSettingsTabContent,
} from '../../../../../../layouts/accountSettingsLayout/accountSettingsTab/accountSettingsTab';
import {
  AccountSettingsSection,
  AccountSettingsSectionContent,
  AccountSettingsSectionSubtitle,
  AccountSettingsSectionTitle,
} from '../../../../../../layouts/accountSettingsLayout/accountSettingsSection/accountSettingsSection';
import { Controller } from 'react-hook-form';
import { useUserSettings } from '../../../../../../components/userPermissions/hooks';
import { useCreateReceivedMeeting } from '../../../../../../hooks/useFeatureFlags';

export const CalendarCard = ({ values }) => {
  const {
    settings: { openCalendarPopupAfterMeeting, createMeetingAfterCalendarEvent },
  } = useUserSettings();
  const [calendarCheckboxChecked, setCalendarCheckboxChecked] = useState(
    openCalendarPopupAfterMeeting,
  );
  const [meetingReceivedCheckboxChecked, setMeetingReceivedCheckboxChecked] = useState(
    createMeetingAfterCalendarEvent,
  );
  const isCreateMeetingReceivedEnabled = useCreateReceivedMeeting();

  return (
    <AccountSettingsTab>
      <AccountSettingsTabContent>
        <AccountSettingsSection>
          <AccountSettingsSectionTitle>Calendar</AccountSettingsSectionTitle>
          <AccountSettingsSectionSubtitle>
            After creating a meeting, the user will be able to create it in his or her calendar.
          </AccountSettingsSectionSubtitle>
          <AccountSettingsSectionContent>
            <div className={styles._checkbox_wrapper}>
              <Controller
                name="openCalendarPopupAfterMeeting"
                render={({ onChange, value }) => (
                  <Checkbox
                    onClick={e => {
                      onChange(e);
                      setCalendarCheckboxChecked(e);
                    }}
                    checked={value}
                  >
                    <Text size="s">Enable the calendar event creation after meeting creation</Text>
                    <Tooltip
                      title="Enabling this option makes events created on Bloobirds to be created on your linked calendar. If this option is disabled, these events will only be visible in the Bloobirds view on calendar"
                      position="top"
                    >
                      <Icon name="infoFilled" color="darkBloobirds" className={styles._info_icon} />
                    </Tooltip>
                  </Checkbox>
                )}
              />
            </div>
          </AccountSettingsSectionContent>
          {calendarCheckboxChecked && (
            <AccountSettingsSectionContent>
              <div className={styles._radio_wrapper}>
                <Controller
                  name="calendarEventDecision"
                  render={({ onChange, value }) => (
                    <RadioGroup onChange={onChange} value={value}>
                      <Radio value="USER_DECISION" defaultChecked>
                        Let users decide if the calendar event will be created on Google / Outlook
                      </Radio>
                      <Radio value="IMPERATIVE">
                        Create the meeting always on Google / Outlook
                      </Radio>
                    </RadioGroup>
                  )}
                />
              </div>
            </AccountSettingsSectionContent>
          )}
          {isCreateMeetingReceivedEnabled && (
            <AccountSettingsSectionContent>
              <div className={styles._checkbox_wrapper}>
                <Controller
                  name="createMeetingAfterCalendarEvent"
                  render={({ onChange, value }) => (
                    <Checkbox
                      onClick={e => {
                        onChange(e);
                        setMeetingReceivedCheckboxChecked(e);
                      }}
                      checked={value}
                    >
                      <Text size="s">
                        Create a meeting in Bloobirds every time someone registered in my database
                        invites me.
                      </Text>
                    </Checkbox>
                  )}
                />
              </div>
            </AccountSettingsSectionContent>
          )}
        </AccountSettingsSection>
      </AccountSettingsTabContent>
    </AccountSettingsTab>
  );
};
