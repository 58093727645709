// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".cadenceList-module___no_results__contents___Tj-r6 {\n  box-sizing: border-box;\n  padding: 150px 227px 150px 227px;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  background-color: white;\n}\n\n.cadenceList-module___no_results__img___KXapo {\n  margin-bottom: 20px;\n}\n\n.cadenceList-module__confirmation_info___i\\+sYi {\n  margin-bottom: 8px;\n  line-height: 24px;\n}\n\n.cadenceList-module__confirmation_body___cCLLH {\n  margin-bottom: 16px;\n}\n\n.cadenceList-module___status__span___sq2fY {\n  display: flex;\n  align-items: center;\n  gap: 4px;\n}\n\n.cadenceList-module___status__span___sq2fY > * {\n  margin: 0 2px;\n}\n\n.cadenceList-module___edit_view_button___P2NJx {\n  width: 73px;\n}\n", "",{"version":3,"sources":["webpack://./src/js/pages/cadencesPage/components/cadenceList/cadenceList.module.css"],"names":[],"mappings":"AAAA;EACE,sBAAsB;EACtB,gCAAgC;EAChC,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,uBAAuB;AACzB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,kBAAkB;EAClB,iBAAiB;AACnB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,QAAQ;AACV;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,WAAW;AACb","sourcesContent":["._no_results__contents {\n  box-sizing: border-box;\n  padding: 150px 227px 150px 227px;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  background-color: white;\n}\n\n._no_results__img {\n  margin-bottom: 20px;\n}\n\n.confirmation_info {\n  margin-bottom: 8px;\n  line-height: 24px;\n}\n\n.confirmation_body {\n  margin-bottom: 16px;\n}\n\n._status__span {\n  display: flex;\n  align-items: center;\n  gap: 4px;\n}\n\n._status__span > * {\n  margin: 0 2px;\n}\n\n._edit_view_button {\n  width: 73px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"_no_results__contents": "cadenceList-module___no_results__contents___Tj-r6",
	"_no_results__img": "cadenceList-module___no_results__img___KXapo",
	"confirmation_info": "cadenceList-module__confirmation_info___i+sYi",
	"confirmation_body": "cadenceList-module__confirmation_body___cCLLH",
	"_status__span": "cadenceList-module___status__span___sq2fY",
	"_edit_view_button": "cadenceList-module___edit_view_button___P2NJx"
};
export default ___CSS_LOADER_EXPORT___;
