import React, { useEffect, useState } from 'react';
import {
  Button,
  Dropdown,
  Icon,
  IconButton,
  Item,
  Select,
  Spinner,
  Text,
  useVisible,
} from '@bloobirds-it/flamingo-ui';
import clsx from 'clsx';
import mixpanel from 'mixpanel-browser';
import styles from '../../homePage.module.css';
import { useUserHomepageSettings } from '../../hooks/useUserHomepageSettings';
import {
  AvailableHomeConfigs,
  Config,
  ConfigType,
  shouldNotShowResultsFilters,
  UserHomeConfig,
} from '../../typings/home';
import {
  getAvailableUserConfig,
  getDefaultHomeConfig,
  getUserSettingConfigType,
} from '../../utils/homepage';
import { useFullSalesEnabled } from '../../../../hooks/useFeatureFlags';
import { useHasBeenVisible } from '../../../../hooks/useHasBeenVisible';
import { TaskList } from '../../components/taskList/taskList';
import { useSelectAll } from '@bloobirds-it/hooks';
import { useMediaQuery, useTaskNavigationStorage } from '../../../../hooks';
import { TASK_TYPE } from '../../../../constants/task';
import { useAppCalendarVisibility } from '../../../../hooks/useUserTasks';
import { MIXPANEL_EVENTS } from '../../../../constants/mixpanel';
import { StartTaskSettings } from '../../../../components/startTaskSettings/startTaskSettings';
import { TabBarTooltip } from '../../../../components/discoveryTooltips/welcomeTooltips/tabBarTooltip';

const taskTypeEquivalence = {
  ON_CADENCE: TASK_TYPE.PROSPECT_CADENCE,
  SCHEDULED: TASK_TYPE.NEXT_STEP,
  MEETING_TASKS: TASK_TYPE.CONTACT_BEFORE_MEETING,
  OVERDUE: 'OVERDUE',
  COMPLETED: 'COMPLETED',
  APPOINTMENTS: TASK_TYPE.NEXT_STEP,
  FOLLOW_UPS: TASK_TYPE.PROSPECT_CADENCE,
  OVERDUE_SALES: 'OVERDUE',
  COMPLETED_SALES: 'COMPLETED',
};

export const LeftContent = () => {
  const { ref, visible, setVisible } = useVisible();
  const hasBeenVisible = useHasBeenVisible(visible);
  const { availableSettings, userSettings, updateHomeSettings } = useUserHomepageSettings();
  const { openAppCalendarWithFilters } = useAppCalendarVisibility();
  const isFullSalesEnabled = useFullSalesEnabled();
  const { isSmallDesktop, isDesktop } = useMediaQuery();

  // In these state we save the actual setting of the user
  const [userSelectorConfig, setUserSelectorConfig] = useState<UserHomeConfig | undefined>();
  const [userFilterConfig, setUserFilterConfig] = useState<UserHomeConfig[] | undefined>(undefined);
  const configTypeSelected = userSelectorConfig
    ? userSelectorConfig?.enumName === Config.PROSPECTING
      ? ConfigType.TASKS_SECTION_FILTERS
      : ConfigType.TASKS_SECTION_FILTERS_SALES
    : null;

  // These are the settings available for every user
  const filterOptions = availableSettings?.availableConfigs?.find(
    (config: AvailableHomeConfigs) => config.configType === configTypeSelected,
  );
  const defaultTasksSectionFilters = getAvailableUserConfig(availableSettings, configTypeSelected);
  const selectorAvailableConfigs = getAvailableUserConfig(
    availableSettings,
    ConfigType.TASKS_SECTION_SELECTOR,
  );
  const defaultSelectorOption = getDefaultHomeConfig(availableSettings, Config.PROSPECTING);

  // These are the possible settings that the user could have saved in db
  const userSavedSelectorConfig = getUserSettingConfigType(
    userSettings,
    ConfigType.TASKS_SECTION_SELECTOR,
  );
  const defaultUserSelectorConfig = userSavedSelectorConfig?.find(c => c.enabled);
  const userConfig = getUserSettingConfigType(userSettings, configTypeSelected);

  // Here we define which is the setting the user actually has to add it in the state
  useEffect(() => {
    // Save Filters
    if (userConfig) {
      setUserFilterConfig(userConfig);
    } else {
      setUserFilterConfig(defaultTasksSectionFilters);
    }
  }, [userSettings, defaultTasksSectionFilters, userConfig]);

  useEffect(() => {
    // Save Stage
    if (defaultUserSelectorConfig || defaultSelectorOption) {
      setUserSelectorConfig(defaultUserSelectorConfig || defaultSelectorOption);
    }
  }, [defaultUserSelectorConfig, defaultSelectorOption]);

  // Only make the request when the dropdown is being closed
  useEffect(() => {
    if (hasBeenVisible && !visible) {
      updateHomeSettings(userFilterConfig, configTypeSelected);
    }
  }, [visible, hasBeenVisible]);

  // These are the requests that we need to update both settings
  const updateSelector = (configToUpdate: UserHomeConfig) => {
    mixpanel.track(MIXPANEL_EVENTS.HOME_TASKS_SELECTOR_CHANGED);
    setUserSelectorConfig(configToUpdate);
    updateHomeSettings([configToUpdate], ConfigType.TASKS_SECTION_SELECTOR);
  };
  const updateTasksSettings = (configToUpdate: UserHomeConfig, enabled: boolean) => {
    mixpanel.track(MIXPANEL_EVENTS.HOME_TASKS_FILTERS_CHANGED);
    const filtersToUpdate = userFilterConfig.map(config => {
      if (config.id === configToUpdate.id) {
        return {
          id: config.id,
          name: config.name,
          enumName: config.enumName,
          ordering: config.ordering,
          enabled: enabled,
          extraConfig: config.extraConfig,
        };
      } else {
        return config;
      }
    });
    setUserFilterConfig(filtersToUpdate);
  };

  // This is needed to define the selected items and select checkbox actions
  const { selectedItems, initialItems } = useSelectAll();
  const { startNavigation, setSalesNavigation } = useTaskNavigationStorage();

  const taskTypes = userFilterConfig
    ?.filter(filter => filter.enabled)
    ?.map(filter => {
      // @ts-ignore
      return taskTypeEquivalence[filter.enumName];
    });
  const shouldNotShowResults =
    shouldNotShowResultsFilters?.some(
      array => JSON.stringify(array) === JSON.stringify(taskTypes),
    ) || taskTypes?.length === 0;

  // This is needed to correctly set the task navigation settings
  const [changingTaskNavigationSettings, setChangingTaskNavigationSettings] = useState(false);

  useEffect(() => {
    setSalesNavigation(userSelectorConfig?.enumName === 'SALES');
  }, [userSelectorConfig]);

  return (
    <div className={styles.leftContent__container}>
      <div className={styles.column__header}>
        <div className={styles.column__title}>
          <TabBarTooltip defaultTooltipVisible />
          <Text size="m" color="softPeanut" className={styles.title_text}>
            {isDesktop && isFullSalesEnabled ? 'Today' : 'Today tasks'}
          </Text>
          {isFullSalesEnabled && (
            <Select value={userSelectorConfig} size="small" onChange={updateSelector}>
              {selectorAvailableConfigs?.map(config => (
                <Item value={config} key={config.id}>
                  {config.name}
                </Item>
              ))}
            </Select>
          )}
          <div className={styles.sliderRotate}>
            <Dropdown
              ref={ref}
              visible={visible}
              anchor={
                <IconButton
                  size={20}
                  onClick={() => setVisible(!visible)}
                  name="sliders"
                  color="bloobirds"
                />
              }
            >
              {filterOptions?.availableConfigs?.map((option: UserHomeConfig) => {
                const configInUser = userFilterConfig?.find(c => c.id === option.id);
                return (
                  <Item
                    value={option.id}
                    key={option.id}
                    className={clsx(
                      styles._tasks_item,
                      (option?.enumName === Config.OVERDUE ||
                        option?.enumName === Config.OVERDUE_SALES) &&
                        styles.overdue_item,
                    )}
                    onClick={() => updateTasksSettings(option, !configInUser?.enabled)}
                  >
                    <Text
                      size="s"
                      htmlTag="span"
                      color={configInUser?.enabled ? 'bloobirds' : 'softPeanut'}
                    >
                      {option.name}
                    </Text>
                    <Icon name={configInUser?.enabled ? 'eye' : 'eyeOff'} size={16} />
                  </Item>
                );
              })}
            </Dropdown>
          </div>
          <IconButton
            name="chevronOpen"
            onClick={() => openAppCalendarWithFilters(userSelectorConfig.enumName, taskTypes)}
            className={styles.chevron}
            color="bloobirds"
            size={20}
          />
        </div>
        <div className={styles.column__title}>
          <StartTaskSettings
            callingFromSalesPage={isFullSalesEnabled && userSelectorConfig?.enumName === 'SALES'}
            updateLoading={setChangingTaskNavigationSettings}
          />
          <div
            className={clsx(
              styles._start_button,
              (initialItems?.length === 0 || shouldNotShowResults) && styles.notAllowed,
            )}
          >
            <Button
              color={
                initialItems?.length !== 0 && !shouldNotShowResults
                  ? changingTaskNavigationSettings
                    ? 'softBloobirds'
                    : 'bloobirds'
                  : 'verySoftPeanut'
              }
              className={styles.startButton}
              size="small"
              onClick={() => {
                if (!changingTaskNavigationSettings) {
                  mixpanel.track(MIXPANEL_EVENTS.HOME_STARTED_TASKS);
                  const navigableTasks = selectedItems?.length > 0 ? selectedItems : initialItems;
                  startNavigation(navigableTasks);
                }
              }}
              disabled={initialItems?.length === 0 || shouldNotShowResults}
            >
              {changingTaskNavigationSettings && (
                <div className={styles._button_spinner}>
                  <Spinner color={'white'} name="loadingCircle" size={14} />
                </div>
              )}
              {!changingTaskNavigationSettings ? (
                isSmallDesktop || isFullSalesEnabled ? (
                  <Icon name="play" size={16} color="white" />
                ) : (
                  'Start '
                )
              ) : (
                ''
              )}
              {(selectedItems?.length || initialItems?.length) && !shouldNotShowResults
                ? changingTaskNavigationSettings
                  ? ''
                  : `(${selectedItems?.length || initialItems?.length})`
                : ''}
            </Button>
          </div>
        </div>
      </div>
      <div className={styles.task_list}>
        <TaskList
          activeSelector={userSelectorConfig}
          activeFilters={userFilterConfig}
          taskTypes={taskTypes}
          shouldNotShowResults={shouldNotShowResults}
        />
      </div>
    </div>
  );
};
