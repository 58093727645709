import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import {
  Button,
  Checkbox,
  Icon,
  Input,
  Text,
  TextArea,
  useToasts,
} from '@bloobirds-it/flamingo-ui';
import { Controller, useForm } from 'react-hook-form';
import { isEmpty } from 'lodash';
import { ServiceApi } from '../../../misc/api/service';
import styles from './emailSettings.module.css';
import { useActiveUser, useEmailConnections, useQueryParams } from '../../../hooks';
import { useEmailSignature } from '@bloobirds-it/email';
import { GoogleSignIn, MicrosoftSignIn } from '../../../components/BrandedButtons';
import { fetchAndOpenLegacyUrl, fetchAndOpenNylasUrl } from './emailSettings.services';
import ConnectionCard from '../../../components/connectionCard';
import { useUserSettings } from '../../../components/userPermissions/hooks';
import SignatureEditor from './signatureEditor';
import { useRichTextEditorPlugins, deserialize, serialize } from '@bloobirds-it/rich-text-editor';
import { validateHtml } from '../../../utils/strings.utils';
import useDebounce from '../../../hooks/useDebounce';
import clsx from 'clsx';
import { api } from '../../../utils/api';
import { useUserHelpers } from '../../../hooks/useUserHelpers';
import { UserHelperKeys } from '../../../constants/userHelperKeys';
import { isHtml } from '@bloobirds-it/utils';

const submitEmail = (
  inputEmail,
  onSubmit,
  setIsSubmittingObject,
  setNewEmailSubmitted,
  isSubmittingObject,
) => {
  ServiceApi.request({
    url: '/service/users/me/updateEmail',
    method: 'POST',
    body: { email: inputEmail },
  })
    .then(() => {
      if (onSubmit) {
        onSubmit();
      }
      setIsSubmittingObject({ ...isSubmittingObject, email: false });
      setNewEmailSubmitted(true);
    })
    .catch(() => {
      setIsSubmittingObject({ ...isSubmittingObject, email: false });
    });
};

const needsEmailUpdate = (data, email) => data.email !== '' && data.email !== email;

const needsSignatureUpdate = (data, signature) =>
  data.signature !== signature.body || data.enableSignature !== signature.enabled;

const EmailSettingsView = ({ email, onSubmit }) => {
  const { signature, updateSignature, isSubmitting: isSubmittingSignature } = useEmailSignature();
  const { connections, isSubmitting: isSubmittingConnections } = useEmailConnections();
  const { activeUser } = useActiveUser();
  const hasConnections = connections?.list && connections.list.length > 0;
  const { createToast } = useToasts();
  const settings = useUserSettings();
  const params = useQueryParams();
  const error = params.get('error');
  const deserializePlugins = useRichTextEditorPlugins({ replaceParagraphs: true });
  const serializePlugins = useRichTextEditorPlugins();
  const helpers = useUserHelpers();

  const parseUserSignature = () =>
    signature.body
      ? isHtml(signature.body)
        ? deserialize(signature.body, { format: 'HTML', plugins: deserializePlugins })
        : [
            {
              type: 'p',
              children: [{ text: signature.body }],
            },
          ]
      : undefined;

  const userSignature = parseUserSignature();

  const googleOnClick =
    settings?.settings.gmailConnectButtonType === 'LEGACY'
      ? fetchAndOpenLegacyUrl
      : fetchAndOpenNylasUrl;

  const emailIsDuplicated = error === 'ALREADY_CONNECTED';

  const emailConnections = {
    ...connections,
    duplicateEmail: {
      isDuplicated: emailIsDuplicated,
      errorMessage: emailIsDuplicated ? 'Connection not created. Email account already exists' : '',
    },
  };

  const {
    duplicateEmail: { isDuplicated: isDuplicatedEmail, errorMessage: duplicatedEmailError },
    list: nylasConnections,
  } = emailConnections;

  const defaultValues = {
    email: '',
    signature: userSignature,
    htmlSignature: signature.body,
    enableSignature: signature.enabled,
    emailTrackingNotificationsEnabled: false,
  };

  const {
    register,
    handleSubmit,
    errors,
    control,
    setValue,
    reset,
    watch,
    setError,
    clearErrors,
  } = useForm({
    defaultValues,
  });

  useEffect(() => {
    api
      .get(`/entities/users/${activeUser.id}`)
      .then(reponse => {
        setValue(
          'emailTrackingNotificationsEnabled',
          reponse?.data?.emailTrackingNotificationsEnabled,
        );
      })
      .catch(console.error);
  }, []);

  const [isSubmittingObject, setIsSubmittingObject] = useState({ email: false });
  const [newEmailSubmitted, setNewEmailSubmitted] = useState(false);
  const [isRawHtmlSignature, setIsRawHtmlSignature] = useState(false);

  const [signatureEditorValue, setSignatureEditorValue] = useState(false);

  const isSubmitting =
    (isSubmittingObject.email && isSubmittingSignature) || isSubmittingConnections;

  useEffect(() => {
    if (signature.loaded) {
      setValue('signature', userSignature);
      setSignatureEditorValue(
        userSignature || [
          {
            type: 'p',
            children: [{ text: '' }],
          },
        ],
      );
      setValue('htmlSignature', signature.body);
      setValue('enableSignature', signature.enabled);
      setIsRawHtmlSignature(signature.isRawHtml);
    }
  }, [signature.loaded]);

  useEffect(() => {
    if (nylasConnections?.length > 0) {
      helpers.save(UserHelperKeys.CONNECT_EMAIL_ACCOUNT);
    }
  }, [nylasConnections]);

  const onFormSubmit = async data => {
    let submitted = false;

    if (needsEmailUpdate(data, email)) {
      submitEmail(
        data.email,
        onSubmit,
        setIsSubmittingObject,
        setNewEmailSubmitted,
        isSubmittingObject,
      );
      submitted = true;
    }

    if (needsSignatureUpdate(data, signature)) {
      let htmlSignature = data.htmlSignature;
      if (isRawHtmlSignature && htmlSignature) {
        const normalizedSignature = validateHtml(htmlSignature, true);
        if (!normalizedSignature) {
          return;
        }
        htmlSignature = normalizedSignature;
        setValue('htmlSignature', htmlSignature);
        setValue(
          'signature',
          deserialize(htmlSignature, { format: 'HTML', plugins: deserializePlugins }),
        );
      }
      if (!isRawHtmlSignature) {
        setValue(
          'htmlSignature',
          serialize(data?.signature, { format: 'AST', plugins: serializePlugins }),
        );
      }

      updateSignature({
        body: isRawHtmlSignature
          ? htmlSignature
          : serialize(data.signature, { format: 'AST', plugins: serializePlugins }),
        enabled: data.enableSignature,
        isRawHtml: isRawHtmlSignature,
      });
      submitted = true;
    }

    await api.patch(`/entities/users/${activeUser.id}`, {
      emailTrackingNotificationsEnabled: data.emailTrackingNotificationsEnabled,
    });

    if (submitted) {
      createToast({ type: 'success', message: 'Email settings updated' });
    }
  };

  const htmlSignature = useDebounce(watch('htmlSignature'), 200);
  useEffect(() => {
    if (htmlSignature && isRawHtmlSignature) {
      const normalizedSignature = validateHtml(htmlSignature, true);
      if (!normalizedSignature) {
        setError('htmlSignature', {
          type: 'manual',
          message:
            'The HTML of the signature is invalid, it may contain non closed tags or invalid characters.',
        });
      } else {
        clearErrors('htmlSignature');
      }
    }
  }, [htmlSignature]);

  const onChangeEditionMode = mode => event => {
    event.stopPropagation();
    event.preventDefault();
    setIsRawHtmlSignature(mode === 'HTML');
  };

  return (
    <div className={styles._container} data-intercom="user-settings-page-email">
      <div className={styles._content__box}>
        <div className={styles._section__box}>
          <div
            className={clsx(styles._email_buttons__wrapper, {
              [styles._email_buttons__disconnected]: !hasConnections,
            })}
          >
            {hasConnections ? (
              <Text size="m" weight="bold" color="softPeanut" htmlTag="span">
                Your connected email accounts
              </Text>
            ) : (
              <Text size="m" weight="bold" color="softPeanut" htmlTag="span">
                You have no accounts connected. Connect your first account with Google or Outlook.
              </Text>
            )}
            <div className={styles._email_buttons__container}>
              {settings?.settings.gmailConnectButtonEnabled && (
                <GoogleSignIn onClick={googleOnClick} />
              )}
              {settings?.settings.microsoftConnectButtonEnabled && (
                <MicrosoftSignIn onClick={() => fetchAndOpenNylasUrl('outlook')} />
              )}
            </div>
          </div>
          {isDuplicatedEmail && (
            <div className={styles._message_error__container}>
              <Icon name="alertTriangle" color="tomato" />
              <span className={styles._message_error__text}>{duplicatedEmailError}</span>
            </div>
          )}
          <div className={styles._connections_container}>
            {nylasConnections?.map(connection => (
              <ConnectionCard
                data={connection}
                isNylas
                key={connection.email}
                isDefault={connection.email === connections.defaultConnection}
              />
            ))}
          </div>
        </div>
        <form className={styles._section__box} onSubmit={handleSubmit(onFormSubmit)}>
          <div className={styles._form__box}>
            <Text color="peanut" size="m" weight="medium">
              Email tracking notifications
            </Text>
            <Controller
              name="emailTrackingNotificationsEnabled"
              control={control}
              render={({ onChange, value }) => (
                <Checkbox checked={value} onClick={onChange} expand>
                  Notify me when a lead opens, clicks or replies my emails
                </Checkbox>
              )}
            />
          </div>
          <div className={styles._form__box}>
            <Text color="peanut" size="m" weight="medium">
              Change email
            </Text>
            <Text color="peanut" size="s">
              Your email address is currently <b>{email}</b>
            </Text>
            <Input
              placeholder="New email address"
              name="email"
              innerRef={register({ pattern: /^\S+@\S+$/i })}
              error={errors.email && 'Must be a valid email'}
              width="100%"
            />
          </div>
          <div>
            <div className={styles._signature__container}>
              <Text color="peanut" size="m" weight="medium">
                Change email signature
              </Text>
              <div className={styles._switchButtons__container}>
                <Button
                  variant={!isRawHtmlSignature ? 'primary' : 'secondary'}
                  onClick={onChangeEditionMode('EDITOR')}
                  size="small"
                >
                  Editor
                </Button>
                <Button
                  variant={isRawHtmlSignature ? 'primary' : 'secondary'}
                  onClick={onChangeEditionMode('HTML')}
                  size="small"
                >
                  HTML
                </Button>
              </div>
            </div>
            <div className={styles._editor}>
              <Controller
                name="signature"
                control={control}
                render={props => (
                  <SignatureEditor
                    plugins={deserializePlugins}
                    hidden={isRawHtmlSignature}
                    defaultValue={signatureEditorValue}
                    {...props}
                  />
                )}
              />
            </div>

            <div className={styles._htmlSignature__container} hidden={!isRawHtmlSignature}>
              <Controller
                name="htmlSignature"
                as={
                  <TextArea
                    rows="8"
                    width="100%"
                    placeholder="HTML signature"
                    autoScroll={false}
                    error={errors?.htmlSignature?.message}
                  />
                }
                control={control}
              />
              <Text color="peanut" size="s" weight="medium">
                HTML preview
              </Text>
              <div className={styles._htmlPreview__container}>
                <div dangerouslySetInnerHTML={{ __html: htmlSignature }} />
              </div>
            </div>
            <Controller
              name="enableSignature"
              control={control}
              render={({ onChange, value }) => (
                <Checkbox checked={value} onClick={onChange} expand>
                  Insert my signature within the email editor whenever I compose an email
                </Checkbox>
              )}
            />
          </div>
          {newEmailSubmitted && !isSubmitting && (
            <Text color="peanut" size="s">
              We&apos;ve sent a verification email to the new email. Once it is verified it will be
              set as as your email.
            </Text>
          )}
          <div className={styles._buttons__container}>
            <Button type="submit" disabled={!isEmpty(errors) || isSubmitting}>
              Save Changes
            </Button>
            <Button
              variant="clear"
              onClick={() =>
                reset({
                  email: '',
                  signature: userSignature,
                  htmlSignature: signature.body,
                  enableSignature: signature.enabled,
                  defaultConnection: hasConnections
                    ? connections.defaultConnection
                    : 'no-connections',
                })
              }
            >
              Cancel
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};

EmailSettingsView.propTypes = {
  email: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default EmailSettingsView;
