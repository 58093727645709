import React from 'react';
import { BobjectTypes } from '../../../../../constants/bobject';
import { TaskCardButtons } from './taskCardButtons';
import { MainBobjectButtons } from './mainBobjectButtons';
import { Bobject } from '../../../../../typings/bobjects';

interface CardButtonsProps {
  bobject: Bobject;
  isHovering: boolean;
}

export const CardButtons = ({ bobject, isHovering }: CardButtonsProps) => {
  const bobjectType = bobject?.id?.typeName;

  switch (bobjectType) {
    case BobjectTypes.Task:
      return <TaskCardButtons bobject={bobject} isHovering={isHovering} />;
    default:
      return <MainBobjectButtons bobject={bobject} isHovering={isHovering} />;
  }
};
