import React from 'react';
import { Modal } from '@bloobirds-it/flamingo-ui';
import OpportunityControl from '../contactFlowModal/opportunityControl/opportunityControl';
import useOpportunityControl from '../../hooks/useOpportunityControl';

const OpportunityControlModal = () => {
  const { closeModal, leads, assignedTo } = useOpportunityControl();

  return (
    <Modal open onClose={closeModal} title="Opportunities Control">
      <OpportunityControl handleClose={closeModal} leads={leads} assignedTo={assignedTo} />
    </Modal>
  );
};

export default OpportunityControlModal;
