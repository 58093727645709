import { Icon, IconButton, Text, Tooltip } from '@bloobirds-it/flamingo-ui';
import React from 'react';
import { useController, useFormContext } from 'react-hook-form';
import clsx from 'clsx';
import { useCalendar } from '../../hooks/useCalendar';
import styles from './conferencingForm.module.css';
import { GoogleMeetSvg, GoogleMeetWhiteSvg } from '../../../../../assets/svg';

export const ConferencingForm = () => {
  const { conferencingGoogleMeet, setConferencingGoogleMeet } = useCalendar();
  const { control } = useFormContext();
  const {
    field: { value: conferencingValue, onChange: conferencingOnChange },
  } = useController({
    control,
    name: 'conferencingGoogleMeet',
    defaultValue: conferencingGoogleMeet,
  });

  return (
    <div>
      <div
        className={clsx(styles._conferencing_container, {
          [styles._conferencing_container_marked]: conferencingValue,
        })}
      >
        <div className={styles._title}>
          <div className={styles._google_meet_icon}>
            {conferencingValue ? <GoogleMeetWhiteSvg /> : <GoogleMeetSvg />}
          </div>
          <Text size="s" color={conferencingValue ? 'white' : 'peanut'}>
            {conferencingValue
              ? 'Conference link by Google Meet'
              : 'Add Google Meet video conference'}
          </Text>
        </div>
        <IconButton
          name={conferencingValue ? 'cross' : 'plus'}
          color={conferencingValue ? 'white' : 'bloobirds'}
          onClick={() => {
            setConferencingGoogleMeet(!conferencingGoogleMeet);
            conferencingOnChange(!conferencingValue);
          }}
        />
      </div>
    </div>
  );
};
