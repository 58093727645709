import React from 'react';
import { Skeleton, Text } from '@bloobirds-it/flamingo-ui';
import { useActivityEvolution } from '../../hooks/useActivityEvolution';
import styles from './activity.module.css';
import { TagMetric } from '../../../../components/tagMetric/tagMetric';
import { HomeMetricsResponse, PREVIOUS_TIME_WINDOW, TIME_WINDOW } from '../../typings/home';
import { useMediaQuery } from '../../../../hooks';

export const getMetricValue = (metric: string, data: HomeMetricsResponse) => {
  return data?.metrics.find(m => m?.label === metric);
};

export const Activity = ({ timeWindow }: { timeWindow: TIME_WINDOW }) => {
  const { data } = useActivityEvolution(timeWindow);
  const { isSmallDesktop } = useMediaQuery();

  const meetingValue = getMetricValue('MEETINGS_CREATED', data);
  const emailsValue = getMetricValue('OUTGOING_EMAIL', data);
  const callsValue = getMetricValue('OUTGOING_CALL', data);
  const linkedinValue = getMetricValue('OUTGOING_LINKEDIN', data);
  const tasksValue = getMetricValue('TASKS', data);

  return (
    <div className={styles.container}>
      <div className={styles.row}>
        <Text size="s" color="softPeanut" className={styles.title}>
          {/*@ts-ignore */}
          {timeWindow ? TIME_WINDOW[timeWindow] : 'Loading...'}
        </Text>
        {data?.metrics ? (
          <div className={styles.metrics_container}>
            <TagMetric value={meetingValue.value} color="extraMeeting" />
            <TagMetric value={emailsValue.value} color="tangerine" />
            <TagMetric value={callsValue.value} color="extraCall" />
            <TagMetric value={linkedinValue.value} color="darkBloobirds" />
            <TagMetric value={tasksValue.value} color="bloobirds" />
          </div>
        ) : (
          <div className={styles.skeleton}>
            <Skeleton height={50} width={isSmallDesktop ? 300 : 600} variant="rect" />
          </div>
        )}
      </div>
      <div className={styles.row}>
        <Text size="s" color="softPeanut" className={styles.title}>
          {/*@ts-ignore */}
          {timeWindow ? PREVIOUS_TIME_WINDOW[timeWindow] : 'Loading...'}
        </Text>
        {data?.metrics ? (
          <div className={styles.metrics_container}>
            <TagMetric value={meetingValue.previousValue} color="softTomato" title="MEETINGS" />
            <TagMetric value={emailsValue.previousValue} color="softTangerine" title="EMAILS" />
            <TagMetric value={callsValue.previousValue} color="softMelon" title="CALLS" />
            <TagMetric value={linkedinValue.previousValue} color="softBloobirds" title="LINKEDIN" />
            <TagMetric value={tasksValue.previousValue} color="verySoftBloobirds" title="TASKS" />
          </div>
        ) : (
          <div className={styles.skeleton}>
            <Skeleton height={50} width={isSmallDesktop ? 300 : 600} variant="rect" />
          </div>
        )}
      </div>
    </div>
  );
};
