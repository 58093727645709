import { useEffect, useMemo, useState } from 'react';

import { BobjectTypes, SearchType } from '@bloobirds-it/types';
import { atom, useRecoilState, useResetRecoilState } from 'recoil';
import spacetime from 'spacetime';
import useSWR from 'swr';

import { RangeType } from '../../../../hooks/useDashboardFilters';
import { api } from '../../../../utils/api';
import {
  AnalyzeCadenceTableResponse,
  CadenceTableKPI,
  ObjectsInCadenceMetric,
} from './analyzeCadenceList.typings';

const cadenceAnalyzeOrderAtom = atom({
  key: 'analyticsCadenceListOrderAtom',
  default: {},
});

const cadenceAnalyzePageAtom = atom({
  key: 'cadenceAnalyzePageAtom',
  default: 0,
});

const cadenceAnalyzePageSizeAtom = atom({
  key: 'cadenceAnalyzePageSizeAtom',
  default: 20,
});

const today = new Date();
const firstDayThisMonth = new Date(today.getFullYear(), today.getMonth(), 1);
const lastDayThisMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);

const cadenceAnalyzeFiltersAtom = atom<{
  selectedBobjectType: BobjectTypes[];
  startedBy: string[];
  startDate: string;
  endDate: string;
}>({
  key: 'cadenceAnalyzeFiltersAtom',
  default: {
    selectedBobjectType: [],
    startedBy: [],
    startDate: spacetime(firstDayThisMonth).format('iso-short'),
    endDate: spacetime(lastDayThisMonth).format('iso-short'),
  },
});

export const useAnalyzeCadenceList = (cadenceId?: string) => {
  const [sort, setSort] = useRecoilState<{ [category: string]: 'ASC' | 'DESC' }>(
    cadenceAnalyzeOrderAtom,
  );
  const [orderingField, setOrderingField] = useState('');
  const [rangeFilterDate, setRangeFilterDate] = useState<{
    type: RangeType;
    end: Date;
    start: Date;
  }>({
    type: SearchType.THIS_MONTH,
    start: firstDayThisMonth,
    end: lastDayThisMonth,
  });
  const [filters, setFilters] = useRecoilState(cadenceAnalyzeFiltersAtom);
  const [page, setPage] = useRecoilState(cadenceAnalyzePageAtom);
  const resetPage = useResetRecoilState(cadenceAnalyzePageAtom);
  const [pageSize, setPageSize] = useRecoilState(cadenceAnalyzePageSizeAtom);

  useEffect(() => {
    setFilters({
      ...filters,
      startDate: spacetime(rangeFilterDate?.start).format('iso-short'),
      endDate: spacetime(rangeFilterDate?.end).format('iso-short'),
    });
  }, [rangeFilterDate]);

  const query = useMemo(() => {
    return {
      type: (filters.selectedBobjectType?.length === 0
        ? [BobjectTypes.Company, BobjectTypes.Lead, BobjectTypes.Opportunity]
        : filters.selectedBobjectType
      )?.map(type => type.toUpperCase()),
      startedBy: filters.startedBy,
      startDate: filters.startDate,
      endDate: filters.endDate,
      ...(cadenceId ? { cadenceId: cadenceId } : {}),
    };
  }, [filters, cadenceId]);

  useEffect(() => {
    setPage(0);
    setPageSize(20);
  }, [cadenceId]);

  const fetchCadences = () => {
    return api
      .post('analytics/cadence/performance', {
        ...query,
        page,
        pageSize,
      })
      .then(res => res?.data);
  };

  const fetchObjectsInCadence = () => {
    return (
      cadenceId &&
      api
        .post('analytics/cadence/objects', {
          ...query,
          cadenceId: cadenceId,
          page,
          pageSize,
        })
        .then(res => res?.data)
    );
  };

  const { data: cadences, mutate: mutateCadences, error: cadencesError } = useSWR<
    AnalyzeCadenceTableResponse
  >(
    `/analytics/cadence/performance/${page}/${pageSize}/${filters.selectedBobjectType.join(
      ',',
    )}/${filters.startedBy.join(',')}/${filters.startDate}/${filters.endDate}`,
    fetchCadences,
    {
      revalidateOnFocus: false,
      revalidateIfStale: false,
      revalidateOnReconnect: true,
    },
  );

  const {
    data: objectsInCadence,
    mutate: mutateObjectsInCadence,
    error: objectsInCadenceError,
  } = useSWR<AnalyzeCadenceTableResponse>(
    `/analytics/cadence/objects/${cadenceId}/${page}/${pageSize}/${filters.selectedBobjectType.join(
      ',',
    )}/${filters.startedBy.join(',')}/${filters.startDate}/${filters.endDate}`,
    fetchObjectsInCadence,
    {
      revalidateOnFocus: false,
      revalidateIfStale: false,
      revalidateOnReconnect: true,
    },
  );

  const fetchKpis = () => {
    return api
      .post('analytics/cadence/aggregation', {
        ...query,
        ...(cadenceId ? { cadenceId: cadenceId } : {}),
        page,
        pageSize,
      })
      .then(res => res?.data);
  };

  const { data: kpis, mutate: mutateKPIs, error: kpiError } = useSWR<CadenceTableKPI[][]>(
    `/analytics/cadence/aggregation/${
      cadenceId ? cadenceId + '/' : '/'
    }${filters.selectedBobjectType.join(',')}/${filters.startedBy.join(',')}/${filters.startDate}/${
      filters.endDate
    }`,
    fetchKpis,
    {
      revalidateOnFocus: false,
      revalidateIfStale: false,
      revalidateOnReconnect: true,
    },
  );

  const totalElements = useMemo(
    () => (cadenceId ? objectsInCadence?.totalElements : cadences?.totalElements),
    [cadences, objectsInCadence, cadenceId],
  );

  const headers = useMemo(() => (cadenceId ? objectsInCadence?.headers : cadences?.headers), [
    cadences,
    objectsInCadence,
    cadenceId,
  ]);

  const orderedAnalyticsRows = useMemo(() => {
    const dataToUse = cadenceId ? objectsInCadence : cadences;
    if (dataToUse?.result && dataToUse?.result?.length) {
      const filtered = dataToUse?.result;
      const headerLabels = headers?.map(
        (headerItem: ObjectsInCadenceMetric | CadenceTableKPI | any) =>
          headerItem?.label?.toLowerCase() || headerItem?.icon?.toLowerCase(),
      );

      const availableSortingFields = filtered?.length && [
        ...Object.keys(filtered[0]),
        ...(headerLabels || []),
      ];

      const isStatisticField = filtered?.length && headerLabels?.includes(orderingField);
      if (availableSortingFields?.includes(orderingField) && orderingField) {
        return filtered?.sort((a: any, b: any) => {
          if (
            !a?.kpis
              ?.map((kpi: CadenceTableKPI) => kpi?.label?.toLowerCase())
              ?.includes(orderingField) &&
            !a[orderingField]
          )
            return;
          if (
            !b?.kpis
              ?.map((kpi: CadenceTableKPI) => kpi?.label?.toLowerCase())
              ?.includes(orderingField) &&
            !b[orderingField]
          )
            return;
          // @ts-ignore
          if (sort[orderingField] === 'ASC') {
            return isStatisticField
              ? a.kpis.filter(
                  (kpi: CadenceTableKPI) => kpi?.label?.toLowerCase() === orderingField,
                )[0].value -
                  b.kpis.filter(
                    (kpi: CadenceTableKPI) => kpi?.label?.toLowerCase() === orderingField,
                  )[0].value
              : a[orderingField].localeCompare(b[orderingField]);
          } else {
            return isStatisticField
              ? b.kpis.filter(
                  (kpi: CadenceTableKPI) => kpi?.label?.toLowerCase() === orderingField,
                )[0].value -
                  a.kpis.filter(
                    (kpi: CadenceTableKPI) => kpi?.label?.toLowerCase() === orderingField,
                  )[0].value
              : b[orderingField].localeCompare(a[orderingField]);
          }
        });
      } else {
        return filtered;
      }
    }
    return [];
  }, [orderingField, sort, cadences, objectsInCadence, cadenceId]);

  const handleReorder = (category: string) => {
    // @ts-ignore
    if (sort[category]) {
      setSort({
        // @ts-ignore
        [category]: sort[category] === 'ASC' ? 'DESC' : 'ASC',
      });
    } else {
      setSort({ [category]: 'ASC' });
    }
    setOrderingField(category);
  };

  return {
    analyticsListProps: {
      orderedAnalyticsRows,
      headers,
      refreshAnalyticsRows: cadenceId ? mutateObjectsInCadence : mutateCadences,
      analyticsListError: cadenceId ? objectsInCadenceError : cadencesError,
      isLoading: cadenceId ? !objectsInCadence : !cadences,
      sort,
      handleReorder,
    },
    kpisProps: { kpis, refreshKPIs: mutateKPIs, isKpisLoading: !kpis, kpiError },
    rangeDateProps: {
      rangeFilterDate,
      setRangeFilterDate,
    },
    startedByProps: {
      startedBy: filters.startedBy,
      setStartedBy: (value: string[]) => setFilters({ ...filters, startedBy: value }),
    },
    totalElements,
    ...filters,
    setSelectedBobjecType: (value: BobjectTypes[]) =>
      setFilters({ ...filters, selectedBobjectType: value }),
    page,
    setPage,
    resetPage,
    pageSize,
    setPageSize,
    setOrderingField,
  };
};
