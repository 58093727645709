import React, { useState } from 'react';
import {
  AccountSettingsTab,
  AccountSettingsTabContent,
  AccountSettingsTabHeader,
  AccountSettingsTabHeaderLeft,
  AccountSettingsTabHeaderRight,
  AccountSettingsTableContainer,
  AccountSettingsTabTitle,
} from '../../../../layouts/accountSettingsLayout/accountSettingsTab/accountSettingsTab';
import { Button, SearchInput, Item, Select } from '@bloobirds-it/flamingo-ui';
import styles from '../businessAssetsPage.module.css';
import useMediaQuery from '../../../../hooks/useMediaQuery';
import { useFullSalesEnabled } from '../../../../hooks/useFeatureFlags';
import { CadencesList } from './cadenceList/cadenceList';
import { CreateEditCadenceSettings } from './createEditCadenceSettings/createEditCadenceSettings';
import { BOBJECT_TYPES } from '../../../../constants/bobject';
import { useBobjectTypes } from '../../../../hooks/useBobjectTypes';
import { useLocation } from 'react-router';

const CadenceTab = () => {
  const location = useLocation();
  const isFullSalesEnabled = useFullSalesEnabled();
  const bobjectTypes = useBobjectTypes();
  const defaultBobjectTypeId = bobjectTypes?.findBy('name')(location?.state || 'Company')?.id;
  const [selectedBobjectType, setSelectedBobjecType] = useState(defaultBobjectTypeId);
  const [isModalOpen, setIsModalOpen] = useState();

  const [searchValue, setSearchValue] = useState();
  const { isSmallDesktop } = useMediaQuery();

  const BOBJECT_TYPES_ALLOWED = [BOBJECT_TYPES.COMPANY, BOBJECT_TYPES.LEAD];

  if (isFullSalesEnabled) {
    BOBJECT_TYPES_ALLOWED.push(BOBJECT_TYPES.OPPORTUNITY);
  }

  return (
    <AccountSettingsTab>
      <>
        <AccountSettingsTabHeader>
          <AccountSettingsTabHeaderLeft>
            <div className={styles.tabTitle__container}>
              <AccountSettingsTabTitle icon="list" color="purple">
                Cadences {isFullSalesEnabled && 'for: '}
              </AccountSettingsTabTitle>
              <Select
                value={selectedBobjectType}
                onChange={v => {
                  setSearchValue('');
                  setSelectedBobjecType(v);
                }}
                color="purple"
              >
                {bobjectTypes
                  ?.all()
                  ?.filter(bt => BOBJECT_TYPES_ALLOWED.includes(bt.name))
                  ?.map(type => {
                    if (!isFullSalesEnabled && type.name === 'Opportunity') {
                      return null;
                    }
                    return (
                      <Item value={type.id} key={`type-${type?.id}`}>
                        {type.name}
                      </Item>
                    );
                  })}
              </Select>
            </div>
          </AccountSettingsTabHeaderLeft>
          <AccountSettingsTabHeaderRight>
            <SearchInput
              width={200}
              placeholder="Search"
              onChange={setSearchValue}
              value={searchValue}
            />
            <Button
              dataTest="createCadence"
              iconLeft="plus"
              color="purple"
              onClick={() => setIsModalOpen(true)}
            >
              {!isSmallDesktop && 'New cadence'}
            </Button>
          </AccountSettingsTabHeaderRight>
        </AccountSettingsTabHeader>
        <AccountSettingsTabContent>
          <AccountSettingsTableContainer>
            <CadencesList searchValue={searchValue} bobjectType={selectedBobjectType} />
          </AccountSettingsTableContainer>
        </AccountSettingsTabContent>
      </>
      {isModalOpen && (
        <CreateEditCadenceSettings
          onClose={() => setIsModalOpen(false)}
          bobjectType={selectedBobjectType}
        />
      )}
    </AccountSettingsTab>
  );
};

export default CadenceTab;
