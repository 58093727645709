import useSWR from 'swr';
import { RestApi } from '../misc/api/rest';
import { useUserSettings } from '../components/userPermissions/hooks';

// Use this hook in case you want to sort your request, if not use useEntity
export const useScenariosList = () => {
  const settings = useUserSettings();
  const { data: scenarios } = useSWR('/useCases', () =>
    RestApi.get({
      path: `useCases?account.id=${settings.account.id}&page=0&size=1000&sort=ordering%2CASC`,
    }),
  );

  return {
    scenarios: scenarios?._embedded?.useCases,
  };
};
