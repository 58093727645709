import React from 'react';
import { Button, Text } from '@bloobirds-it/flamingo-ui';
import { useSetRecoilState } from 'recoil';
import styles from './searchBarButton.module.css';
import { openGeneralSearchBarAtom } from '../../../generalSearchBar/generalSearchBar';
import clsx from 'clsx';

export const SearchBarButton = ({ shortPlaceholder = false }: { shortPlaceholder?: boolean }) => {
  const setOpen = useSetRecoilState(openGeneralSearchBarAtom);

  const commandText = () => {
    if (shortPlaceholder) {
      return 'Search...';
    }

    if (navigator.appVersion.indexOf('Win') != -1) {
      return 'Search... (Ctrl + K)';
    } else if (navigator.appVersion.indexOf('Mac') != -1) {
      return 'Search... (Cmd⌘ + K)';
    }

    return `Search... `;
  };
  return (
    <Button
      size={'small'}
      variant={'alternative'}
      iconLeft={'search'}
      className={clsx(styles._searchButton, { [styles._shortVersion]: shortPlaceholder })}
      onClick={() => setOpen(open => !open)}
    >
      <Text className={styles._searchButtonText} size={'xs'} color={'gray'}>
        {commandText()}
      </Text>
    </Button>
  );
};
