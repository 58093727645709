// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".notesForm-module___notes__box___1alci > div {\n  justify-content: center;\n  border-radius: 3px 3px 0 0;\n  width: 303px;\n}\n\n.notesForm-module___notes__box___1alci {\n  border: 1px solid var(--softPeanut);\n  border-radius: 4px;\n  margin-top: 4px;\n}\n\n.notesForm-module___notes__container___F6\\+zR {\n  margin-top: 16px;\n}\n\n.notesForm-module___notes_content___26lQg {\n  height: 100px;\n  overflow-y: scroll;\n  overflow-x: hidden;\n}\n", "",{"version":3,"sources":["webpack://./src/js/components/newMeetingModal/components/notesForm/notesForm.module.css"],"names":[],"mappings":"AAAA;EACE,uBAAuB;EACvB,0BAA0B;EAC1B,YAAY;AACd;;AAEA;EACE,mCAAmC;EACnC,kBAAkB;EAClB,eAAe;AACjB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,kBAAkB;EAClB,kBAAkB;AACpB","sourcesContent":["._notes__box > div {\n  justify-content: center;\n  border-radius: 3px 3px 0 0;\n  width: 303px;\n}\n\n._notes__box {\n  border: 1px solid var(--softPeanut);\n  border-radius: 4px;\n  margin-top: 4px;\n}\n\n._notes__container {\n  margin-top: 16px;\n}\n\n._notes_content {\n  height: 100px;\n  overflow-y: scroll;\n  overflow-x: hidden;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"_notes__box": "notesForm-module___notes__box___1alci",
	"_notes__container": "notesForm-module___notes__container___F6+zR",
	"_notes_content": "notesForm-module___notes_content___26lQg"
};
export default ___CSS_LOADER_EXPORT___;
