// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".searchLeadsGuests-module___item_wrapper___uU1mr {\n  width: 520px;\n  max-height: 200px;\n  overflow-y: auto;\n  overflow-x: hidden;\n}\n\n.searchLeadsGuests-module___lead__info___pwqOT {\n  margin-left: 8px;\n}\n\n.searchLeadsGuests-module___lead__company___YtgAG {\n  display: flex;\n  align-items: center;\n  text-overflow: ellipsis;\n  white-space: nowrap;\n  overflow: hidden;\n}\n\n.searchLeadsGuests-module___lead__company___YtgAG > * {\n  margin-right: 4px;\n}\n\n.searchLeadsGuests-module___company__icon___1x4wR {\n  flex-shrink: 0;\n}\n\n.searchLeadsGuests-module__noResults___aM2VQ {\n  padding: 8px;\n}\n\n.searchLeadsGuests-module__invite_title___vQoBz {\n  margin-right: 4px;\n}\n\n.searchLeadsGuests-module__item___wAocB {\n  width: 100%;\n}\n\n.searchLeadsGuests-module__invite_other___lwapr {\n  display: flex;\n  width: 100%;\n}\n", "",{"version":3,"sources":["webpack://./src/js/components/newMeetingModal/components/searchLeadsGuests/searchLeadsGuests.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,iBAAiB;EACjB,gBAAgB;EAChB,kBAAkB;AACpB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,mBAAmB;EACnB,gBAAgB;AAClB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,aAAa;EACb,WAAW;AACb","sourcesContent":["._item_wrapper {\n  width: 520px;\n  max-height: 200px;\n  overflow-y: auto;\n  overflow-x: hidden;\n}\n\n._lead__info {\n  margin-left: 8px;\n}\n\n._lead__company {\n  display: flex;\n  align-items: center;\n  text-overflow: ellipsis;\n  white-space: nowrap;\n  overflow: hidden;\n}\n\n._lead__company > * {\n  margin-right: 4px;\n}\n\n._company__icon {\n  flex-shrink: 0;\n}\n\n.noResults {\n  padding: 8px;\n}\n\n.invite_title {\n  margin-right: 4px;\n}\n\n.item {\n  width: 100%;\n}\n\n.invite_other {\n  display: flex;\n  width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"_item_wrapper": "searchLeadsGuests-module___item_wrapper___uU1mr",
	"_lead__info": "searchLeadsGuests-module___lead__info___pwqOT",
	"_lead__company": "searchLeadsGuests-module___lead__company___YtgAG",
	"_company__icon": "searchLeadsGuests-module___company__icon___1x4wR",
	"noResults": "searchLeadsGuests-module__noResults___aM2VQ",
	"invite_title": "searchLeadsGuests-module__invite_title___vQoBz",
	"item": "searchLeadsGuests-module__item___wAocB",
	"invite_other": "searchLeadsGuests-module__invite_other___lwapr"
};
export default ___CSS_LOADER_EXPORT___;
