import React from 'react';
import styles from './twilioInstallationPage.module.css';
import { Disconnected } from '../../../../../../assets/svg';
import { Button, Text } from '@bloobirds-it/flamingo-ui';
import { AccountSettingsTab } from '../../../../../layouts/accountSettingsLayout/accountSettingsTab/accountSettingsTab';

export const TwilioInstallationPage = ({ setModalVisible }) => (
  <AccountSettingsTab>
    <div className={styles._auth_container}>
      <div className={styles._ellipse}>
        <Disconnected />
      </div>
      <Text
        className={styles._auth_header}
        size="xxl"
        weight="medium"
        color="peanut"
        align="center"
      >
        Twilio is not connected
      </Text>
      <Text
        className={styles._auth_text}
        size="m"
        weight="regular"
        color="softPeanut"
        align="center"
      >
        Connecting your Twilio account will allow you to call with the Bloobirds dialer.
      </Text>
      <Text size="m" weight="regular" color="softPeanut" align="center">
        Do you wish to connect your Twilio account?
      </Text>
      <Button
        className={styles._auth_button}
        iconLeft="settings"
        onClick={() => setModalVisible(true)}
      >
        CONNECT TWILIO
      </Button>
    </div>
  </AccountSettingsTab>
);
