// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".addToCalendarModal-module___main___jGAiL {\n  margin: 32px auto;\n  display: flex;\n  align-items: center;\n}\n\n.addToCalendarModal-module___calendar___-2Dfq {\n  flex-shrink: 0;\n  box-sizing: border-box;\n  margin-right: 16px;\n  border: 1px solid var(--verySoftPeanut);\n}\n\n.addToCalendarModal-module___calendar___-2Dfq > header {\n  box-sizing: border-box;\n  background-color: var(--tomato);\n}\n\n.addToCalendarModal-module___calendar___-2Dfq > div {\n  padding: 4px 8px;\n}\n\n.addToCalendarModal-module___text___7edJZ {\n  display: flex;\n  flex-direction: column;\n  gap: 4px;\n}\n", "",{"version":3,"sources":["webpack://./src/js/components/addToCalendarModal/addToCalendarModal.module.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,cAAc;EACd,sBAAsB;EACtB,kBAAkB;EAClB,uCAAuC;AACzC;;AAEA;EACE,sBAAsB;EACtB,+BAA+B;AACjC;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,QAAQ;AACV","sourcesContent":["._main {\n  margin: 32px auto;\n  display: flex;\n  align-items: center;\n}\n\n._calendar {\n  flex-shrink: 0;\n  box-sizing: border-box;\n  margin-right: 16px;\n  border: 1px solid var(--verySoftPeanut);\n}\n\n._calendar > header {\n  box-sizing: border-box;\n  background-color: var(--tomato);\n}\n\n._calendar > div {\n  padding: 4px 8px;\n}\n\n._text {\n  display: flex;\n  flex-direction: column;\n  gap: 4px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"_main": "addToCalendarModal-module___main___jGAiL",
	"_calendar": "addToCalendarModal-module___calendar___-2Dfq",
	"_text": "addToCalendarModal-module___text___7edJZ"
};
export default ___CSS_LOADER_EXPORT___;
