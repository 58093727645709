import { ColorType, IconType } from '@bloobirds-it/flamingo-ui';

interface Header {
  label: string;
  icon?: IconType;
  tooltip?: string;
}

export const headerNames: Header[] = [
  {
    label: 'Task Icon and Name',
  },
  {
    label: 'Task description',
  },
  {
    label: 'Extra fields',
    icon: 'infoFilled',
    tooltip:
      'A task that has required fields will open a modal to fill them before logging the activity',
  },
  {
    label: 'Status',
  },
];

export interface PickableIcon {
  name: IconType;
  color: ColorType;
}

export const pickableIcons: PickableIcon[] = [
  { name: 'customTask', color: 'bloobirds' },
  { name: 'whatsapp', color: 'extraCall' },
  { name: 'linkedin', color: 'darkBloobirds' },
  { name: 'calendar', color: 'tomato' },
  { name: 'noteAction', color: 'banana' },
  { name: 'person', color: 'tangerine' },
  { name: 'chat', color: 'purple' },
  { name: 'postalMail', color: 'softPeanut' },
  { name: 'calendarphone', color: 'grape' },
  { name: 'checkDouble', color: 'extraCall' },
  { name: 'bell', color: 'bloobirds' },
  { name: 'starUnchecked', color: 'banana' },
  { name: 'location', color: 'tomato' },
  { name: 'mail', color: 'tangerine' },
  { name: 'pitch', color: 'lightPurple' },
  { name: 'fileOpportunity', color: 'extraCall' },
];
