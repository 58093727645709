import React from 'react';
import { DiscoveryTooltip } from '@bloobirds-it/flamingo-ui';
import styles from './salesforceTooltips.module.css';
import { useUserHelpers } from '../../../hooks/useUserHelpers';
import { UserHelperTooltipsKeys } from '../../../constants/userHelperKeys';
// @ts-ignore
import LogsDiscovery from '../../../../assets/tooltipImages/integrationsDiscovery/LogsDiscovery.png';

export const SalesforceLogsTooltip = ({
  defaultTooltipVisible = true,
}: {
  defaultTooltipVisible?: boolean;
}) => {
  const { save, has } = useUserHelpers();
  const shouldBeVisible =
    has(UserHelperTooltipsKeys.SALESFORCE_SYNCING) && !has(UserHelperTooltipsKeys.SALESFORCE_LOGS);
  return (
    <div className={styles._logs_wrapper}>
      {shouldBeVisible && (
        <DiscoveryTooltip title="Check the logs" visible={defaultTooltipVisible} isPersistent>
          <DiscoveryTooltip.TooltipImage className={styles._image}>
            <img src={LogsDiscovery} width={225} alt="Syncing logs DT" />
          </DiscoveryTooltip.TooltipImage>
          <DiscoveryTooltip.TooltipFooter description="See what went wrong when synchronizing between Salesforce and Bloobirds. Clicking on a row will display a detailed explanation for each log ✨">
            <DiscoveryTooltip.TooltipButton
              variant="secondary"
              className={styles._primary_button}
              isMainButton
              size="small"
              onClick={() => {
                save(UserHelperTooltipsKeys.SALESFORCE_LOGS);
              }}
            >
              Ok
            </DiscoveryTooltip.TooltipButton>
          </DiscoveryTooltip.TooltipFooter>
        </DiscoveryTooltip>
      )}
    </div>
  );
};
