import React, { useState } from 'react';
import styles from './manageTab.module.css';
import { Pagination, SearchInput } from '@bloobirds-it/flamingo-ui';
import { CadencesList } from '../components/cadenceList/cadenceList';
import {
  CadencesTab,
  CadencesTabContent,
  CadencesTabFooter,
  CadencesTabHeader,
  CadencesTabHeaderLeft,
  CadencesTabHeaderRight,
  CadencesTableContainer,
} from '../../../layouts/cadencesLayout/cadencesTabLayout/cadencesTabLayout';
import { CreateEditCadenceSettings } from '../components/createEditCadenceSettings/createEditCadenceSettings';
import { useManageCadenceList } from '../components/cadenceList/useManageCadenceList';
import { AuthorFilter, BobjectTypeFilter } from '../components/filters/cadenceFilters';

export const ManageTab = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const {
    totalCadences,
    page,
    pageSize,
    setPage,
    setPageSize,
    searchValue,
    setSearchValue,
    selectedBobjectType,
    setSelectedBobjecType,
  } = useManageCadenceList();

  return (
    <CadencesTab>
      <>
        <CadencesTabHeader>
          <CadencesTabHeaderLeft>
            <div className={styles.tabTitle__container}>
              <BobjectTypeFilter
                selectedBobjectType={selectedBobjectType}
                setSelectedBobjecType={setSelectedBobjecType}
                setSearchValue={setSearchValue}
              />
              <AuthorFilter />
            </div>
          </CadencesTabHeaderLeft>
          <CadencesTabHeaderRight>
            <SearchInput
              width="200"
              placeholder="Search"
              color="softBloobirds"
              onChange={(value: string) => setSearchValue(value)}
              value={searchValue}
            />
          </CadencesTabHeaderRight>
        </CadencesTabHeader>
        <CadencesTabContent>
          <CadencesTableContainer>
            <CadencesList />
          </CadencesTableContainer>
        </CadencesTabContent>
        <CadencesTabFooter>
          <Pagination
            rowsPerPageOptions={[10, 20, 50]}
            page={page}
            count={totalCadences}
            rowsPerPage={pageSize}
            onChangePage={setPage}
            onChangeRowsPerPage={setPageSize}
          />
        </CadencesTabFooter>
        {isModalOpen && (
          <CreateEditCadenceSettings
            onClose={() => setIsModalOpen(false)}
            bobjectType={selectedBobjectType}
          />
        )}
      </>
    </CadencesTab>
  );
};
