import React, { useEffect, useMemo, useState } from 'react';
import {
  Button,
  Icon,
  ModalContent,
  ModalFooter,
  ShortTermRelativeDatePicker,
  Spinner,
  Text,
  useToasts,
} from '@bloobirds-it/flamingo-ui';
import { BobjectApi } from '../../../misc/api/bobject';
import { useContactFlow } from '../../../hooks';
import { getTextFromLogicRole } from '../../../utils/bobjects.utils';
import styles from './scheduleNextStep.module.css';
import { COMPANY_FIELDS_LOGIC_ROLE } from '../../../constants/company';
import { LEAD_FIELDS_LOGIC_ROLE } from '../../../constants/lead';
import {
  TASK_FIELDS_LOGIC_ROLE,
  TASK_STATUS_VALUE_LOGIC_ROLE,
  TASK_TYPE,
} from '../../../constants/task';
import { BOBJECT_TYPES } from '../../../constants/bobject';
import { useSelectedOpportunity } from '../../../hooks/useSelectedOpportunity';
import { BobjectType } from '../../../typings/bobjects';
import { BobjectTypes, OPPORTUNITY_FIELDS_LOGIC_ROLE, UserHelperKeys } from '@bloobirds-it/types';
import { useController, useForm } from 'react-hook-form';
import { AssignedToSelector, BobjectSelector } from '@bloobirds-it/bobjects';
import { useActiveUserSettings, useUserHelpers } from '@bloobirds-it/hooks';
import { ScheduleShortTimes, ScheduleShortTimesValues, Unit } from './scheduleNextStep.constants';
import { add } from '@bloobirds-it/utils';

const createEntity = (data: any, entity: BobjectType) =>
  BobjectApi.request().bobjectType(entity).create(data);

const ScheduleNextStep = ({
  handleBack,
  handleSkip,
  handleClose,
}: {
  handleBack: () => void;
  handleSkip: () => void;
  handleClose: () => void;
}) => {
  const { setScheduleStepData, referencedBobject } = useContactFlow();
  const { selectedOpportunity } = useSelectedOpportunity();
  const { createToast } = useToasts();
  const { settings } = useActiveUserSettings();
  const [assignedToId, setAssignedToId] = useState<string>(settings?.user?.id);
  const [isSubmitting, setIsSubmitting] = useState(false);
  let bobjectName: string;
  const bobject = referencedBobject;
  switch (bobject?.id?.typeName) {
    case 'Company':
      bobjectName = getTextFromLogicRole(bobject, COMPANY_FIELDS_LOGIC_ROLE.NAME);
      break;
    case 'Opportunity':
      bobjectName = getTextFromLogicRole(bobject, OPPORTUNITY_FIELDS_LOGIC_ROLE.NAME);
      break;
    case 'Lead':
      bobjectName = getTextFromLogicRole(bobject, LEAD_FIELDS_LOGIC_ROLE.FULL_NAME);
      break;
  }
  const [bobjectSelectedName, setBobjectSelectedName] = useState<string>(bobjectName);
  const { get, saveCustom } = useUserHelpers();
  const savedDefaultValue = get(UserHelperKeys.SCHEDULE_NEXT_STEP_DATETIME_FILTER);
  const NOW = new Date();

  const { control, handleSubmit: handleSubmitForm, watch, reset } = useForm();

  const { field: titleField } = useController({
    control,
    name: TASK_FIELDS_LOGIC_ROLE.TITLE,
  });

  const {
    field: { value: taskDate, onChange: taskDateOnChange },
  } = useController({
    control,
    name: TASK_FIELDS_LOGIC_ROLE.SCHEDULED_DATETIME,
  });

  const {
    field: { onChange: relatedOnChange },
  } = useController({
    control,
    name: 'related',
    defaultValue: referencedBobject,
  });

  useEffect(() => {
    return () => reset();
  }, []);

  const dateTime = watch(TASK_FIELDS_LOGIC_ROLE.SCHEDULED_DATETIME);
  const title = watch(TASK_FIELDS_LOGIC_ROLE.TITLE);
  const related = watch('related');

  const dateTimeValue = useMemo(() => {
    if (dateTime?.type) {
      return dateTime?.type === ScheduleShortTimes.custom
        ? dateTime?.date
        : add(
            NOW,
            ScheduleShortTimesValues[dateTime?.type]?.unit as Unit,
            ScheduleShortTimesValues[dateTime?.type]?.amount as number,
          );
    } else {
      return savedDefaultValue
        ? add(
            NOW,
            ScheduleShortTimesValues[savedDefaultValue]?.unit as Unit,
            ScheduleShortTimesValues[savedDefaultValue]?.amount as number,
          )
        : add(NOW, 'minutes', 10);
    }
  }, [NOW, savedDefaultValue, dateTime?.type]);

  function onSubmit(data: any) {
    setIsSubmitting(true);
    const { related, ...taskInfo } = data;

    let body = {
      [TASK_FIELDS_LOGIC_ROLE.TITLE]: taskInfo[TASK_FIELDS_LOGIC_ROLE.TITLE] ?? 'Untitled task',
      [TASK_FIELDS_LOGIC_ROLE.TASK_TYPE]: TASK_TYPE.NEXT_STEP,
      [TASK_FIELDS_LOGIC_ROLE.ASSIGNED_TO]: assignedToId,
      [TASK_FIELDS_LOGIC_ROLE.STATUS]: TASK_STATUS_VALUE_LOGIC_ROLE.TODO,
      [TASK_FIELDS_LOGIC_ROLE.SCHEDULED_DATETIME]: dateTimeValue,
    };

    if (related) {
      const relatedBobjectType =
        related?.bobjectType?.toUpperCase() || related?.id?.typeName?.toUpperCase();
      // @ts-ignore
      body[TASK_FIELDS_LOGIC_ROLE[relatedBobjectType]] =
        related?.rawBobject?.id || related?.id?.value;
    }

    if (selectedOpportunity) {
      body = { ...body, [TASK_FIELDS_LOGIC_ROLE.OPPORTUNITY]: selectedOpportunity?.id?.value };
    }

    createEntity(body, BOBJECT_TYPES.TASK as BobjectType)
      .then(() => {
        createToast({ type: 'success', message: 'Task has been successfully scheduled!' });
        setIsSubmitting(false);
        handleClose();
      })
      .catch(error => {
        if (error) createToast({ type: 'error', message: 'Something went wrong!' });
        handleClose();
      });
  }

  useEffect(() => {
    const relatedDefaultBobjectType = related?.bobjectType || related?.id?.typeName;
    const relatedDefaultId = related?.rawBobject?.id || related?.id?.value;
    let bobjectId = {};

    if (relatedDefaultBobjectType === BobjectTypes.Opportunity) {
      bobjectId = { opportunityId: relatedDefaultId };
    } else if (relatedDefaultBobjectType === BobjectTypes.Company) {
      bobjectId = { companyId: relatedDefaultId };
    } else {
      bobjectId = { leadId: relatedDefaultId };
    }

    setScheduleStepData({
      time: null,
      companyId: null,
      leadId: null,
      opportunityId: null,
      title: title ?? 'Untitled task',
      dateTime: dateTimeValue,
      ...bobjectId,
    });
  }, [title, dateTime, related]);

  return (
    <>
      <ModalContent>
        <div className={styles.content_container}>
          <div className={styles.editor}>
            <span className={styles.modal_title}>
              <Icon name="clock" color="bloobirds" size={16} />
              <Text size="l">Task</Text>
            </span>
            <span className={styles.divider} />
            <span className={styles.taskInfo}>
              <Icon name="clock" color="softPeanut" size={16} />
              <Text size="xs" color="softPeanut">
                Due date
              </Text>
              <div className={styles.relative_date_picker}>
                <ShortTermRelativeDatePicker
                  size="small"
                  value={taskDate}
                  onChange={value => {
                    if (value?.type !== ScheduleShortTimes.custom) {
                      saveCustom({
                        key: UserHelperKeys.SCHEDULE_NEXT_STEP_DATETIME_FILTER,
                        data: value?.type,
                      });
                    }
                    taskDateOnChange(value);
                  }}
                  defaultValue={{
                    date: dateTimeValue,
                    type: savedDefaultValue ? savedDefaultValue : ScheduleShortTimes.tenMinutes,
                  }}
                  borderless
                  width="100px"
                  dropdownProps={{
                    zIndex: 10000,
                    arrow: true,
                    position: 'bottom',
                  }}
                />
              </div>
              <span className={styles.assigned_to}>
                <Icon name="personAdd" color="softPeanut" size={16} />
                <Text size="xs" color="softPeanut">
                  Assigned to
                </Text>
                <AssignedToSelector
                  assignedToId={assignedToId}
                  updateAssignedTo={setAssignedToId}
                />
              </span>
              <BobjectSelector
                accountId={settings?.account?.id}
                selected={bobjectSelectedName}
                id="static"
                size="small"
                onBobjectChange={(bobject: any) => {
                  const bobjectType = bobject?.bobjectType;
                  relatedOnChange(bobject);
                  if (bobjectType === BobjectTypes.Company) {
                    setBobjectSelectedName(bobject?.companyName);
                  } else if (bobjectType === BobjectTypes.Lead) {
                    setBobjectSelectedName(bobject?.fullName);
                  } else if (bobjectType === BobjectTypes.Opportunity) {
                    setBobjectSelectedName(bobject?.name);
                  }
                }}
              />
            </span>
            <span className={styles.divider} />
            <textarea
              className={styles.textArea}
              placeholder="Describe your task... "
              autoFocus
              {...titleField}
            />
          </div>
          <div>
            <div className={styles.bottom_bar}>
              <span className={styles.record_related}>
                <div className={styles.bobject_selector}></div>
              </span>
            </div>
          </div>
        </div>
      </ModalContent>
      <ModalFooter>
        <div className={styles.buttonsContainer}>
          <Button variant="clear" onClick={handleBack}>
            Back
          </Button>
          <div>
            <Button variant="secondary" onClick={handleSkip}>
              Skip
            </Button>
            <Button onClick={handleSubmitForm(onSubmit)} disabled={isSubmitting}>
              {isSubmitting ? (
                <Spinner name="loadingCircle" size={12} />
              ) : (
                'Save & schedule next step'
              )}
            </Button>
          </div>
        </div>
      </ModalFooter>
    </>
  );
};

export default ScheduleNextStep;
