import { useController, useFormContext } from 'react-hook-form';
import {
  Chip,
  ChipGroup,
  DateTimePicker,
  Icon,
  Input,
  Item,
  Select,
  Text,
  TextArea,
  Tooltip,
} from '@bloobirds-it/flamingo-ui';
import React, { useEffect, useState } from 'react';
import { atom, useRecoilState } from 'recoil';
import { recoilPersist } from 'recoil-persist';
import styles from '../../meetingModal.module.css';
import AutoCompleteSearchCompanies from '../../../autoCompleteSearchCompanies/autoCompleteSearchCompanies';
import AutoCompleteSearchLeads from '../../../autoCompleteSearchLeads/autoCompleteSearchLeads';
import { getRelatedBobject, getValueFromLogicRole } from '../../../../utils/bobjects.utils';
import { useActiveUser, useEntity, useLeads } from '../../../../hooks';
import { LEAD_FIELDS_LOGIC_ROLE } from '../../../../constants/lead';
import { useFullSalesEnabled } from '../../../../hooks/useFeatureFlags';
import {
  ACTIVITY_FIELDS_LOGIC_ROLE,
  MEETING_MAIN_TYPE_VALUES,
} from '../../../../constants/activity';
import { BobjectPicklistValueEntity } from '../../../../typings/entities.js';
import { useGeneratePlaceHolder } from '../../hooks/useEventPlaceholder';
import { useUserHelpers } from '../../../../hooks/useUserHelpers';
import { UserHelperKeys } from '../../../../constants/userHelperKeys';
import { MeetingDiscoveryTooltip } from '../../../discoveryTooltips/meetingForm';
import { useCalendar } from '../../hooks/useCalendar';
import { Bobject } from '../../../../typings/bobjects';

const { persistAtom } = recoilPersist();

const meetingTypeAtom = atom({
  key: 'meetingTypeAtom',
  default: null,
  effects: [persistAtom],
});

function getEmailFromCompany(company: Bobject) {
  const companyEmails = company
    ? company.fields.filter(field => field.value && field.type === 'EMAIL')
    : [];

  return companyEmails.length > 0 ? companyEmails[0] : undefined;
}

export function MainInfoForm({ prospectingStage }: { prospectingStage: boolean }) {
  const { activeAccount } = useActiveUser();
  const [disabledCompany, setDisabledCompany] = React.useState(false);
  const [disabledLead, setDisabledLead] = React.useState(false);
  const [meetingTypeStored, setMeetingTypeStored] = useRecoilState(meetingTypeAtom);
  const isFullSalesEnabled = useFullSalesEnabled();
  useGeneratePlaceHolder();
  const { setMeetingDuration, setInvitees, invitees } = useCalendar();
  const { setValue, errors, control } = useFormContext();
  const { searchLeads } = useLeads('meetingCalendarModal');
  const activityAccountExecutiveField = useEntity('bobjectFields')?.findByLogicRole(
    ACTIVITY_FIELDS_LOGIC_ROLE.ACCOUNT_EXECUTIVE,
  );
  const accountExecutivePicklistValues = useEntity('bobjectPicklistFieldValues')?.filterBy(
    'bobjectGlobalPicklist',
  )(activityAccountExecutiveField?.bobjectGlobalPicklist);
  const activityAssignedToField = useEntity('bobjectFields')?.findByLogicRole(
    ACTIVITY_FIELDS_LOGIC_ROLE.MEETING_ASSIGNED_TO,
  );
  const activityAssignedToValues = useEntity('bobjectPicklistFieldValues')?.filterBy(
    'bobjectGlobalPicklist',
  )(activityAssignedToField?.bobjectGlobalPicklist);
  const meetingTypeField = useEntity('bobjectFields')?.findByLogicRole(
    ACTIVITY_FIELDS_LOGIC_ROLE.MEETING_MAIN_TYPE,
  );
  const meetingTypes = useEntity('bobjectPicklistFieldValues')?.filterBy('bobjectField')(
    meetingTypeField?.id,
  );
  const firstMeetingType = meetingTypes?.find(
    (type: BobjectPicklistValueEntity) =>
      type?.logicRole === MEETING_MAIN_TYPE_VALUES.FIRST_MEETING,
  );
  const followUpMeetingType = meetingTypes?.find(
    (type: BobjectPicklistValueEntity) => type?.logicRole === MEETING_MAIN_TYPE_VALUES.FOLLOW_UP,
  );
  const restMeetingTypes = meetingTypes?.filter(
    (type: BobjectPicklistValueEntity) =>
      type?.logicRole !== MEETING_MAIN_TYPE_VALUES.FIRST_MEETING &&
      type.logicRole !== MEETING_MAIN_TYPE_VALUES.FOLLOW_UP,
  );
  const { has } = useUserHelpers();
  const users = useEntity('users');

  const {
    field: { ref: titleRef, value: title, onChange: titleOnChange },
  } = useController({ control, name: 'title', rules: { required: true } });
  const errorTitle = errors && errors['title'] && 'This field is required';

  const {
    field: { value: meetingType, onChange: meetingTypeOnChange },
  } = useController({
    control,
    name: ACTIVITY_FIELDS_LOGIC_ROLE.MEETING_MAIN_TYPE,
    defaultValue:
      meetingTypeStored || prospectingStage ? firstMeetingType?.id : followUpMeetingType?.id,
    rules: { required: true },
  });
  const meetingTypeError =
    errors && errors['ACTIVITY__MEETING_MAIN_TYPE'] && 'This field is required';

  const {
    field: { ref: internalNoteRef, value: internalNote, onChange: internalNoteOnChange },
  } = useController({ control, name: ACTIVITY_FIELDS_LOGIC_ROLE.NOTE, defaultValue: '' });

  const {
    field: { value: dateTime, onChange: dateTimeOnChange },
  } = useController({ control, name: 'dateTime', defaultValue: null, rules: { required: true } });
  const errorDatetime = errors && errors['dateTime'] && 'This field is required';
  const {
    field: { ref: durationRef, value: duration, onChange: durationOnChange },
  } = useController({ control, name: 'duration', rules: { required: true } });
  const errorDuration = errors && errors['duration'] && 'This field is required';

  const {
    field: { value: company, onChange: companyOnChange },
  } = useController({
    control,
    name: 'company',
    rules: { required: false },
  });

  const {
    field: { value: lead, onChange: leadOnChange },
  } = useController({
    control,
    name: 'lead',
    rules: { required: false },
  });

  const {
    field: { value: accountExecutive, onChange: accountExecutiveOnChange },
  } = useController({
    control,
    name: ACTIVITY_FIELDS_LOGIC_ROLE.ACCOUNT_EXECUTIVE,
    rules: { required: activityAccountExecutiveField?.required && !isFullSalesEnabled },
  });
  const errorAe =
    errors && errors[ACTIVITY_FIELDS_LOGIC_ROLE.ACCOUNT_EXECUTIVE] && 'This field is required';

  const {
    field: { value: assignedToValue, onChange: activityAssignedToOnChange },
  } = useController({
    control,
    name: ACTIVITY_FIELDS_LOGIC_ROLE.MEETING_ASSIGNED_TO,
    defaultValue: '',
    rules: { required: activityAssignedToField?.required && isFullSalesEnabled },
  });
  const errorAssignedTo =
    errors && errors[ACTIVITY_FIELDS_LOGIC_ROLE.MEETING_ASSIGNED_TO] && 'This field is required';

  useEffect(() => {
    // Check if lead has a related company
    if (lead) {
      const relatedCompany = getValueFromLogicRole(lead, 'LEAD__COMPANY');
      if (!relatedCompany) {
        setDisabledCompany(true);
        setValue('company', null);
      } else if (relatedCompany && !company) {
        setDisabledCompany(false);
        const company = getRelatedBobject(lead, 'Company');
        setValue('company', company);
      }
    }
  }, [lead]);

  useEffect(() => {
    // Check if lead has a related company
    if (company) {
      searchLeads({
        injectReferences: false,
        query: {
          LEAD__COMPANY: [company?.id?.value],
        },
        formFields: true,
        pageSize: 10,
      }).then(({ totalMatching }) => {
        if (totalMatching === 0) {
          setDisabledLead(totalMatching === 0);
          setValue('lead', null);
        }
      });
      if (lead) {
        const leadRelatedCompany = getValueFromLogicRole(lead, LEAD_FIELDS_LOGIC_ROLE.COMPANY);
        if (leadRelatedCompany !== company?.id?.value) {
          setValue('lead', null);
        }
      }
    }
  }, [company]);
  const [launchTooltip, setLaunchTooltip] = useState();
  const defaultTooltipVisible = !has(UserHelperKeys.NEW_MEETING_MODAL);

  useEffect(() => {
    setTimeout(() => {
      setLaunchTooltip(true);
    }, 2000);
  }, []);

  useEffect(() => {
    // If there is no title, set it to the default name
    if (!title) {
      if (company && !title) {
        setValue(
          'title',
          `${getValueFromLogicRole(company, 'COMPANY__NAME')} <> ${activeAccount.name}`,
        );
      } else if (lead && !title) {
        setValue('title', `${getValueFromLogicRole(lead, 'LEAD__NAME')} <> ${activeAccount.name}`);
      }
    }
  }, [company, lead]);

  return (
    <div className={styles._main_row}>
      <div className={styles._main_info_title}>
        <Text size="m">Meeting details</Text>
        {launchTooltip && <MeetingDiscoveryTooltip defaultTooltipVisible={defaultTooltipVisible} />}
      </div>
      <AutoCompleteSearchCompanies
        onChange={(v: string) => {
          const companyEmail = getEmailFromCompany(company);
          const companyName = getValueFromLogicRole(company, 'COMPANY__NAME');
          if (!invitees?.find(invitee => invitee?.email === companyEmail?.value)) {
            setInvitees(curr => [
              ...curr,
              {
                type: 'Company',
                email: companyEmail?.value,
                name: companyName,
              },
            ]);
          }
          companyOnChange(v);
        }}
        size="small"
        value={getValueFromLogicRole(company, 'COMPANY__NAME') || ''}
        name="company"
        disabled={disabledCompany}
        onCompanyIdChange={undefined}
        width={'304px'}
      />
      <Input
        width="100%"
        placeholder="Title *"
        name="title *"
        innerRef={titleRef}
        value={title}
        onChange={titleOnChange}
        error={errorTitle}
      />
      {isFullSalesEnabled && (
        <>
          <div className={styles._meetingType}>
            <ChipGroup
              value={meetingType}
              onChange={v => {
                setMeetingTypeStored(v);
                meetingTypeOnChange(v);
              }}
            >
              {firstMeetingType && (
                <Chip size="small" key={firstMeetingType?.value} value={firstMeetingType?.id}>
                  {firstMeetingType?.value}
                </Chip>
              )}
              {followUpMeetingType && (
                <Chip size="small" key={followUpMeetingType?.value} value={followUpMeetingType?.id}>
                  {followUpMeetingType?.value}
                </Chip>
              )}
              {restMeetingTypes?.map((type: any) => (
                <Chip size="small" key={type?.value} value={type?.logicRole || type?.value}>
                  {type?.value}
                </Chip>
              ))}
            </ChipGroup>
            <Text size="xxs" color="softPeanut" htmlTag="span">
              {/* eslint-disable-next-line react/no-unescaped-entities */}
              What's this{' '}
            </Text>
            <Tooltip
              title="This is the Meeting Type, use only First Meeting in case that this meeting handovers the lead or company to the Account Executive"
              position="top"
            >
              <Icon name="infoFilled" size={14} />
            </Tooltip>
          </div>
          {meetingTypeError && (
            <Text color="tomato" size="xs">
              {meetingTypeError}
            </Text>
          )}
        </>
      )}
      <div className={styles._date_picker}>
        <DateTimePicker
          width="190px"
          size="small"
          placeholder="Date *"
          value={dateTime}
          onChange={dateTimeOnChange}
          error={errorDatetime}
        />
        <div className={styles._duration_input}>
          <Input
            width="112px"
            size="small"
            placeholder="Duration (min) *"
            adornment={<Icon size={12} color="softPeanut" name="clock" />}
            value={duration}
            onChange={v => {
              setMeetingDuration(v);
              durationOnChange(v);
            }}
            innerRef={durationRef}
            error={errorDuration}
          />
        </div>
      </div>
      <AutoCompleteSearchLeads
        onChange={(v: string) => {
          const leadEmail = getValueFromLogicRole(lead, 'LEAD__EMAIL');
          const leadName = getValueFromLogicRole(lead, 'LEAD__NAME');
          if (!invitees?.find(invitee => invitee?.email === leadEmail)) {
            setInvitees(curr => [
              ...curr,
              {
                type: 'Lead',
                email: leadEmail,
                name: leadName,
              },
            ]);
          }
          leadOnChange(v);
        }}
        size="small"
        value={getValueFromLogicRole(lead, LEAD_FIELDS_LOGIC_ROLE.FULL_NAME) || ''}
        name="lead"
        injectReferences
        disabled={disabledLead}
        onLeadIdChange={undefined}
        companyId={company?.id?.value}
        width={'304px'}
      />
      {isFullSalesEnabled ? (
        <Select
          width="304px"
          value={assignedToValue}
          placeholder={`${activityAssignedToField?.name || 'Meeting Assigned to '} ${
            activityAssignedToField?.required ? '*' : ''
          }`}
          size="labeled"
          borderless={false}
          onChange={(v: string) => {
            activityAssignedToOnChange(v);
          }}
          error={errorAssignedTo}
          autocomplete={activityAssignedToValues?.filter((ae: any) => ae?.enabled).length > 7}
        >
          {activityAssignedToValues
            ?.filter((ae: any) => ae?.enabled)
            .map((ae: any) => (
              <Item
                key={ae.id}
                value={ae?.id}
                label={ae.value}
                onClick={(v: string) => {
                  const user = users?.find((user: any) => user?.id === v);
                  if (!invitees?.find(invitee => invitee?.email === user?.email)) {
                    setInvitees(curr => [
                      ...curr,
                      {
                        type: 'AE',
                        email: user?.email,
                      },
                    ]);
                  }
                }}
              >
                {ae.value}
              </Item>
            ))}
        </Select>
      ) : (
        <Select
          width="100%"
          value={accountExecutive}
          placeholder={`Account executive ${activityAccountExecutiveField?.required ? '*' : ''}`}
          size="small"
          borderless={false}
          onChange={(v: string) => {
            accountExecutiveOnChange(v);
          }}
          error={errorAe}
          autocomplete={accountExecutivePicklistValues?.filter((ae: any) => ae?.enabled).length > 7}
        >
          {accountExecutivePicklistValues
            ?.filter((ae: any) => ae?.enabled)
            .map((ae: any) => (
              <Item
                key={ae.id}
                value={ae?.id}
                label={ae.value}
                onClick={(v: string) => {
                  const ae = accountExecutivePicklistValues?.find((ae: any) => ae?.id === v);
                  if (!invitees?.find(invitee => invitee?.email === ae?.value)) {
                    setInvitees(curr => [
                      ...curr,
                      {
                        type: 'AE',
                        email: ae?.value,
                      },
                    ]);
                  }
                }}
              >
                {ae.value}
              </Item>
            ))}
        </Select>
      )}
      <TextArea
        width="100%"
        placeholder="Note (Internal)"
        name="Note (Internal)"
        innerRef={internalNoteRef}
        value={internalNote || ''}
        onChange={internalNoteOnChange}
        minRows={1}
        maxRows={10}
        autoScroll={true}
      />
    </div>
  );
}
