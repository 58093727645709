// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".opportunityControl-module___actionContainer___VSt6N {\n  margin-bottom: 40px;\n}\n\n.opportunityControl-module___actionContainer___VSt6N > p {\n  margin-bottom: 16px;\n}\n\n.opportunityControl-module___actionContainer___VSt6N > div > div:not(:first-child) {\n  margin-left: 8px;\n}\n\n.opportunityControl-module___opportunityContainer___dV9j5 > p {\n  margin-bottom: 8px;\n}\n\n.opportunityControl-module___selectedOpportunity__container___aTyaR {\n  display: flex;\n  justify-content: center;\n  margin-top: 42px;\n}\n\n.opportunityControl-module___buttons__wrapper___UBvBJ {\n  margin-top: 36px;\n  display: flex;\n  width: 100%;\n  justify-content: space-between;\n}\n\n.opportunityControl-module___forward__buttons___Id0rP > button {\n  margin-left: 18px;\n}\n\n.opportunityControl-module__hideButton___3UgKT {\n  visibility: hidden;\n}\n", "",{"version":3,"sources":["webpack://./src/js/components/contactFlowModal/opportunityControl/opportunityControl.module.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;AACrB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;EAChB,aAAa;EACb,WAAW;EACX,8BAA8B;AAChC;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,kBAAkB;AACpB","sourcesContent":["._actionContainer {\n  margin-bottom: 40px;\n}\n\n._actionContainer > p {\n  margin-bottom: 16px;\n}\n\n._actionContainer > div > div:not(:first-child) {\n  margin-left: 8px;\n}\n\n._opportunityContainer > p {\n  margin-bottom: 8px;\n}\n\n._selectedOpportunity__container {\n  display: flex;\n  justify-content: center;\n  margin-top: 42px;\n}\n\n._buttons__wrapper {\n  margin-top: 36px;\n  display: flex;\n  width: 100%;\n  justify-content: space-between;\n}\n\n._forward__buttons > button {\n  margin-left: 18px;\n}\n\n.hideButton {\n  visibility: hidden;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"_actionContainer": "opportunityControl-module___actionContainer___VSt6N",
	"_opportunityContainer": "opportunityControl-module___opportunityContainer___dV9j5",
	"_selectedOpportunity__container": "opportunityControl-module___selectedOpportunity__container___aTyaR",
	"_buttons__wrapper": "opportunityControl-module___buttons__wrapper___UBvBJ",
	"_forward__buttons": "opportunityControl-module___forward__buttons___Id0rP",
	"hideButton": "opportunityControl-module__hideButton___3UgKT"
};
export default ___CSS_LOADER_EXPORT___;
