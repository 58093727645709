import { groupBy } from 'lodash';
import { ACTIVITY_FIELDS_LOGIC_ROLE } from '../constants/activity';

// TODO: Make unit test for this function
export const aggregationToChartData = ({ data }) => {
  return data
    ?.sort((a, b) => a.fieldDataList[0]?.valueOrdering - b.fieldDataList[0]?.valueOrdering)
    ?.map(field => {
      return {
        color: field.fieldDataList[0].valueBackgroundColor,
        group: field.fieldDataList[0].text,
        value: field.value,
      };
    });
};

export const aggregationToStackedChartData = ({ data }) => {
  if (data) {
    const values = groupBy(
      data,
      d => d?.fieldDataList?.find(x => x?.logicRole === ACTIVITY_FIELDS_LOGIC_ROLE.USER)?.value,
    );
    return Object.values(values).map(value => {
      const userField = value[0]?.fieldDataList?.find(
        fields => fields?.logicRole === ACTIVITY_FIELDS_LOGIC_ROLE.USER,
      );
      const username = userField?.text;
      const userId = userField?.value;
      const stacks = value?.reduce((target, k, index) => {
        const key = value[index]?.fieldDataList?.find(
          x => x?.logicRole === ACTIVITY_FIELDS_LOGIC_ROLE.TYPE,
        )?.text;
        target[key] = value[index]?.value;
        return target;
      }, {});
      return {
        groupKey: username,
        id: userId,
        ...stacks,
      };
    });
  } else {
    return undefined;
  }
};
