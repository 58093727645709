import { Skeleton } from '@bloobirds-it/flamingo-ui';
import React, { FC, useEffect } from 'react';
import { LEAD_FIELDS_LOGIC_ROLE } from '../../../constants/lead';
import { useSelectedLead } from '../../../hooks/useSelectedLead';
import { getValueFromLogicRole } from '../../../utils/bobjects.utils';
import CadenceTableOld from '../cadenceTable/cadenceTableOld';
import { useContactBobjects } from '../contactPageContext';
import styles from '../contactPage.module.css';
import ContactTabs from '../contactTabs/contactTabs';
import LeadTableActions from '../leadTableActions/leadTableActions';
import TasksBox from '../tasksBox/tasksBox';
import LeadCard from './leadCard/leadCard';
import SendToSalesModal from '../sendToSalesModal/sendToSalesModal';
import useSendToSales from '../../../hooks/useSendToSales';
import { useCadenceControl, useDocumentTitle } from '../../../hooks';
import { CadenceTable } from '@bloobirds-it/cadence';
import { useNewCadenceTableEnabled } from '../../../hooks/useFeatureFlags';
import { useUserSettings } from '../../../components/userPermissions/hooks';

interface LeadPageProps {
  companyId: boolean;
}

const getLeadFullName = (lead: any) =>
  getValueFromLogicRole(lead, LEAD_FIELDS_LOGIC_ROLE.FULL_NAME) ||
  getValueFromLogicRole(lead, LEAD_FIELDS_LOGIC_ROLE.EMAIL);

const LeadPage: FC<LeadPageProps> = () => {
  const { resetSelectedLead } = useSelectedLead();
  const contactBobjects = useContactBobjects();
  const { isOpen: isSendToSalesOpen, closeSendToSalesModal } = useSendToSales();
  const { openCadenceControl } = useCadenceControl();
  const isNewCadenceTableEnabled = useNewCadenceTableEnabled();
  const settings = useUserSettings();
  const userId = settings?.user?.id;

  useDocumentTitle(getLeadFullName(contactBobjects?.active));
  const InfoCard = LeadCard;

  useEffect(
    () => () => {
      resetSelectedLead();
    },
    [],
  );

  return (
    <div>
      <div className={styles._info__container}>
        <div className={styles._info__row}>
          <div className={styles._infoCard__container}>
            {contactBobjects?.active ? (
              <InfoCard bobject={contactBobjects?.active} />
            ) : (
              <Skeleton variant="rect" width={328} height={435} />
            )}
          </div>
          <div className={styles._info__column}>
            {contactBobjects?.active ? (
              <TasksBox hasChangedTheBobject={contactBobjects.hasActiveBobjectUpdated} />
            ) : (
              <Skeleton height={96} width="100%" variant="rect" />
            )}
            <>
              <div className={styles._cadence__row}>
                <div className={styles._cadence__container}>
                  {!isNewCadenceTableEnabled ? (
                    contactBobjects?.active && contactBobjects?.leads ? (
                      <CadenceTableOld
                        offsetDays={-2}
                        bobject={contactBobjects?.active}
                        handleClickTitle={(step: any) =>
                          openCadenceControl({
                            bobjectToSet: contactBobjects?.active,
                            previousStep: false,
                            step,
                          })
                        }
                      />
                    ) : (
                      <Skeleton width="100%" height={264} variant="rect" />
                    )
                  ) : (
                    <CadenceTable
                      activeUserId={userId}
                      bobject={{
                        id: contactBobjects?.urlBobject.id,
                        companyId: getValueFromLogicRole(
                          contactBobjects.active,
                          LEAD_FIELDS_LOGIC_ROLE.COMPANY,
                        ),
                        ...(contactBobjects?.active
                          ? {
                              assignedTo: getValueFromLogicRole(
                                contactBobjects.active,
                                LEAD_FIELDS_LOGIC_ROLE.ASSIGNED_TO,
                              ),
                              cadenceId: getValueFromLogicRole(
                                contactBobjects.active,
                                LEAD_FIELDS_LOGIC_ROLE.CADENCE,
                              ),
                              targetMarket: null,
                            }
                          : {
                              assignedTo: null,
                              cadenceId: null,
                              targetMarket: null,
                            }),
                      }}
                      leads={[]}
                      openCadenceControl={(step: any) =>
                        openCadenceControl({
                          bobjectToSet: contactBobjects?.active,
                          previousStep: false,
                          step,
                        })
                      }
                    />
                  )}
                </div>
              </div>
              <LeadTableActions bobjectType="Lead" leads={[contactBobjects?.active]} />
            </>
          </div>
        </div>
      </div>
      <ContactTabs />
      {isSendToSalesOpen && <SendToSalesModal open onClose={closeSendToSalesModal} />}
    </div>
  );
};

export default LeadPage;
