import React, { useEffect } from 'react';
import {
  Button,
  CircularBadge,
  Icon,
  Input,
  Modal,
  ModalCloseIcon,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalTitle,
  Text,
} from '@bloobirds-it/flamingo-ui';
import { Controller, FormProvider, useController, useForm } from 'react-hook-form';
import { TEMPLATE_TYPES, TEMPLATE_TYPES_COPIES } from '../../../../utils/templates.utils';
import MessagingSidebarSettings from '../messagingSidebarSettings';
import MessagingTemplateFooterActions from '../../../../components/messagingTemplates/messagingTemplateFooterActions/messagingTemplateFooterActions';
import styles from './messagingTemplateForm.module.css';
import {
  deserialize,
  EditorToolbar,
  EditorToolbarControlsSection,
  EditorToolbarFileAttachment,
  EditorToolbarFontStylesSection,
  EditorToolbarImage,
  EditorToolbarListsSection,
  EditorToolbarSection,
  EditorToolbarTemplateVariable,
  EditorToolbarTextMarksSection,
  FloatingTemplateVariable,
  TemplateEditorToolbarMeetingLink,
  RichTextEditor,
  useRichTextEditorPlugins,
} from '@bloobirds-it/rich-text-editor';
import useSWR from 'swr';
import { api } from '../../../../utils/api';
import { useEntity, useRouter } from '../../../../hooks';
import { PAGES_ROUTES } from '../../../../pages/playbookPages/sidebar/sidebar';
import useAttachedFiles from '../../../../hooks/useAttachedFiles';
import { checkIfIsEmpty } from '@bloobirds-it/email';
import spacetime from 'spacetime';
import { UserHelperKeys } from '../../../../constants/userHelperKeys';
import { useUserHelpers } from '../../../../hooks/useUserHelpers';
import { formatDate } from '../../../../utils/dates.utils';
import { useUserSettings } from '../../../../components/userPermissions/hooks';
import { useLocalStorage } from '@bloobirds-it/hooks';
import { AttachmentList } from '@bloobirds-it/misc';

function ConfirmationModal({ data, onClose, openMode, onSubmit, onDiscard, cadences }) {
  const { history } = useRouter();

  const cadencesUsingTemplate = cadences?.data?.cadences;

  return (
    <Modal open={!!openMode} onClose={onClose} width={640}>
      <ModalHeader variant="gradient" color="purple" className={styles.modal_header}>
        <ModalTitle variant="gradient">
          <div className={styles.modal_title}>
            <Icon color="purple" name="autoMail" size={24} />
            <Text color="peanut" size="s">
              Email template in cadence
            </Text>
          </div>
        </ModalTitle>
        <ModalCloseIcon size="small" onClick={onClose} color="purple" />
      </ModalHeader>
      <ModalContent>
        {openMode === 'SAVE' ? (
          <Text color="softPeanut" size="s" className={styles.modal_text}>
            This template is being used in {cadencesUsingTemplate?.length} cadence(s), this changes
            will be applied to all the auto-email tasks that are using this template and already
            scheduled.
          </Text>
        ) : (
          <Text color="softPeanut" size="s" className={styles.modal_text}>
            This template cannot be delete because is being used in {cadencesUsingTemplate?.length}{' '}
            cadence(s), if you want to delete it unlink the template from these cadences.
          </Text>
        )}
        {cadencesUsingTemplate && (
          <ul>
            {cadencesUsingTemplate?.map(
              cadence =>
                'name' in cadence && (
                  <li key={cadence.id}>
                    <Text color="softPeanut" size="s">
                      {cadence.name}
                    </Text>
                  </li>
                ),
            )}
          </ul>
        )}
      </ModalContent>
      <ModalFooter>
        <div />
        {openMode === 'SAVE' ? (
          <div>
            <Button
              variant="secondary"
              color="lightPurple"
              onClick={onDiscard}
              className={styles.secondary_button}
            >
              Discard Changes
            </Button>
            <Button variant="primary" color="purple" onClick={() => onSubmit(data)}>
              Accept
            </Button>
          </div>
        ) : (
          <div>
            <Button
              variant="secondary"
              color="lightPurple"
              onClick={() => history.push(PAGES_ROUTES.CADENCES)}
              className={styles.secondary_button}
            >
              Go to Cadences
            </Button>
            <Button variant="primary" color="purple" onClick={onClose}>
              Close
            </Button>
          </div>
        )}
      </ModalFooter>
    </Modal>
  );
}

const MessagingTemplateForm = ({
  mode,
  templateType,
  messagingTemplate,
  onSave,
  onDelete,
  onCancel,
  error,
}) => {
  const { data: cadences } = useSWR(
    messagingTemplate ? `/messaging/messagingTemplates/${messagingTemplate.id}/cadences` : null,
    url =>
      api.get(url, {
        headers: { 'Content-Type': 'application/json' },
        data: {},
      }),
  );

  const [subjectEditor, setSubjectEditor] = React.useState(null);
  const [bodyEditor, setBodyEditor] = React.useState(null);

  const [modalMode, setModalMode] = React.useState(null);
  const [dataToSubmit, setDataToSubmit] = React.useState(null);
  const { save } = useUserHelpers();
  const {
    attachedFiles,
    removeAttachedFile,
    uploadAttachedFile,
    syncAttachments,
  } = useAttachedFiles();
  const settings = useUserSettings();
  const { get, remove } = useLocalStorage();
  const subjectQueryString = get('subjectQueryStringKey')?.split('=')[1];
  const bodyQueryString = get('bodyQueryStringKey')?.split('=')[1];
  const isSnippet = templateType === TEMPLATE_TYPES.SNIPPET;

  useEffect(() => {
    if (bodyQueryString && subjectQueryString) {
      remove('subjectQueryStringKey');
      remove('bodyQueryStringKey');
    }
  }, []);

  useEffect(() => {
    if (messagingTemplate && messagingTemplate.mediaFiles?.length > 0) {
      syncAttachments(messagingTemplate.mediaFiles);
    }
  }, [messagingTemplate]);

  const subjectPlugins = useRichTextEditorPlugins({
    templateVariables: true,
    replaceTemplateVariables: false,
    singleLine: true,
    marks: false,
    elements: false,
    images: false,
    autoReplace: false,
  });

  const contentPlugins = useRichTextEditorPlugins({
    templateVariables: true,
    replaceTemplateVariables: false,
    marks: true,
    elements: true,
    images: true,
  });

  const users = useEntity('users');

  const author = users
    ?.all()
    ?.find(
      user =>
        user?.id === messagingTemplate?.createdBy ||
        (!messagingTemplate && user?.id === settings?.user?.id),
    );

  const defaultValues = {
    id: messagingTemplate?.id,
    name:
      mode !== 'CLONE'
        ? bodyQueryString
          ? `New email ${isSnippet ? 'snippet' : 'template'} ${formatDate(
              new Date(),
              'MMM dd, yyyy hh:mm a',
            )}`
          : messagingTemplate?.name
        : `${messagingTemplate?.name} - Copy`,
    subject: deserialize(
      subjectQueryString
        ? decodeURIComponent(atob(subjectQueryString))
        : messagingTemplate?.subject,
      {
        format: subjectQueryString ? 'AST' : messagingTemplate?.format,
        plugins: subjectPlugins,
      },
    ),
    content: deserialize(
      bodyQueryString ? decodeURIComponent(atob(bodyQueryString)) : messagingTemplate?.content,
      {
        format: bodyQueryString ? 'AST' : messagingTemplate?.format,
        plugins: contentPlugins,
      },
    ),
    isOfficial: mode === 'CLONE' ? false : messagingTemplate?.isOfficial,
    isBattlecard: messagingTemplate?.isBattlecard || false,
    visibility: messagingTemplate ? messagingTemplate.visibility === 'PUBLIC' : true,
    stage: messagingTemplate?.stage || 'PROSPECT',
    segmentationValues: messagingTemplate?.segmentationValues
      ? messagingTemplate?.segmentationValues
      : {},
    ...(isSnippet ? { shortcut: messagingTemplate?.shortcut } : {}),
  };

  const methods = useForm({ defaultValues });

  const { field: contentProps } = useController({
    control: methods.control,
    name: 'content',
  });
  const { field: subjectProps } = useController({
    control: methods.control,
    name: 'subject',
  });

  const { field: shortcutProps } = useController({
    control: methods.control,
    name: 'shortcut',
  });

  const onSubmit = async data => {
    const templatePayload = {
      id: messagingTemplate?.id,
      name: data?.name ? data.name : '',
      subject: !checkIfIsEmpty(data.subject) ? JSON.stringify(data.subject) : '',
      content: data.content ? JSON.stringify(data.content) : '',
      visibility: data.visibility ? 'PUBLIC' : 'PRIVATE',
      type: templateType,
      stage: data.stage,
      segmentationValues: data?.segmentationValues ? data?.segmentationValues : {},
      format: 'AST',
      isOfficial: data.isOfficial,
      isBattlecard: data.isBattlecard,
      mediaFileIds: attachedFiles?.length > 0 ? attachedFiles.map(file => file.id) : [],
      ...(isSnippet && { shortcut: data.shortcut }),
    };
    if (mode === 'CLONE') {
      await onSave({ ...templatePayload, id: undefined });
    } else {
      await onSave(templatePayload);
      save(UserHelperKeys.CREATE_FIRST_EMAIL_TEMPLATE);
    }
  };

  function openConfirmationModal(data) {
    setModalMode('SAVE');
    setDataToSubmit(data);
  }

  function openDeleteModal() {
    setModalMode('DELETE');
    setDataToSubmit(null);
  }

  useEffect(() => {
    if (error) {
      methods?.setError('name', { type: 'custom', message: error?.name });
    }
  }, [error, methods?.setError]);

  const isEmailtemplate = templateType === TEMPLATE_TYPES.EMAIL;
  const shouldShowImages = isEmailtemplate || templateType === TEMPLATE_TYPES.PITCH;
  const shouldShowTemplateVariables = isEmailtemplate || templateType === TEMPLATE_TYPES.LINKEDIN;

  return (
    <FormProvider {...methods}>
      <form
        onSubmit={methods.handleSubmit(
          messagingTemplate?.cadenceUsages > 0 && mode === 'EDITION'
            ? openConfirmationModal
            : onSubmit,
        )}
      >
        <main className={styles._content}>
          <div className={styles._editor}>
            <Button
              className={styles._back__button}
              onClick={onCancel}
              variant="clear"
              color="bloobirds"
              iconLeft="arrowLeft"
            >
              Back to the list
            </Button>
            <Text className={styles._title} color="softPeanut" size="l" weight="medium">
              Configure {TEMPLATE_TYPES_COPIES[templateType]} template
            </Text>
            {mode !== 'CLONE' && mode !== 'CREATION' && (
              <div className={styles._audit_box}>
                <CircularBadge
                  size="s"
                  color="lightPeanut"
                  style={{ color: 'var(--white)', fontSize: '9px' }}
                  backgroundColor={author?.color || 'lightPeanut'}
                >
                  {author?.shortname || 'U'}
                </CircularBadge>
                <Text color="softPeanut" size="s">
                  Created by:{' '}
                </Text>
                <Text color="peanut" size="s">
                  {`${author?.name}${
                    messagingTemplate?.creationDatetime
                      ? ` on ${spacetime(messagingTemplate?.creationDatetime).format('nice-short')}`
                      : ''
                  }`}
                </Text>
                <Text color="softPeanut" size="s">
                  {messagingTemplate?.updatedBy ? `Last updated by: ` : 'Last updated '}{' '}
                </Text>
                {messagingTemplate?.updatedBy && (
                  <Text color="peanut" size="s">
                    {messagingTemplate?.updatedBy}
                    {`${
                      messagingTemplate?.updateDatetime
                        ? ` on ${spacetime(messagingTemplate?.updateDatetime).format('nice-short')}`
                        : ''
                    }`}
                  </Text>
                )}
              </div>
            )}
            <div className={styles._form__row}>
              <Controller
                name="name"
                as={
                  <Input
                    width="100%"
                    error={methods.errors?.name?.message}
                    placeholder={`${isSnippet ? 'Internal' : 'Template'} Name`}
                  />
                }
                control={methods.control}
                rules={{ required: 'A name for the template is required' }}
              />
            </div>
            <Text color="peanut" size="m" weight="medium">
              Content
            </Text>
            {isEmailtemplate && (
              <RichTextEditor
                placeholder="Enter email subject..."
                plugins={subjectPlugins}
                style={{ width: '100%' }}
                defaultValue={subjectProps.value}
                onChange={subjectProps.onChange}
                setEditor={setSubjectEditor}
              >
                {editor => (
                  <div className={styles.subjectAst}>
                    {editor}
                    {subjectEditor && <FloatingTemplateVariable editor={subjectEditor} />}
                  </div>
                )}
              </RichTextEditor>
            )}
            <RichTextEditor
              plugins={contentPlugins}
              defaultValue={contentProps.value}
              onChange={contentProps.onChange}
              setEditor={setBodyEditor}
            >
              {editor => (
                <div className={styles.editor}>
                  <EditorToolbar id="content">
                    <EditorToolbarControlsSection />
                    <EditorToolbarFontStylesSection />
                    <EditorToolbarTextMarksSection />
                    <EditorToolbarListsSection />
                    <EditorToolbarSection>
                      {isEmailtemplate && <TemplateEditorToolbarMeetingLink />}
                      {isEmailtemplate && (
                        <EditorToolbarFileAttachment onAttachment={uploadAttachedFile} />
                      )}
                      {shouldShowImages && <EditorToolbarImage />}
                      {shouldShowTemplateVariables && <EditorToolbarTemplateVariable />}
                    </EditorToolbarSection>
                  </EditorToolbar>
                  <div className={styles.ast}>{editor}</div>
                  {attachedFiles.length > 0 && (
                    <AttachmentList files={attachedFiles} onDelete={removeAttachedFile} />
                  )}
                </div>
              )}
            </RichTextEditor>
            {templateType === TEMPLATE_TYPES.SNIPPET && (
              <div className={styles.shortcutsWrapper}>
                <Text color="peanut" size="m" weight="bold">
                  Shortcuts
                </Text>
                <Text color="softPeanut" size="s">
                  To use a snippet, type “ / ” followed by the snippet name in the text editor,
                  without spaces
                </Text>
                <Input
                  placeholder="Shortcut name"
                  width="100%"
                  value={shortcutProps.value ? '/' + shortcutProps.value : ''}
                  onChange={value => {
                    const cleanValue = value.replace(/\s|\//g, '');
                    const parsedValue =
                      cleanValue.length > 49 ? cleanValue.slice(0, 49) : cleanValue;
                    shortcutProps.onChange(parsedValue);
                  }}
                  onKeyDown={e => {
                    if (e.key === ' ') {
                      e.preventDefault();
                    }
                  }}
                />
              </div>
            )}
          </div>
          <MessagingSidebarSettings
            templateType={templateType}
            templateOwner={messagingTemplate?.createdBy}
            mode={mode}
          />
        </main>
        <MessagingTemplateFooterActions
          type={templateType}
          mode={mode}
          onCancel={onCancel}
          onDelete={cadences?.data?.cadences?.length > 0 ? openDeleteModal : onDelete}
          templateOwner={messagingTemplate?.createdBy}
        />
      </form>
      <ConfirmationModal
        openMode={modalMode}
        data={dataToSubmit}
        onClose={() => setModalMode(false)}
        onSubmit={onSubmit}
        onDiscard={onCancel}
        cadences={cadences}
      />
    </FormProvider>
  );
};

export default MessagingTemplateForm;
