import { Text } from '@bloobirds-it/flamingo-ui';
import React, { Fragment, useEffect, useState } from 'react';
import { isToday } from 'date-fns';
import styles from '../../../homePage.module.css';
import { TaskHomeCard } from '../../../components/taskHomeCard/taskHomeCard';
import { useHomeTasks } from '../utils/useHomeTasks';
import {
  DateGroupHeader,
  EmptyTaskList,
  Filters,
  getTimeMarkerPosition,
  isStatus,
  LoadingSpinner,
  NoFilterSelected,
  Stages,
} from '../utils/utils';
import { injectReferencesSearchProcess } from '../../../../../misc/api/bobject';
import { useSelectAll } from '@bloobirds-it/hooks';

export const TaskList = ({ stage, taskFilters }: { stage: Stages; taskFilters: Filters }) => {
  const { setInitialItems, resetSelectedItems, resetCheckSelectedAll } = useSelectAll();
  const [timeMarkerPosition, setTimeMarkerPosition] = useState(-1);
  const [tasks, setTasks] = useState<[]>();
  const [overdueTasks, setOverdueTasks] = useState<[]>();
  const [page, setPage] = useState(0);
  const [overduePage, setOverduePage] = useState(0);
  const [totalMatching, setTotalMatching] = useState(0);
  const [overdueTotalMatching, setOverdueTotalMatching] = useState(0);

  const { data: tasksData, isLoading: isLoadingNormal } = useHomeTasks(
    stage,
    taskFilters,
    page,
    false,
  );
  const { data: overdueTasksData, isLoading: isLoadingOverdue } = useHomeTasks(
    stage,
    taskFilters,
    overduePage,
    true,
  );
  const isLoading = (isLoadingNormal && page === 0) || (isLoadingOverdue && overduePage === 0);
  const isLoadingMore = (isLoadingNormal && page > 0) || tasks?.length < page * 50;
  const isLoadingMoreOverdue =
    (isLoadingOverdue && overduePage > 0) || overdueTasks?.length < overduePage * 50;
  const hasNextPage = tasks && totalMatching > tasks.length;
  const hasNextPageOverdue = overdueTasks && overdueTotalMatching > overdueTasks.length;

  useEffect(() => {
    if (tasks && page !== 0) {
      setPage(0);
    }
    if (overdueTasks && overduePage !== 0) {
      setOverduePage(0);
    }
  }, [taskFilters]);

  useEffect(() => {
    if (tasksData?.data) {
      if (tasksData?.data?.totalMatching !== totalMatching) {
        setTotalMatching(tasksData?.data?.totalMatching);
      }
      const newTasks = injectReferencesSearchProcess(tasksData?.data).contents;
      setTimeMarkerPosition(getTimeMarkerPosition(newTasks));
      setTasks(newTasks);
    }
  }, [tasksData]);

  useEffect(() => {
    if (overdueTasksData?.data) {
      if (overdueTasksData?.data?.totalMatching !== overdueTotalMatching) {
        setOverdueTotalMatching(overdueTasksData?.data?.totalMatching);
      }
      const newTasks = injectReferencesSearchProcess(overdueTasksData?.data).contents;
      setOverdueTasks(newTasks);
    }
  }, [overdueTasksData]);

  const isAnyFilterSelected = (() => {
    const prospect = taskFilters.PROSPECT.find(f => !isStatus(f.enumName) && f.enabled);
    const sales = taskFilters.SALES.find(f => !isStatus(f.enumName) && f.enabled);
    if (stage === 'PROSPECT') {
      return !!prospect;
    } else if (stage === 'SALES') {
      return !!sales;
    } else {
      return !!prospect || !!sales;
    }
  })();
  const isOverdueSelected = (() => {
    const prospect = taskFilters.PROSPECT.find(f => f.enumName.includes('OVERDUE'))?.enabled;
    const sales = taskFilters.SALES.find(f => f.enumName.includes('OVERDUE'))?.enabled;
    if (stage === 'PROSPECT') {
      return !!prospect;
    } else if (stage === 'SALES') {
      return !!sales;
    } else {
      return !!prospect || !!sales;
    }
  })();
  const thereAreTasksToShow = tasks?.length > 0 || (isOverdueSelected && overdueTasks?.length > 0);

  // Select items
  useEffect(() => {
    if (!!tasks && isOverdueSelected && !!overdueTasks) {
      setInitialItems([...overdueTasks, ...tasks]);
    } else if (tasks) {
      setInitialItems(tasks);
    } else if (overdueTasks) {
      if (isOverdueSelected) {
        setInitialItems(overdueTasks);
      } else {
        setInitialItems([]);
      }
    }
  }, [tasks, overdueTasks, isOverdueSelected]);
  useEffect(
    () => () => {
      resetSelectedItems();
      resetCheckSelectedAll();
    },
    [],
  );

  if (!isAnyFilterSelected) {
    return <NoFilterSelected />;
  } else if (isLoading) {
    return <LoadingSpinner />;
  } else if (!thereAreTasksToShow) {
    return <EmptyTaskList />;
  } else {
    return (
      <>
        {isOverdueSelected && (
          <>
            {overdueTasks?.map((task: any, index: number) => (
              <Fragment key={task?.id?.value}>
                {index === 0 && <DateGroupHeader text="Overdue" />}
                <TaskHomeCard task={task} />
              </Fragment>
            ))}
            {hasNextPageOverdue && !isLoadingMoreOverdue && (
              <div className={styles._show_more} onClick={() => setOverduePage(overduePage + 1)}>
                <Text size="xxs" color="bloobirds">
                  Load More
                </Text>
              </div>
            )}
            {isLoadingMoreOverdue && <LoadingSpinner />}
          </>
        )}
        {tasks?.map((task: { id: { value: React.Key } }, index: number) => {
          return (
            <Fragment key={task.id.value}>
              {index === 0 && <DateGroupHeader text="Tasks for today" />}
              <TaskHomeCard task={task} />
              {index === timeMarkerPosition && isToday(new Date()) && (
                <div className={styles._time_marker}>
                  <span className={styles._time_marker_bullet} />
                  <span className={styles._time_marker_line} />
                </div>
              )}
            </Fragment>
          );
        })}
        {hasNextPage && !isLoadingMore && (
          <div className={styles._show_more} onClick={() => setPage(page + 1)}>
            <Text size="xxs" color="bloobirds">
              Load More
            </Text>
          </div>
        )}
        {isLoadingMore && <LoadingSpinner />}
      </>
    );
  }
};
