import React from 'react';
import GenericIntegrationLayout from '../../../layouts/genericIntegrationLayout';
import { CRM } from '../../../constants/integrations';

const DynamicsIntegrationPage = () => {
  const fields = [
    {
      name: 'resource',
      defaultValue: '',
      placeholder: 'Dynamic Instance URL',
      inputName: 'resource',
      inputType: 'text',
    },
    {
      name: 'username',
      defaultValue: '',
      placeholder: 'Username',
      inputName: 'username',
      inputType: 'text',
    },
    {
      name: 'password',
      defaultValue: '',
      placeholder: 'Password',
      inputName: 'password',
      inputType: 'password',
    },
    {
      name: 'clientId',
      defaultValue: '',
      placeholder: 'Client ID',
      inputName: 'clientId',
      inputType: 'text',
    },
  ];

  return (
    <GenericIntegrationLayout
      driver="msndynamics"
      integrationName="Microsoft Dynamics"
      syncDirections={['DYNAMICS']}
      crm={CRM.DYNAMICS}
      fields={fields}
    />
  );
};

export default DynamicsIntegrationPage;
