import { DiscoveryTooltip } from '@bloobirds-it/flamingo-ui';
import React from 'react';
import styles from './meetingForm.modules.css';
import { useUserHelpers } from '../../hooks/useUserHelpers';
import homeFiltersTooltip from '../../../assets/tooltipImages/homeFiltersTooltip.png';
import { UserHelperKeys, UserHelperTooltipsKeys } from '../../constants/userHelperKeys';
import { useQuickStartEnabled } from '../../hooks/useQuickStartGuide';

export const HomeFiltersTooltip = ({
  defaultTooltipVisible,
  handlerRef,
}: {
  defaultTooltipVisible: boolean;
  handlerRef: React.RefObject<HTMLDivElement>;
}) => {
  const { save, has } = useUserHelpers();
  const hasQSGEnabled = useQuickStartEnabled();

  return (
    <span className={styles._home_filters_tooltip}>
      {hasQSGEnabled && !has(UserHelperTooltipsKeys.SHOW_QSG_FILTER) && (
        <DiscoveryTooltip
          title="Enable the KPIs you need the most at any time"
          isPersistent
          visible={defaultTooltipVisible}
          anchor={<div />}
          position="right-start"
          height="260px"
        >
          <DiscoveryTooltip.TooltipImage className={styles._home_filters_image}>
            <img src={homeFiltersTooltip} width={230} alt={'calendar'} />
          </DiscoveryTooltip.TooltipImage>
          <DiscoveryTooltip.TooltipFooter description="With this filter you can choose to hide and display KPIs that you like most. Remember you can also order this sections by dragging and dropping them in their new positions">
            <div ref={handlerRef}>
              <DiscoveryTooltip.TooltipButton
                isMainButton
                onClick={() => {
                  save(UserHelperKeys.ENABLE_KPI_METRICS);
                  save(UserHelperTooltipsKeys.SHOW_QSG_FILTER);
                }}
              >
                Ok
              </DiscoveryTooltip.TooltipButton>
            </div>
          </DiscoveryTooltip.TooltipFooter>
        </DiscoveryTooltip>
      )}
    </span>
  );
};
