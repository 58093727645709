import { useSearchSubscription } from '@bloobirds-it/plover';
import {
  atom,
  DefaultValue,
  useRecoilValue,
  useResetRecoilState,
  useSetRecoilState,
  useRecoilState,
  selectorFamily,
  atomFamily,
} from 'recoil';
import { endOfDay, endOfMonth, getMonth, isFuture, isPast, startOfDay } from 'date-fns';
import { BOBJECT_TYPES } from '../constants/bobject';
import { TASK_FIELDS_LOGIC_ROLE, TASK_STATUS_VALUE_LOGIC_ROLE, TASK_TYPE } from '../constants/task';
import SessionManagerFactory from '../misc/session';
import { useEffect } from 'react';
import { injectReferencesSearchProcess } from '../misc/api/bobject';
import { usePausePeriods } from '@bloobirds-it/hooks';
import { getRangeBetweenDates, isToday } from '../utils/dates.utils';
import { useFullSalesEnabled } from './useFeatureFlags';
import mixpanel from 'mixpanel-browser';
import { TasksHookFamilies } from '../typings/tasks';

const SessionManager = SessionManagerFactory();

const BASE_SEARCH_REQUEST = {
  formFields: true,
  pageSize: 1000,
  injectReferences: false,
};

const PROSPECTING_DEFAULT_FILTERS = [
  TASK_TYPE.NEXT_STEP,
  TASK_TYPE.PROSPECT_CADENCE,
  TASK_TYPE.CONTACT_BEFORE_MEETING,
];

const SALES_DEFAULT_FILTERS = [TASK_TYPE.NEXT_STEP, TASK_TYPE.PROSPECT_CADENCE];

const calendarOpenAtom = atom({
  key: 'appCalendarOpenAtom',
  default: false,
});

const calendarExtended = atom({
  key: 'appCalendarExtended',
  default: true,
});

const userFilterAtom = atom({
  key: 'appCalendarUserFilter',
  default: undefined,
});

const dateFilterAtom = atomFamily({
  key: 'appCalendarDateFilter',
  default: new Date(),
});

const prospectingTypesFilterAtom = atomFamily({
  key: 'appCalendarProspectingTypesFilter',
  default: PROSPECTING_DEFAULT_FILTERS,
});

const salesTypesFilterAtom = atomFamily({
  key: 'appCalendarSalesTypesFilter',
  default: SALES_DEFAULT_FILTERS,
});

const tasksTypeFilterAtom = atomFamily({
  key: 'appCalendarTasksTypeFilter',
  default: 'PROSPECTING',
});

const filtersAtom = selectorFamily({
  key: 'appCalendarFilters',
  get: family => ({ get }) => {
    const userFilter = get(userFilterAtom);
    const dateFilter = get(dateFilterAtom(family));
    const prospectingTypesFilter = get(prospectingTypesFilterAtom(family));
    const salesTypesFilter = get(salesTypesFilterAtom(family));
    const tasksTypeFilter = get(tasksTypeFilterAtom(family));

    return {
      user: userFilter,
      date: dateFilter,
      prospectingTypes: prospectingTypesFilter,
      salesTypes: salesTypesFilter,
      tasksType: tasksTypeFilter,
    };
  },
  set: family => ({ set, reset }, value) => {
    if (value instanceof DefaultValue) {
      reset(userFilterAtom);
      reset(dateFilterAtom(family));
      reset(prospectingTypesFilterAtom(family));
      reset(salesTypesFilterAtom(family));
      reset(tasksTypeFilterAtom(family));
    } else {
      if (value.user) set(userFilterAtom, value.user);
      if (value.date) set(dateFilterAtom(family), value.date);
      if (value.prospectingTypes) set(prospectingTypesFilterAtom(family), value.prospectingTypes);
      if (value.salesTypes) set(salesTypesFilterAtom(family), value.salesTypes);
      if (value.tasksType) set(tasksTypeFilterAtom(family), value.tasksType);
    }
  },
});

const itemsAtom = atomFamily({
  key: 'appCalendarTodayTasksItemsAtom',
  default: [],
});

const responseAtom = selectorFamily({
  key: 'appCalendarTodayTasksResponse',
  get: () => null,
  set: family => ({ set }, response) => {
    set(itemsAtom(family), response?.contents);
  },
});

const itemsOverdueAtom = atomFamily({
  key: 'appCalendarTodayTasksOverdueItemsAtom',
  default: [],
});

const responseOverdueAtom = selectorFamily({
  key: 'appCalendarTodayOverdueTasksResponse',
  get: () => null,
  set: family => ({ set }, response) => {
    set(itemsOverdueAtom(family), response?.contents);
  },
});

const queryAtom = selectorFamily({
  key: 'appCalendarQuery',
  get: family => ({ get }) => {
    const filters = get(filtersAtom(family));
    const isAllFilters = checkIsAllFilters(filters, family);
    const types = getTypesFilter(filters);
    const showOverdue = isAllFilters || types.includes('OVERDUE');

    return {
      [TASK_FIELDS_LOGIC_ROLE.ASSIGNED_TO]: filters.user || SessionManager?.getUser()?.id,
      [TASK_FIELDS_LOGIC_ROLE.STATUS]:
        showOverdue || isAllFilters
          ? [TASK_STATUS_VALUE_LOGIC_ROLE.TODO, TASK_STATUS_VALUE_LOGIC_ROLE.OVERDUE]
          : [TASK_STATUS_VALUE_LOGIC_ROLE.TODO],
    };
  },
});

const getTypesFilter = filters =>
  filters.tasksType === 'PROSPECTING' ? filters.prospectingTypes : filters.salesTypes;

const getTaskTypes = filters => {
  const types = getTypesFilter(filters);
  const isAllFilters = types.length === 0;
  const DEFAULT_FILTERS =
    filters.tasksType === 'PROSPECTING'
      ? PROSPECTING_DEFAULT_FILTERS
      : [...SALES_DEFAULT_FILTERS, TASK_TYPE.MEETING];
  const taskTypes = isAllFilters
    ? DEFAULT_FILTERS
    : types.filter(type => !['COMPLETED', 'OVERDUE'].includes(type));
  return filters.tasksType === 'SALES' && taskTypes.includes(TASK_TYPE.NEXT_STEP)
    ? [...taskTypes, TASK_TYPE.MEETING]
    : taskTypes;
};

const getTasksOpportunityType = filters => {
  return filters.tasksType === 'PROSPECTING' ? ['__MATCH_EMPTY_ROWS__'] : ['__MATCH_FULL_ROWS__'];
};

const checkIsAllFilters = (filters, family) => {
  const types = getTypesFilter(filters);
  return types.length === 0 && family !== TasksHookFamilies.Home;
};

export const useAppCalendarVisibility = () => {
  const [calendarOpen, setCalendarOpen] = useRecoilState(calendarOpenAtom);
  const [isExtended, setIsExtended] = useRecoilState(calendarExtended);
  const {
    setProspectingTypesFilter,
    setSalesTypesFilter,
    setTasksTypeFilter,
  } = useUserTasksFilters(TasksHookFamilies.Calendar);

  const openCalendar = () => {
    if (!calendarOpen) {
      mixpanel.track('CALENDAR_OPENED');
      setCalendarOpen(true);
    }
  };

  const closeCalendar = () => {
    if (calendarOpen) {
      setCalendarOpen(false);
    }
  };

  const openAppCalendarWithFilters = (taskTypeFilter, filters) => {
    if (taskTypeFilter === 'PROSPECTING') {
      setTasksTypeFilter('PROSPECTING');
      setProspectingTypesFilter(filters);
    } else if (taskTypeFilter === 'SALES') {
      setTasksTypeFilter('SALES');
      setSalesTypesFilter(filters);
    }
    setIsExtended(false);
    setCalendarOpen(true);
  };

  return {
    isOpen: calendarOpen,
    openCalendar,
    closeCalendar,
    isExtended,
    setIsExtended,
    openAppCalendarWithFilters,
  };
};

export const useUserTasks = family => {
  const query = useRecoilValue(queryAtom(family));
  const filters = useRecoilValue(filtersAtom(family));
  const isFullSalesEnabled = useFullSalesEnabled();
  const types = getTypesFilter(filters);
  const isCurrentMonth = getMonth(new Date(filters.date)) === getMonth(new Date());
  const isPastDate = isPast(new Date(filters.date));
  const isFutureDate = isFuture(new Date(filters.date));
  const isAllFilters = checkIsAllFilters(filters, family);
  const showOverdue = isAllFilters || types.includes('OVERDUE');
  const showCompleted = isAllFilters || types.includes('COMPLETED');
  let statuses = showOverdue
    ? [TASK_STATUS_VALUE_LOGIC_ROLE.TODO, TASK_STATUS_VALUE_LOGIC_ROLE.OVERDUE]
    : [TASK_STATUS_VALUE_LOGIC_ROLE.TODO];
  statuses = !showCompleted
    ? [...statuses]
    : [
        ...statuses,
        TASK_STATUS_VALUE_LOGIC_ROLE.COMPLETED,
        TASK_STATUS_VALUE_LOGIC_ROLE.COMPLETED_OVERDUE,
      ];

  const { data: pastTasks } = useSearchSubscription(
    (isPastDate || isCurrentMonth) &&
      (showCompleted || showOverdue) && {
        query: {
          ...query,
          ...(isFullSalesEnabled ? { TASK__OPPORTUNITY: getTasksOpportunityType(filters) } : {}),
          [TASK_FIELDS_LOGIC_ROLE.SCHEDULED_DATETIME]: {
            query: {
              lt: startOfDay(new Date()),
            },
            searchMode: 'RANGE__SEARCH',
          },
          [TASK_FIELDS_LOGIC_ROLE.IS_AUTOMATED_EMAIL]: [
            'TASK__IS_ACTION_AUTOMATED_EMAIL__NO',
            '__MATCH_EMPTY_ROWS__',
          ],
          [TASK_FIELDS_LOGIC_ROLE.STATUS]: !showOverdue
            ? statuses.filter(status => status !== TASK_STATUS_VALUE_LOGIC_ROLE.TODO)
            : statuses,
          [TASK_FIELDS_LOGIC_ROLE.TASK_TYPE]: getTaskTypes(filters),
        },
        ...BASE_SEARCH_REQUEST,
      },
    BOBJECT_TYPES.TASK,
  );

  const { data: todayTasks } = useSearchSubscription(
    isCurrentMonth && {
      query: {
        ...query,
        ...(isFullSalesEnabled ? { TASK__OPPORTUNITY: getTasksOpportunityType(filters) } : {}),
        [TASK_FIELDS_LOGIC_ROLE.SCHEDULED_DATETIME]: {
          query: {
            lte: endOfDay(new Date()),
            gte: startOfDay(new Date()),
          },
          searchMode: 'RANGE__SEARCH',
        },
        [TASK_FIELDS_LOGIC_ROLE.IS_AUTOMATED_EMAIL]: [
          'TASK__IS_ACTION_AUTOMATED_EMAIL__NO',
          '__MATCH_EMPTY_ROWS__',
        ],
        [TASK_FIELDS_LOGIC_ROLE.STATUS]: statuses,
        [TASK_FIELDS_LOGIC_ROLE.TASK_TYPE]: getTaskTypes(filters),
      },
      ...BASE_SEARCH_REQUEST,
    },
    BOBJECT_TYPES.TASK,
  );

  const { data: futureTasks } = useSearchSubscription(
    (isFutureDate || isCurrentMonth) && {
      query: {
        ...query,
        ...(isFullSalesEnabled ? { TASK__OPPORTUNITY: getTasksOpportunityType(filters) } : {}),
        [TASK_FIELDS_LOGIC_ROLE.SCHEDULED_DATETIME]: {
          query: {
            gt: isCurrentMonth ? endOfDay(new Date()) : startOfDay(new Date(filters.date)),
            lte: endOfMonth(new Date(filters.date)),
          },
          searchMode: 'RANGE__SEARCH',
        },
        [TASK_FIELDS_LOGIC_ROLE.STATUS]: statuses,
        [TASK_FIELDS_LOGIC_ROLE.TASK_TYPE]: getTaskTypes(filters),
      },
      ...BASE_SEARCH_REQUEST,
    },
    BOBJECT_TYPES.TASK,
  );

  return {
    data: [
      ...(pastTasks?.data.contents || []),
      ...(todayTasks?.data.contents || []),
      ...(futureTasks?.data.contents || []),
    ],
  };
};

export const useUserDateTasks = ({ date, family }) => {
  const query = useRecoilValue(queryAtom(family));
  const filters = useRecoilValue(filtersAtom(family));
  const isFullSalesEnabled = useFullSalesEnabled();
  const setResponse = useSetRecoilState(responseAtom(family));
  const items = useRecoilValue(itemsAtom(family));
  const resetItems = useResetRecoilState(itemsAtom(family));
  const isAllFilters = checkIsAllFilters(filters, family);
  const types = getTypesFilter(filters);
  const showCompleted = isAllFilters || types.includes('COMPLETED');
  const isTodayDate = isToday(new Date(date));
  const isFutureDate = isFuture(new Date(date));
  const tasksTypes = getTaskTypes(filters);
  const makeRequest =
    !!date && (isTodayDate || isFutureDate || showCompleted) && tasksTypes.length > 0;

  const { data, isValidating } = useSearchSubscription(
    makeRequest && {
      ...BASE_SEARCH_REQUEST,
      query: {
        ...query,
        ...(isFullSalesEnabled ? { TASK__OPPORTUNITY: getTasksOpportunityType(filters) } : {}),
        [TASK_FIELDS_LOGIC_ROLE.SCHEDULED_DATETIME]: {
          query: {
            gte: startOfDay(date),
            lte: endOfDay(date),
          },
          searchMode: 'RANGE__SEARCH',
        },
        [TASK_FIELDS_LOGIC_ROLE.IS_AUTOMATED_EMAIL]: [
          'TASK__IS_ACTION_AUTOMATED_EMAIL__NO',
          '__MATCH_EMPTY_ROWS__',
        ],
        [TASK_FIELDS_LOGIC_ROLE.TASK_TYPE]: tasksTypes,
        [TASK_FIELDS_LOGIC_ROLE.STATUS]: [
          ...(isTodayDate || isFutureDate ? [TASK_STATUS_VALUE_LOGIC_ROLE.TODO] : []),
          ...(showCompleted
            ? [
                TASK_STATUS_VALUE_LOGIC_ROLE.COMPLETED,
                TASK_STATUS_VALUE_LOGIC_ROLE.COMPLETED_OVERDUE,
              ]
            : []),
        ],
      },
      sort: [
        {
          field: TASK_FIELDS_LOGIC_ROLE.SCHEDULED_DATETIME,
          direction: 'ASC',
        },
      ],
      injectReferences: true,
    },
    BOBJECT_TYPES.TASK,
  );

  useEffect(() => {
    if (data?.data) {
      const extendedResponse = injectReferencesSearchProcess(data?.data);
      setResponse(extendedResponse);
    } else {
      setResponse({ contents: [] });
    }
  }, [data]);

  return { tasks: items, isLoading: !data && isValidating, resetItems };
};

export const useUserDateOverdueTasks = ({ date, family }) => {
  const query = useRecoilValue(queryAtom(family));
  const filters = useRecoilValue(filtersAtom(family));
  const isFullSalesEnabled = useFullSalesEnabled();
  const setResponseOverdue = useSetRecoilState(responseOverdueAtom(family));
  const items = useRecoilValue(itemsOverdueAtom(family));
  const resetItems = useResetRecoilState(itemsOverdueAtom(family));
  const isAllFilters = checkIsAllFilters(filters, family);
  const types = getTypesFilter(filters);
  const tasksTypes = getTaskTypes(filters);
  const showOverdue = isAllFilters || types.includes('OVERDUE');
  const makeRequest = !!date && showOverdue && tasksTypes?.length > 0;

  const { data, isValidating } = useSearchSubscription(
    makeRequest && {
      ...BASE_SEARCH_REQUEST,
      query: {
        ...query,
        ...(isFullSalesEnabled ? { TASK__OPPORTUNITY: getTasksOpportunityType(filters) } : {}),
        [TASK_FIELDS_LOGIC_ROLE.SCHEDULED_DATETIME]: {
          query: {
            lt: startOfDay(date),
          },
          searchMode: 'RANGE__SEARCH',
        },
        [TASK_FIELDS_LOGIC_ROLE.TASK_TYPE]: tasksTypes,
        [TASK_FIELDS_LOGIC_ROLE.IS_AUTOMATED_EMAIL]: [
          'TASK__IS_ACTION_AUTOMATED_EMAIL__NO',
          '__MATCH_EMPTY_ROWS__',
        ],
        [TASK_FIELDS_LOGIC_ROLE.STATUS]: [
          TASK_STATUS_VALUE_LOGIC_ROLE.OVERDUE,
          TASK_STATUS_VALUE_LOGIC_ROLE.TODO,
        ],
      },
      sort: [
        {
          field: TASK_FIELDS_LOGIC_ROLE.SCHEDULED_DATETIME,
          direction: 'ASC',
        },
      ],
      injectReferences: true,
    },
    BOBJECT_TYPES.TASK,
  );

  useEffect(() => {
    if (data?.data) {
      const extendedResponse = injectReferencesSearchProcess(data?.data);
      setResponseOverdue(extendedResponse);
    } else {
      setResponseOverdue({ contents: [] });
    }
  }, [data]);

  return { tasks: items, isLoading: !data && isValidating, resetItems };
};

export const useUserPausePeriods = () => {
  const { periods } = usePausePeriods({
    userId: SessionManager?.getUser()?.id,
  });
  const notFinishedPeriods = periods?.list.filter(period => !period.finished);
  const datesOfPeriods = notFinishedPeriods
    .map(period => getRangeBetweenDates(period.startDate, period.endDate))
    .flat();

  return { periods: datesOfPeriods };
};

export const useUserTasksFilters = family => {
  const [userFilter, setUserFilter] = useRecoilState(userFilterAtom);
  const dateFilter = useRecoilValue(dateFilterAtom(family));
  const prospectingTypesFilter = useRecoilValue(prospectingTypesFilterAtom(family));
  const salesTypesFilter = useRecoilValue(salesTypesFilterAtom(family));
  const tasksTypeFilter = useRecoilValue(tasksTypeFilterAtom(family));
  const resetDateFilter = useResetRecoilState(dateFilterAtom(family));
  const resetProspectingTypesFilter = useResetRecoilState(prospectingTypesFilterAtom(family));
  const resetSalesTypesFilter = useResetRecoilState(salesTypesFilterAtom(family));
  const resetTasksTypeFilter = useResetRecoilState(tasksTypeFilterAtom(family));
  const resetAllFilters = useResetRecoilState(filtersAtom(family));
  const resetOverdueItems = useResetRecoilState(itemsOverdueAtom(family));
  const setFilters = useSetRecoilState(filtersAtom(family));

  return {
    userFilter,
    dateFilter,
    prospectingTypesFilter,
    salesTypesFilter,
    tasksTypeFilter,
    resetDateFilter,
    resetProspectingTypesFilter,
    resetSalesTypesFilter,
    resetTasksTypeFilter,
    resetAllFilters,
    setUserFilter,
    setDateFilter: value => {
      setFilters({ date: value });
    },
    setTasksTypeFilter: value => {
      setFilters({ tasksType: value });
    },
    setProspectingTypesFilter: value => {
      const isAllFilters = value.length === 0 && family !== TasksHookFamilies.Home;
      const showOverdue = isAllFilters || value.includes('OVERDUE');

      if (!showOverdue) {
        resetOverdueItems();
      }

      setFilters({ prospectingTypes: value });
    },

    setSalesTypesFilter: value => {
      const isAllFilters = value.length === 0 && family !== TasksHookFamilies.Home;
      const showOverdue = isAllFilters || value.includes('OVERDUE');

      if (!showOverdue) {
        resetOverdueItems();
      }

      setFilters({ salesTypes: value });
    },
  };
};
