import useSWR from 'swr';
import { MessagingTemplate } from '../typings/messaging';

type UseLatestThreadMessagingTemplateOptions = {
  cadenceId: string;
  dayNumber: number;
};

function useLatestThreadMessagingTemplate({
  cadenceId,
  dayNumber,
}: UseLatestThreadMessagingTemplateOptions) {
  const url = `/messaging/cadences/${cadenceId}/lastThreadMessagingTemplate?dayNumber=${dayNumber}`;
  const { data, error } = useSWR<MessagingTemplate>(url);
  return {
    messagingTemplate: data,
    loading: !error && !data,
  };
}

export default useLatestThreadMessagingTemplate;
