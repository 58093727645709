import React from 'react';
import styles from './activityBody.module.css';
import { isHtml } from '../../../../utils/strings.utils';
import { Text } from '@bloobirds-it/flamingo-ui';
import { getFieldByLogicRole, getTextFromLogicRole } from '../../../../utils/bobjects.utils';
import InboundFieldGrid from './inboundFieldGrid';
import TextMessage from './textMessage';
import HtmlMessage from './htmlMessage';
import OpportunityCardBody from './opportunityCardBody';
import {
  ACTIVITY_FIELDS_LOGIC_ROLE,
  ACTIVITY_TYPES_VALUES_LOGIC_ROLE,
  TYPES_STATUS_VALUES_LOGIC_ROLE,
} from '../../../../constants/activity';
import {
  OPPORTUNITY_STATUS_LOGIC_ROLE,
  OPPORTUNITY_FIELDS_LOGIC_ROLE,
} from '../../../../constants/opportunity';
import { parseEmailPixels } from '../activityItem.utils';
import { MeetingCardBody } from './meetingCardBody/meetingCardBody';
import { useCalendarEnabled } from '../../../../hooks/useFeatureFlags';

const opportunityFieldWithCard = [TYPES_STATUS_VALUES_LOGIC_ROLE.OPPORTUNITY_CREATED];

const Body = ({ bobject }) => {
  const type = getFieldByLogicRole(bobject, ACTIVITY_FIELDS_LOGIC_ROLE.TYPE)?.valueLogicRole;
  const isCalendarMeetingEnabled = useCalendarEnabled();

  if (type === ACTIVITY_TYPES_VALUES_LOGIC_ROLE.INBOUND) {
    return <InboundFieldGrid bobject={bobject} />;
  }

  if (type === ACTIVITY_TYPES_VALUES_LOGIC_ROLE.STATUS) {
    const statusField = getFieldByLogicRole(bobject, ACTIVITY_TYPES_VALUES_LOGIC_ROLE.STATUS)
      ?.valueLogicRole;
    const opportunityField = getFieldByLogicRole(bobject, ACTIVITY_FIELDS_LOGIC_ROLE.OPPORTUNITY);
    const opportunity = opportunityField?.referencedBobject;
    const opportunityStatus = getFieldByLogicRole(
      opportunity,
      OPPORTUNITY_FIELDS_LOGIC_ROLE.STATUS,
    );
    let isClosed = false;
    if (
      statusField === TYPES_STATUS_VALUES_LOGIC_ROLE.OPPORTUNITY_STATUS_CHANGED &&
      (opportunityStatus?.valueLogicRole === OPPORTUNITY_STATUS_LOGIC_ROLE.CLOSED_LOST ||
        opportunityStatus?.valueLogicRole === OPPORTUNITY_STATUS_LOGIC_ROLE.CLOSED_WON)
    ) {
      isClosed = true;
    }

    if (opportunityFieldWithCard.includes(statusField) || isClosed) {
      return <OpportunityCardBody opportunity={opportunity} />;
    }
  }

  const message = getTextFromLogicRole(bobject, ACTIVITY_FIELDS_LOGIC_ROLE.MESSAGE_BODY);

  if (message && type === ACTIVITY_TYPES_VALUES_LOGIC_ROLE.EMAIL) {
    const isHtmlMessage = isHtml(message);
    return isHtmlMessage ? <HtmlMessage value={message} /> : <TextMessage value={message} />;
  }

  if (type === ACTIVITY_TYPES_VALUES_LOGIC_ROLE.MEETING && isCalendarMeetingEnabled) {
    return <MeetingCardBody meeting={bobject} />;
  }

  return null;
};

const ActivityBody = ({ bobject }) => {
  const note = getTextFromLogicRole(bobject, ACTIVITY_FIELDS_LOGIC_ROLE.NOTE);
  const aircallNote = getTextFromLogicRole(bobject, ACTIVITY_FIELDS_LOGIC_ROLE.AIRCALL_NOTE);
  const type = getFieldByLogicRole(bobject, ACTIVITY_FIELDS_LOGIC_ROLE.TYPE)?.valueLogicRole;
  const isCalendarMeetingEnabled = useCalendarEnabled();

  const noteToShow = isHtml(note) ? (
    <div dangerouslySetInnerHTML={{ __html: parseEmailPixels(note) }} />
  ) : (
    note
  );
  return (
    <div className={styles._body}>
      <Body bobject={bobject} />
      {type !== ACTIVITY_TYPES_VALUES_LOGIC_ROLE.MEETING && note && note !== 'null' && (
        <div className={styles._note_message}>
          <Text color="peanut" size="xs">
            Note: {noteToShow}
          </Text>
        </div>
      )}
      {type === ACTIVITY_TYPES_VALUES_LOGIC_ROLE.MEETING && !isCalendarMeetingEnabled && (
        <div className={styles._note_message}>
          <Text color="peanut" size="xs">
            Note: {noteToShow}
          </Text>
        </div>
      )}
      {aircallNote && aircallNote !== '' && (
        <div className={styles._note_message}>
          <Text color="peanut" size="xs">
            Aircall Note: {aircallNote}
          </Text>
        </div>
      )}
    </div>
  );
};

export default ActivityBody;
