import React, { useEffect, useMemo, useState } from 'react';
import {
  ColorType,
  ExpandableBox,
  ExpandableStep,
  IconButton,
  IconType,
  SubStep,
  Text,
} from '@bloobirds-it/flamingo-ui';
import { atom, useRecoilState, useRecoilValue } from 'recoil';
import { isBefore } from 'date-fns';
import { recoilPersist } from 'recoil-persist';
import { isObject } from 'lodash';
import styles from './quickStartGuide.module.css';
import { useUserSettings } from '../../../../components/userPermissions/hooks';
import { useEntity, usePhoneConnections, useRouter } from '../../../../hooks';
import { UserHelperKeys, UserHelperTooltipsKeys } from '../../../../constants/userHelperKeys';
import { api } from '../../../../utils/api';
import { COMPANY_FIELDS_LOGIC_ROLE } from '../../../../constants/company';
import { LEAD_FIELDS_LOGIC_ROLE } from '../../../../constants/lead';
import { getTextFromLogicRole } from '../../../../utils/bobjects.utils';
import { bobjectUrl } from '../../../../app/_constants/routes';
import { BobjectTypes } from '../../../../constants/bobject';
import { useUserHelpers } from '../../../../hooks/useUserHelpers';
import { WelcomeTooltip } from '../../../../components/discoveryTooltips/welcomeTooltips/welcomeTooltip';
import { getAdminQuickStartGuideBlocks } from './adminGuideDefinition.constants';
import { SelectCRMViewModal } from '../chooseCRMModal/selectCRMModal';
import InvitationForm from '../invitationForm/invitationForm';
import { useUserCreatedModal } from '../../../accountSettingsPages/usersPage/hooks/useUserCreatedModal';
import { UserCreatedModal } from '../../../accountSettingsPages/usersPage/components/userCreatedModal/userCreatedModal';
import { useCadences } from '../../../../hooks/useCadences';

const { persistAtom } = recoilPersist();

const tooltipTextDictionary = {
  [UserHelperKeys.LAUNCH_YOUR_FIRST_CADENCE]: 'This step requires a cadence to be completed',
  [UserHelperKeys.SEND_YOUR_FIRST_EMAIL]:
    'This step requires a company or lead assigned to you to be completed',
};

const AdminQSGInfoAtom = atom({
  key: 'AdminQSGInfo',
  default: { openedAdminBlocks: ['DEFINE_PLAYBOOK'], contactableBobjects: {} },
  effects: [persistAtom],
});

function getTourKeys(key: UserHelperKeys) {
  switch (key) {
    case UserHelperKeys.TAKE_TOUR_PROSPECT_TAB:
      // eslint-disable-next-line max-len
      return `${UserHelperTooltipsKeys.COMPANIES_AND_LEADS_TOOLTIP},${UserHelperTooltipsKeys.ON_CADENCE_FILTERS},${UserHelperTooltipsKeys.ON_CADENCE_DISCOVERY_TOOLTIP},${UserHelperTooltipsKeys.START_TO_FINISH},${UserHelperTooltipsKeys.START_TASKS},`;
    case UserHelperKeys.TAKE_TOUR_ON_GENERAL_SETTINGS:
      // eslint-disable-next-line max-len
      return `${UserHelperTooltipsKeys.GENERAL_SETTINGS_ASSIGNMENTS},${UserHelperTooltipsKeys.GENERAL_SETTINGS_LEADS},${UserHelperTooltipsKeys.GENERAL_SETTINGS_MEETINGS}`;
    case UserHelperKeys.CONNECT_CRM_TOUR:
      // eslint-disable-next-line max-len
      return `${UserHelperTooltipsKeys.NO_HUBSPOT_LOGS},${UserHelperTooltipsKeys.NO_SALESFORCE_LOGS},${UserHelperTooltipsKeys.SALESFORCE_USERS},${UserHelperTooltipsKeys.SALESFORCE_SYNCING},${UserHelperTooltipsKeys.SALESFORCE_LOGS},${UserHelperTooltipsKeys.SALESFORCE_OBJECTS_SYNCING},${UserHelperTooltipsKeys.SALESFORCE_FIELDS},${UserHelperTooltipsKeys.HUBSPOT_USERS},${UserHelperTooltipsKeys.HUBSPOT_FIELDS},${UserHelperTooltipsKeys.HUBSPOT_OBJECTS_SYNCING},${UserHelperTooltipsKeys.HUBSPOT_SYNCING},${UserHelperTooltipsKeys.HUBSPOT_LOGS}`;
    case UserHelperKeys.SET_UP_DASHBOARDS_TOUR:
      // eslint-disable-next-line max-len
      return `${UserHelperTooltipsKeys.DASHBOARDS_FILTERS},${UserHelperTooltipsKeys.DASHBOARDS_GROUP_BY},${UserHelperTooltipsKeys.DASHBOARDS_DATE_FILTER},${UserHelperTooltipsKeys.DASHBOARDS_GENERAL}`;
    case UserHelperKeys.SET_COMPANY_FIELDS_TOUR:
      // eslint-disable-next-line max-len
      return `${UserHelperTooltipsKeys.FIELDS_CREATE},${UserHelperTooltipsKeys.FIELDS_PREVIEW_FORMS},${UserHelperTooltipsKeys.FIELDS_CHOOSE_BOBJECT}`;
    case UserHelperKeys.SET_LEAD_FIELDS_TOUR:
      // eslint-disable-next-line max-len
      return `${UserHelperTooltipsKeys.FIELDS_CREATE},${UserHelperTooltipsKeys.FIELDS_PREVIEW_FORMS},${UserHelperTooltipsKeys.FIELDS_CHOOSE_BOBJECT}`;
    case UserHelperKeys.TAKE_TOUR_ON_INBOX:
      // eslint-disable-next-line max-len
      return `${UserHelperTooltipsKeys.INBOX_BULK_ACTIONS},${UserHelperTooltipsKeys.INBOX_INDIVIDUAL_ACTIONS},${UserHelperTooltipsKeys.INBOX_FILTERS},${UserHelperTooltipsKeys.INBOX_LEFT_SIDE_NAVIGATION},${UserHelperTooltipsKeys.INBOX_NO_TASKS},`;
    default:
      return 'none';
  }
}

function getSkippableText(key: string) {
  switch (key) {
    case UserHelperKeys.CONNECT_CRM_TOUR:
      return 'Mark step as completed without connecting a CRM';
  }
}

const Footer = () => (
  <div className={styles.hint_container}>
    <div className={styles.emoji}>🤓</div>
    <Text size="s" align="center">
      Check out Academy courses for best practices and level up content from Bloobirds Experts{' '}
    </Text>
    <div
      className={styles.academy_link}
      onClick={() => window.open('https://academy.bloobirds.com', '_blank')}
    >
      <Text size="s" color="bloobirds" align="center">
        https://academy.bloobirds.com
      </Text>
      <IconButton name="arrowRight" color="bloobirds" size={20} />
    </div>
  </div>
);

type SubStepProps = {
  label: string;
  key: UserHelperKeys;
  linkYoutube?: string;
  linkHowTo?: string;
  linkTour?: string;
  linkNavigation?: string;
  skippable?: boolean;
  skippableText?: string;
};

type StepProps = {
  goals: SubStepProps[];
  title: string;
  icon: IconType;
  iconColor: ColorType;
  defaultOpened?: boolean;
  setTasksCompleted: (tasksCompleted: (prevState: number) => number) => void;
  setSelectCRMModalIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  onClick?: () => void;
  toggleFiltersVisible?: () => void;
};

function getNavigation(
  linkNavigation: string,
  contactableBobjects: {
    Company: { url: string; creationDateTime: string };
    Lead: { url: string; creationDateTime: string };
  },
  key: UserHelperKeys,
) {
  if (!linkNavigation && contactableBobjects) {
    const contactableCompany = contactableBobjects?.Company;
    const contactableLead = contactableBobjects?.Lead;
    if (!contactableCompany?.url && contactableLead?.url) return contactableLead.url;
    if (contactableCompany?.url && !contactableLead?.url) return contactableCompany.url;
    const wasCompanyCreatedFirst = isBefore(
      new Date(contactableCompany?.creationDateTime),
      new Date(contactableLead?.creationDateTime),
    );

    return `${
      wasCompanyCreatedFirst ? contactableCompany?.url : contactableLead?.url
    }?fromGuide=${key}`;
  }
  return linkNavigation;
}

function getIsCompleted(
  userHelpers: Record<UserHelperKeys, Date>,
  key: UserHelperKeys,
  conditions: Record<UserHelperKeys, boolean>,
) {
  //DEFAULT COMPLETE
  if (userHelpers && userHelpers[key]) return true;
  //PREVIOUS COMPLETION
  return !!conditions[key];
}

function getIsDisabled(key: UserHelperKeys, conditions: { [helperKey: string]: boolean }) {
  return {
    isDisabled: !!conditions[key],
    tooltipText: tooltipTextDictionary[key],
  };
}

const Step = ({ goals, setSelectCRMModalIsOpen, defaultOpened, ...props }: StepProps) => {
  const { userHelpers }: { userHelpers: Record<UserHelperKeys, Date>[] } = useUserSettings();
  const { user } = useUserSettings();
  const { cadences } = useCadences([BobjectTypes.Company, BobjectTypes.Lead]);
  const { save, reset } = useUserHelpers();
  const { connections } = usePhoneConnections();
  const hasConnections = connections?.list?.length > 0;
  const { history } = useRouter();
  const savedHelpersNumber = isObject(userHelpers) && Object.keys(userHelpers)?.length;
  const numberOfTotalGoals = goals?.length;
  const completedTasks = useMemo(
    () =>
      goals.reduce(
        (acc, { key }) =>
          acc +
          (getIsCompleted(userHelpers, key, {
            [UserHelperKeys.SET_UP_REMINDERS]: user.remaindersEnabled,
            [UserHelperKeys.SAVE_NUMBER_SETTINGS]: hasConnections,
          })
            ? 1
            : 0),
        0,
      ),
    [goals.length, savedHelpersNumber],
  );

  const progressPercentage = (completedTasks / numberOfTotalGoals) * 100;
  const hasTargetMarkets = useEntity('targetMarkets')?.all()?.length !== 0;
  const hasBuyerPersonas = useEntity('idealCustomerProfiles')?.all()?.length !== 0;
  const emptyEntities = [
    !hasTargetMarkets && UserHelperKeys.CHECK_OUT_YOUR_TARGET_MARKETS,
    !hasBuyerPersonas && UserHelperKeys.CHECK_OUT_YOUR_BUYER_PERSONAS,
  ];
  const { contactableBobjects } = useRecoilValue(AdminQSGInfoAtom);
  const [showInvitationForm, setShowInvitationForm] = useState<boolean>(true);

  function handleCardClick(key: UserHelperKeys, linkNavigationFunction: string) {
    if (key === UserHelperKeys.ENABLE_KPI_METRICS) {
      props.toggleFiltersVisible();
    } else if (key === UserHelperKeys.CONNECT_CRM_TOUR) {
      setSelectCRMModalIsOpen(true);
    } else if (key === UserHelperKeys.INVITE_TEAM) {
      setShowInvitationForm(!showInvitationForm);
    } else if (key === UserHelperKeys.DOWNLOAD_CHROME_EXTENSION) {
      return window.open(linkNavigationFunction, '_blank');
    } else if (key === UserHelperKeys.CREATE_FIRST_LIST) {
      reset(
        `${UserHelperTooltipsKeys.CREATE_FIRST_LIST_FILTERS_COLUMNS},${UserHelperTooltipsKeys.SAVE_LIST}`,
      );
    }
    if (linkNavigationFunction) {
      if (linkNavigationFunction.includes('app')) {
        history.push(linkNavigationFunction);
      } else {
        window.open(linkNavigationFunction, '_blank');
      }
    }
    if (key.includes('TOUR')) reset(getTourKeys(key));
  }

  function handleCardComplete(key: UserHelperKeys, skippable: boolean) {
    if (skippable) {
      save(key);
    }
  }

  return (
    <>
      <ExpandableStep
        progressPercentage={progressPercentage}
        defaultOpened={defaultOpened && progressPercentage !== 100}
        {...props}
      >
        {goals.map(goal => {
          const { label, key, linkYoutube, linkHowTo, linkTour, linkNavigation, skippable } = goal;
          const isCompleted = getIsCompleted(userHelpers, key, {
            [UserHelperKeys.SET_UP_REMINDERS]: user.remaindersEnabled,
            [UserHelperKeys.SAVE_NUMBER_SETTINGS]: hasConnections,
          });
          if (emptyEntities.includes(goal.key)) return;
          const linkNavigationFunction = getNavigation(linkNavigation, contactableBobjects, key);

          const { isDisabled, tooltipText } = getIsDisabled(key, {
            [UserHelperKeys.LAUNCH_YOUR_FIRST_CADENCE]: !cadences,
            [UserHelperKeys.SEND_YOUR_FIRST_EMAIL]:
              Object.values(contactableBobjects).filter(bobject => bobject.url !== '').length === 0,
          });

          return (
            <>
              <SubStep
                key={key}
                isDisabled={isDisabled}
                tooltipText={tooltipText}
                completed={isCompleted}
                title={label}
                linkHowTo={linkHowTo}
                linkTour={linkTour}
                linkYoutube={linkYoutube}
                linkNavigation={linkNavigationFunction}
                onClick={() => {
                  if (key.includes('TOUR')) reset(getTourKeys(key));
                  handleCardClick(key, linkNavigationFunction);
                }}
                onComplete={() => handleCardComplete(key, skippable)}
                skippable={!!skippable}
                skippableText={getSkippableText(key)}
              />
              {showInvitationForm && key === UserHelperKeys.INVITE_TEAM && <InvitationForm />}
            </>
          );
        })}
      </ExpandableStep>
    </>
  );
};

export const AdminQuickStartGuide = ({
  toggleFiltersVisible,
}: {
  toggleFiltersVisible: () => void;
}) => {
  const [tasksCompleted, setTasksCompleted] = useState(0);
  const [tasksTotal, setTasksTotal] = useState(0);
  const [{ openedAdminBlocks, contactableBobjects }, setAdminQSGInfo] = useRecoilState(
    AdminQSGInfoAtom,
  );
  const settings = useUserSettings();
  const [selectCRMModalIsOpen, setSelectCRMModalIsOpen] = useState(false);
  const { has } = useUserHelpers();
  const adminQuickStartGuideBlocks = getAdminQuickStartGuideBlocks({
    READY_TO_LINKEDIN: { hasExtension: has(UserHelperKeys.DOWNLOAD_CHROME_EXTENSION) },
  });
  const { handleCloseUserCreatedModal, modalOpen: userCreatedOpen } = useUserCreatedModal();

  useEffect(() => {
    if (Object.values(contactableBobjects).filter(bobject => bobject.url !== '').length === 0) {
      const searchQuery = {
        page: 0,
        formFields: true,
        pageSize: 1,
        injectReferences: true,
        sort: [] as Array<string>,
      };
      const userId = settings?.user?.id;
      api
        .post(`/bobjects/${settings?.account.id}/Company/search`, {
          query: {
            [COMPANY_FIELDS_LOGIC_ROLE.ASSIGNED_TO]: userId,
          },
          ...searchQuery,
        })
        .then(responseCompany => {
          const company = responseCompany?.data?.contents[0];
          api
            .post(`/bobjects/${settings?.account.id}/Lead/search`, {
              query: {
                [LEAD_FIELDS_LOGIC_ROLE.ASSIGNED_TO]: userId,
              },
              ...searchQuery,
            })
            .then(responseLead => {
              const lead = responseLead?.data?.contents[0];
              setAdminQSGInfo({
                openedAdminBlocks: openedAdminBlocks,
                contactableBobjects: {
                  [BobjectTypes.Company]: {
                    url: bobjectUrl(company),
                    creationDateTime: getTextFromLogicRole(
                      company,
                      COMPANY_FIELDS_LOGIC_ROLE.CREATION_DATETIME,
                    ),
                  },
                  [BobjectTypes.Lead]: {
                    url: bobjectUrl(lead),
                    creationDateTime: getTextFromLogicRole(
                      lead,
                      LEAD_FIELDS_LOGIC_ROLE.CREATION_DATETIME,
                    ),
                  },
                },
              });
            });
        });
    }
  }, []);

  function handleStepClick(key: string) {
    const openedAdminBlockInfo = openedAdminBlocks.includes(key)
      ? openedAdminBlocks.filter((block: string) => block !== key)
      : [...openedAdminBlocks, key];
    setAdminQSGInfo({
      openedAdminBlocks: openedAdminBlockInfo,
      contactableBobjects,
    });
  }

  useEffect(() => {
    const totalGoals = adminQuickStartGuideBlocks.reduce((acc, block) => {
      return acc + block.goals.length;
    }, 0);

    setTasksTotal(totalGoals);
  }, [adminQuickStartGuideBlocks]);

  return (
    <>
      <ExpandableBox
        defaultOpened={true}
        title="Your quick start guide 🚀"
        subtitle={
          <>
            <WelcomeTooltip defaultTooltipVisible />
            Start prospecting in less than <b>10 minutes!</b>
          </>
        }
        progressPercentage={(tasksCompleted / tasksTotal) * 100}
        progressText={`${tasksCompleted} / ${tasksTotal} tasks completed`}
      >
        <div className={styles.container}>
          {adminQuickStartGuideBlocks.map(block => (
            <Step
              key={block.key}
              defaultOpened={openedAdminBlocks?.includes(block?.key)}
              setTasksCompleted={setTasksCompleted}
              setSelectCRMModalIsOpen={setSelectCRMModalIsOpen}
              onClick={() => handleStepClick(block.key)}
              toggleFiltersVisible={toggleFiltersVisible}
              {...block}
            />
          ))}
          <Footer />
        </div>
      </ExpandableBox>
      {selectCRMModalIsOpen && (
        <SelectCRMViewModal onClose={() => setSelectCRMModalIsOpen(false)} />
      )}
      {userCreatedOpen && <UserCreatedModal onClose={handleCloseUserCreatedModal} />}
    </>
  );
};
