// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".columnHeader-module___row___WbjCO {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  padding: 6px 8px;\n  border-top: 1px solid var(--verySoftPeanut);\n  height: 20px;\n  cursor: pointer;\n}\n\n.columnHeader-module___row___WbjCO:nth-child(1) {\n  border: 0;\n}\n", "",{"version":3,"sources":["webpack://./src/js/components/timetable/columnHeader/columnHeader.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,gBAAgB;EAChB,2CAA2C;EAC3C,YAAY;EACZ,eAAe;AACjB;;AAEA;EACE,SAAS;AACX","sourcesContent":["._row {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  padding: 6px 8px;\n  border-top: 1px solid var(--verySoftPeanut);\n  height: 20px;\n  cursor: pointer;\n}\n\n._row:nth-child(1) {\n  border: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"_row": "columnHeader-module___row___WbjCO"
};
export default ___CSS_LOADER_EXPORT___;
