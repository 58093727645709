import React from 'react';
import { Skeleton } from '@bloobirds-it/flamingo-ui';
import { StatsItem } from '../../../../components/statsItem/statsItem';
import styles from './../../homePage.module.css';
import { TIME_WINDOW } from '../../typings/home';
import { useMeetingsStatistics } from '../../hooks/useActivityEvolution';
import { useMediaQuery } from '../../../../hooks';

export const MeetingsStatistics = ({ timeWindow }: { timeWindow: TIME_WINDOW }) => {
  const { data } = useMeetingsStatistics(timeWindow);
  const { isSmallDesktop } = useMediaQuery();
  const createdMetrics = data?.metrics?.find(metric => metric?.label === 'MEETINGS_CREATED');
  const scheduledMetrics = data?.metrics?.find(metric => metric?.label === 'MEETINGS_SCHEDULED');
  const qualifiedMetrics = data?.metrics?.find(metric => metric?.label === 'MEETINGS_QUALIFIED');
  const noShowMetrics = data?.metrics?.find(metric => metric?.label === 'MEETINGS_NO_SHOW');

  return (
    <div className={styles.statisticsContainer}>
      {!data ? (
        <div className={styles.skeleton}>
          <Skeleton height={50} width={isSmallDesktop ? 300 : 600} variant="rect" />
        </div>
      ) : (
        <>
          <StatsItem
            value={createdMetrics?.value}
            percentage={createdMetrics?.change}
            name="CREATED"
            size="small"
          />
          <StatsItem
            value={scheduledMetrics?.value}
            percentage={scheduledMetrics?.change}
            name="SCHEDULED"
            size="small"
          />
          <StatsItem
            value={qualifiedMetrics?.value}
            percentage={qualifiedMetrics?.change}
            name="QUALIFIED"
            size="small"
          />
          <StatsItem
            value={noShowMetrics?.value}
            percentage={noShowMetrics?.change}
            name="NO SHOW"
            size="small"
          />
        </>
      )}
    </div>
  );
};
