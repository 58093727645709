import React, { useState, useEffect, useMemo } from 'react';
import {
  ChipGroup,
  ModalContent,
  Text,
  Chip,
  Select,
  Item,
  Button,
  ModalFooter,
} from '@bloobirds-it/flamingo-ui';
import mixpanel from 'mixpanel-browser';
import {
  useBobjectFormVisibility,
  useCadenceControl,
  useBobjectFormCreation,
} from '../../../hooks';
import styles from './opportunityControl.module.css';
import { getValueFromLogicRole } from '../../../utils/bobjects.utils';
import OpportunityDetails from '../../opportunityDetails';
import { useSelectedOpportunity } from '../../../hooks/useSelectedOpportunity';
import { useActiveOpportunities } from '../../../hooks/useActiveOpportunities';
import { useActiveCompany } from '../../../hooks/useActiveCompany';
import { Bobject } from '../../../typings/bobjects';
import { OPPORTUNITY_FIELDS_LOGIC_ROLE } from '../../../constants/opportunity';
import { useSelectedLead } from '../../../hooks/useSelectedLead';

const CONTROL_MODES = Object.seal({
  NEW: 'NEW',
  EDIT: 'EDIT',
});

const OpportunityControl = ({
  handleBack,
  handleClose,
  leads,
  assignedTo,
}: {
  handleBack?: () => void;
  handleClose: () => void;
  leads?: any[];
  assignedTo?: string;
}) => {
  const [controlMode, setControlMode] = useState(CONTROL_MODES.NEW);
  const [selectedOpportunityId, setSelectedOpportunity] = useState(null);
  const { openAddOpportunity } = useBobjectFormCreation();
  const { openEditModal } = useBobjectFormVisibility();
  const { activeOpportunities: opportunities } = useActiveOpportunities();
  const { selectedOpportunity: currentSelectedOpportunity } = useSelectedOpportunity();
  const { selectedLead: currentSelectedLead } = useSelectedLead();
  const { company } = useActiveCompany();
  const { openCadenceControl } = useCadenceControl();
  const firstLead = Array.isArray(leads) ? leads[0] : null;
  const mainLead = leads?.find(lead => lead.mainLead) || firstLead;

  const selectedOpportunity = useMemo(
    () => opportunities?.find((opp: Bobject) => opp.id.value === selectedOpportunityId),
    [selectedOpportunityId],
  );

  useEffect(() => {
    if (controlMode === CONTROL_MODES.NEW) {
      setSelectedOpportunity(null);
    }
  }, [controlMode]);

  useEffect(() => {
    if (currentSelectedOpportunity) {
      setControlMode(CONTROL_MODES.EDIT);
      setSelectedOpportunity(currentSelectedOpportunity?.id.value);
    }
  }, [currentSelectedOpportunity]);

  const handleContinue = () => {
    handleClose();
    selectedOpportunity
      ? openEditModal({ bobject: selectedOpportunity, onSuccess: openCadenceControl })
      : openAddOpportunity({
          bobject: company || currentSelectedLead,
          company,
          lead: mainLead,
          onSuccess: openCadenceControl,
          assignedTo: assignedTo,
        });
  };

  const handleSkip = () => {
    handleClose();
  };

  useEffect(() => {
    mixpanel.track('ENTERED_IN_CC_OPPORTUNITY_STEP_2_OPPORTUNITY_CONTROL');
  }, []);

  return (
    <>
      <ModalContent>
        <div className={styles._actionContainer}>
          <Text dataTest="Text-Modal-OpportunityControl" size="m" weight="medium">
            What do you want to do with your opportunities?
          </Text>
          <ChipGroup value={controlMode} onChange={setControlMode}>
            <Chip
              dataTest="contactFlowEditOpportunity"
              value={CONTROL_MODES.EDIT}
              disabled={opportunities?.length === 0}
            >
              Edit an existing one
            </Chip>
            <Chip value={CONTROL_MODES.NEW}>Create a new one</Chip>
          </ChipGroup>
        </div>
        <div className={styles._opportunityContainer}>
          <Text size="m" weight="medium">
            Choose from your active opportunities to continue
          </Text>
          <Select
            dataTest="opportunityDropdown"
            defaultValue={selectedOpportunityId}
            value={selectedOpportunityId}
            onChange={setSelectedOpportunity}
            disabled={opportunities?.length === 0 || controlMode === CONTROL_MODES.NEW}
            width="100%"
          >
            <Item value="">
              <em>None</em>
            </Item>
            {opportunities?.map((opportunity: Bobject) => (
              <Item
                dataTest="opportunityDropdownName"
                value={opportunity.id.value}
                key={`opportunity-${opportunity.id.value}`}
              >
                {getValueFromLogicRole(opportunity, OPPORTUNITY_FIELDS_LOGIC_ROLE.NAME)}
              </Item>
            ))}
          </Select>
          {selectedOpportunityId && (
            <div className={styles._selectedOpportunity__container}>
              <OpportunityDetails opportunity={selectedOpportunity} />
            </div>
          )}
        </div>
      </ModalContent>
      <ModalFooter>
        <div className={styles._buttons__wrapper}>
          <Button className={styles.hideButton} variant="clear" onClick={handleBack}>
            Back
          </Button>
          <div className={styles._forward__buttons}>
            <Button dataTest="Form-Skip" variant="secondary" onClick={handleSkip}>
              Skip
            </Button>
            <Button
              dataTest="formContinue"
              disabled={controlMode === CONTROL_MODES.EDIT && !selectedOpportunity}
              onClick={handleContinue}
            >
              Continue
            </Button>
          </div>
        </div>
      </ModalFooter>
    </>
  );
};

export default OpportunityControl;
