import {
  Button,
  Input,
  Item,
  Modal,
  ModalContent,
  ModalFooter,
  ModalSection,
  Radio,
  RadioGroup,
  Select,
  Spinner,
  Text,
  useToasts,
} from '@bloobirds-it/flamingo-ui';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import React, { useMemo, useState } from 'react';

import { PermissionsModalSection } from './permissionsModalSection';
import { USER_ROLES_BLACKLIST } from '../../constants/userForm.constants';
import { api } from '../../../../../utils/api';
import { isEmail } from '../../../../../misc/utils';
import { mutate } from 'swr';
import { randomizeColor } from '../../../../../utils/styles.utils';
import styles from '../../styles/usersPage.module.css';
import { useCreateEditUserModal } from '../../hooks/useCreateEditUserModal';
import { useEntity } from '../../../../../hooks';
import {
  useFullSalesEnabled,
  useNewCadenceTableEnabled,
} from '../../../../../hooks/useFeatureFlags';
import { useUserCreatedModal } from '../../hooks/useUserCreatedModal';
import { useUserSettingsReload } from '../../../../../components/userPermissions/hooks';

export interface PermissionField {
  fieldName: string;
  enumName: string;
  id: string;
}

export const CreateEditUserModal = () => {
  const {
    handleCloseUserModal,
    modalInfo,
  }: {
    handleCloseUserModal: () => void;
    modalInfo: {
      editAll: boolean;
      userPermissions: string[];
    };
  } = useCreateEditUserModal();
  const userRoles = useEntity('userRoles');
  const userPermissionsFields = useEntity('userPermissions');
  const hasSalesEnabled = useFullSalesEnabled();
  const isNewCadenceTableEnabled = useNewCadenceTableEnabled();
  const reloadUserSettings = useUserSettingsReload();
  const generalSectionEnums: string[] = [
    'EDIT_ALL',
    'DOWNLOAD_LIST',
    'BULK_ACTIONS',
    ...(isNewCadenceTableEnabled ? ['VIEW_CADENCES'] : []),
  ];
  const generalModalSections = useMemo(() => {
    const generalSections: PermissionField[] = [];
    generalSectionEnums.forEach(section =>
      generalSections.push(
        userPermissionsFields?.find((field: { enumName: string }) => field?.enumName === section),
      ),
    );
    return generalSections;
  }, [userPermissionsFields]);
  const generalSectionsIds = generalModalSections?.map(section => section?.id);

  const visibilityModalSections = useMemo(() => {
    const visibilitySectionEnums: string[] = [
      'VIEW_INBOUND_TAB',
      'VIEW_INBOX',
      'VIEW_OUTBOX_TAB',
      'VIEW_ASSIGN_TAB',
      'VIEW_PROSPECT_TAB',
      ...(hasSalesEnabled ? ['VIEW_SALES_TAB'] : []),
      'VIEW_DASHBOARDS_TAB',
      'VIEW_ADD_QC_TAB',
      ...(!isNewCadenceTableEnabled ? ['VIEW_CADENCES'] : []),
    ];
    const visibilitySections: PermissionField[] = [];
    visibilitySectionEnums.forEach(section =>
      visibilitySections.push(
        userPermissionsFields?.find((field: { enumName: string }) => field?.enumName === section),
      ),
    );
    return visibilitySections;
  }, [userPermissionsFields]);
  const visibilitySectionsIds = visibilityModalSections?.map(section => section?.id);
  const { handleOpenUserCreatedModal } = useUserCreatedModal();
  const employeeRoles = useEntity('employeeRoles');
  const isCreation = Object.keys(modalInfo).length === 0;

  const defaultValues = useMemo(
    () =>
      isCreation
        ? {
            assignable: true,
            editAll: true,
            generalPermissions: [],
            visibilityPermissions: [],
          }
        : {
            ...modalInfo,
            ...{
              generalPermissions: modalInfo?.userPermissions,
              visibilityPermissions: modalInfo?.userPermissions,
            },
          },
    [generalSectionsIds, visibilitySectionsIds],
  );

  const methods = useForm({ defaultValues });

  const { createToast } = useToasts();
  const [isSubmitting, setIsSubmitting] = useState();
  const handleSave = data => {
    setIsSubmitting(true);
    const shortNameLetters = data.name.replace(/[^a-zA-Z ]/g, ' ').split(' ');
    const shortName =
      shortNameLetters.length >= 2
        ? shortNameLetters[0].slice(0, 1).toUpperCase() +
          shortNameLetters[1].slice(0, 1).toUpperCase()
        : shortNameLetters[0].slice(0, 2).toUpperCase();
    const parsedPermissionsIds = [];
    if (data?.generalPermissions)
      parsedPermissionsIds.push(
        ...data.generalPermissions.filter(permission => generalSectionsIds.includes(permission)),
      );
    if (data?.visibilityPermissions)
      parsedPermissionsIds.push(
        ...data.visibilityPermissions.filter(permission =>
          visibilitySectionsIds.includes(permission),
        ),
      );
    const newData = {
      name: data?.name,
      employeeRole: data?.employeeRole,
      assignable: data.assignable,
      userRoles: [data?.userRoles],
      shortname: shortName,
      email: data?.email.trim().toLowerCase(),
      color: data?.color || randomizeColor(),
      userPermissions: parsedPermissionsIds,
    };

    if (isCreation) {
      api
        .post('/utils/service/users/create', { ...newData })
        .then(() => {
          mutate('/users');
          setIsSubmitting(false);
          handleCloseUserModal();
          handleOpenUserCreatedModal();
        })
        .catch(() => {
          createToast({
            message: 'There was an error creating the user, please try again!',
            type: 'error',
          });
          setIsSubmitting(false);
        });
    } else {
      api
        .patch(`/utils/service/users/${modalInfo.id}`, { ...newData })
        .then(() => {
          createToast({ message: 'User updated successfully', type: 'success' });
          mutate('/users');
          reloadUserSettings();
          setIsSubmitting(false);
          handleCloseUserModal();
        })
        .catch(() => {
          createToast({
            message: 'There was an error creating the user, please try again!',
            type: 'error',
          });
          setIsSubmitting(false);
        });
    }
  };

  return (
    <Modal open title="Create new User" onClose={handleCloseUserModal} width={760}>
      <ModalContent>
        <FormProvider {...methods}>
          <ModalSection title="User information" icon="person">
            <div className={styles._section}>
              <div className={styles._section__row}>
                <Controller
                  name="name"
                  rules={{
                    required: 'This field is required',
                  }}
                  render={({ onChange, value }) => (
                    <Input
                      width={290}
                      name="name"
                      error={methods.errors.name?.message}
                      value={value}
                      onChange={onChange}
                      placeholder="Full name *"
                    />
                  )}
                />
                <Controller
                  name="email"
                  rules={{
                    required: 'This field is required',
                    validate: v => isEmail(v) || 'The email format is not correct',
                  }}
                  render={({ onChange, value }) => (
                    <Input
                      width={290}
                      name="email"
                      error={methods.errors.email?.message}
                      value={value}
                      onChange={onChange}
                      placeholder="Email *"
                    />
                  )}
                />
              </div>
              <div className={styles._section__row}>
                <Controller
                  name="userRoles"
                  rules={{
                    required: 'This field is required',
                  }}
                  render={({ onChange, value }) => (
                    <Select
                      width={290}
                      onChange={onChange}
                      value={value}
                      placeholder="User role *"
                      error={methods.errors.userRoles?.message}
                    >
                      {userRoles
                        ?.all()
                        ?.filter(role => !USER_ROLES_BLACKLIST?.includes(role?.enumName))
                        ?.map(role => (
                          <Item key={role?.id} value={role?.id} data-test={role?.enumName}>
                            {role?.name}
                          </Item>
                        ))}
                    </Select>
                  )}
                />
                <Controller
                  name="employeeRole"
                  rules={{
                    required: 'This field is required',
                  }}
                  render={({ onChange, value }) => (
                    <Select
                      width={290}
                      onChange={onChange}
                      error={methods.errors.employeeRole?.message}
                      value={value}
                      placeholder="Employee Role *"
                    >
                      {employeeRoles?.all()?.map(role => (
                        <Item key={role?.id} value={role?.id} data-test={role?.name}>
                          {role?.name}
                        </Item>
                      ))}
                    </Select>
                  )}
                />
              </div>
            </div>
          </ModalSection>
          <ModalSection title="User permissions" icon="lock">
            <Controller
              name="generalPermissions"
              render={({ onChange, value }) => (
                <PermissionsModalSection
                  sections={generalModalSections}
                  sectionTitle="General Permissions"
                  value={value?.filter(val => generalSectionsIds.includes(val))}
                  updateValue={onChange}
                />
              )}
            />
            <Controller
              name="visibilityPermissions"
              render={({ onChange, value }) => (
                <PermissionsModalSection
                  sections={visibilityModalSections}
                  sectionTitle="Visibility Permissions"
                  selectAllHandle="View all"
                  value={value?.filter(val => visibilitySectionsIds.includes(val))}
                  updateValue={onChange}
                />
              )}
            />
            <div className={styles._last_section}>
              <Text size="m" color="peanut" weight="bold">
                Should this user be shown in the &quot;Assign&quot; tab in order to be able to
                assign companies to him/her?
              </Text>
              <Controller
                name="assignable"
                render={({ onChange, value }) => (
                  <RadioGroup onChange={onChange} value={value}>
                    <Radio value size="small">
                      <Text size="s" inline>
                        This user{' '}
                        <Text size="s" weight="bold" inline>
                          will be visible
                        </Text>{' '}
                        in the assign tab{' '}
                      </Text>
                    </Radio>
                    <Radio value={false} size="small">
                      <Text size="s" inline>
                        This user{' '}
                        <Text size="s" weight="bold" inline>
                          won&apos;t be visible
                        </Text>{' '}
                        on the assign tab{' '}
                      </Text>
                    </Radio>
                  </RadioGroup>
                )}
              />
            </div>
          </ModalSection>
        </FormProvider>
      </ModalContent>
      <ModalFooter>
        <div>
          <Button variant="clear" onClick={handleCloseUserModal}>
            CANCEL
          </Button>
        </div>
        <div>
          <Button onClick={methods.handleSubmit(handleSave)} data-test="save-user-button">
            {isSubmitting ? <Spinner color="white" name="loadingCircle" size={16} /> : 'SAVE'}
          </Button>
        </div>
      </ModalFooter>
    </Modal>
  );
};
