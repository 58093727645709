import { Controller, useForm } from 'react-hook-form';
import { Button, Icon, Input, Skeleton, Text, Tooltip, useToasts } from '@bloobirds-it/flamingo-ui';
import React, { useEffect } from 'react';
import { EmailSettings } from '../../../../typings/messaging';
import {
  AccountSettingsTabContent,
  AccountSettingsTabHeader,
  AccountSettingsTabHeaderLeft,
  AccountSettingsTabSubtitle,
  AccountSettingsTabTitle,
} from '../../../../layouts/accountSettingsLayout/accountSettingsTab/accountSettingsTab';
import styles from './emailSettingsForm.module.css';
import AccountSettingsLayout from '../../../../layouts/accountSettingsLayout';
import AccountSettingsTab from '../../../../layouts/accountSettingsLayout/accountSettingsTab';
import { useEmailSettings } from '../../../../hooks/useEmailSettings';
import { AccountSettingsSectionFooter } from '../../../../layouts/accountSettingsLayout/accountSettingsSection/accountSettingsSection';
import { useUserHelpers } from '../../../../hooks/useUserHelpers';
import { UserHelperKeys } from '../../../../constants/userHelperKeys';

function EmailSettingsForm() {
  const { settings, updateSettings, loading } = useEmailSettings();
  const { createToast } = useToasts();
  const { control, formState, errors, reset, handleSubmit, watch } = useForm<EmailSettings>({
    defaultValues: {
      dailyLimit: 200,
      minuteLimit: 30,
    },
  });

  const dailyLimitValue = watch('dailyLimit');
  const minuteLimitValue = watch('minuteLimit');

  const { save } = useUserHelpers();

  useEffect(() => {
    if (settings) {
      reset(settings);
    }
  }, [settings, reset]);

  const onSave = async (values: EmailSettings) => {
    try {
      await updateSettings(values);
      createToast({
        type: 'success',
        message: 'Email settings updated',
      });
    } catch {
      createToast({
        type: 'error',
        message: 'Failed to update email settings',
      });
    }
    save(UserHelperKeys.CONNECT_EMAIL_ACCOUNT_ADMIN);
  };

  return (
    <AccountSettingsLayout title="Email Settings" subtitle="">
      <AccountSettingsTab>
        <AccountSettingsTabHeader>
          <AccountSettingsTabHeaderLeft>
            <AccountSettingsTabTitle icon="unlock">Email safety</AccountSettingsTabTitle>
            <AccountSettingsTabSubtitle>
              Configure general email safety settings, such as maximum number of emails sent per
              day, delay before sending the first email etc.
            </AccountSettingsTabSubtitle>
          </AccountSettingsTabHeaderLeft>
        </AccountSettingsTabHeader>
        <AccountSettingsTabContent>
          <form onSubmit={handleSubmit(onSave)}>
            <section className={styles.section}>
              <Text size="m" color="peanut" weight="bold">
                How many emails can be sent a user in a day?
              </Text>
              <Text className={styles.subtitle} size="s" color="peanut">
                <span>
                  We recommend keeping this <b>under 200 emails</b>{' '}
                </span>
                <Tooltip
                  title="Set the maximum number of emails that can be sent per day. This will prevent mail servers from treating your emails as high-volume mass mail, make emails go out more gradually, and allow you to promptly handle the responses."
                  position="top"
                >
                  <Icon name="infoFilled" color="darkBloobirds" size={20} />
                </Tooltip>
              </Text>
              <div className={styles.input}>
                {loading ? (
                  <Skeleton width={96} height={41} />
                ) : (
                  <Controller
                    name="dailyLimit"
                    control={control}
                    rules={{
                      required: {
                        value: true,
                        message: 'Required',
                      },
                      min: {
                        value: 1,
                        message: 'This field requires a minimum of one (1) email per day.',
                      },
                    }}
                    render={({ value, onChange }) => (
                      <div className={styles.input}>
                        <Input
                          value={value}
                          width="124px"
                          errorStyle={!!errors.dailyLimit?.message}
                          onChange={onChange}
                          type="number"
                          placeholder="Emails"
                        />
                        <Text size="s" color="softPeanut">
                          / day
                        </Text>
                      </div>
                    )}
                  />
                )}
              </div>
              {errors.dailyLimit?.message ? (
                <Text size="s" color="tomato">
                  {errors.dailyLimit?.message}
                </Text>
              ) : null}
            </section>
            <section className={styles.section}>
              <Text size="m" color="peanut" weight="bold">
                How many emails can be sent at most per minute?
              </Text>
              <Text className={styles.subtitle} size="s" color="peanut">
                <span>
                  We recommend keeping it <b>lower than 30</b>{' '}
                </span>
                <Tooltip
                  title="Set the maximum number of emails that could be sent via this email account per minute. For example, for Outlook accounts, keep it lower than 30"
                  position="top"
                >
                  <Icon name="infoFilled" color="darkBloobirds" size={20} />
                </Tooltip>
              </Text>
              <div className={styles.input}>
                {loading ? (
                  <Skeleton width={96} height={41} />
                ) : (
                  <Controller
                    name="minuteLimit"
                    control={control}
                    rules={{
                      required: {
                        value: true,
                        message: 'Required',
                      },
                      min: {
                        value: 1,
                        message: 'This field requires a minimum of one (1) email per minute.',
                      },
                    }}
                    render={({ value, onChange }) => (
                      <div className={styles.input}>
                        <Input
                          value={value}
                          width="124px"
                          errorStyle={!!errors.minuteLimit?.message}
                          warningStyle={value > dailyLimitValue}
                          onChange={onChange}
                          type="number"
                          placeholder="Emails"
                        />
                        <Text size="s" color="softPeanut">
                          / minute
                        </Text>
                      </div>
                    )}
                  />
                )}
              </div>
              {minuteLimitValue > dailyLimitValue ? (
                <Text size="s" color="banana">
                  It’s recommended that this field does not exceed emails/day limit.
                </Text>
              ) : null}
              {errors.minuteLimit?.message ? (
                <Text size="s" color="tomato">
                  {errors.minuteLimit?.message}
                </Text>
              ) : null}
            </section>
            <AccountSettingsSectionFooter>
              <Button type="submit" disabled={formState.isSubmitting || !formState.isDirty}>
                Save
              </Button>
            </AccountSettingsSectionFooter>
          </form>
        </AccountSettingsTabContent>
      </AccountSettingsTab>
    </AccountSettingsLayout>
  );
}

export default EmailSettingsForm;
