import {
  Button,
  Callout,
  Icon,
  Input,
  Item,
  Modal,
  ModalCloseIcon,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Select,
  Spinner,
  Text,
  useToasts,
} from '@bloobirds-it/flamingo-ui';
import React, { useState } from 'react';
import styles from '../../businessAssetsPage.module.css';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { APP_PLAYBOOK_CADENCES_EDIT } from '../../../../../app/_constants/routes';
import { useRouter } from '../../../../../hooks';
import { useFullSalesEnabled } from '../../../../../hooks/useFeatureFlags';
import { useCadences } from '@bloobirds-it/cadence';
import { forceSelectedEntitiesCacheRefresh } from '../../../../../hooks/entities/useEntity.utils';
import { MIXPANEL_EVENTS } from '../../../../../constants/mixpanel';
import mixpanel from 'mixpanel-browser';
import { BOBJECT_TYPES } from '../../../../../constants/bobject';
import CadenceIcon from '../../../../../components/cadenceControlModal/cadenceIcon';
import { useBobjectTypes } from '../../../../../hooks/useBobjectTypes';
import { useCadenceV2Enabled } from '@bloobirds-it/hooks';
import { APP_CADENCES_EDIT } from '@bloobirds-it/types';
import { useUserSettings } from '../../../../../components/userPermissions/hooks';

export const CloneCadenceModal = ({ cadence, onClose, refresh }) => {
  const bobjectTypes = useBobjectTypes();
  const isFullSalesEnabled = useFullSalesEnabled();
  const settings = useUserSettings();

  const { clone } = useCadences(cadence?.bobjectType, settings?.acocunt?.id);

  const { history } = useRouter();

  const methods = useForm({ defaultValues: cadence });

  const BOBJECT_TYPES_ALLOWED = [BOBJECT_TYPES.COMPANY, BOBJECT_TYPES.LEAD];

  if (isFullSalesEnabled) {
    BOBJECT_TYPES_ALLOWED.push(BOBJECT_TYPES.OPPORTUNITY);
  }
  const cadenceV2Enabled = useCadenceV2Enabled(settings?.account?.id);

  const [isSubmitting, setIsSubmitting] = useState();
  const { createToast } = useToasts();

  const onSave = values => {
    setIsSubmitting(true);
    const valuesToSave = {
      name: values?.name,
      bobjectTypeToClone: values?.bobjectType || cadence?.bobjectType,
      cadenceId: cadence?.id,
    };
    clone(valuesToSave)
      .then(response => {
        createToast({ message: 'Cadence cloned successfully', type: 'success' });
        forceSelectedEntitiesCacheRefresh(['bobjectPicklistFieldValues']);
        refresh();
        mixpanel.track(MIXPANEL_EVENTS.CADENCE_CLONED);
        setIsSubmitting(false);
        onClose();
        history.push(
          `${cadenceV2Enabled ? APP_CADENCES_EDIT : APP_PLAYBOOK_CADENCES_EDIT}?cadence=${
            response?.data?.id
          }&name=${response?.data?.name}&bobjectType=${response?.data?.bobjectType}`,
        );
      })
      .catch(() => {
        setIsSubmitting(false);
        methods.setError('name', {
          message: 'Another cadence with the same name already exists',
          type: 'validate',
        });
      });
  };
  const hasCadenceBobjectType = !!methods.watch('bobjectType');

  return (
    <Modal open onClose={() => {}}>
      <FormProvider {...methods}>
        <ModalHeader color="verySoftPurple">
          <div className={styles._title__container}>
            <Icon size={24} color="purple" name="cadence" className={styles._icon} />
            <Text size="l">Clone cadence</Text>
          </div>
          <ModalCloseIcon color="peanut" onClick={onClose} />
        </ModalHeader>
        <ModalContent>
          <Text size="m" color="peanut">
            Are you sure you want to clone “Inbound Direct request”?
          </Text>
          <Text size="m" color="softPeanut">
            Select whether you want to use it with companies or opportunities.
          </Text>
          <Controller
            name="name"
            rules={{ required: 'This field is required' }}
            render={({ onChange, value }) => (
              <Input
                placeholder="Cadence name"
                name="cadenceName"
                onChange={onChange}
                error={methods.errors.name?.message}
                value={value}
                className={styles._main_info__input}
                width="100%"
                color="purple"
              />
            )}
          />
          <Controller
            name="bobjectType"
            rules={{
              required: 'This field is required',
            }}
            render={({ onChange, value }) => (
              <div className={styles._main_info__input_mid}>
                <Select
                  error={methods.errors.bobjectType?.message}
                  placeholder="Clone cadence for"
                  size="medium"
                  onChange={onChange}
                  value={value}
                  width="100%"
                  color="purple"
                >
                  {bobjectTypes
                    ?.all()
                    ?.filter(bt => BOBJECT_TYPES_ALLOWED.includes(bt.name))
                    ?.map(type => {
                      if (!isFullSalesEnabled && type.name === 'Opportunity') {
                        return null;
                      }
                      return (
                        <Item value={type.id} key={`type-${type?.id}`}>
                          {type.name}
                        </Item>
                      );
                    })}
                </Select>
              </div>
            )}
          />
          {hasCadenceBobjectType && (
            <div className={styles._cadence_callout__container}>
              <Callout withoutIcon={true} width="100%">
                <div className={styles._cadence_callout__wrapper}>
                  <CadenceIcon color="lightPurple" />
                  <Text size="m">
                    👉 After cloning a cadence from a different object, remember to check the{' '}
                    <b>selected email</b> field and <b>template variables</b>.
                  </Text>
                </div>
              </Callout>
            </div>
          )}
        </ModalContent>
        <ModalFooter>
          <Button variant="clear" color="lightPurple" onClick={onClose}>
            CANCEL
          </Button>
          <Button color="purple" onClick={methods.handleSubmit(onSave)}>
            {isSubmitting ? <Spinner name="loadingCircle" size={16} color="white" /> : <>CLONE</>}
          </Button>
        </ModalFooter>
      </FormProvider>
    </Modal>
  );
};
