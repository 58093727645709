export const STEPS_PROPS = Object.seal({
  CALL_RESULTS_OPP: { title: 'Report call result' },
  CALL_RESULTS: { title: 'Report call result' },
  CHANGE_STATUS: { title: 'Updates the status and decide on the next step' },
  CONVERT_OBJECT: { title: 'Send to sales', width: 656 },
  INITIAL: {},
  NOTES_AND_QQ: { title: 'Report call result', width: 870 },
  OPPORTUNITY_CONTROL: { title: 'Opportunities Control' },
  SALES_CHANGE_STATUS: {},
  SCHEDULE_NEXT_STEPS: {},
});
