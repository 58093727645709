import React from 'react';
import { Text } from '@bloobirds-it/flamingo-ui';
import styles from './slides.module.css';

type Props = {
  text: React.ReactElement;
  svg: string;
  children?: React.ReactElement;
};

function CommonScreen({ text, svg, children }: Props) {
  return (
    <div key={'AutomatedTask'} className={styles.backgroundSoftPurple}>
      <div className={styles.subtitleContent}>
        <Text size="m" align="center" color="softPeanut" className={styles.subtitleText}>
          🤔 uhhmm wait... why{' '}
          <Text size="m" weight="bold" color="bloobirds" inline className={styles.subtitleText}>
            Bloobirds
          </Text>
        </Text>
      </div>
      <div className={styles.titleContent}>
        <Text size="xl" align="center">
          {text}
        </Text>
      </div>
      <div className={styles.svgContent}>
        <img height="260px" src={svg} alt="svg" />
      </div>
      {children}
    </div>
  );
}

export default CommonScreen;
