import React from 'react';
import { DiscoveryTooltip } from '@bloobirds-it/flamingo-ui';
import styles from './hubspotTooltips.module.css';
import { useUserHelpers } from '../../../hooks/useUserHelpers';
import { UserHelperKeys, UserHelperTooltipsKeys } from '../../../constants/userHelperKeys';
// @ts-ignore
import FieldsDiscovery from '../../../../assets/tooltipImages/integrationsDiscovery/FieldsDiscovery.png';

export const HubspotFieldsTooltip = ({
  defaultTooltipVisible = true,
}: {
  defaultTooltipVisible?: boolean;
}) => {
  const { save, has } = useUserHelpers();
  const shouldBeVisible =
    has(UserHelperTooltipsKeys.HUBSPOT_USERS) && !has(UserHelperTooltipsKeys.HUBSPOT_FIELDS);
  return (
    <div className={styles._fields_syncing_wrapper}>
      {shouldBeVisible && (
        <DiscoveryTooltip
          title="Keep your fields updated"
          visible={defaultTooltipVisible}
          isPersistent
        >
          <DiscoveryTooltip.TooltipImage className={styles._image}>
            <img src={FieldsDiscovery} width={155} alt="Hubspot syncing fields DT" />
          </DiscoveryTooltip.TooltipImage>
          <DiscoveryTooltip.TooltipFooter description="Map your fields between Hubspot and Bloobirds, so no information is ever lost. Check out our in depth guide on how to do this easily and flawlessly ✨">
            <DiscoveryTooltip.TooltipButton
              variant="secondary"
              className={styles._primary_button}
              isMainButton
              size="small"
              onClick={() => {
                save(UserHelperTooltipsKeys.HUBSPOT_FIELDS);
                save(UserHelperKeys.CONNECT_CRM_TOUR);
              }}
            >
              Ok
            </DiscoveryTooltip.TooltipButton>
          </DiscoveryTooltip.TooltipFooter>
        </DiscoveryTooltip>
      )}
    </div>
  );
};
