import React from 'react';
import mixpanel from 'mixpanel-browser';
import { CardButton } from '@bloobirds-it/flamingo-ui';
import { MIXPANEL_EVENTS } from '../../../../../constants/mixpanel';
import { Bobject } from '../../../../../typings/bobjects';
import useAddLead from '../../../../../hooks/useAddLead';

export const CardAddLeadButton = ({
  isSmallDesktop,
  bobject,
  mixpanelKey,
}: {
  isSmallDesktop?: boolean;
  bobject?: Bobject;
  mixpanelKey?: string;
}) => {
  const { openAddLeadModal } = useAddLead();

  return (
    <CardButton
      iconLeft="personAdd"
      dataTest="Subhome-AddLead"
      variant="secondary"
      onClick={event => {
        mixpanel.track(MIXPANEL_EVENTS[`ADD_LEAD_ACTION_CLICKED_ON_${mixpanelKey}_TAB`]);
        event.preventDefault();
        event.stopPropagation();
        openAddLeadModal({ company: bobject });
      }}
    >
      {!isSmallDesktop && 'Add lead'}
    </CardButton>
  );
};
