// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".tagMetric-module__container___RJsgb {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  margin: 4px;\n}\n\n.tagMetric-module__metric___qYWDQ {\n  padding: 8px;\n  min-width: 55px;\n  border-radius: 4px;\n  height: 32px;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n\n.tagMetric-module__title___pPmEz {\n  margin-top: 8px;\n}\n", "",{"version":3,"sources":["webpack://./src/js/components/tagMetric/tagMetric.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,WAAW;AACb;;AAEA;EACE,YAAY;EACZ,eAAe;EACf,kBAAkB;EAClB,YAAY;EACZ,aAAa;EACb,uBAAuB;EACvB,mBAAmB;AACrB;;AAEA;EACE,eAAe;AACjB","sourcesContent":[".container {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  margin: 4px;\n}\n\n.metric {\n  padding: 8px;\n  min-width: 55px;\n  border-radius: 4px;\n  height: 32px;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n\n.title {\n  margin-top: 8px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "tagMetric-module__container___RJsgb",
	"metric": "tagMetric-module__metric___qYWDQ",
	"title": "tagMetric-module__title___pPmEz"
};
export default ___CSS_LOADER_EXPORT___;
