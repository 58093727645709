import React from 'react';
import { EXAMPLES } from './exampleBusinessAsset.constants';
import styles from './exampleBusinessAsset.module.css';
import { CircularBadge, Text, Divider, Tag } from '@bloobirds-it/flamingo-ui';
import { randomizeColorName } from '../../../../utils/styles.utils';

export const ExampleBusinessAsset = ({ type, lenguage }) => (
  <div className={styles.examples__container}>
    <div className={styles.example__description}>
      <Text size="m" color="softPeanut">
        This is a business model example
      </Text>
      <Text size="m" color="peanut">
        {EXAMPLES[type][lenguage].description}
      </Text>
    </div>
    <div className={styles.example__icon}>
      <span role="img" aria-label="point right finger" className={styles.icon}>
        👉
      </span>
    </div>
    <div className={styles.example__cards}>
      <div>
        <Text size="m" color="softPeanut" align="left">
          ...and this is how we translate it to {type}
        </Text>
      </div>
      <div className={styles.example__cards__container}>
        {EXAMPLES[type][lenguage].examples.map(example => (
          <div className={styles.example__card} key={example?.title}>
            <div className={styles.card__header}>
              <CircularBadge size="l" color={randomizeColorName()} className={styles.avatar}>
                {example.shortname || 'TS'}
              </CircularBadge>
              <div className={styles.card__title}>
                <Text size="m" color="peanut" weight="bold">
                  {example.title}
                </Text>
                <Text size="xs" color="peanut">
                  {example.subtitle}
                </Text>
              </div>
            </div>
            <Divider />
            <div className={styles.card__segmentation__container}>
              {example.segmentation.map(seg => (
                <div className={styles.card__segmentation__element} key={seg?.title}>
                  <Text size="s" color="peanut" inline>
                    {seg.title}:
                  </Text>
                  {seg.values.map(v => (
                    <Tag uppercase={false} key={v}>
                      <Text size="xs">{v}</Text>
                    </Tag>
                  ))}
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  </div>
);
