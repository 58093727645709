import React from 'react';
import { Text } from '@bloobirds-it/flamingo-ui';
import styles from './tagMetric.module.css';

export const TagMetric = ({
  value,
  color,
  title,
}: {
  value: number;
  color: string;
  title?: string | null;
}) => {
  const backgroundColor = 'var(--' + color + ')';
  const valueLength = value.toString().length;

  const getSize = () => {
    switch (true) {
      case valueLength <= 3:
        return 'xxl';
      case valueLength === 4:
        return 'xl';
      case valueLength >= 5:
        return 'm';
    }
  };
  return (
    <div className={styles.container}>
      <div
        className={styles.metric}
        style={{ backgroundColor: backgroundColor || 'var(--bloobirds)' }}
      >
        <Text htmlTag="span" size={getSize()} align="center" color="white" weight="bold">
          {value === null ? 0 : value}
        </Text>
      </div>
      {title && (
        <Text color="softPeanut" size="s" className={styles.title}>
          {title}
        </Text>
      )}
    </div>
  );
};
