import React, { useState } from 'react';
import TwilioConfig from './twilioConfig';
import { useUserSettings } from '../../../../components/userPermissions/hooks';
import { TwilioInstallationPage } from './twilioInstallationPage/twilioInstallationPage';
import { TwilioConfigModal } from './twilioConfigModal/twilioConfigModal';

export function TwilioIntegration() {
  const settings = useUserSettings();
  const isTwilioInstalled = settings?.account?.dialerTypes?.includes('BLOOBIRDS_DIALER');
  const [modalVisible, setModalVisible] = useState(false);

  return (
    <>
      {isTwilioInstalled ? (
        <TwilioConfig setModalVisible={setModalVisible} />
      ) : (
        <TwilioInstallationPage setModalVisible={setModalVisible} />
      )}{' '}
      {modalVisible && (
        <TwilioConfigModal
          isTwilioInstalled={isTwilioInstalled}
          setModalVisible={setModalVisible}
        />
      )}
    </>
  );
}
