import React from 'react';
import { CardButton } from '@bloobirds-it/flamingo-ui';
import mixpanel from 'mixpanel-browser';
import { useRescheduleCadenceTask } from './useRescheduleCadenceTask';
import { Bobject } from '../../typings/bobjects';
import { MIXPANEL_EVENTS } from '../../constants/mixpanel';
import { useMediaQuery } from '../../hooks';

const CardRescheduleTaskButton = ({ task, size = 'm' }: { task: Bobject; size?: 'm' | 's' }) => {
  const { openRescheduleTaskModal } = useRescheduleCadenceTask();
  const { isSmallDesktop } = useMediaQuery();

  return (
    <CardButton
      iconLeft="clock"
      dataTest="Reschedule-Task"
      variant="secondary"
      onClick={event => {
        mixpanel.track(MIXPANEL_EVENTS.RESCHEDULE_TASK_FROM_CARD);
        event.preventDefault();
        event.stopPropagation();
        openRescheduleTaskModal(task);
      }}
    >
      {!isSmallDesktop && size === 'm' && 'Reschedule'}
    </CardButton>
  );
};

export default CardRescheduleTaskButton;
