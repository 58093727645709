import React, { useEffect, useRef, useState } from 'react';
import {
  CheckItem,
  DateTimePicker,
  Input,
  Item,
  MultiSelect,
  Select,
  Text,
  TextArea,
} from '@bloobirds-it/flamingo-ui';
import { useController, useFormContext } from 'react-hook-form';
import styles from '../../meetingModal.module.css';
import { useBobjectFieldGroups } from '../../../../hooks/useBobjectFieldGroups';
import { Bobject, BobjectField } from '../../../../typings/bobjects';
import {
  getFieldsThatAreConditioned,
  groupFields,
} from '../../../bobjectForm/section/field/baseField/baseField.view';
import { ACTIVITY_FIELDS_LOGIC_ROLE } from '../../../../constants/activity';
import { getFieldValueById } from '../../../../utils/bobjects.utils';
import { useFullSalesEnabled } from '../../../../hooks/useFeatureFlags';
import { useQualifyingQuestions } from '../../../../hooks';
import useActiveMessagingFilters from '../../../../hooks/useActiveMessagingFilters';
import { getFilteredQQsBySegmentation } from './activityDetailsForm.utils';
import { CopyText } from '../../../../pages/contactPages/infoCardTemplate/copyText/copyText';
import { useCalendar } from '../../hooks/useCalendar';

const DISCARDED_FIELDS = [
  ACTIVITY_FIELDS_LOGIC_ROLE.TIME,
  ACTIVITY_FIELDS_LOGIC_ROLE.LEAD,
  ACTIVITY_FIELDS_LOGIC_ROLE.COMPANY,
  ACTIVITY_FIELDS_LOGIC_ROLE.TYPE,
  ACTIVITY_FIELDS_LOGIC_ROLE.TITLE,
  ACTIVITY_FIELDS_LOGIC_ROLE.MEETING_DURATION,
  ACTIVITY_FIELDS_LOGIC_ROLE.CREATE_EVENT,
  ACTIVITY_FIELDS_LOGIC_ROLE.OPPORTUNITY,
  ACTIVITY_FIELDS_LOGIC_ROLE.NOTE,
  ACTIVITY_FIELDS_LOGIC_ROLE.MEETING_MAIN_TYPE,
  ACTIVITY_FIELDS_LOGIC_ROLE.MEETING_ASSIGNED_TO,
];

function checkFieldConditions(field: BobjectField, watch: any) {
  if (field.fieldConditionsByField.length > 0) {
    const relatedFields = getFieldsThatAreConditioned(field.fieldConditionsByField, 'Activity');
    const values = watch(relatedFields);
    const grouped = groupFields(field.fieldConditionsByField);

    const hasRelatedFields = relatedFields.length > 0;
    const checkAllConditions = Object.keys(values).map(key => grouped[key].includes(values[key]));
    const satisfiesFieldCondition = checkAllConditions.every(value => value === true);

    if ((hasRelatedFields && !satisfiesFieldCondition) || !field.satisfiesFieldCrossCondition) {
      return false;
    }
  }
  return true;
}

function Field({ field, ...props }: { field: any }) {
  const values = field?.fieldValues();

  switch (field.type) {
    case 'Text':
      return field.multiline ? <TextArea {...props} /> : <Input {...props} />;
    case 'Picklist':
    case 'Global Picklist':
      // @ts-ignore
      // eslint-disable-next-line no-case-declarations
      return (
        <Select {...props} borderless={false} autocomplete={values?.length > 6}>
          {values?.map(({ value, label }: { value: any; label: any }) => (
            <Item key={value} value={value} label={label}>
              {label}
            </Item>
          ))}
        </Select>
      );
    case 'Multi global picklist':
      return (
        <MultiSelect {...props} borderless={false} autocomplete={values?.length > 6}>
          {values?.map(({ value, label }: { value: any; label: any }) => (
            <CheckItem key={value} value={value} label={label}>
              {label}
            </CheckItem>
          ))}
        </MultiSelect>
      );
    case 'Number':
      return <Input {...props} type="number" />;
    case 'DateTime':
    case 'Date':
      return <DateTimePicker {...props} withTimePicker={field.type === 'DateTime'} />;
    default:
      return <Input {...props} />;
  }
}

function MeetingField({
  field,
  isRequiredBeforeMeeting,
  textsToCopy,
  setTextsToCopy,
  section,
}: {
  field: any;
  isRequiredBeforeMeeting: boolean;
  textsToCopy: any;
  setTextsToCopy: React.Dispatch<any>;
  section: string;
}) {
  const { watch, errors, setValue, getValues, control } = useFormContext();
  const { invitees, setInvitees } = useCalendar();
  const mustBeRequired = isRequiredBeforeMeeting || field?.required;
  const {
    field: { value, onChange },
  } = useController({
    control,
    name: field?.logicRole || field.name,
    rules: { required: mustBeRequired },
  });

  const defaultPicklistValue = field?.defaultPicklistValue;
  const defaultValue = field?.defaultValue;
  const fieldName = field?.logicRole || field?.name;
  const company = watch('company');
  const error = errors && errors[fieldName] && 'This field is required';

  useEffect(() => {
    const currentValue = getValues(fieldName);
    if (!currentValue && (defaultPicklistValue || defaultValue)) {
      setValue(fieldName, defaultPicklistValue || defaultValue);
    }
  }, [defaultPicklistValue, defaultValue]);

  useEffect(() => {
    if (company && isRequiredBeforeMeeting) {
      setValue(fieldName, getFieldValueById(company, fieldName));
    }
    if (!company && isRequiredBeforeMeeting) {
      setValue(fieldName, null);
    }
  }, [company]);

  const ref = useRef();

  // Scroll to error
  const errorMessage = errors[fieldName];
  const firstError = Object.keys(errors)[0];
  useEffect(() => {
    if (errorMessage && firstError === fieldName) {
      // @ts-ignore
      ref?.current?.scrollIntoView({ behaviour: 'smooth', block: 'center' });
    }
  }, [errorMessage]);

  const getValue = () => {
    try {
      if (field.type === 'DateTime' || field.type === 'Date') {
        return value ? new Date(value) : value;
      } else {
        return value || field?.defaultPicklistValue || '';
      }
    } catch {
      return value;
    }
  };

  const values = field?.fieldValues();
  const getFieldValue = (e: string) => {
    switch (field.type) {
      case 'Picklist':
      case 'Global Picklist':
        return values.filter((v: any) => v.value === e)[0]?.label;
      default:
        return e;
    }
  };

  const handleOnChange = (e: string) => {
    if (field?.logicRole === ACTIVITY_FIELDS_LOGIC_ROLE.ACCOUNT_EXECUTIVE) {
      const value = field?.fieldValues()?.find((v: any) => v?.value === e);
      if (!invitees?.find(invitee => invitee?.email === value?.label)) {
        setInvitees(curr => [
          ...curr,
          {
            type: 'AE',
            email: value?.label,
          },
        ]);
      }
    }
    onChange(e);
    let textToCopyTmp = textsToCopy;
    textToCopyTmp = {
      ...textToCopyTmp,
      ...{
        [section]: {
          ...textToCopyTmp[section],
          ...{
            [field.label]: getFieldValue(e),
          },
        },
      },
    };
    setTextsToCopy(textToCopyTmp);
  };
  return (
    <div className={styles._input_field} ref={ref}>
      <Field
        field={field}
        //@ts-ignore
        onChange={(e: string) => handleOnChange(e)}
        value={getValue()}
        defaultValue={value || field?.defaultPicklistValue || ''}
        name={fieldName}
        size="labeled"
        width="304px"
        placeholder={`${field?.label}${mustBeRequired ? ' *' : ''}`}
        required={mustBeRequired}
        error={error}
      />
    </div>
  );
}

export interface FormDataInterface {
  company: Bobject;
  lead: Bobject;
}

function ActivityDetailsForm({
  isEditionModal,
  formData,
}: {
  isEditionModal: boolean;
  formData: FormDataInterface;
}) {
  const { company } = formData;
  const filters = useActiveMessagingFilters();
  const { qualifyingQuestions } = useQualifyingQuestions(filters);
  const requiredQQs = qualifyingQuestions?.filter((qq: any) => qq?.isRequiredBeforeMeeting);
  const filteredQQs = getFilteredQQsBySegmentation(requiredQQs, formData);

  const meetingFormFields = useBobjectFieldGroups({
    bobject: null,
    bobjectType: 'Activity',
    companyBobjectId: company?.id?.value || null,
    options: {
      type: 'Meeting',
    },
    modalId: undefined,
    segmentatedQQs: filteredQQs,
  });

  const isFullSalesEnabled = useFullSalesEnabled();
  const { watch, getValues } = useFormContext();
  const defaultFormValues = getValues();
  const [textsToCopy, setTextsToCopy] = useState<any>({});

  const activityDetailsFilterFunction = (field: BobjectField, isRequiredBeforeMeeting: boolean) => {
    if (isRequiredBeforeMeeting) {
      return !isEditionModal;
    }
    return (
      !field.deprecated &&
      !field.readOnly &&
      isValidAeField(field) &&
      checkFieldConditions(field, watch) &&
      !DISCARDED_FIELDS.includes(field.logicRole)
    );
  };

  useEffect(() => {
    let createTextToCopyObject = {};
    meetingFormFields?.sections?.forEach(section => {
      const isRequiredBeforeMeeting = section?.title === 'Required information to close Meeting';
      let defaultValues = {};
      section?.fields
        ?.filter((field: BobjectField) =>
          activityDetailsFilterFunction(field, isRequiredBeforeMeeting),
        )
        .forEach((field: BobjectField) => {
          const getValue = (fieldValue: string) => {
            return field?.fieldValues()?.filter((v: any) => v.value === fieldValue)[0]?.label;
          };
          if (field.defaultPicklistValue || field.defaultGlobalPicklistValue) {
            defaultValues = {
              ...defaultValues,
              ...{
                [field.label]: getValue(field.defaultPicklistValue),
              },
            };
          } else if (field.defaultValue) {
            defaultValues = {
              ...defaultValues,
              ...{
                [field.label]: field.defaultValue,
              },
            };
          } else if (Object.keys(defaultFormValues).includes(field.name)) {
            defaultValues = {
              ...defaultValues,
              ...(getValue(defaultFormValues[field.name]) || defaultFormValues[field.name]
                ? {
                    [field.label]:
                      getValue(defaultFormValues[field.name]) || defaultFormValues[field.name],
                  }
                : undefined),
            };
          }
        });
      createTextToCopyObject = {
        ...createTextToCopyObject,
        ...{
          [section.title]: defaultValues,
        },
      };
    });
    setTextsToCopy(createTextToCopyObject);
  }, [meetingFormFields.sections]);

  const getClipboardText = (sectionTitle: string) => {
    let textForClipboard = '';

    const sectionText = textsToCopy[sectionTitle] || {};
    const textArray = Object.keys(sectionText).map(
      key => `<div><span style="font-weight: bold">${key}</span>: ${sectionText[key]}</div>`,
    );
    textArray.forEach(element => (textForClipboard = textForClipboard.concat('\n' + element)));

    return textForClipboard;
  };

  const isValidAeField = (field: BobjectField) => {
    if (isFullSalesEnabled && field?.logicRole === ACTIVITY_FIELDS_LOGIC_ROLE.ACCOUNT_EXECUTIVE) {
      return true;
    } else if (
      !isFullSalesEnabled &&
      field?.logicRole === ACTIVITY_FIELDS_LOGIC_ROLE.ACCOUNT_EXECUTIVE
    ) {
      return false;
    }
    return true;
  };

  return (
    <div className={styles._additionalFields_content}>
      {meetingFormFields?.sections?.map(section => {
        const isRequiredBeforeMeeting = section?.title === 'Required information to close Meeting';
        const sectionFields = section.fields
          ?.filter((field: BobjectField) =>
            activityDetailsFilterFunction(field, isRequiredBeforeMeeting),
          )
          .map((field: BobjectField) => (
            <MeetingField
              key={field.name}
              field={field}
              isRequiredBeforeMeeting={isRequiredBeforeMeeting}
              textsToCopy={textsToCopy}
              setTextsToCopy={setTextsToCopy}
              section={section.title}
            />
          ));

        return sectionFields.length > 0 ? (
          <div className={styles._additionalFields_section} key={section.title}>
            <div className={styles._additionalFields_content_title}>
              <CopyText
                isLinkTypeField={false}
                textToCopy={getClipboardText(section.title)}
                htmlFormat={true}
                alwaysDisplay={true}
              >
                <Text size="s" color="softPeanut">
                  {section.title}
                </Text>
              </CopyText>
            </div>
            <div className={styles._additionalFields_fields}>{sectionFields}</div>
          </div>
        ) : null;
      })}
    </div>
  );
}

export default ActivityDetailsForm;
