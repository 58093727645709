import { startOfDay, subDays } from 'date-fns';
import { TASK_FIELDS_LOGIC_ROLE } from '../../../constants/task';
import { getValueFromLogicRole } from '../../../utils/bobjects.utils';
import { Bobject } from '../../../typings/bobjects';

export const checkIsOverdue = (item: Bobject) => {
  const date = new Date(getValueFromLogicRole(item, TASK_FIELDS_LOGIC_ROLE.SCHEDULED_DATETIME));

  return startOfDay(new Date(date)) <= subDays(startOfDay(new Date()), 1);
};
