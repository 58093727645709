import { atom, useRecoilState } from 'recoil';
import { TASK_FIELDS_LOGIC_ROLE } from '../constants/task';
import { BobjectApi } from '../misc/api/bobject';
import mixpanel from 'mixpanel-browser';
import { MIXPANEL_EVENTS } from '../constants/mixpanel';

const rescheduleModalOpenAtom = atom({
  key: 'rescheduleModalOpenAtom',
  default: false,
});

const bobjectAtom = atom({
  key: 'rescheduledTaskBobjectAtom',
  default: null,
});

const updateTask = async (taskId, data) => {
  return await BobjectApi.request().bobjectType('Task').partialSet({ bobjectId: taskId, data });
};

const bulkUpdateTasks = async tasksData => {
  return await BobjectApi.request().bobjectType('Task').bulkPartialSet(tasksData);
};

const isBulkAction = bobjectToCheck => Array.isArray(bobjectToCheck);

export const useRescheduleTask = () => {
  const [rescheduleModalOpen, setRescheduleModalOpen] = useRecoilState(rescheduleModalOpenAtom);
  const [bobject, setBobject] = useRecoilState(bobjectAtom);

  const openRescheduleModal = ({ bobjectToSet }) => {
    if (bobjectToSet) {
      setBobject(bobjectToSet);
    }

    if (!rescheduleModalOpen) {
      setRescheduleModalOpen(true);
    }
  };

  const closeRescheduleModal = () => {
    if (rescheduleModalOpen) {
      setRescheduleModalOpen(false);
    }
  };

  const rescheduleTasks = date => {
    if (isBulkAction(bobject)) {
      let data;
      bobject.forEach(bobjectItem => {
        data = {
          ...data,
          [bobjectItem?.id.objectId]: {
            [TASK_FIELDS_LOGIC_ROLE.SCHEDULED_DATETIME]: date,
            [TASK_FIELDS_LOGIC_ROLE.SCHEDULED_DATE]: date,
          },
        };
      });
      mixpanel.track(MIXPANEL_EVENTS.RESCHEDULE_BULK_ACTION_CONFIRMED_ON_SCHEDULED_TAB);
      return bulkUpdateTasks(data);
    }
    mixpanel.track(MIXPANEL_EVENTS.RESCHEDULE_ACTION_CONFIRMED_ON_SCHEDULE_TAB);
    return updateTask(bobject?.id?.objectId, {
      [TASK_FIELDS_LOGIC_ROLE.SCHEDULED_DATETIME]: date,
      [TASK_FIELDS_LOGIC_ROLE.SCHEDULED_DATE]: date,
    });
  };

  return {
    isOpen: rescheduleModalOpen,
    bobject,
    closeRescheduleModal,
    openRescheduleModal,
    rescheduleTasks,
  };
};
