import { useUserSettings } from '../components/userPermissions/hooks';
import { useUserHelpers } from './useUserHelpers';
import { getQuickStartGuideBlocks } from '../pages/homePage/components/quickStartGuide/guideDefinition.constants';
import { useIsAccountAdmin } from './usePermissions';
import { getAdminQuickStartGuideBlocks } from '../pages/homePage/components/quickStartGuide/adminGuideDefinition.constants';

export const useQuickStartEnabled = () => {
  const {
    user: { skipQuickStartGuide },
  } = useUserSettings();

  return !skipQuickStartGuide;
};

export const useQuickStartGuideCompleted = () => {
  const { helpers } = useUserHelpers();
  const isAccountAdmin = useIsAccountAdmin();
  const QSGBlocks = isAccountAdmin ? getAdminQuickStartGuideBlocks() : getQuickStartGuideBlocks();
  const QSGGoals = QSGBlocks.flatMap(block => block.goals.map(goal => goal.key));
  if (helpers) {
    const remainingGoals = QSGGoals.filter(goal => !Object.keys(helpers).includes(goal));
    return remainingGoals?.length < 2;
  } else {
    return false;
  }
};

export const useQuickStartGuideCompletedAggregation = () => {
  const { helpers } = useUserHelpers();
  const isAccountAdmin = useIsAccountAdmin();
  const QSGBlocks = isAccountAdmin ? getAdminQuickStartGuideBlocks() : getQuickStartGuideBlocks();
  const QSGGoals = QSGBlocks.flatMap(block => block.goals.map(goal => goal.key));
  const completedGoals = QSGGoals.filter(goal => Object.keys(helpers).includes(goal));

  return completedGoals?.length;
};
