import React, { useEffect, useRef, useState } from 'react';
import styles from './analyzeTab.module.css';
import { Button, Pagination, Text } from '@bloobirds-it/flamingo-ui';
import {
  CadencesTab,
  CadencesTabContent,
  CadencesTabFooter,
  CadencesTabHeader,
  CadencesTabHeaderLeft,
  CadencesTabHeaderRight,
  CadencesTableContainer,
} from '../../../layouts/cadencesLayout/cadencesTabLayout/cadencesTabLayout';
import { CreateEditCadenceSettings } from '../components/createEditCadenceSettings/createEditCadenceSettings';
import { AnalyzeCadencesList } from '../components/analyzeCadenceList/analyzeCadenceList';
import { useAnalyzeCadenceList } from '../components/analyzeCadenceList/useAnalyzeCadenceList';
import {
  BobjectTypeFilter,
  StartDateFilter,
  StartedByFilter,
} from '../components/filters/cadenceFilters';
import { useCadenceV2Enabled, useRouter } from '@bloobirds-it/hooks';
import CadenceSelector from '../../../components/cadenceSelector/cadenceSelector';
import { useQueryParam } from '../../../hooks/useQueryParams';
import {
  APP_CADENCES_ANALYZE,
  APP_CL,
  APP_CL_COMPANIES,
  APP_CL_LEADS,
  APP_CL_OPPORTUNITIES,
  BobjectTypes,
  cadenceEditUrl,
  cadenceEditUrlV2,
} from '@bloobirds-it/types';
import SessionManagerFactory from '../../../misc/session';
import { useBobjectTypes } from '../../../hooks/useBobjectTypes';
import { KpisBlock } from '../components/kpisBlock/kpisBlock';
import { useClickAway } from 'react-use';

export const AnalyzeTab = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isCadenceSelectorOpen, setIsCadenceSelectorOpen] = useState(false);
  const SessionManager = SessionManagerFactory();
  const cadenceV2Enabled = useCadenceV2Enabled(SessionManager.getAccount()?.id);
  const id = useQueryParam('id');
  const name = useQueryParam('name');
  const bobjectType = useQueryParam('bobjectType');
  const bobjectTypes = useBobjectTypes();
  const isCadenceView = !!id;
  const { history } = useRouter();
  const {
    selectedBobjectType,
    setSelectedBobjecType,
    page,
    setPage,
    pageSize,
    resetPage,
    setPageSize,
    totalElements,
    rangeDateProps,
    startedByProps,
    analyticsListProps,
    kpisProps,
  } = useAnalyzeCadenceList(id);
  const ref = useRef<HTMLDivElement>(null);
  useClickAway(ref, () => setIsCadenceSelectorOpen(false));

  useEffect(() => {
    resetPage();
  }, []);

  return (
    <CadencesTab>
      <>
        <CadencesTabHeader>
          <CadencesTabHeaderLeft>
            <div className={styles.tabTitle__container}>
              <Text
                size="xl"
                color={name ? 'bloobirds' : 'peanut'}
                weight={name ? 'bold' : 'regular'}
              >
                {name ? name : 'All cadences'}
              </Text>
              <div style={{ display: 'flex', gap: 4 }}>
                <Button
                  variant="secondary"
                  iconRight="search"
                  size="small"
                  uppercase={false}
                  onClick={() => setIsCadenceSelectorOpen(!isCadenceSelectorOpen)}
                >
                  Select
                </Button>
                {isCadenceView && (
                  <Button
                    variant="secondary"
                    iconRight="cross"
                    size="small"
                    uppercase={false}
                    onClick={() => {
                      history.goBack();
                    }}
                  >
                    Clear
                  </Button>
                )}
              </div>
            </div>
          </CadencesTabHeaderLeft>
          <CadencesTabHeaderRight>
            {!isCadenceView && (
              <>
                <BobjectTypeFilter
                  selectedBobjectType={selectedBobjectType}
                  setSelectedBobjecType={setSelectedBobjecType}
                />

                <StartedByFilter {...startedByProps} />
              </>
            )}
            <StartDateFilter {...rangeDateProps} />

            {isCadenceView && (
              <CadenceActionButtons
                openEditModal={() =>
                  history.push(
                    `${
                      cadenceV2Enabled ? cadenceEditUrlV2(id) : cadenceEditUrl(id)
                    }&name=${name}&bobjectType=${bobjectType}`,
                  )
                }
                enrollCallback={() => {
                  const routes: {
                    [key: string]: string;
                  } = {
                    [BobjectTypes.Company]: APP_CL_COMPANIES,
                    [BobjectTypes.Lead]: APP_CL_LEADS,
                    [BobjectTypes.Opportunity]: APP_CL_OPPORTUNITIES,
                  };

                  const route = routes[bobjectType] || APP_CL;

                  history.push(route);
                }}
              />
            )}
          </CadencesTabHeaderRight>
        </CadencesTabHeader>
        <CadencesTabContent>
          <>
            <KpisBlock {...kpisProps} />
            <CadencesTableContainer>
              <AnalyzeCadencesList
                cadenceId={id}
                totalRows={totalElements}
                {...analyticsListProps}
              />
            </CadencesTableContainer>
          </>
        </CadencesTabContent>
        <CadencesTabFooter>
          <Pagination
            rowsPerPageOptions={[10, 20, 50]}
            page={page}
            count={totalElements}
            rowsPerPage={pageSize}
            onChangePage={setPage}
            onChangeRowsPerPage={setPageSize}
          />
        </CadencesTabFooter>

        {isModalOpen && (
          <CreateEditCadenceSettings
            onClose={() => setIsModalOpen(false)}
            bobjectType={selectedBobjectType}
          />
        )}
        {isCadenceSelectorOpen && (
          <CadenceSelector
            onCadenceSelected={cadence => {
              const bobjectType = bobjectTypes?.get(cadence.bobjectType);
              history.push(
                `${APP_CADENCES_ANALYZE}?id=${cadence.id}&name=${cadence.name}&bobjectType=${bobjectType?.name}`,
              );
              setIsCadenceSelectorOpen(false);
            }}
            ref={ref}
          />
        )}
      </>
    </CadencesTab>
  );
};

export const CadenceActionButtons = ({
  openEditModal,
  enrollCallback,
}: {
  openEditModal: () => void;
  enrollCallback: () => void;
}) => (
  <div style={{ display: 'flex', gap: 4 }}>
    <Button
      variant="secondary"
      iconRight="edit"
      size="small"
      uppercase={false}
      onClick={openEditModal}
    >
      Edit
    </Button>
    {/* <Button iconRight="deliver" size="small" uppercase={false} onClick={enrollCallback}>
      Enroll
    </Button>*/}
  </div>
);
