// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".automatedFilters-module___clear_wrapper___a7Fb2 {\n  display: flex;\n  align-items: center;\n}\n\n.automatedFilters-module___clear_wrapper___a7Fb2 > button {\n  padding-bottom: 0;\n  padding-top: 0;\n}\n\n.automatedFilters-module___filters__title___UN6uq {\n  margin-right: 12px;\n}\n\n.automatedFilters-module___quick_filter_wrapper___eFw4O > div {\n  margin-right: 8px;\n}\n", "",{"version":3,"sources":["webpack://./src/js/pages/outboxPages/automated/automatedFilters/automatedFilters.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,iBAAiB;EACjB,cAAc;AAChB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,iBAAiB;AACnB","sourcesContent":["._clear_wrapper {\n  display: flex;\n  align-items: center;\n}\n\n._clear_wrapper > button {\n  padding-bottom: 0;\n  padding-top: 0;\n}\n\n._filters__title {\n  margin-right: 12px;\n}\n\n._quick_filter_wrapper > div {\n  margin-right: 8px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"_clear_wrapper": "automatedFilters-module___clear_wrapper___a7Fb2",
	"_filters__title": "automatedFilters-module___filters__title___UN6uq",
	"_quick_filter_wrapper": "automatedFilters-module___quick_filter_wrapper___eFw4O"
};
export default ___CSS_LOADER_EXPORT___;
