import React from 'react';
import { TableRow } from '@material-ui/core';
import {Checkbox, IconButton, Tooltip, useToasts} from '@bloobirds-it/flamingo-ui';
import { useLocation } from 'react-router';
import { bobjectModel } from '../../../../misc/model/bobjectFieldsModel';
import Cell from './Cell';
import RowActions from '../../actionsRow/ActionsRow';
import { APP_CL_LEADS, bobjectUrl } from '../../../../app/_constants/routes';
import { BobjectFieldPill } from '../../../filter/field/pill';
import { DateTextField, NumberTextField, PhoneTextField } from '../../../filter/field/field';
import { getRelatedBobject, getValueFromLogicRole } from '../../../../utils/bobjects.utils';
import {
  useBobjectDetails,
  useBobjectFormVisibility,
  useContextMenu,
  useEntity,
  useRouter,
} from '../../../../hooks';
import { usePreviousUrl } from '@bloobirds-it/hooks';
import RightClickContextMenu from '../../../rightClickContextMenu';
import { BOBJECT_TYPES } from '../../../../constants/bobject';
import styles from './row.module.css';
import { useBobjectTypes } from '../../../../hooks/useBobjectTypes';
import { ACTIVITY_FIELDS_LOGIC_ROLE } from '../../../../constants/activity';
import { useBulkActionsEnabled, useFullSalesEnabled } from '../../../../hooks/useFeatureFlags';
import { COMPANY_FIELDS_LOGIC_ROLE } from '../../../../constants/company';
import { LEAD_FIELDS_LOGIC_ROLE } from '../../../../constants/lead';
import { OPPORTUNITY_FIELDS_LOGIC_ROLE } from '../../../../constants/opportunity';
import { useTableContext } from '../../context/bobjectTable.context';
import { Bobject } from '../../../../typings/bobjects';
import { useUserPermissions } from '../../../userPermissions/hooks';
import { isUrl } from '../../../../misc/utils';
import { api } from '../../../../utils/api';

const NAME_FIELD_LOGIC_ROLE = [
  COMPANY_FIELDS_LOGIC_ROLE.NAME,
  LEAD_FIELDS_LOGIC_ROLE.FULL_NAME,
  OPPORTUNITY_FIELDS_LOGIC_ROLE.NAME,
  OPPORTUNITY_FIELDS_LOGIC_ROLE.COMPANY,
];

const getBobjectUrl = (bobjectParam, bobjectType, hasSalesEnabled) => {
  const bobjectNeedReference = [
    BOBJECT_TYPES.ACTIVITY,
    BOBJECT_TYPES.TASK,
    BOBJECT_TYPES.OPPORTUNITY,
    BOBJECT_TYPES.LEAD,
  ];
  const { name: bobjectTypeName = '' } = bobjectType;
  let url;
  let bobject = bobjectParam;

  if (bobjectNeedReference.includes(bobjectTypeName)) {
    let referencedBobject = getRelatedBobject(bobject, BOBJECT_TYPES.COMPANY);
    referencedBobject = referencedBobject || getRelatedBobject(bobject, BOBJECT_TYPES.LEAD);
    referencedBobject = referencedBobject || getRelatedBobject(bobject, BOBJECT_TYPES.OPPORTUNITY);
    bobject = referencedBobject;
  }
  if (bobject || bobjectParam) {
    const isOpportunity = bobjectTypeName === BOBJECT_TYPES.OPPORTUNITY;
    const isLead = bobjectTypeName === BOBJECT_TYPES.LEAD;
    url =
      isOpportunity || isLead
        ? bobjectUrl(bobjectParam, hasSalesEnabled && isOpportunity ? undefined : bobject)
        : bobjectUrl(bobject);
  }
  return url;
};

const onClickRow = (
  event,
  history,
  setPreviousUrl,
  hasSalesEnabled,
  { bobject, bobjectType, openEditModal, rowClick },
) => {
  const {
    target: {
      dataset: { excludeHandler },
    },
  } = event;
  const fieldCompany = getValueFromLogicRole(bobject, ACTIVITY_FIELDS_LOGIC_ROLE.COMPANY);
  const fieldLead = getValueFromLogicRole(bobject, ACTIVITY_FIELDS_LOGIC_ROLE.LEAD);
  const emptyActivity = bobjectType.name === BOBJECT_TYPES.ACTIVITY && !fieldCompany && !fieldLead;
  if (excludeHandler) {
    event.preventDefault();
    event.stopPropagation();
  } else if (rowClick === 'openForm') {
    event.preventDefault();
    openEditModal({ bobject });
  } else if (!emptyActivity && (event.ctrlKey || event.metaKey)) {
    window.open(getBobjectUrl(bobject, bobjectType, hasSalesEnabled), '_blank');
  } else if (emptyActivity) {
    event.preventDefault();
  } else {
    const url =
      bobject?.id.typeName === BOBJECT_TYPES.LEAD
        ? `${APP_CL_LEADS}/${bobject?.id.objectId}`
        : getBobjectUrl(bobject, bobjectType, hasSalesEnabled);
    history.push(url);
    document.querySelector('#content').scroll({ top: 0 });
  }
};

const NameField = ({ text, bobject }) => {
  const { openBobjectDetails } = useBobjectDetails();
  return (
    <Tooltip title={text?.length > 33 ? text : ''} position="top">
      <div
        data-test={`TableCell-${bobject?.id.typeName}_Name_${text}`}
        className={styles.nameField}
        data-exclude-handler="name"
        onClick={event => {
          openBobjectDetails({ id: bobject?.id.value, showContactButton: true });
          event.stopPropagation();
          event.preventDefault();
        }}
      >
        {text || `Untitled ${bobject?.id.typeName}`}
      </div>
    </Tooltip>
  );
};

const getField = (
  bobject,
  bobjectField,
  bobjectType,
  bobjectTypes,
  model,
  bobjectPicklistFieldValues,
) => {
  let field;
  let isReference = false;
  // Case the field belongs to a referenced bobject
  if (bobjectType.id !== bobjectField.bobjectType) {
    const referencedBobject = getRelatedBobject(
      bobject,
      bobjectTypes.get(bobjectField.bobjectType).name,
    );
    // 2. find the field
    if (referencedBobject) {
      field = bobjectModel(referencedBobject).findById(bobjectField.id);
      isReference = true;
    }
  } else {
    // case: the fields belongs to the object itself
    field = model.findById(bobjectField.id);
  }
  if (field === undefined) {
    return '';
  }

  if (field && !field.text) {
    const value = bobject?.raw?.contents[field?.name];
    if (value && value !== '') {
      const picklistValue = bobjectPicklistFieldValues.get(value);
      field['text'] = picklistValue?.value;
    }
  }

  return {
    isReference,
    field,
  };
};

const getReferencedBobjectText = referencedBobjectFields =>
  referencedBobjectFields?.find(
    referencedBobjectField =>
      referencedBobjectField.logicRole === 'COMPANY__NAME' ||
      referencedBobjectField.logicRole === 'LEAD__FULL_NAME',
  )?.text;

const display = (
  bobject,
  bobjectField,
  bobjectType,
  bobjectTypes,
  model,
  bobjectPicklistFieldValues,
  createToast,
) => {
  const { isReference, field } = getField(
    bobject,
    bobjectField,
    bobjectType,
    bobjectTypes,
    model,
    bobjectPicklistFieldValues,
  );

  if (!field) {
    return null;
  }

  if (field.valueBackgroundColor !== null && field.valueBackgroundColor !== undefined) {
    return <BobjectFieldPill field={field} />;
  }
  if (field.logicRole === ACTIVITY_FIELDS_LOGIC_ROLE.CALL_RECORD_URL) {
    const recordCall = getValueFromLogicRole(bobject, ACTIVITY_FIELDS_LOGIC_ROLE.CALL_RECORD_URL);
    if (!recordCall) {
      return null;
    }
    const getSignedCallRecordingUrl = async () => {
      const oldRecordingRegex = /^(https:\/\/record-calls.bloobirds.com\/)(.{34})/g;
      let callSid = recordCall;
      const itsADeprecatedRecordingLink = recordCall.match(oldRecordingRegex);
      if (!itsADeprecatedRecordingLink && isUrl(recordCall)) {
        return recordCall;
      }
      if (recordCall && itsADeprecatedRecordingLink) {
        callSid = recordCall.split('/').at(-1);
      } else {
        callSid = recordCall.split('/')[1];
      }
      const signedUrl = await api.get(`/calls/whiteLabel/calls/${callSid}/recording`);
      if (signedUrl.status === 200) {
        return signedUrl.data.url;
      } else {
        throw new Error('Failed to get signed url');
      }
    };
    return (
      <IconButton
        name="voicemail"
        size={24}
        color="bloobirds"
        onClick={e => {
          e.preventDefault();
          e.stopPropagation();
          getSignedCallRecordingUrl()
            .then(url => {
              window.open(url, '_blank');
            })
            .catch(() => {
              createToast({
                message: 'Failed to get the recording, it may have been deleted',
                type: 'error',
              });
            });
        }}
      />
    );
  }
  if (NAME_FIELD_LOGIC_ROLE.includes(field.logicRole) && !isReference) {
    return (
      <NameField
        text={
          bobjectType.name === BOBJECT_TYPES.OPPORTUNITY &&
          field.logicRole === 'OPPORTUNITY__COMPANY'
            ? getReferencedBobjectText(field.referencedBobject?.fields)
            : field.text
        }
        bobject={
          bobjectType.name === BOBJECT_TYPES.OPPORTUNITY &&
          field.logicRole === 'OPPORTUNITY__COMPANY'
            ? getRelatedBobject(bobject, BOBJECT_TYPES.COMPANY)
            : bobject
        }
      />
    );
  }
  if (field.type === 'DATE' || field.type === 'DATETIME') {
    return <DateTextField field={field} />;
  }
  if (field.type === 'NUMBER' || field.type === 'DOUBLE') {
    return <NumberTextField field={field} />;
  }
  if (field.type === 'PHONE') {
    return <PhoneTextField field={field} />;
  }
  if (field.type === 'REFERENCE') {
    if (!field.referencedBobject) {
      return '';
    }
    return getReferencedBobjectText(field.referencedBobject.fields);
  }
  return (
    <Tooltip title={field?.text?.length > 33 ? field?.text : ''} position="top">
      <div
        data-test={`TableCell-${bobject?.id.typeName}_${field?.text}`}
        className={styles.standardField}
        data-exclude-handler="name"
      >
        {field.text}
      </div>
    </Tooltip>
  );
};

export const Row = props => {
  const {
    bobject,
    bobjectFields,
    actionsRow,
    bobjectType,
    rowClick,
    dataTest: dataTestRow,
  } = props;
  const bobjectTypes = useBobjectTypes();
  const model = bobjectModel(bobject);
  const { history } = useRouter();
  const {
    ref,
    xPos,
    yPos,
    isContextMenuVisible,
    handleContextMenu,
    hideContextMenu,
  } = useContextMenu();
  const { selectFunctions } = useTableContext();
  const { openEditModal } = useBobjectFormVisibility();
  const { setPreviousUrl } = usePreviousUrl();
  const fieldCompany = getValueFromLogicRole(bobject, 'ACTIVITY__COMPANY');
  const fieldLead = getValueFromLogicRole(bobject, 'ACTIVITY__LEAD');
  const emptyActivity = bobjectType.name === BOBJECT_TYPES.ACTIVITY && !fieldCompany && !fieldLead;
  const bobjectPicklistFieldValues = useEntity('bobjectPicklistFieldValues');
  const hasSalesEnabled = useFullSalesEnabled();
  const { createToast } = useToasts();
  const { selectedItems, selectOneItem } = selectFunctions;
  // TEMPORAL
  const isLists = useLocation()?.pathname.includes('app/cl/');
  const { bulkActions: hasBulkActionsPermission } = useUserPermissions();
  const hasBulkActionsEnabled = useBulkActionsEnabled();
  const canUseBulkActions = hasBulkActionsPermission && hasBulkActionsEnabled;
  const shouldShowCheckbox = isLists && canUseBulkActions;
  const isChecked = selectedItems?.some(
    (item: Bobject) => item?.id?.objectId === bobject?.id?.objectId,
  );

  return (
    <>
      <TableRow
        className={styles.row}
        onClick={event =>
          onClickRow(event, history, setPreviousUrl, hasSalesEnabled, {
            bobject,
            bobjectType,
            openEditModal,
            rowClick,
          })
        }
        onContextMenu={handleContextMenu}
        onMouseDown={event => {
          if (event.button === 1 && !emptyActivity) {
            window.open(getBobjectUrl(bobject, bobjectType, hasSalesEnabled), '_blank');
            event.preventDefault();
          }
        }}
        ref={ref}
      >
        {shouldShowCheckbox && (
          <Cell className={styles.checkbox} dataTest="checkBox-cell">
            <Checkbox
              size={'small'}
              checked={isChecked}
              onClick={(value, event) => {
                event.stopPropagation();
                event.preventDefault();
                selectOneItem(bobject);
              }}
            />
          </Cell>
        )}
        {bobjectTypes &&
          bobjectFields?.map((bobjectField, index) => {
            const key = bobjectField.name.replace(/(\s)+/, '_').concat(`_${index}`);
            const dataTest = `${dataTestRow}_${bobjectType.name}_${bobjectField.name}`;
            let actions;
            if (actionsRow !== undefined && index === 0) {
              actions = <RowActions bobject={bobject} actionsRow={actionsRow} />;
            }
            return (
              <Cell key={key} actions={actions} dataTest={dataTest}>
                {display(
                  bobject,
                  bobjectField,
                  bobjectType,
                  bobjectTypes,
                  model,
                  bobjectPicklistFieldValues,
                  createToast,
                )}
              </Cell>
            );
          })}
        {isContextMenuVisible && !emptyActivity && (
          <RightClickContextMenu
            url={getBobjectUrl(bobject, bobjectType, hasSalesEnabled)}
            xPos={xPos}
            yPos={yPos}
            hideContextMenu={hideContextMenu}
          />
        )}
      </TableRow>
    </>
  );
};
