import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { Button, Icon, Spinner } from '@bloobirds-it/flamingo-ui';
import mixpanel from 'mixpanel-browser';
import styles from '../../../homePage.module.css';
import { StartTaskSettings } from '../../../../../components/startTaskSettings/startTaskSettings';
import { MIXPANEL_EVENTS } from '../../../../../constants/mixpanel';
import { useTaskNavigationStorage } from '../../../../../hooks';
import { useSelectAll } from '@bloobirds-it/hooks';
import { Bobject } from '@bloobirds-it/types';

export const StartTask = ({ isSales, disabled }: { isSales: boolean; disabled: boolean }) => {
  // This is needed to correctly set the task navigation settings
  const [changingTaskNavigationSettings, setChangingTaskNavigationSettings] = useState(false);

  const { selectedItems, initialItems } = useSelectAll();
  const { startNavigation, setSalesNavigation } = useTaskNavigationStorage();

  // Set the task navigation settings
  useEffect(() => setSalesNavigation(isSales), [isSales]);

  return (
    <>
      <StartTaskSettings
        callingFromSalesPage={isSales}
        updateLoading={setChangingTaskNavigationSettings}
      />
      <div
        className={clsx(
          styles._start_button,
          (initialItems?.length === 0 || disabled) && styles.notAllowed,
        )}
      >
        <Button
          color={
            initialItems?.length !== 0 && !disabled
              ? changingTaskNavigationSettings
                ? 'softBloobirds'
                : 'bloobirds'
              : 'verySoftPeanut'
          }
          className={styles.startButton}
          size="small"
          onClick={() => {
            if (!changingTaskNavigationSettings) {
              mixpanel.track(MIXPANEL_EVENTS.HOME_STARTED_TASKS);
              const navigableTasks = selectedItems?.length > 0 ? selectedItems : initialItems;
              startNavigation(navigableTasks as Bobject[]);
            }
          }}
          disabled={initialItems?.length === 0 || disabled}
        >
          {changingTaskNavigationSettings && (
            <div className={styles._button_spinner}>
              <Spinner color={'white'} name="loadingCircle" size={14} />
            </div>
          )}
          {!changingTaskNavigationSettings ? <Icon name="play" size={16} color="white" /> : ''}
          {(selectedItems?.length || initialItems?.length) && !disabled
            ? changingTaskNavigationSettings
              ? ''
              : `(${selectedItems?.length || initialItems?.length})`
            : ''}
        </Button>
      </div>
    </>
  );
};
