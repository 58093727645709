import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardLeft,
  CardRight,
  IconButton,
  Label,
  Text,
  useToasts,
} from '@bloobirds-it/flamingo-ui';
import React from 'react';
import { getFieldByLogicRole, getTextFromLogicRole } from '../../../utils/bobjects.utils';
import { useActivity, useMeetingResult } from '../../../hooks';
import { Bobject } from '../../../typings/bobjects';
import {
  ACTIVITY_FIELDS_LOGIC_ROLE,
  MEETING_MAIN_TYPE_VALUES,
  REPORTED_VALUES_LOGIC_ROLE,
} from '../../../constants/activity';
import styles from './meetingTaskBoxCard.module.css';
import { BobjectNameLink } from '../../../components/bobjectNameLinks/bobjectNameLink';
import { AssigneeComponent } from '../../subhomePages/prospectingPage/components/fieldTypeComponent';
import CardIcon from '../../../components/cardIcon/cardIcon';
import DateText from '../../../components/activitySection/activityItem/dateText';

export const MeetingTaskBoxCard = ({ bobject }: { bobject: Bobject }) => {
  const { updateActivity } = useActivity('activityCard');
  const { openMeetingResult } = useMeetingResult();
  const bobjectId = bobject?.id?.objectId;
  const { createToast } = useToasts();
  const lead = getFieldByLogicRole(bobject, ACTIVITY_FIELDS_LOGIC_ROLE.LEAD)?.referencedBobject;
  const company = getFieldByLogicRole(bobject, ACTIVITY_FIELDS_LOGIC_ROLE.COMPANY)
    ?.referencedBobject;
  const date = getTextFromLogicRole(bobject, ACTIVITY_FIELDS_LOGIC_ROLE.TIME);
  const assignee = getFieldByLogicRole(bobject, ACTIVITY_FIELDS_LOGIC_ROLE.MEETING_ASSIGNED_TO);
  const activityCreator = getFieldByLogicRole(bobject, ACTIVITY_FIELDS_LOGIC_ROLE.USER);
  const meetingType = getFieldByLogicRole(bobject, ACTIVITY_FIELDS_LOGIC_ROLE.MEETING_MAIN_TYPE);
  const isFirstMeeting =
    meetingType?.valueLogicRole === MEETING_MAIN_TYPE_VALUES.FIRST_MEETING ||
    !meetingType?.valueLogicRole;
  const isReported =
    getFieldByLogicRole(bobject, ACTIVITY_FIELDS_LOGIC_ROLE.REPORTED).valueLogicRole ===
    REPORTED_VALUES_LOGIC_ROLE.YES;

  const markAsReported = () => {
    updateActivity(bobject?.id.objectId, {
      [ACTIVITY_FIELDS_LOGIC_ROLE.REPORTED]: REPORTED_VALUES_LOGIC_ROLE.YES,
    }).then(() => {
      createToast({ message: 'Meeting successfully reported', type: 'success' });
    });
  };

  return (
    <div className={styles._card}>
      <Card key={bobjectId}>
        <CardHeader>
          <CardLeft>
            <CardIcon name="calendar" color="tomato" />
          </CardLeft>
          <CardBody>
            <Text size="s" className={styles._callCard_body__text}>
              <b>Meeting arranged</b> with
            </Text>
            {lead && (
              <div className={styles._callCard_body__link}>
                <BobjectNameLink bobject={lead} icon="person" />
              </div>
            )}
            {lead && company && <div className={styles._separator} />}
            {company && (
              <div className={styles._callCard_body__link}>
                <BobjectNameLink bobject={company} icon="company" />
              </div>
            )}
            {(assignee || activityCreator) && (
              <AssigneeComponent value={assignee?.value ? assignee : activityCreator} />
            )}
            {meetingType && (
              <Label
                overrideStyle={{
                  padding: '3px 4px',
                  color: 'var(--peanut)',
                  marginLeft: '8px',
                }}
                uppercase={false}
              >
                {meetingType?.text || 'First Meeting'}
              </Label>
            )}
          </CardBody>
          <CardRight>
            {date && (
              <div className={styles._date_text_wrapper}>
                <DateText date={date} />
              </div>
            )}
            {isReported ? (
              <div data-test="Icon-thumbsUp" className={styles._button_reported_container}>
                <IconButton name="thumbsUp" color="melon" size={16} />
              </div>
            ) : (
              <Button
                dataTest="Activity-ReportResult"
                variant="secondary"
                size="small"
                iconLeft="thumbsUp"
                onClick={e => {
                  e.stopPropagation();
                  e.preventDefault();
                  if (!isFirstMeeting) {
                    markAsReported();
                  } else {
                    openMeetingResult(bobject);
                  }
                }}
              >
                {isFirstMeeting ? 'Report result' : 'Mark as done'}
              </Button>
            )}
            <div />
          </CardRight>
        </CardHeader>
      </Card>
    </div>
  );
};
