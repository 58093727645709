import React, { useState } from 'react';
import { Button, Checkbox, Text } from '@bloobirds-it/flamingo-ui';
import styles from '../../styles/generalSettingsPage.module.css';
import {
  AccountSettingsTab,
  AccountSettingsTabContent,
  AccountSettingsTabTitle,
} from '../../../../../layouts/accountSettingsLayout/accountSettingsTab/accountSettingsTab';
import {
  AccountSettingsSectionContent,
  AccountSettingsSectionSubtitle,
  AccountSettingsSectionTitle,
} from '../../../../../layouts/accountSettingsLayout/accountSettingsSection/accountSettingsSection';
import { useGeneralSettings } from '../../hooks/useGeneralSettings';
import { LeadsTooltip } from '../../../../../components/discoveryTooltips/generalSettingsTourTooltips/leadsTooltip';
import { useQuickStartEnabled } from '../../../../../hooks/useQuickStartGuide';

export const LeadCard = ({ value }) => {
  const [hasChanges, setHasChanges] = useState(false);
  const [isEmailMatching, setIsEmailMatching] = useState(value);
  const { updateEmailMatching } = useGeneralSettings();

  const hasQSGEnabled = useQuickStartEnabled();

  const handleChanges = () => {
    setHasChanges(!hasChanges);
    setIsEmailMatching(!isEmailMatching);
  };
  const handleSubmit = () => {
    updateEmailMatching(isEmailMatching);
    setHasChanges(false);
  };
  return (
    <AccountSettingsTab>
      <AccountSettingsTabTitle icon="person">
        <div style={{ display: 'flex', height: 24 }} id="leadsGeneralSettings">
          <Text>Leads</Text>
          {hasQSGEnabled && <LeadsTooltip defaultTooltipVisible />}
        </div>
      </AccountSettingsTabTitle>
      <AccountSettingsTabContent />
      <AccountSettingsTabContent>
        <AccountSettingsTab>
          <AccountSettingsSectionTitle>Lead email matching</AccountSettingsSectionTitle>
          <AccountSettingsSectionSubtitle>
            New leads matching in domain with an existing company will be automatically matched.
          </AccountSettingsSectionSubtitle>
          <AccountSettingsSectionContent>
            <div className={styles._checkbox_wrapper}>
              <Checkbox checked={isEmailMatching} onClick={handleChanges}>
                <div>
                  <Text size="s">Enable lead email matching with existing companies</Text>
                </div>
              </Checkbox>
            </div>
          </AccountSettingsSectionContent>
        </AccountSettingsTab>
      </AccountSettingsTabContent>
      <AccountSettingsTabContent>
        <Button disabled={!hasChanges} onClick={handleSubmit}>
          SAVE
        </Button>
      </AccountSettingsTabContent>
    </AccountSettingsTab>
  );
};
