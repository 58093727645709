// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".stepCardList-module___dashed_line___u0VM2 {\n  width: 3px;\n  height: 16px;\n  margin-left: 24px;\n  background-color: var(--lighterGray);\n  border-left: 1px dashed var(--verySoftPeanut);\n}\n\n.stepCardList-module__dayLabel___6nm6A {\n  padding: 2px 8px;\n  background-color: var(--white);\n  border-radius: 4px;\n  display: inline-block;\n}\n", "",{"version":3,"sources":["webpack://./src/js/pages/playbookPages/businessAssetsPage/cadenceTab/cadenceEditionPage/stepCardList/stepCardList.module.css"],"names":[],"mappings":"AAAA;EACE,UAAU;EACV,YAAY;EACZ,iBAAiB;EACjB,oCAAoC;EACpC,6CAA6C;AAC/C;;AAEA;EACE,gBAAgB;EAChB,8BAA8B;EAC9B,kBAAkB;EAClB,qBAAqB;AACvB","sourcesContent":["._dashed_line {\n  width: 3px;\n  height: 16px;\n  margin-left: 24px;\n  background-color: var(--lighterGray);\n  border-left: 1px dashed var(--verySoftPeanut);\n}\n\n.dayLabel {\n  padding: 2px 8px;\n  background-color: var(--white);\n  border-radius: 4px;\n  display: inline-block;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"_dashed_line": "stepCardList-module___dashed_line___u0VM2",
	"dayLabel": "stepCardList-module__dayLabel___6nm6A"
};
export default ___CSS_LOADER_EXPORT___;
