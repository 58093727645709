// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".cadenceFeedbackStep-module__content___41xKP {\n  display: flex;\n  align-items: center;\n  flex-direction: column;\n}\n\n.cadenceFeedbackStep-module__content___41xKP > svg {\n  margin-bottom: 26px;\n}\n\n.cadenceFeedbackStep-module__content___41xKP > p {\n  margin-bottom: 16px;\n  text-align: center;\n}\n\n.cadenceFeedbackStep-module__footer___eyLoY {\n  justify-content: flex-end;\n}\n", "",{"version":3,"sources":["webpack://./src/js/components/cadenceControlModal/cadenceFeedbackStep/cadenceFeedbackStep.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,sBAAsB;AACxB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,mBAAmB;EACnB,kBAAkB;AACpB;;AAEA;EACE,yBAAyB;AAC3B","sourcesContent":[".content {\n  display: flex;\n  align-items: center;\n  flex-direction: column;\n}\n\n.content > svg {\n  margin-bottom: 26px;\n}\n\n.content > p {\n  margin-bottom: 16px;\n  text-align: center;\n}\n\n.footer {\n  justify-content: flex-end;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"content": "cadenceFeedbackStep-module__content___41xKP",
	"footer": "cadenceFeedbackStep-module__footer___eyLoY"
};
export default ___CSS_LOADER_EXPORT___;
