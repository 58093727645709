import React, { useState } from 'react';
import { Icon, IconType, ColorType, Text, Select, Item, Tooltip } from '@bloobirds-it/flamingo-ui';
import styles from './contentBlock.module.css';

interface SelectorOption {
  id: string;
  value: string;
}

export const ContentBlock = React.forwardRef(
  (
    {
      children,
      title,
      titleExtra,
      iconName,
      iconColor,
      minHeight,
      selectorOptions,
      defaultSelectorOption,
      onChange,
      draggable,
      blockEnum,
      helperText,
      rightContent,
      ...props
    }: {
      title: string;
      draggable?: boolean;
      iconName?: IconType;
      iconColor?: ColorType;
      minHeight?: string;
      children: any;
      selectorOptions?: SelectorOption[];
      onChange?: (blockEnum: string, value: string) => void;
      defaultSelectorOption?: string;
      blockEnum?: string;
      helperText?: string;
      rightContent?: any;
      titleExtra?: any;
    },
    ref,
  ) => {
    const defaultValue = selectorOptions
      ?.filter(x => x)
      ?.find(option => option.id === defaultSelectorOption);
    const [selectedValue, setSelectedValue] = useState<string>(defaultValue?.id);
    return (
      <div className={styles.container} ref={ref} {...props}>
        <div className={styles.title}>
          <div className={styles.title_text_container}>
            {iconName && (
              <Icon className={styles.icon} name={iconName} color={iconColor} size={16} />
            )}
            {title && (
              <Text size="s" color="softPeanut" className={styles.title_text}>
                {title}
              </Text>
            )}
            {titleExtra && <div>{titleExtra}</div>}
            {helperText && (
              <Tooltip title={helperText} position="top">
                <Icon name="info" color="bloobirds" size={16} />
              </Tooltip>
            )}
          </div>
          <div className={styles.selector_container}>
            {rightContent &&
              React.cloneElement(
                rightContent,
                typeof children === 'string'
                  ? {}
                  : { selectedValue: selectedValue || defaultValue?.id },
              )}
            {Array.isArray(selectorOptions) && selectorOptions?.length > 0 && (
              <Select
                size="small"
                onChange={value => {
                  setSelectedValue(value);
                  onChange(blockEnum, value);
                }}
                value={selectedValue || defaultValue?.id}
                className={styles.selector}
              >
                {selectorOptions
                  .filter(option => option)
                  .map((option: { id: string; value: string }) => (
                    <Item value={option?.id} key={option?.id}>
                      {option?.value}
                    </Item>
                  ))}
              </Select>
            )}
            {draggable && <Icon name="dragAndDrop" size={20} color="softPeanut" />}
          </div>
        </div>
        <div className={styles.content} style={{ minHeight: minHeight || '36px' }}>
          {React.cloneElement(
            children,
            typeof children === 'string'
              ? {}
              : { selectedValue: selectedValue || defaultValue?.id },
          )}
        </div>
      </div>
    );
  },
);

ContentBlock.defaultProps = {
  draggable: true,
};
