import React, { useEffect, useMemo, useState } from 'react';
import {
  Button,
  Modal,
  ModalContent,
  ModalFooter,
  ModalSection,
  Callout,
  Select,
  Item,
  Section,
  useToasts,
  Spinner,
  Checkbox,
  Tooltip,
  Icon,
} from '@bloobirds-it/flamingo-ui';
import styles from './newFieldMapping.module.css';
import { useActiveUser } from '../../../../hooks';
import SyncRuleCheckbox from './SyncRuleCheckbox/SyncRuleCheckbox';
import {
  useFullSalesEnabled,
  useInboundHubspotEnabled,
  useInboundSalesforceEnabled,
} from '../../../../hooks/useFeatureFlags';
import {
  CRM,
  CRM_DISPLAY_NAME,
  SYNC_RULE,
  TRIGGER_MAPPING_NAMES,
} from '../../../../constants/integrations';
import { RestApi } from '../../../../misc/api/rest';

const NewFieldMapping = ({
  bobjectFields,
  customMappings,
  bobjectTypes,
  customMap,
  open,
  handleOpen,
  handleRefreshMappings,
  triggerMapping,
  mapping,
  crm,
  isFetching,
  crmObjectFields,
  setOrderingCustomMap,
}) => {
  const mappedCrmFields = useMemo(
    () =>
      crmObjectFields?.map(field => (
        <Item key={field.name} label={field.label} value={field.name}>
          {field.label}
        </Item>
      )),
    [crmObjectFields],
  );
  const triggerMappingName = mapping?.name;
  const isActiveHubspotInbound = useInboundHubspotEnabled();
  const isActiveSalesforceInbound = useInboundSalesforceEnabled();
  const salesFeatureEnabled = useFullSalesEnabled();
  const [crmFieldName, setCrmFieldName] = useState(customMap && customMap.keyName);
  const [crmUser, setCrmUser] = useState(customMap?.crmUserId);
  const [existMappingCRM, setExistMappingCRM] = useState(false);
  const [existMappingBB, setExistMappingBB] = useState(false);

  const [selectValue, setSelectValue] = useState(customMap && customMap.bobjectField);
  const [nextStep, setNextStep] = useState(false);
  const [syncRule, setSyncRule] = useState('');

  useEffect(() => {
    setSyncRule(triggerMappingName === 'HUBSPOT_FORM_SUBMISSIONS' ? 'CRM' : customMap?.syncRule);
  }, [customMap]);

  const isHubspot = crm === CRM.HUBSPOT;

  const canRenderSyncRule =
    (isHubspot && isActiveHubspotInbound) || (!isHubspot && isActiveSalesforceInbound);

  const disabledNextStep = !selectValue || !crmFieldName;

  const handleClose = () => {
    setCrmFieldName('');
    setSelectValue('');
    setNextStep(false);
    handleOpen(false);
  };
  const handleSecondButton = () => {
    if (nextStep) {
      setNextStep(false);
    } else {
      handleClose();
    }
  };
  const modalSectionTitle = `Map Bloobirds field to ${CRM_DISPLAY_NAME[crm]} field`;
  const crmPlaceHolder = `${CRM_DISPLAY_NAME[crm]} field`;
  const { activeAccount } = useActiveUser();
  const { createToast } = useToasts();
  const title = customMap ? 'Edit field mapping' : 'Create field mapping';
  const editing = !!customMap;
  const createItems = (field, sectionName) => {
    const bobjectField = field[1];
    const bobjectFieldId = field[0];
    return (
      <Item
        key={bobjectFieldId}
        label={bobjectField.name}
        value={bobjectFieldId}
        section={sectionName}
      >
        {bobjectField.name}
      </Item>
    );
  };

  useEffect(() => {
    setCrmFieldName(customMap && customMap.keyName);
    setSelectValue(customMap && customMap.bobjectField);
    setCrmUser(customMap && customMap.crmUserId);
    setExistMappingCRM(false);
    setExistMappingBB(false);
  }, [customMap, open]);

  const leadFields = useMemo(
    () =>
      Object.entries(bobjectFields)
        ?.filter(bobjectField => bobjectField[1].bobjectType === bobjectTypes.Lead)
        .sort((a, b) => (a[1].name > b[1].name ? 1 : -1))
        .map(bobjectField => createItems(bobjectField, 'lead-fields')),
    [bobjectFields],
  );
  const companyFields = useMemo(
    () =>
      Object.entries(bobjectFields)
        ?.filter(bobjectField => bobjectField[1].bobjectType === bobjectTypes.Company)
        .sort((a, b) => (a[1].name > b[1].name ? 1 : -1))
        .map(bobjectField => createItems(bobjectField, 'company-fields')),
    [bobjectFields],
  );
  const activityFields = useMemo(
    () =>
      Object.entries(bobjectFields)
        ?.filter(bobjectField => bobjectField[1].bobjectType === bobjectTypes.Activity)
        .sort((a, b) => (a[1].name > b[1].name ? 1 : -1))
        .map(bobjectField => createItems(bobjectField, 'activity-fields')),
    [bobjectFields],
  );
  const opportunityFields = useMemo(
    () =>
      salesFeatureEnabled &&
      Object.entries(bobjectFields)
        ?.filter(bobjectField => bobjectField[1].bobjectType === bobjectTypes.Opportunity)
        .sort((a, b) => (a[1].name > b[1].name ? 1 : -1))
        .map(bobjectField => createItems(bobjectField, 'opportunity-fields')),
    [bobjectFields, salesFeatureEnabled],
  );
  const showLeadFields = mapping.bobjectType !== 'Company';
  const showActivityFields =
    mapping.bobjectType === 'Activity' || (mapping.bobjectType === 'Deal' && !salesFeatureEnabled);

  const showOpportunityFields =
    (mapping.bobjectType === 'Opportunity' || mapping.bobjectType === 'Deal') &&
    salesFeatureEnabled;
  const handleOnSubmit = () => {
    const crmField = crmObjectFields?.find(crmObjectField => crmObjectField.name === crmFieldName);
    if (customMap) {
      RestApi.patch({
        entity: 'customMappings',
        id: customMap.id,
        body: {
          account: `/accounts/${activeAccount.id}`,
          triggerMapping: `/customMappings/${customMap.triggerMapping}`,
          bobjectField: `/bobjectFields/${selectValue}`,
          keyName: crmFieldName,
          maxLength: crmField?.length,
          crmFieldType: crmField?.type,
          crmUserId: crmUser,
          syncRule,
        },
      })
        .then(() => {
          handleRefreshMappings(true);
          createToast({ message: 'Field mapping updated!', type: 'success' });
          setOrderingCustomMap({
            value: true,
            column: 'updateDatetime',
          });
        })
        .catch(() => {
          createToast({
            type: 'error',
            message: 'There was an error updating your field mapping!',
          });
        });
    } else {
      RestApi.create({
        entity: 'customMappings',
        body: {
          account: `/accounts/${activeAccount.id}`,
          triggerMapping: `/customMappings/${triggerMapping}`,
          bobjectField: `/bobjectFields/${selectValue}`,
          keyName: crmFieldName,
          maxLength: crmField?.length,
          crmFieldType: crmField?.type,
          crmUserId: crmUser,
          syncRule,
        },
      })
        .then(() => {
          handleRefreshMappings(true);
          createToast({ message: 'New field mapping created!', type: 'success' });
          setOrderingCustomMap({
            value: false,
            column: 'creationDatetime',
          });
        })
        .catch(() =>
          createToast({
            type: 'error',
            message: 'There was an error creating your field mapping!',
          }),
        );
    }
    handleClose();
  };
  const handleCrmField = value => {
    const crmName = customMappings.filter(customMapping => customMapping.keyName === value);
    if (crmName.length > 0) {
      if (!editing || value !== customMap.keyName) setExistMappingCRM(true);
    } else {
      setExistMappingCRM(false);
    }

    setCrmFieldName(value);
  };
  const handleSelectChange = bloobirdsField => {
    const salesforceName = customMappings.filter(
      customMapping => customMapping.bobjectField === bloobirdsField,
    );
    if (salesforceName.length > 0) {
      if (!editing || bloobirdsField !== customMap.bobjectField) setExistMappingBB(true);
    } else {
      setExistMappingBB(false);
    }
    setSelectValue(undefined);
    setSelectValue(bloobirdsField);
  };

  const syncRules = {
    BLOOBIRDS: {
      title: 'Always use Bloobirds',
      description: `${CRM_DISPLAY_NAME[crm]} will always be updated with the most recent value from Bloobirds.`,
    },
    CRM: {
      title: `Always use ${CRM_DISPLAY_NAME[crm]}`,
      description: `Bloobirds will always be updated with the most recent value from ${crm}.`,
    },
    BOTH: {
      title: 'Two-way',
      description: `Both Bloobirds and ${CRM_DISPLAY_NAME[crm]} will always use the most recent value available.`,
    },
    NO_SYNC: {
      title: "Don't sync",
      description: `Changes to this field in either Bloobirds or ${CRM_DISPLAY_NAME[crm]} will not be updated in the other system.`,
    },
  };

  const bobjectType = Object.keys(bobjectTypes).find(
    key => bobjectTypes[key] === bobjectFields[selectValue]?.bobjectType,
  );

  const mappingBobjectType =
    mapping.bobjectType === 'Deal' && salesFeatureEnabled ? 'Opportunity' : 'Activity';

  const crossObjectRule = rule =>
    triggerMappingName !== TRIGGER_MAPPING_NAMES.HUBSPOT_FORM_SUBMISSIONS &&
    triggerMappingName !== TRIGGER_MAPPING_NAMES.LEAD_COMPANY__SALESFORCE &&
    bobjectType !== (mapping.bobjectType === 'Deal' ? mappingBobjectType : mapping.bobjectType) &&
    (rule === SYNC_RULE.CRM || rule === SYNC_RULE.BOTH);

  const exceptionToCrossObject = rule =>
    (triggerMappingName === TRIGGER_MAPPING_NAMES.HUBSPOT_FORM_SUBMISSIONS ||
      triggerMappingName === TRIGGER_MAPPING_NAMES.LEAD_COMPANY__SALESFORCE) &&
    rule !== SYNC_RULE.CRM &&
    rule !== SYNC_RULE.NO_SYNC;

  const disableSyncRule = rule => exceptionToCrossObject(rule) || crossObjectRule(rule);

  return (
    <>
      <Modal title={title} open={open} onClose={handleSecondButton}>
        <ModalContent>
          <ModalSection title={modalSectionTitle} icon="settings">
            <div className={styles._modal_section}>
              {bobjectFields && (
                <Select
                  placeholder="Bloobirds field"
                  autocomplete
                  value={selectValue || ''}
                  onChange={value => {
                    handleSelectChange(value);
                  }}
                  disabled={nextStep}
                  width="100%"
                >
                  <Section id="company-fields">
                    <div className={styles._section}>Company fields</div>
                  </Section>
                  {companyFields}
                  <Section id="lead-fields">
                    {showLeadFields && <div className={styles._section}>Lead fields</div>}
                  </Section>
                  {showLeadFields && leadFields}
                  <Section id="activity-fields">
                    {showActivityFields && <div className={styles._section}>Activity fields</div>}
                  </Section>
                  {showActivityFields && activityFields}
                  <Section id="opportunity-fields">
                    {showOpportunityFields && (
                      <div className={styles._section}>Opportunity fields</div>
                    )}
                  </Section>
                  {showOpportunityFields && opportunityFields}
                </Select>
              )}
              <div className={styles._modal_section_text}>
                <svg className={styles._modal_section_svg}>
                  <g>
                    <path d="M5 10 l250 0" />
                  </g>
                </svg>
              </div>
              {/* eslint-disable-next-line no-nested-ternary */}
              {!isFetching ? (
                <Select
                  placeholder={crmPlaceHolder}
                  onChange={handleCrmField}
                  value={crmFieldName}
                  disabled={nextStep}
                  width="100%"
                  autocomplete
                >
                  {mappedCrmFields}
                </Select>
              ) : (
                <Spinner name="loadingCircle" />
              )}
            </div>
            <div>
              <Checkbox size="small" checked={crmUser} onClick={() => setCrmUser(!crmUser)}>
                User mapping
                <Tooltip
                  title={`Use this mappings when you want to translate a Bloobirds user into a ${CRM_DISPLAY_NAME[crm]} user.`}
                  position="top"
                >
                  <Icon name="infoFilled" size={24} />
                </Tooltip>
              </Checkbox>
            </div>
            {(existMappingCRM || existMappingBB) && (
              <div className={styles._callout}>
                <Callout variant="alert">
                  {existMappingBB && 'Bloobirds field is already mapped. '}
                  {existMappingCRM && `${CRM_DISPLAY_NAME[crm]} field is already mapped.`}
                </Callout>
              </div>
            )}
          </ModalSection>
          {nextStep && canRenderSyncRule && (
            <ModalSection title="Select the sync rule" icon="arrowRight">
              <div className={styles._modal_section_rule}>
                {Object.keys(syncRules).map((rule, index) => (
                  <SyncRuleCheckbox
                    key={`rule-${index}`}
                    title={syncRules[rule].title}
                    description={syncRules[rule].description}
                    checked={rule === syncRule}
                    onChecked={() => setSyncRule(rule)}
                    disabled={disableSyncRule(rule)}
                  />
                ))}
              </div>
            </ModalSection>
          )}
        </ModalContent>
        <ModalFooter>
          <Button variant="clear" color="bloobirds" onClick={handleSecondButton}>
            {nextStep ? 'Back' : 'Cancel'}
          </Button>
          {!nextStep && canRenderSyncRule && (
            <Button
              onClick={() => setNextStep(true)}
              disabled={disabledNextStep || existMappingCRM || existMappingBB}
            >
              Next step
            </Button>
          )}
          {(nextStep ||
            (isHubspot && !isActiveHubspotInbound) ||
            (!isHubspot && !isActiveSalesforceInbound)) && (
            <Button
              disabled={disabledNextStep || existMappingCRM || existMappingBB}
              onClick={handleOnSubmit}
            >
              Confirm
            </Button>
          )}
        </ModalFooter>
      </Modal>
    </>
  );
};
export default NewFieldMapping;
