// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".noteAndQQ-module___content__wrapper___HnaPQ {\n  display: flex;\n  align-items: end;\n}\n\n.noteAndQQ-module___textarea__wrapper___dIx25 {\n  margin-bottom: 72px;\n  width: 100%;\n}\n\n.noteAndQQ-module___content__wrapper___HnaPQ > section:nth-child(1) {\n  min-width: 290px;\n  margin-right: 60px;\n}\n\n.noteAndQQ-module___content__wrapper___HnaPQ > section:nth-child(2) {\n  min-width: 400px;\n}\n\n.noteAndQQ-module___section__wrapper___Wfn07 {\n  max-height: 384px;\n  min-height: 370px;\n  overflow-y: auto;\n  overflow-x: hidden;\n}\n\n.noteAndQQ-module___section__wrapper___Wfn07 > *:first-child > header {\n  margin-top: 0;\n}\n\n.noteAndQQ-module___full_section___dwOFl {\n  width: 100%;\n}\n", "",{"version":3,"sources":["webpack://./src/js/components/contactFlowModal/noteAndQQ/noteAndQQ.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,gBAAgB;AAClB;;AAEA;EACE,mBAAmB;EACnB,WAAW;AACb;;AAEA;EACE,gBAAgB;EAChB,kBAAkB;AACpB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,iBAAiB;EACjB,iBAAiB;EACjB,gBAAgB;EAChB,kBAAkB;AACpB;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,WAAW;AACb","sourcesContent":["._content__wrapper {\n  display: flex;\n  align-items: end;\n}\n\n._textarea__wrapper {\n  margin-bottom: 72px;\n  width: 100%;\n}\n\n._content__wrapper > section:nth-child(1) {\n  min-width: 290px;\n  margin-right: 60px;\n}\n\n._content__wrapper > section:nth-child(2) {\n  min-width: 400px;\n}\n\n._section__wrapper {\n  max-height: 384px;\n  min-height: 370px;\n  overflow-y: auto;\n  overflow-x: hidden;\n}\n\n._section__wrapper > *:first-child > header {\n  margin-top: 0;\n}\n\n._full_section {\n  width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"_content__wrapper": "noteAndQQ-module___content__wrapper___HnaPQ",
	"_textarea__wrapper": "noteAndQQ-module___textarea__wrapper___dIx25",
	"_section__wrapper": "noteAndQQ-module___section__wrapper___Wfn07",
	"_full_section": "noteAndQQ-module___full_section___dwOFl"
};
export default ___CSS_LOADER_EXPORT___;
