import React, { useState } from 'react';
import {
  AccountSettingsTab,
  AccountSettingsTabContent,
  AccountSettingsTabHeader,
  AccountSettingsTabHeaderLeft,
  AccountSettingsTabHeaderRight,
  AccountSettingsTableContainer,
  AccountSettingsTabSubtitle,
  AccountSettingsTabTitle,
} from '../../../../layouts/accountSettingsLayout/accountSettingsTab/accountSettingsTab';
import { Button, SearchInput } from '@bloobirds-it/flamingo-ui';
import { useOpenScenarioModal } from '../../../../hooks/useSteppableModal';
import { ScenariosList } from './scenariosList/scenariosList';
import { ScenarioModal } from './scenarioModal/scenarioModal';
import useMediaQuery from '../../../../hooks/useMediaQuery';

const ScenarioTab = () => {
  const {
    handleOpenCreateEditModal,
    modalOpen,
    handleCloseTargetMarketModal,
    isCreation,
  } = useOpenScenarioModal();
  const [searchValue, setSearchValue] = useState();
  const { isSmallDesktop } = useMediaQuery();

  return (
    <AccountSettingsTab>
      <>
        <AccountSettingsTabHeader>
          <AccountSettingsTabHeaderLeft>
            <AccountSettingsTabTitle icon="company" color="purple">
              Scenarios
            </AccountSettingsTabTitle>
            <AccountSettingsTabSubtitle>
              Scenarios are different use cases for your solution depending on the current problem
              encountered daily by your client. Specifically, it’s their pain point, and your goal
              is to make them aware of both the hurt and thefix. Pain points are dependent on the
              maturity of the client company{' '}
            </AccountSettingsTabSubtitle>
          </AccountSettingsTabHeaderLeft>
          <AccountSettingsTabHeaderRight>
            <SearchInput
              width={200}
              placeholder="Search"
              onChange={value => setSearchValue(value)}
            />
            <Button
              iconLeft="plus"
              onClick={() => handleOpenCreateEditModal({ isCreationType: true })}
              color="purple"
            >
              {!isSmallDesktop && 'New Scenario'}
            </Button>
          </AccountSettingsTabHeaderRight>
        </AccountSettingsTabHeader>
        <AccountSettingsTabContent>
          <AccountSettingsTableContainer>
            <ScenariosList searchValue={searchValue} />
          </AccountSettingsTableContainer>
        </AccountSettingsTabContent>
        {modalOpen && (
          <ScenarioModal handleClose={handleCloseTargetMarketModal} isCreation={isCreation} />
        )}
      </>
    </AccountSettingsTab>
  );
};

export default ScenarioTab;
