import { Checkbox, Text } from '@bloobirds-it/flamingo-ui';
import styles from '../../../styles/generalSettingsPage.module.css';
import React from 'react';
import {
  AccountSettingsTabContent,
  AccountSettingsTab,
} from '../../../../../../layouts/accountSettingsLayout/accountSettingsTab/accountSettingsTab';
import {
  AccountSettingsSection,
  AccountSettingsSectionContent,
  AccountSettingsSectionSubtitle,
  AccountSettingsSectionTitle,
} from '../../../../../../layouts/accountSettingsLayout/accountSettingsSection/accountSettingsSection';
import { Controller } from 'react-hook-form';

export const InformationRequired = () => (
  <AccountSettingsTab>
    <AccountSettingsTabContent>
      <AccountSettingsSection>
        <AccountSettingsSectionTitle>Information required</AccountSettingsSectionTitle>
        <AccountSettingsSectionSubtitle>
          To create a meeting the user must have filled in all the required company and lead
          information.
        </AccountSettingsSectionSubtitle>
        <AccountSettingsSectionContent>
          <div className={styles._checkbox_wrapper}>
            <Controller
              name="meetingFieldsRequiredEnabled"
              render={({ onChange, value }) => (
                <Checkbox onClick={onChange} checked={value}>
                  <div>
                    <Text size="s">
                      Make it mandatory to fill in information to create meetings
                    </Text>
                  </div>
                </Checkbox>
              )}
            />
          </div>
        </AccountSettingsSectionContent>
      </AccountSettingsSection>
    </AccountSettingsTabContent>
  </AccountSettingsTab>
);
