import { IconButton, Item, Select, Text } from '@bloobirds-it/flamingo-ui';
import React, { useEffect, useMemo, useState } from 'react';
import mixpanel from 'mixpanel-browser';
import styles from '../../homePage.module.css';
import { useAppCalendarVisibility } from '../../../../hooks/useUserTasks';
import { useMediaQuery } from '../../../../hooks';
import { FilterDropdown } from './components/filterDropdown';
import { StartTask } from './components/startTask';
import { TaskList } from './components/taskList';
import { defaultFilters, Filters, isStatus, Stages } from './utils/utils';
import { useUserHomepageSettings } from '../../hooks/useUserHomepageSettings';
import { ConfigType, UserHomeConfig } from '../../typings/home';
import { MIXPANEL_EVENTS } from '../../../../constants/mixpanel';
import { getAvailableUserConfig, getUserSettingConfigType } from '../../utils/homepage';
import { TabBarTooltip } from '../../../../components/discoveryTooltips/welcomeTooltips/tabBarTooltip';

const LeftContent = () => {
  const { openAppCalendarWithFilters } = useAppCalendarVisibility();
  const { isDesktop } = useMediaQuery();
  const { availableSettings, userSettings, updateHomeSettings } = useUserHomepageSettings();
  // TODO: update App Calendar to use Sales Conversion

  const [selectedStage, setSelectedStage] = useState<Stages>('ALL');
  const [selectedFilters, setSelectedFilters] = useState(defaultFilters);
  const [isAnyFilterSelected, setIsAnyFilterSelected] = useState(false);

  /*** save config to BE ***/
  const availableStages = useMemo(
    () => getAvailableUserConfig(availableSettings, ConfigType.TASKS_SECTION_SELECTOR),
    [availableSettings],
  );
  const userStageConfig = useMemo(
    () => getUserSettingConfigType(userSettings, ConfigType.TASKS_SECTION_SELECTOR),
    [userSettings],
  );
  useEffect(() => {
    if (userStageConfig) {
      const prospect = userStageConfig.find(stage => stage.enumName === 'PROSPECTING')?.enabled;
      const sales = userStageConfig.find(stage => stage.enumName === 'SALES')?.enabled;
      if ((prospect && sales) || (!prospect && !sales)) {
        if (selectedStage !== 'ALL') setSelectedStage('ALL');
      } else if (prospect) {
        if (selectedStage !== 'PROSPECT') setSelectedStage('PROSPECT');
      } else if (sales) {
        if (selectedStage !== 'SALES') setSelectedStage('SALES');
      }
    }
  }, [userStageConfig]);

  const updateSelector = (stage: Stages) => {
    let config: UserHomeConfig[];
    if (stage === 'ALL') {
      config = availableStages.map(availableStage => {
        return { ...availableStage, enabled: true };
      });
    } else if (stage === 'SALES') {
      config = availableStages.map(availableStage => {
        if (availableStage.enumName === 'SALES') {
          return { ...availableStage, enabled: true };
        } else return { ...availableStage, enabled: false };
      });
    } else {
      config = availableStages.map(availableStage => {
        if (availableStage.enumName === 'PROSPECTING') {
          return { ...availableStage, enabled: true };
        } else return { ...availableStage, enabled: false };
      });
    }
    updateHomeSettings(config, ConfigType.TASKS_SECTION_SELECTOR);
  };

  /*** local from here on ***/

  const getTaskTypes = () => {
    return selectedFilters.PROSPECT.concat(selectedFilters.SALES);
  };

  const updateStage = (stage: Stages) => {
    mixpanel.track(MIXPANEL_EVENTS.HOME_TASKS_SELECTOR_CHANGED);
    setSelectedStage(stage);
    updateSelector(stage);
  };

  const updateFilters = (filters: Filters) => {
    setSelectedFilters(filters);
    if (selectedStage === 'ALL') {
      setIsAnyFilterSelected(
        !!filters.PROSPECT.find(f => !isStatus(f.enumName) && f.enabled) ||
          !!filters.SALES.find(f => !isStatus(f.enumName) && f.enabled),
      );
    } else {
      setIsAnyFilterSelected(
        !!filters[selectedStage].find(f => !isStatus(f.enumName) && f.enabled),
      );
    }
  };

  return (
    <div className={styles.leftContent__container}>
      <div className={styles.column__header}>
        <div className={styles.column__title}>
          <TabBarTooltip defaultTooltipVisible />
          <Text size="m" color="softPeanut" className={styles.title_text}>
            {isDesktop ? 'Today' : 'Today tasks'}
          </Text>
          <Select size="small" onChange={updateStage} value={selectedStage}>
            <Item value="ALL" key="home-left-stage-all">
              All
            </Item>
            <Item value="PROSPECT" key="home-left-stage-prospecting">
              Prospecting
            </Item>
            <Item value="SALES" key="home-left-stage-sales">
              Sales
            </Item>
          </Select>
          <div className={styles.sliderRotate}>
            <FilterDropdown stage={selectedStage} updateFilters={updateFilters} />
          </div>
          <IconButton
            name="chevronOpen"
            onClick={() =>
              openAppCalendarWithFilters(
                selectedStage === 'SALES' ? 'SALES' : 'PROSPECTING',
                getTaskTypes(),
              )
            }
            className={styles.chevron}
            color="bloobirds"
            size={20}
          />
        </div>
        <div className={styles.column__title}>
          <StartTask isSales={selectedStage === 'SALES'} disabled={!isAnyFilterSelected} />
        </div>
      </div>
      <div className={styles.task_list}>
        <TaskList stage={selectedStage} taskFilters={selectedFilters} />
      </div>
    </div>
  );
};

export default LeftContent;
