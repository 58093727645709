import { Button, Icon, IconType, Text, Tooltip } from '@bloobirds-it/flamingo-ui';
import { top } from '@popperjs/core';
import React from 'react';
import clsx from 'clsx';
import { clone } from 'lodash';
import {
  defaultSearchCompany,
  SearchBobjectCompany,
  SearchBobjectType,
  StrDict,
} from '@bloobirds-it/types';
import styles from '../generalSearchBar.module.css';
import { generateDatePrefix } from '../../../utils/dates.utils';
import { StatusLabel } from '../../statusLabel/statusLabel';
import { bobjectUrl } from '../../../app/_constants/routes';
import { getName, getSubtitle } from '../searchBar.utils';
import { useBobjectDetails, useEntity } from '../../../hooks';
import { BobjectType } from '../../../typings/bobjects';
import { BobjectTypes } from '../../../constants/bobject';
import { AssigneeComponent } from '../../../pages/subhomePages/components/subhomeCards/fieldTypeComponent';

const ICONS: Partial<Record<BobjectType, IconType>> = {
  Lead: 'person',
  Company: 'company',
  Opportunity: 'fileOpportunity',
};

function resolveHit(hits: { [key: string]: string }, hitByName: string) {
  const obj = clone(hits);
  if (obj && obj[hitByName]) {
    delete obj[hitByName];
    return Object.values(obj)[0];
  }
  return Object.values(obj)[0];
}

interface CardLeftComponentProps {
  bobject: SearchBobjectType;
  hits: StrDict;
  handleCompanyClicked: (bobject: SearchBobjectCompany, event: MouseEvent) => void;
  compressed?: boolean;
}

function CardLeftComponent({
  bobject,
  hits,
  handleCompanyClicked,
  compressed = false,
}: CardLeftComponentProps) {
  const bobjectFields = useEntity('bobjectFields');

  const type = bobject.bobjectType;
  const { name, hitByName } = getName(bobjectFields, bobject, hits);
  const subtitle = getSubtitle(bobject);
  const companyName = type !== 'Company' && bobject.companyName;
  const companyId = type !== 'Company' && bobject.companyId;
  const companyWebsite = type !== 'Company' && bobject.companyWebsite;

  const contentClassNames = clsx(styles.bobjectItemContent, {
    [styles.bobjectItemContentCompressed]: compressed,
    [styles.bobjectItemContentNotCompressed]: !compressed,
  });

  const spanNameClassNames = clsx(styles.bobjectItemContentSpan, {
    [styles.bobjectItemNameSpanCompressed]: compressed,
    [styles.bobjectItemNameSpan]: !compressed,
  });

  const spanHitClassNames = clsx(styles.bobjectItemContentSpan, {
    [styles.bobjectItemContentInfoPartSpace]: !!companyName,
    [styles.bobjectItemNameSpan]: !companyName,
  });

  const companyIconClassNames = clsx(styles.bobjectItemContentInfoRowSeparator, {
    [styles.bobjectItemContentInfoCompanyIconWithSubtitle]: !!subtitle,
    [styles.bobjectItemContentInfoCompanyIcon]: !subtitle,
  });

  const companyNameClassNames = clsx(styles.bobjectItemContentSpan, {
    [styles.bobjectItemContentInfoPartSpace]: !!subtitle,
    [styles.bobjectItemContentInfoAlone]: !subtitle,
  });

  const firstHit = hits && resolveHit(hits, hitByName);

  function handleCompanyClick(event: React.MouseEvent<HTMLDivElement, MouseEvent>) {
    const url = bobjectUrl({
      id: { typeName: companyId.split('/')[1], objectId: companyId.split('/')[2] },
    });
    const company: SearchBobjectCompany = {
      ...defaultSearchCompany,
      rawBobject: { ...defaultSearchCompany.rawBobject, id: companyId },
      bobjectType: BobjectTypes.Company,
      companyName,
      url,
      website: companyWebsite,
    };
    // @ts-ignore
    handleCompanyClicked(company, event);
    event.stopPropagation();
  }

  return (
    <>
      <div className={styles.circleIcon}>
        <Icon name={ICONS[type as BobjectTypes]} size={20} color="bloobirds" />
      </div>
      <div className={contentClassNames}>
        <div className={styles.bobjectItemName}>
          <Text size="s" color="bloobirds" className={spanNameClassNames}>
            <span dangerouslySetInnerHTML={{ __html: name }} />
          </Text>
        </div>
        <div className={styles.bobjectItemContentInfoRow}>
          <Text size="xs" color="softPeanut" className={spanHitClassNames}>
            {firstHit ? <span dangerouslySetInnerHTML={{ __html: firstHit }} /> : subtitle}
          </Text>
          {companyName && (
            <div className={companyIconClassNames}>
              {(firstHit || subtitle) && <Icon name={'circle'} size={15} color={'softPeanut'} />}
              <Icon name={'company'} size={15} color={'bloobirds'} />
            </div>
          )}
          <div onClick={event => handleCompanyClick(event)} style={{ cursor: 'pointer' }}>
            <Text size="xs" color="bloobirds" className={companyNameClassNames}>
              {companyName}
            </Text>
          </div>
        </div>
      </div>
    </>
  );
}

function CardCenterComponent({ bobject }: { bobject: SearchBobjectType }) {
  return (
    <div className={styles.bobjectItemContentCenter}>
      <div className={styles.bobjectItemContentCenterColumn}>
        <AssigneeComponent value={{ value: bobject.assignedTo }} />
      </div>
      <div className={styles.bobjectItemContentCenterColumn}>
        <div className={styles.bobjectItemContentCenterRow}>
          <Tooltip title={'Attempts'} position={top}>
            <Icon name={'check'} size={15} color={'softPeanut'} />
            <Text size="xs" color="softPeanut">
              {bobject.attempts}
            </Text>
          </Tooltip>
        </div>
        <div className={styles.bobjectItemContentCenterRow}>
          <Tooltip title={'Touches'} position={top}>
            <Icon name={'checkDouble'} size={15} color={'bloobirds'} />
            <Text size="xs" color="softPeanut">
              {bobject.touches}
            </Text>
          </Tooltip>
        </div>
      </div>
      <div className={styles.bobjectItemContentCenterColumn}>
        <div className={styles.bobjectItemContentCenterRow}>
          <Tooltip title={'Last Attempt'} position={top}>
            <Icon name={'calendar'} size={15} color={'softPeanut'} />
            <Text size="xs" color="softPeanut">
              {generateDatePrefix(
                bobject.lastAttemptDate && new Date(bobject.lastAttemptDate),
                true,
              )}
            </Text>
          </Tooltip>
        </div>
        <div className={styles.bobjectItemContentCenterRow}>
          <Tooltip title={'Last Touch'} position={top}>
            <Icon name={'calendar'} size={15} color={'softPeanut'} />
            <Text size="xs" color="softPeanut">
              {generateDatePrefix(bobject.lastTouchDate && new Date(bobject.lastTouchDate), true)}
            </Text>
          </Tooltip>
        </div>
      </div>
    </div>
  );
}

interface StatusI {
  value: string;
  textColor: string;
  backgroundColor: string;
}

export function SearchStatusLabel({ status }: { status: StatusI }) {
  return status ? (
    <StatusLabel
      name={status.value}
      textColor={status.textColor}
      backgroundColor={status.backgroundColor}
      maxWidth="200px"
    />
  ) : null;
}

interface PreviewI {
  isSelected: boolean;
  bobject: SearchBobjectType;
}

export function SearchPreviewButton({ isSelected, bobject }: PreviewI) {
  const { openBobjectDetails } = useBobjectDetails();
  return isSelected ? (
    <div className={styles.bobjectItem_preview_button_wrapper}>
      <Button
        dataTest="SearchBar-Preview"
        variant="secondary"
        uppercase={false}
        size="small"
        className={styles.bobjectItem_preview_button}
        onClick={event => {
          openBobjectDetails({ id: bobject.rawBobject.id });
          event.stopPropagation();
        }}
      >
        Preview
      </Button>
    </div>
  ) : null;
}

export const SearchCardCenter = React.memo(CardCenterComponent);
export const SearchCardLeft = React.memo(CardLeftComponent);
