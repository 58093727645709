import React, { useState } from 'react';
import {
  Button,
  Label,
  Modal,
  ModalContent,
  ModalFooter,
  ModalSection,
  Text,
  useToasts,
} from '@bloobirds-it/flamingo-ui';
import { useActivity, useMeetingResult } from '../../../../../../hooks';
import { ACTIVITY_TYPES } from '../../../../../../constants/activity';
import styles from './meetingResultModal.module.css';
import {
  getNameFieldLRFromBobjectType,
  getReferencedBobject,
  getTextFromLogicRole,
} from '@bloobirds-it/utils';
import { BobjectPicklistValueEntity } from '../../../../../../typings/entities.js';

const MeetingResultModal = () => {
  const { closeMeetingResult, activity, meetingResults } = useMeetingResult();
  const [selectedMeetingResult, setSelectedMeetingResult] = useState<string>();
  const { createToast } = useToasts();
  const referencedBobject = getReferencedBobject(activity);
  const referencedBobjectName = getTextFromLogicRole(
    referencedBobject,
    getNameFieldLRFromBobjectType(referencedBobject?.id?.typeName),
  );
  const { reportedActivityResult } = useActivity('meetingResult');
  const disabledButton = !selectedMeetingResult;

  const saveMeetingResult = () => {
    reportedActivityResult({
      valueLogicRole: selectedMeetingResult,
      activityId: activity?.id.objectId,
      activityType: ACTIVITY_TYPES.MEETING as keyof typeof ACTIVITY_TYPES,
    });
    closeMeetingResult();
    createToast({ type: 'success', message: 'Activity updated!' });
  };

  return (
    <Modal open title="Report meeting result" onClose={closeMeetingResult}>
      <ModalContent>
        <ModalSection
          size="l"
          title={`You just finished a meeting with ${referencedBobjectName}`}
          icon="calendar"
        >
          <div className={styles._section__wrapper}>
            <div className={styles._section_title__wrapper}>
              <Text size="m" weight="medium" color="peanut">
                What is the meeting result?*
              </Text>
            </div>
          </div>
          <div className={styles._labels__wrapper}>
            <div className={styles._label__content}>
              {meetingResults &&
                meetingResults.map((result: BobjectPicklistValueEntity) => (
                  <Label
                    dataTest={result?.value}
                    uppercase={false}
                    inline={false}
                    align="center"
                    key={`meeting-result-${result?.value}`}
                    selected={
                      selectedMeetingResult &&
                      [result?.logicRole, result.id].includes(selectedMeetingResult)
                    }
                    onClick={() => setSelectedMeetingResult(result?.logicRole || result?.id)}
                  >
                    {result?.value}
                  </Label>
                ))}
            </div>
          </div>
        </ModalSection>
      </ModalContent>
      <ModalFooter>
        <div className={styles._buttons__wrapper}>
          <Button variant="clear" onClick={closeMeetingResult}>
            Cancel
          </Button>
          <Button dataTest="Form-Save" disabled={disabledButton} onClick={saveMeetingResult}>
            SAVE AND CONTINUE
          </Button>
        </div>
      </ModalFooter>
    </Modal>
  );
};

export default MeetingResultModal;
