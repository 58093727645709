import { useMessagingTemplates } from './useMessagingTemplates';
import { TEMPLATE_TYPES } from '../utils/templates.utils';
import { BobjectType } from '../typings/bobjects';

interface UseAutomationEmailTemplateOptions {
  bobjectType?: BobjectType;
}

function useAutomationEmailTemplates({ bobjectType }: UseAutomationEmailTemplateOptions) {
  const { messagingTemplates, isLoading } = useMessagingTemplates({
    name: null,
    segmentationValues: {},
    visibility: 'PUBLIC',
    stage: bobjectType === 'Opportunity' ? 'SALES' : 'PROSPECT',
    type: TEMPLATE_TYPES.EMAIL,
    page: 0,
    size: 200,
  });

  const validTemplates = messagingTemplates
    .filter(template => template.format === 'AST')
    .filter(template => template.visibility === 'PUBLIC');

  return {
    messagingTemplates: validTemplates,
    loading: isLoading,
  };
}

export default useAutomationEmailTemplates;
