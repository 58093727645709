import React from 'react';
import { Action, ColorType, IconType } from '@bloobirds-it/flamingo-ui';
import { useHistory } from 'react-router';
import styles from '../generalSearchBar.module.css';
import { SearchActionType, SearchBobjectType } from '@bloobirds-it/types';

type handleClickType = (e: MouseEvent) => void;

/*** Action Base Button ***/
function SearchBarAction(color: ColorType, icon: IconType, handleClick: handleClickType) {
  return (
    <Action
      color={color}
      icon={icon}
      onClick={e => {
        // @ts-ignore
        handleClick(e);
        e.stopPropagation();
      }}
      key={`action-${icon}`}
    />
  );
}

/*** Action Buttons ***/
function CallAction(handleClick: handleClickType) {
  return SearchBarAction('melon', 'phone', handleClick);
}
function EmailAction(handleClick: handleClickType) {
  return SearchBarAction('tangerine', 'mail', handleClick);
}
function LinkedInAction(handleClick: handleClickType) {
  return SearchBarAction('darkBloobirds', 'linkedin', handleClick);
}
function MeetingAction(handleClick: handleClickType) {
  return SearchBarAction('tomato', 'calendar', handleClick);
}
function ReportActivityAction(handleClick: handleClickType) {
  return SearchBarAction('bloobirds', 'thumbsUp', handleClick);
}
function ChatBubbleAction(handleClick: handleClickType) {
  return SearchBarAction('bloobirds', 'chat', handleClick);
}

/*** Action Button Groups ***/
function MainBobjectActions(doAction: (action: SearchActionType, event: MouseEvent) => void) {
  return (
    <>
      {CallAction(e => doAction('call', e))}
      {EmailAction(e => doAction('email', e))}
      {LinkedInAction(e => doAction('linkedin', e))}
      {MeetingAction(e => doAction('meeting', e))}
    </>
  );
}

/*** Export General Action Buttons Component ***/
export function BobjectActions({
  bobject,
  closeModal,
}: {
  bobject: SearchBobjectType;
  closeModal: () => void;
}) {
  const history = useHistory();
  const doAction = (action: SearchActionType, event: MouseEvent) => {
    if (event.metaKey || (navigator.appVersion.indexOf('Mac') == -1 && event.ctrlKey)) {
      window.open(`${bobject.url}?action=${action}`, '_blank');
    } else {
      history.push(`${bobject.url}?action=${action}`);
    }
    closeModal();
  };

  return (
    <div className={styles.bobjectItemSelectedActionsColumn}>
      <div className={styles.bobjectItemSelectedActionsRow}>{MainBobjectActions(doAction)}</div>
    </div>
  );
}
