import { Action, Tooltip } from '@bloobirds-it/flamingo-ui';
import React, { FC, useEffect, useMemo } from 'react';
import { companyUrl, leadUrl, opportunityUrl } from '../../../app/_constants/routes';
import { useBobjectPermissions, useUserSettings } from '../../../components/userPermissions/hooks';
import { BobjectTypes } from '../../../constants/bobject';
import { COMPANY_FIELDS_LOGIC_ROLE } from '../../../constants/company';
import { LEAD_FIELDS_LOGIC_ROLE } from '../../../constants/lead';
import { OPPORTUNITY_FIELDS_LOGIC_ROLE } from '../../../constants/opportunity';
import {
  useBobjectFormCreation,
  useCadenceControl,
  useContactView,
  useDialerVisibility,
  useQueryParams,
  useRouter,
} from '../../../hooks';
import {
  MinimizableModalType,
  useMinimizableModals,
} from '../../../hooks/emails/useMinimizableModals';
import { useSelectedLead } from '../../../hooks/useSelectedLead';
import { useSelectedOpportunity } from '../../../hooks/useSelectedOpportunity';
import { useContactBobjects } from '../contactPageContext';
import { Bobject, BobjectType } from '../../../typings/bobjects';
import { getFieldByLogicRole, getValueFromLogicRole } from '../../../utils/bobjects.utils';
import styles from './leadTableActions.module.css';
import { useUserHelpers } from '../../../hooks/useUserHelpers';
import { UserHelperKeys } from '../../../constants/userHelperKeys';
import { LeadTableActionsTooltip } from '../../../components/discoveryTooltips/leadTableActionsTooltip';
import { LeadTableCallAction } from './actionComponents/callAction';
import { LeadTableMeetingAction } from './actionComponents/meetingAction';
import { LeadTableLinkedInAction } from './actionComponents/linkedInAction';
import { LeadTableMoreActions } from './actionComponents/moreActions';
import { EMAIL_MODE } from '../../../constants/email';
import { useCalendarEnabled } from '../../../hooks/useFeatureFlags';
import { LeadTableOpportunityAction } from './actionComponents/opportunityAction';

interface LeadTableActionsProps {
  bobjectType: BobjectType;
  leads?: Bobject[];
}

const LeadTableActions: FC<LeadTableActionsProps> = ({ bobjectType, leads }) => {
  const { setActiveTabs, setTab, scrollToContactTabs } = useContactView();
  const queryParams = useQueryParams();
  const { history, location } = useRouter();
  const {
    openAddActivity,
    openAddLead,
    openAddLeadWithOpportunity,
    openAddOpportunity,
  } = useBobjectFormCreation();
  const settings = useUserSettings();
  const redirectToActivity = settings?.user?.tabOnCall === 'ACTIVITIES';
  const { selectedLead, updateSelectedLead } = useSelectedLead();
  const { company } = useContactBobjects();
  const { selectedOpportunity } = useSelectedOpportunity();
  const { checkPermissions } = useBobjectPermissions();
  const { openCadenceControl } = useCadenceControl();
  const { openMinimizableModal } = useMinimizableModals();
  const { openDialer } = useDialerVisibility();
  const isCalendarEnabled = useCalendarEnabled();

  const handleOpenModal = (type: MinimizableModalType) => {
    const companyName = company
      ? getValueFromLogicRole(company, COMPANY_FIELDS_LOGIC_ROLE.NAME)
      : 'New something';
    openMinimizableModal({
      title: companyName && companyName !== '' ? companyName.slice(0, 10) : 'Untitled company',
      type,
      data: {
        company: company
          ? {
              name: getValueFromLogicRole(company, COMPANY_FIELDS_LOGIC_ROLE.NAME),
              url: companyUrl(company),
              data: company,
              stage: getFieldByLogicRole(company, COMPANY_FIELDS_LOGIC_ROLE.STAGE)?.valueLogicRole,
            }
          : undefined,
        lead: selectedLead && {
          name: getValueFromLogicRole(selectedLead, LEAD_FIELDS_LOGIC_ROLE.FULL_NAME),
          url: leadUrl(selectedLead),
          data: selectedLead,
          stage: getFieldByLogicRole(selectedLead, LEAD_FIELDS_LOGIC_ROLE.STAGE)?.valueLogicRole,
        },
        opportunity: selectedOpportunity && {
          name: getValueFromLogicRole(selectedOpportunity, OPPORTUNITY_FIELDS_LOGIC_ROLE.NAME),
          url: opportunityUrl(undefined, selectedOpportunity),
          data: selectedOpportunity,
        },
        leads,
      },
    });
  };

  const bobjectToUse = () => {
    switch (bobjectType) {
      case BobjectTypes.Company:
        return company;
      case BobjectTypes.Opportunity:
        return selectedOpportunity;
      case BobjectTypes.Lead:
        return selectedLead;
      default:
        return undefined;
    }
  };

  const hasPermissions = useMemo(() => {
    return checkPermissions(bobjectToUse());
  }, [company, selectedOpportunity, selectedLead]);

  useEffect(() => {
    if (!selectedLead) {
      if (queryParams.has('leadId')) {
        updateSelectedLead(queryParams.get('leadId'));
      }
    }
  }, [queryParams.toString()]);

  useEffect(() => {
    if (queryParams.has('action') && location.pathname.includes(bobjectToUse()?.id.objectId)) {
      switch (queryParams.get('action')) {
        case 'call':
          handleCallAction();
          handleOpenDialer();
          break;
        case 'email':
          handleMailAction();
          handleOpenMail();
          break;
        case 'linkedin':
          handleLinkedInAction();
          break;
        case 'meeting':
          handleMeetingAction();
          break;
      }
      history.push(location.pathname.split('?')[0]);
    }
  }, [
    queryParams.toString(),
    company?.id.objectId,
    selectedOpportunity?.id.objectId,
    selectedLead?.id.objectId,
  ]);

  // Action functions:
  const handleOpenDialer = () => {
    openDialer({
      company,
      leads,
      selectedLeadId: selectedLead?.id?.value,
      opportunity: selectedOpportunity,
    });
  };

  const handleCallAction = () => {
    if (setTab) {
      setActiveTabs(
        redirectToActivity
          ? {
              tab: 'Activity',
              subtab: '',
            }
          : {
              tab: 'Messaging',
              subtab: 'Pitches & Snippets',
            },
      );
    }
    scrollToContactTabs();
  };

  const handleOpenMail = () => {
    openMinimizableModal({
      type: 'email',
      title: 'New Email',
      data: {
        template: {
          body: '',
          subject: '',
          id: undefined,
          format: '',
          mediaFiles: undefined,
        },
        mode: EMAIL_MODE.SEND,
        isBlankEmail: true,
        company,
        leads,
        lead: selectedLead,
        opportunity: selectedOpportunity,
        pageBobjectType: bobjectType,
      },
    });
  };

  const handleMailAction = () => {
    setActiveTabs({ tab: 'Messaging', subtab: 'Email Templates' });
    scrollToContactTabs();
  };

  const handleLinkedInAction = async () => {
    await setActiveTabs({ tab: 'Messaging', subtab: 'Linkedin Templates' });
    scrollToContactTabs();
  };

  const handleMeetingAction = () => {
    if (isCalendarEnabled) {
      handleOpenModal('calendarMeeting');
    } else {
      handleOpenModal('meeting');
    }
  };

  const handleOpportunityAction = () => {
    openAddOpportunity({
      bobject: bobjectToUse(),
      company,
      onSuccess: openCadenceControl,
      lead: selectedLead,
      assignedTo: null,
    });
  };

  const handleLogActivity = () => {
    openAddActivity({
      lead: selectedLead,
      hasOneLead: leads?.length === 1,
      bobject: bobjectToUse(),
    });
  };

  const handleNewLead = () => {
    bobjectType === BobjectTypes.Opportunity
      ? openAddLeadWithOpportunity({ bobject: selectedOpportunity })
      : openAddLead({ bobject: company });
  };

  return (
    <div className={styles._actions__container}>
      {hasPermissions && (
        <>
          <LeadTableCallAction
            leads={leads}
            company={company}
            selectedLead={selectedLead}
            onClick={handleCallAction}
            onOpenDialerClick={handleOpenDialer}
          />
          <LeadTableMailAction onClick={handleMailAction} />
          <LeadTableLinkedInAction selectedLead={selectedLead} />
          <LeadTableTaskAction onClick={() => handleOpenModal('task')} />
          <LeadTableNoteAction onClick={() => handleOpenModal('note')} />
          <LeadTableMeetingAction
            company={company}
            lead={selectedLead}
            handleOpenModal={handleMeetingAction}
          />
          {bobjectType !== BobjectTypes.Opportunity && (
            <LeadTableOpportunityAction
              bobjectType={bobjectType}
              onAction={handleOpportunityAction}
            />
          )}
          <LeadTableMoreActions
            bobjectType={bobjectType}
            leads={leads}
            logActivity={handleLogActivity}
            newLead={handleNewLead}
          />
        </>
      )}
    </div>
  );
};

// SMALL LEAD-TABLE-ACTION COMPONENTS

const LeadTableMailAction = ({ onClick }: { onClick: () => void }) => {
  const { has } = useUserHelpers();
  return (
    <Tooltip title={has(UserHelperKeys.SEND_YOUR_FIRST_EMAIL) ? 'Email' : ''} position="top">
      <LeadTableActionsTooltip helperKey={UserHelperKeys.SEND_YOUR_FIRST_EMAIL}>
        <Action icon="mail" color="tangerine" dataTest="mailButton" onClick={onClick} />
      </LeadTableActionsTooltip>
    </Tooltip>
  );
};

const LeadTableTaskAction = ({ onClick }: { onClick: () => void }) => {
  return (
    <Tooltip title="Task" position="top" trigger="hover">
      <Action icon="taskAction" color="softBloobirds" dataTest="taskButton" onClick={onClick} />
    </Tooltip>
  );
};

const LeadTableNoteAction = ({ onClick }: { onClick: () => void }) => {
  return (
    <Tooltip title="Note" position="top" trigger="hover">
      <Action icon="noteAction" color="banana" onClick={onClick} />
    </Tooltip>
  );
};

export default LeadTableActions;
