export enum TASK_TYPE {
  ADD_LEADS_TO_QC = 'ADD_LEADS_TO_QC',
  CONTACT_BEFORE_MEETING = 'CONTACT_BEFORE_MEETING',
  MEETING = 'MEETING',
  NEXT_STEP = 'NEXT_STEP',
  PROSPECT_CADENCE = 'PROSPECT_CADENCE',
  START_CADENCE = 'START_CADENCE',
  SCHEDULED_EMAIL = 'SCHEDULED_EMAIL',
}

export enum TASK_STATUS_VALUE_LOGIC_ROLE {
  COMPLETED_OVERDUE = 'TASK__STATUS__COMPLETED_OVERDUE',
  COMPLETED = 'TASK__STATUS__COMPLETED',
  OVERDUE = 'TASK__STATUS__OVERDUE',
  REJECTED = 'TASK__STATUS__REJECTED',
  TODO = 'TASK__STATUS__TODO',
}

export const TASK_AUTOMATED_STATUS_LOGIC_ROLE = Object.freeze({
  PENDING: 'TASK__AUTOMATED_STATUS__PENDING',
  COMPLETED: 'TASK__AUTOMATED_STATUS__COMPLETED',
  FAILED: 'TASK__AUTOMATED_STATUS__FAILED',
  PAUSED: 'TASK__AUTOMATED_STATUS__PAUSED',
  RESCHEDULED: 'TASK__AUTOMATED_STATUS__RESCHEDULED',
});

export const TASK_AUTOMATED_ERROR_LOGIC_ROLE = Object.freeze({
  MISSING_EMAIL: 'TASK__AUTOMATION_ERROR__MISSING_EMAIL',
  OPT_OUT_ENABLED: 'TASK__AUTOMATION_ERROR__OPT_OUT_ENABLED',
  PAUSED_MORE_THAN_7_DAYS: 'TASK__AUTOMATION_ERROR__PAUSED_MORE_THAN_7_DAYS',
  STOPPED_ACCOUNT: 'TASK__AUTOMATION_ERROR__STOPPED_ACCOUNT',
  UNKNOWN_ERROR: 'TASK__AUTOMATION_ERROR__UNKNOWN_ERROR',
  VARIABLE_NOT_RESOLVED: 'TASK__AUTOMATION_ERROR__MISSING_VARIABLE',
  DAILY_LIMIT_EXCEEDED: 'TASK__AUTOMATION_ERROR__DAILY_LIMIT_EXCEEDED',
  INVALID_RECIPIENT: 'TASK__AUTOMATION_ERROR__INVALID_RECIPIENT',
});

export const TASK_AUTOMATED_PAUSED_REASONS_LOGIC_ROLE = Object.freeze({
  OVERDUE: 'TASK__AUTOMATION_PAUSE_REASON__OVERDUE',
  TOUCH: 'TASK__AUTOMATION_PAUSE_REASON__TOUCH',
});

export const TASK_FIELDS_LOGIC_ROLE = Object.freeze({
  ASSIGNED_TO: 'TASK__ASSIGNED_TO',
  AUTOMATED_STATUS: 'TASK__AUTOMATED_STATUS',
  AUTOMATION_ERROR: 'TASK__AUTOMATION_ERROR',
  AUTOMATION_PAUSE_REASON: 'TASK__AUTOMATION_PAUSE_REASON',
  CADENCE: 'TASK__CADENCE',
  COMPANY: 'TASK__COMPANY',
  COMPLETED_DATE: 'TASK__COMPLETED_DATE',
  CREATION_DATETIME: 'TASK__CREATION_DATETIME',
  DESCRIPTION: 'TASK__DESCRIPTION',
  EMAIL_METADATA: 'TASK__EMAIL_METADATA',
  IS_AUTOMATED: 'TASK__IS_AUTOMATED',
  LEAD: 'TASK__LEAD',
  OPPORTUNITY: 'TASK__OPPORTUNITY',
  SCHEDULED_DATE: 'TASK__SCHEDULED_DATE',
  SCHEDULED_DATETIME: 'TASK__SCHEDULED_DATETIME',
  STATUS: 'TASK__STATUS',
  TASK_TYPE: 'TASK__TASK_TYPE',
  TITLE: 'TASK__TITLE',
  UPDATE_DATETIME: 'TASK__UPDATE_DATETIME',
  AUTOMATED_EMAIL_TO: 'TASK__AUTOMATED_EMAIL_TO',
  IS_AUTOMATED_EMAIL: 'TASK__IS_ACTION_AUTOMATED_EMAIL',
  IS_ACTION_CALL: 'TASK__IS_ACTION_CALL',
  IS_ACTION_EMAIL: 'TASK__IS_ACTION_EMAIL',
  IS_ACTION_LINKEDIN: 'TASK__IS_ACTION_LINKEDIN_MESSAGE',
  MEETING_ACTIVITY: 'TASK__ACTIVITY_MEETING',
  TEMPLATE: 'TASK__TEMPLATE_ID',
  STEP_ID: 'TASK__CADENCE_STEP_ID',
  CUSTOM_TASK: 'TASK__CUSTOM_TASK',
  SCHEDULED_MODE: 'TASK__SCHEDULED_MODE',
});

export const TASK_ACTION = Object.freeze({
  CALL: 'TASK__IS_ACTION_CALL',
  EMAIL: 'TASK__IS_ACTION_EMAIL',
  LINKEDIN_MESSAGE: 'TASK__IS_ACTION_LINKEDIN_MESSAGE',
  AUTOMATED_EMAIL: 'TASK__IS_ACTION_AUTOMATED_EMAIL',
});

export const TASK_ACTION_VALUE = Object.freeze({
  CALL_YES: 'TASK__IS_ACTION_CALL__YES',
  EMAIL_YES: 'TASK__IS_ACTION_EMAIL__YES',
  LINKEDIN_MESSAGE_YES: 'TASK__IS_ACTION_LINKEDIN_MESSAGE__YES',
  AUTOMATED_EMAIL_YES: 'TASK__IS_ACTION_AUTOMATED_EMAIL__YES',
  AUTOMATED_EMAIL_NO: 'TASK__IS_ACTION_AUTOMATED_EMAIL__NO',
});

export const TASK_AUTOMATED_VALUE = Object.freeze({
  AUTOMATED_YES: 'TASK__IS_AUTOMATED__YES',
});
