import React from 'react';
import clsx from 'clsx';
import AllMyOppsFilters from './allMyOppsFilters';
import { OppsList } from './components/allMyOppsList';
import { AllMyOppsKanban } from './components/allMyOppsKanban/allMyOppsKanban';
import styles from './allMyOpps.module.css';
import { useSubhomeContext } from '../../subhomeContext';
import { useSalesAllMyOppsViewMode } from './useSalesAllMyOpps';
import SubhomeStats from '../../../../layouts/subhomeLayout/subhomeContent/subhomeStats/subhomeStats';

const AllMyOppsList = () => {
  const [viewMode] = useSalesAllMyOppsViewMode();
  const isKanban = viewMode === 'KANBAN';

  return <>{isKanban ? <AllMyOppsKanban /> : <OppsList />}</>;
};

export function AllMyOppsListContent() {
  const { selectedItems } = useSubhomeContext();

  return (
    <>
      <SubhomeStats
        tab={'salesAllOpportunities'}
        thereAreItemsSelected={selectedItems.length > 0}
        showAssignedToFilter
      />
      <div
        className={clsx(styles._filter_container, {
          [styles._transition]: selectedItems.length > 0,
        })}
      >
        <AllMyOppsFilters />
      </div>
      <AllMyOppsList />
    </>
  );
}
