import { Spinner, Text } from '@bloobirds-it/flamingo-ui';
import React, { Fragment, useEffect, useState } from 'react';
import { isToday } from 'date-fns';
import styles from '../../homePage.module.css';
import { TASK_FIELDS_LOGIC_ROLE } from '../../../../constants/task';
import { TasksHookFamilies } from '../../../../typings/tasks';
import { UserHomeConfig } from '../../typings/home';
import {
  useUserDateOverdueTasks,
  useUserDateTasks,
  useUserTasksFilters,
} from '../../../../hooks/useUserTasks';
import { TaskHomeCard } from '../taskHomeCard/taskHomeCard';
import { getValueFromLogicRole } from '../../../../utils/bobjects.utils';
import { EmptyTaskList } from '../../pages/leftContentSalesConversion/utils/utils';
import { useSelectAll } from '@bloobirds-it/hooks';

const DateGroupHeader = ({ text }: { text: string }) => (
  <header>
    <Text color="softPeanut" weight="medium" size="xs" inline className={styles.heading_text}>
      {text}
    </Text>
  </header>
);

const getTimeMarkerPosition = (tasks: any) => {
  const shouldHaveMarker = tasks?.map((task: any) => {
    const currentDateTime = new Date();
    const scheduledDatetime = getValueFromLogicRole(
      task,
      TASK_FIELDS_LOGIC_ROLE.SCHEDULED_DATETIME,
    );

    return scheduledDatetime && new Date(scheduledDatetime) < currentDateTime;
  });

  return shouldHaveMarker.lastIndexOf(true);
};

export const TaskList = ({
  activeSelector,
  activeFilters,
  taskTypes,
  shouldNotShowResults,
}: {
  activeSelector: UserHomeConfig;
  activeFilters: UserHomeConfig[];
  taskTypes: any[];
  shouldNotShowResults: boolean;
}) => {
  const { tasks, isLoading } = useUserDateTasks({
    date: new Date(),
    family: TasksHookFamilies.Home,
  });
  const {
    setTasksTypeFilter,
    setProspectingTypesFilter,
    setSalesTypesFilter,
  } = useUserTasksFilters(TasksHookFamilies.Home);
  const length = tasks?.length;
  const { tasks: overdueTasks } = useUserDateOverdueTasks({
    date: new Date(),
    family: TasksHookFamilies.Home,
  });
  const overdueLength = overdueTasks?.length;
  const showOverdue = taskTypes?.includes('OVERDUE');
  const [timeMarkerPosition, setTimeMarkerPosition] = useState();

  useEffect(() => {
    if (activeSelector) {
      setTasksTypeFilter(activeSelector.enumName);
    }
  }, [activeSelector]);
  useEffect(() => {
    if (activeSelector && activeSelector.enumName === 'PROSPECTING' && activeFilters) {
      setProspectingTypesFilter(taskTypes);
    }
    if (activeSelector && activeSelector.enumName === 'SALES' && activeFilters) {
      setSalesTypesFilter(taskTypes);
    }
  }, [activeSelector, activeFilters]);
  useEffect(() => {
    setTimeMarkerPosition(getTimeMarkerPosition(tasks));
    const intervalId = setInterval(
      () => setTimeMarkerPosition(getTimeMarkerPosition(tasks)),
      60000,
    );

    return () => intervalId && clearInterval(intervalId);
  }, [tasks]);

  // Selected items
  const { setInitialItems, resetSelectedItems, resetCheckSelectedAll } = useSelectAll();
  useEffect(() => {
    setInitialItems([...overdueTasks, ...tasks]);
  }, [tasks, overdueTasks]);
  useEffect(
    () => () => {
      resetSelectedItems();
      resetCheckSelectedAll();
    },
    [],
  );

  return (
    <>
      {isLoading ? (
        <>
          {shouldNotShowResults ? (
            <div className={styles.noTasks}>
              <Text size="m" color="softPeanut" align="center">
                You should select a Task Type to see tasks!
              </Text>
            </div>
          ) : (
            <div className={styles.spinner}>
              <Spinner size={24} name={'loadingCircle'} />
            </div>
          )}
        </>
      ) : (
        <>
          {length === 0 && overdueLength === 0 ? (
            <>
              {shouldNotShowResults ? (
                <div className={styles.noTasks}>
                  <Text size="m" color="softPeanut" align="center">
                    You should select a Task Type to see tasks!
                  </Text>
                </div>
              ) : (
                <div style={{ height: '89%' }}>
                  <div className={styles.noTasks}>
                    <EmptyTaskList />
                  </div>
                </div>
              )}
            </>
          ) : (
            <>
              {shouldNotShowResults ? (
                <div className={styles.noTasks}>
                  <Text size="m" color="softPeanut" align="center">
                    You should select a Task Type to see tasks!
                  </Text>
                </div>
              ) : (
                <>
                  {showOverdue && (
                    <>
                      {overdueTasks?.map((task: any, index: number) => (
                        <Fragment key={task?.id?.value}>
                          {index === 0 && <DateGroupHeader text="Overdue" />}
                          <TaskHomeCard task={task} />
                        </Fragment>
                      ))}
                    </>
                  )}
                  {tasks?.map((task: { id: { value: React.Key } }, index: number) => {
                    return (
                      <Fragment key={task.id.value}>
                        {index === 0 && <DateGroupHeader text="Tasks for today" />}
                        <TaskHomeCard task={task} />
                        {index === timeMarkerPosition && isToday(new Date()) && (
                          <div className={styles._time_marker}>
                            <span className={styles._time_marker_bullet} />
                            <span className={styles._time_marker_line} />
                          </div>
                        )}
                      </Fragment>
                    );
                  })}
                </>
              )}
            </>
          )}
        </>
      )}
    </>
  );
};
