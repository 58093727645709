import React, { FC, useEffect, useState } from 'react';
import classnames from 'clsx';
import { Text } from '@bloobirds-it/flamingo-ui';
import { useUserPermissions } from '../../../../components/userPermissions/hooks';
import SideBar from '../sideBar/sideBar';
import styles from '../../v1/dashboardPage/dashboardPage.module.css';
import { DrillDownModal } from '../drillDownModal/drillDownModal';
import { useDocumentTitle, useNewDashboard } from '../../../../hooks';
import { DashboardPageContent } from './dashboardPageContent/dashboardPageContent';
import DashboardPageSection from './dashboardPageSection/dashboardPageSection';
import { useNewDrillDownModal } from '../../../../hooks/useNewDrillDownModal';
import SyncingPage from './syncingPage/syncingPage';
import { api } from '../../../../utils/api';
import { SyncStatusResponse } from '../../../../constants/newDashboards';
import { useNewInactiveHandling } from '../../../../hooks/useFeatureFlags';
import useDashboardFilters from '../../../../hooks/useDashboardFilters';
import { useInSalesDashboards } from '../../../../hooks/useInSalesDashboards';
import { useInFunnelPage } from '../../../../hooks/useInFunnelPage';
import ErrorPage from '../../../errorPage';
import { newInactive } from '../../../../constants/dashboardDefinitions/dataQualityProspecting';

const DashboardPage: FC = () => {
  const isInSalesDashboard = useInSalesDashboards();
  const isInFunnelPage = useInFunnelPage();
  const { dashboards: canSeeDashboards } = useUserPermissions();
  const isNewInactiveEnabled = useNewInactiveHandling();
  const { definition } = useNewDashboard();
  const { openDrillDown } = useNewDrillDownModal();
  const [filtersReady, setFiltersReady] = useState<boolean>(false);
  const { initialLoad, dateRangeTypeFilter, intervalFilter, clearFilters } = useDashboardFilters();
  useDocumentTitle('Dashboards');
  const [isSyncing, setIsSyncing] = useState<boolean>(false);
  const [loadSync, setLoadSync] = useState(true);

  useEffect(() => {
    api.get<SyncStatusResponse>('/analytics/admin/sync/status').then(response => {
      setIsSyncing(response?.data?.status !== 'UP_TO_DATE');
      setLoadSync(false);
    });
  }, []);

  useEffect(() => {
    const scroll = document.getElementById('scroll_top_dashboard');
    if (scroll) scroll.scrollTop = 0;
  }, [definition]);

  useEffect(() => {
    if (intervalFilter && dateRangeTypeFilter && !filtersReady) {
      setFiltersReady(true);
    }
  }, [intervalFilter, dateRangeTypeFilter]);

  useEffect(() => {
    if (isInSalesDashboard || isInFunnelPage) {
      initialLoad({ initialRange: 'this_month', initialInterval: 'month' });
    } else {
      initialLoad({ initialRange: 'this_week', initialInterval: 'week' });
    }
  }, []);

  if (isSyncing) {
    return <SyncingPage />;
  }

  if (canSeeDashboards === false) {
    return (
      <div className={classnames(styles.root, styles.root_withErrors)}>
        <ErrorPage
          action={{
            name: 'Refresh',
            handleClick: () => {
              clearFilters();
              window.location.reload();
            },
            icon: 'refresh',
          }}
          showSupport={false}
        >
          <Text color="softPeanut" align="center">
            Oops! There was an error fetching the data.
          </Text>
        </ErrorPage>
      </div>
    );
  }

  if (isNewInactiveEnabled && definition.title === 'Data Quality') {
    // @ts-ignore
    definition.sections[definition.sections.length - 1] = { ...newInactive };
  }

  return (
    <>
      {!loadSync && filtersReady && !isSyncing && (
        <div className={styles.root}>
          <SideBar />
          <div className={styles.content}>
            <DashboardPageContent title={definition.title}>
              {openDrillDown && <DrillDownModal />}
              {definition.sections.map(section => (
                <DashboardPageSection key={section.title} section={section} />
              ))}
            </DashboardPageContent>
          </div>
        </div>
      )}
    </>
  );
};

export default DashboardPage;
