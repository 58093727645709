import React, { useLayoutEffect, useRef, useState } from 'react';
import { Modal, AnimationTooltip, Slider, SliderPage } from '@bloobirds-it/flamingo-ui';
import styles from './welcomeScreens.module.css';
import { useIsAccountAdmin } from '../../hooks/usePermissions';
import FirstScreen from './slides/firstScreen';
import CommonScreen from './slides/commonScreen';
import LastScreen from './slides/lastScreen';
import {
  AutomatedTaskText,
  ContactabilityToolsText,
  CorrectContactFlowText,
  GuidesSaleText,
  SalesTeamText,
  StrategicMetricsProText,
  WhatWhenHowText,
} from './screenTexts';
// Icons
// @ts-ignore
import Gmail from '../../../assets/Gmail.png'; // @ts-ignore
import Calendar from '../../../assets/Calendar-icon.png'; // @ts-ignore
import JustCall from '../../../assets/JustCall.png'; // @ts-ignore
import Linkedin from '../../../assets/Linkedin.png'; // @ts-ignore
import Aircall from '../../../assets/Aircall.png'; // @ts-ignore
import Dialer from '../../../assets/Dialer.png';
import { useUserHelpers } from '../../hooks/useUserHelpers';
import { UserHelperKeys } from '../../constants/userHelperKeys';
import { usePreventWindowUnload } from '../../hooks/usePreventWindowUnload';

const Icons = () => (
  <>
    <div className={styles.imgCalendar}>
      <img src={Calendar} alt="Calendar" />
    </div>
    <div className={styles.imgJustCall}>
      <img src={JustCall} alt="JustCall" />
    </div>
    <div className={styles.imgGmail}>
      <img src={Gmail} alt="Gmail" />
    </div>
    <div className={styles.imgLinkedin}>
      <img src={Linkedin} alt="Linkedin" />
    </div>
    <div className={styles.imgAircall}>
      <img src={Aircall} alt="Aircall" />
    </div>
    <div className={styles.imgDialer}>
      <img src={Dialer} alt="Dialer" />
    </div>
  </>
);

export const WelcomeScreens = () => {
  const [openModal, setOpenModal] = useState(true);
  const [refTooltip, setRefTooltip] = useState(null);
  const isAdmin = useIsAccountAdmin();
  const { save } = useUserHelpers();

  usePreventWindowUnload(openModal);

  const ref = useRef<HTMLDivElement>(null);
  const [element, setElement] = useState<HTMLDivElement | null>(null);
  useLayoutEffect(() => {
    setElement(ref.current);
  }, []);

  const saveSettings = () => {
    save(UserHelperKeys.COMPLETE_WELCOME_SCREEN);
  };

  const handleFinish = () => {
    saveSettings();
    setOpenModal(false);
  };

  const tooltipEl = () => (
    <div className={styles.tooltip}>
      <div ref={ref} />
    </div>
  );

  if (isAdmin) {
    return (
      <>
        {tooltipEl()}
        <Modal data-testid="welcomeScreenModal" width={700} open={openModal} onClose={handleFinish}>
          <Slider>
            <SliderPage textNextButton="Tell me more">
              <FirstScreen />
            </SliderPage>
            <SliderPage textNextButton="Continue">
              <CommonScreen
                text={SalesTeamText}
                svg={'https://media.bloobirds.com/public/welcomeScreens/playbook-bg-white.gif'}
              />
              <AnimationTooltip
                element={element}
                key={0}
                arrow
                horizontalAnimation
                position="right-start"
                text={
                  'No more reps delivering alternative pitches and using their own sequences ✨'
                }
                width={'183px'}
                left={window.innerWidth / 2 + 170}
                top={window.innerHeight / 2 - 26}
              ></AnimationTooltip>
            </SliderPage>
            <SliderPage textNextButton="Continue">
              <CommonScreen
                text={GuidesSaleText}
                svg={
                  'https://media.bloobirds.com/public/welcomeScreens/Bloobirds-PV-Data-Collection-Short.gif'
                }
              />
              <AnimationTooltip
                element={element}
                key={1}
                arrow
                horizontalAnimation
                position="right-start"
                text={
                  'Keep your team focused on selling instead of overwhelmed with tedious admin stuff and say goodbye to a CRM filled with wonky data ✨'
                }
                width={'282px'}
                left={window.innerWidth / 2 + 130}
                top={window.innerHeight / 2 - 66}
              ></AnimationTooltip>
            </SliderPage>
            <SliderPage textNextButton="Let's start">
              <CommonScreen
                text={StrategicMetricsProText}
                svg={
                  'https://media.bloobirds.com/public/welcomeScreens/Bloobirds-PV-Dashboards.gif'
                }
              />
              <AnimationTooltip
                element={element}
                key={2}
                arrow
                horizontalAnimation
                position="right-start"
                text={
                  'Open rates and number of calls are not enough. Make decisions backed by strategic metrics and market insights that actually matter.✨'
                }
                width={'282px'}
                left={window.innerWidth / 2 + 150}
                top={window.innerHeight / 2 - 40}
              ></AnimationTooltip>
            </SliderPage>
            <SliderPage>
              <LastScreen onFinish={handleFinish} />
            </SliderPage>
          </Slider>
        </Modal>
      </>
    );
  } else {
    return (
      <>
        {tooltipEl()}
        <Modal data-testid="welcomeScreenModal" width={700} open={openModal} onClose={handleFinish}>
          <Slider>
            <SliderPage textNextButton="Tell me more">
              <FirstScreen />
            </SliderPage>
            <SliderPage textNextButton="Continue">
              <CommonScreen
                text={AutomatedTaskText}
                svg={'https://media.bloobirds.com/public/welcomeScreens/task-assistence.gif'}
              />
              <AnimationTooltip
                element={element}
                key={0}
                arrow
                horizontalAnimation
                position="right-start"
                text={'Forget about losing prospects on lists or not knowing what to do next ✨'}
                width={'221px'}
                left={window.innerWidth / 2 + 130}
                top={window.innerHeight / 2 - 120}
              ></AnimationTooltip>
            </SliderPage>
            <SliderPage textNextButton="Continue">
              <CommonScreen
                text={ContactabilityToolsText}
                svg={
                  'https://media.bloobirds.com/public/welcomeScreens/Bloobirds-PV-LinkedIn-Extension-Video.gif'
                }
              >
                <Icons />
              </CommonScreen>
              <AnimationTooltip
                element={element}
                key={1}
                verticalAnimation
                position="right-start"
                text={'Switching back and forth between different apps is a thing from the past ✨'}
                width={'310px'}
                left={window.innerWidth / 2 - 320}
                top={window.innerHeight / 2 + 66}
              ></AnimationTooltip>
            </SliderPage>
            <SliderPage textNextButton="Continue">
              <CommonScreen
                text={WhatWhenHowText}
                svg={'https://media.bloobirds.com/public/welcomeScreens/playbook-bg-white.gif'}
              />
              <AnimationTooltip
                element={element}
                key={2}
                arrow
                horizontalAnimation
                position="right-start"
                text={
                  'Know which pitch and channel is working best for each scenario and persona ✨'
                }
                width={'237px'}
                left={window.innerWidth / 2 + 160}
                top={window.innerHeight / 2 - 46}
              ></AnimationTooltip>
            </SliderPage>
            <SliderPage textNextButton="Let's start">
              <CommonScreen
                text={CorrectContactFlowText}
                svg={
                  'https://media.bloobirds.com/public/welcomeScreens/Bloobirds-PV-Data-Collection-Short.gif'
                }
              />
              <AnimationTooltip
                element={element}
                key={3}
                arrow
                horizontalAnimation
                position="right-start"
                text={'Stop wasting time filling spreadsheets and form fields ✨'}
                width={'164px'}
                left={window.innerWidth / 2 + 130}
                top={window.innerHeight / 2 - 66}
              ></AnimationTooltip>
            </SliderPage>
            <SliderPage>
              <LastScreen onFinish={handleFinish} />
            </SliderPage>
          </Slider>
        </Modal>
      </>
    );
  }
};

export default WelcomeScreens;
