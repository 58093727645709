import { ServiceApi } from '../../../misc/api/service';
import { api } from '../../../utils/api';

/**
 * @deprecated
 * @param provider
 * @param bbPage
 */
export const fetchAndOpenNylasUrl = (provider = 'gmail', bbPage = '') => {
  api.get('/utils/nylas/generate-url', { provider, 'bb-page': bbPage }).then(payload => {
    window.open(payload.data.url);
  });
};

/**
 * @deprecated
 */
export const fetchAndOpenLegacyUrl = () => {
  api.get('/utils/service/gmail/connections/endpoint').then(payload => {
    window.open(payload.data.endpoint);
  });
};
