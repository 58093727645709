import mixpanel from 'mixpanel-browser';
import {
  CardButton,
  CardHoverButtons,
  Dropdown,
  IconButton,
  Item,
  Tooltip,
  useToasts,
  useVisible,
} from '@bloobirds-it/flamingo-ui';
import React, { useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { Bobject, PluralBobjectTypes } from '../../../../../typings/bobjects';
import { useSetCadenceEnabled } from '../../../../../hooks/useFeatureFlags';
import { MIXPANEL_EVENTS } from '../../../../../constants/mixpanel';
import { getFieldByLogicRole } from '../../../../../utils/bobjects.utils';
import { TASK_FIELDS_LOGIC_ROLE, TASK_TYPE } from '../../../../../constants/task';
import { useRescheduleTask } from '../../../../../hooks/useRescheduleTask';
import CardRescheduleTaskButton from '../../../../../components/rescheduleTask/cardRescheduleTaskButton';
import {
  useBobjectFormVisibility,
  useCadenceControl,
  useMediaQuery,
  useTaskDone,
} from '../../../../../hooks';
import { STEPS } from '../../../../../components/cadenceControlModal/cadenceControlModal.machine';
import { getTaskReferenceBobject } from '../../../../../utils/tasks.utils';
import { useSelectAll } from '@bloobirds-it/hooks';
import { useTaskInfo } from './taskRelatedBobject.utils';
import { useLocationReturn, useParamsReturn } from '../../../salesPage/salesPage.utils';
import { getMixpanelKey } from '../../../subhomes.utils';
import { PROSPECTING_SLUGS, SALES_SLUGS } from '../../../subhomes.constants';
import useStopCadence from '../../../../../hooks/useStopCadence';

const TASK_RESCHEDULE_VALUES = {
  RESCHEDULABLE: 'RESCHEDULABLE',
};
export const TaskCardButtons = ({
  bobject,
  isHovering,
}: {
  bobject: Bobject;
  isHovering: boolean;
}) => {
  const params: useParamsReturn = useParams();
  const location: useLocationReturn = useLocation();
  const pathname = location.pathname;
  const mixpanelKey = getMixpanelKey(params, pathname);
  const { showToast } = useTaskDone();
  const { createToast } = useToasts();
  const { openEditModal } = useBobjectFormVisibility();
  const { openRescheduleModal } = useRescheduleTask();
  const { openCadenceControl } = useCadenceControl();
  const { openStopCadenceModal } = useStopCadence();
  const { getCanBeMarkedAsDone, getCadenceEntity, getTaskActiveStatus } = useTaskInfo(bobject);
  const { ref, visible, setVisible } = useVisible(false);
  const { isSmallDesktop } = useMediaQuery();
  const { selectAllItems, selectedItems } = useSelectAll();
  const referenceBobject = getTaskReferenceBobject(bobject);
  const referenceBobjectType = referenceBobject?.id?.typeName;
  const buttonData = getCanBeMarkedAsDone();
  const isActiveTask = getTaskActiveStatus();
  const cadenceEntity = getCadenceEntity();
  const taskType = getFieldByLogicRole(bobject, TASK_FIELDS_LOGIC_ROLE.TASK_TYPE)?.valueLogicRole;
  const isScheduled =
    getFieldByLogicRole(bobject, TASK_FIELDS_LOGIC_ROLE.TASK_TYPE)?.valueLogicRole ===
    TASK_TYPE.NEXT_STEP;

  const handleMarkAsDone = (event: React.MouseEvent<HTMLElement, MouseEvent>, id: string) => {
    event.preventDefault();
    event.stopPropagation();
    showToast(true, id);
  };
  const tabsWithSetCadenceAvailable = [
    PROSPECTING_SLUGS.ON_CADENCE,
    PROSPECTING_SLUGS.MEETING,
    SALES_SLUGS.FOLLOW_UP,
    SALES_SLUGS.NURTURING,
    PROSPECTING_SLUGS.NURTURING,
  ];
  const { slug } = useParams();
  const isSetCadenceEnabled = useSetCadenceEnabled();
  const hasCadenceHoverOptions =
    tabsWithSetCadenceAvailable.includes(slug) &&
    taskType === TASK_TYPE.PROSPECT_CADENCE &&
    isSetCadenceEnabled;

  const onSaveAction = () => {
    selectAllItems([]);
    createToast({
      type: 'success',
      message:
        selectedItems.length > 1
          ? `${selectedItems.length} ${PluralBobjectTypes[referenceBobjectType]} updated successfully.`
          : `${referenceBobjectType} updated successfully`,
    });
  };

  useEffect(() => {
    if (!isHovering) setVisible(false);
  }, [isHovering]);

  return (
    <>
      <CardHoverButtons>
        {cadenceEntity?.reschedulableMode === TASK_RESCHEDULE_VALUES.RESCHEDULABLE &&
          isActiveTask && <CardRescheduleTaskButton task={bobject} />}
        {isScheduled && (
          <CardButton
            dataTest="Subhome-RescheduleTask"
            iconLeft="clock"
            onClick={event => {
              mixpanel.track(MIXPANEL_EVENTS[`RESHEDULE_ACTION_CLICKED_ON_${mixpanelKey}_TAB`]);
              event.preventDefault();
              event.stopPropagation();
              openRescheduleModal({ bobjectToSet: bobject });
            }}
            variant="secondary"
          >
            {!isSmallDesktop && 'Reschedule'}
          </CardButton>
        )}

        <Tooltip title={buttonData?.tooltip} position="top">
          <CardButton
            dataTest="Subhome-MarkAsDone"
            iconLeft="check"
            onClick={event => {
              mixpanel.track(MIXPANEL_EVENTS[`MARK_AS_DONE_ACTION_CLICKED_ON_${mixpanelKey}_TAB`]);
              handleMarkAsDone(event, bobject?.id.objectId);
            }}
            disabled={buttonData.disabled}
          >
            Mark as done
          </CardButton>
        </Tooltip>
        {isScheduled && (
          <IconButton
            name="edit"
            onClick={event => {
              event.preventDefault();
              event.stopPropagation();
              openEditModal({ bobject });
            }}
          />
        )}
        {hasCadenceHoverOptions && (
          <Dropdown
            ref={ref}
            visible={visible}
            arrow={false}
            anchor={
              <IconButton
                name="moreVertical"
                onClick={event => {
                  event.stopPropagation();
                  setVisible(!visible);
                }}
              />
            }
          >
            <Item
              icon="calendar"
              onClick={(value, event) => {
                mixpanel.track(MIXPANEL_EVENTS[`SET_CADENCE_ACTION_CLICKED_ON_${mixpanelKey}_TAB`]);
                event.preventDefault();
                event.stopPropagation();
                setVisible(false);
                openCadenceControl({
                  previousStep: false,
                  bobjectToSet: referenceBobject,
                  step: STEPS.CONFIGURE_CADENCE,
                  onSaveCallback: onSaveAction,
                  response: undefined,
                });
              }}
            >
              Set cadence
            </Item>
            <Item
              icon="slash"
              onClick={(value, event) => {
                mixpanel.track(
                  MIXPANEL_EVENTS[`STOP_CADENCE_ACTION_CLICKED_ON_${mixpanelKey}_TAB`],
                );
                event.stopPropagation();
                setVisible(false);
                openStopCadenceModal({ bobjectToSet: bobject });
              }}
            >
              Stop cadence
            </Item>
          </Dropdown>
        )}
      </CardHoverButtons>
    </>
  );
};
