import React from 'react';
import styles from './emailSettings.module.css';
import {
  RichTextEditor,
  EditorToolbar,
  EditorToolbarControlsSection,
  EditorToolbarFontStylesSection,
  EditorToolbarImage,
  EditorToolbarListsSection,
  EditorToolbarSection,
  EditorToolbarTextMarksSection,
} from '@bloobirds-it/rich-text-editor';

const SignatureEditor = props => {
  const { plugins, hidden, defaultValue } = props;

  if (!defaultValue) {
    return null;
  }

  return (
    <div
      className={styles._editor__container}
      hidden={hidden}
      data-intercom="user-settings-page-email-email-signature-input"
    >
      <RichTextEditor plugins={plugins} id="signature" defaultValue={defaultValue} {...props}>
        {editor => (
          <>
            <EditorToolbar id="signature">
              <EditorToolbarControlsSection />
              <EditorToolbarFontStylesSection />
              <EditorToolbarTextMarksSection />
              <EditorToolbarListsSection />
              <EditorToolbarSection>
                <EditorToolbarImage />
              </EditorToolbarSection>
            </EditorToolbar>
            <div className={styles._editor__container_ast}>{editor}</div>
          </>
        )}
      </RichTextEditor>
    </div>
  );
};

export default SignatureEditor;
