import React from 'react';
import styles from './syncRuleCheckbox.module.css';
import { Text, Tooltip } from '@bloobirds-it/flamingo-ui';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { useHover } from '../../../../../hooks';

const SyncRuleCheckbox = props => {
  const { title, description, checked, onChecked, disabled } = props;
  const [ref, isHovered] = useHover();

  const checkClass = classnames(styles._radio_button, {
    [styles._radio_button_checked]: (isHovered || checked) && !disabled,
    [styles._radio_button_disabled]: disabled,
  });

  const boxClass = classnames(styles._checkbox_box, {
    [styles._checkbox_box_disabled]: disabled,
  });

  const handleClickCheckbox = () => {
    if (!disabled && onChecked) {
      onChecked();
    }
  };
  return (
    <>
      {disabled ? (
        <div className={boxClass} ref={ref} onClick={handleClickCheckbox}>
          <Tooltip
            title="When mapping fields across objects, information can only be sent from Bloobirds to the CRM."
            position="top"
          >
            <div className={styles._radio_button_holder}>
              <span className={checkClass} />
            </div>
            <div className={styles._checkbox_text_holder}>
              <Text
                color={disabled ? 'softPeanut' : 'bloobirds'}
                className={styles._checkbox_title}
              >
                {title}
              </Text>
              <Text size="m" color={disabled ? 'verySoftPeanut' : 'softPeanut'}>
                {description}
              </Text>
            </div>
          </Tooltip>
        </div>
      ) : (
        <div className={boxClass} ref={ref} onClick={handleClickCheckbox}>
          <div className={styles._radio_button_holder}>
            <span className={checkClass} />
          </div>
          <div className={styles._checkbox_text_holder}>
            <Text color={disabled ? 'softPeanut' : 'bloobirds'} className={styles._checkbox_title}>
              {title}
            </Text>
            <Text size="m" color={disabled ? 'verySoftPeanut' : 'softPeanut'}>
              {description}
            </Text>
          </div>
        </div>
      )}
    </>
  );
};

SyncRuleCheckbox.propTypes = {
  checked: PropTypes.bool,
  description: PropTypes.string,
  onChecked: PropTypes.func,
  title: PropTypes.string,
};

export default SyncRuleCheckbox;
