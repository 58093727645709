import { Direction, SearchRequest, useSearchSubscription } from '@bloobirds-it/plover';
import { endOfDay, startOfDay } from 'date-fns';
import { Filters, Stages } from './utils';
import { TASK_FIELDS_LOGIC_ROLE } from '../../../../../constants/task';
import SessionManagerFactory from '../../../../../misc/session';
import { BOBJECT_TYPES } from '../../../../../constants/bobject';

// eslint-disable-next-line import/no-unresolved
import { getHomeTasksQueriesAll } from './orQueries';

const SessionManager = SessionManagerFactory();

export const useHomeTasks = (stage: Stages, taskFilters: Filters, page = 0, overdue = false) => {
  const date = new Date();
  const scheduleQuery = overdue
    ? { lt: startOfDay(date) }
    : { gte: startOfDay(date), lte: endOfDay(date) };
  const query = {
    [TASK_FIELDS_LOGIC_ROLE.ASSIGNED_TO]: SessionManager?.getUser()?.id,
    [TASK_FIELDS_LOGIC_ROLE.SCHEDULED_DATETIME]: {
      ['query']: scheduleQuery,
      searchMode: 'RANGE__SEARCH',
    },
    [TASK_FIELDS_LOGIC_ROLE.IS_AUTOMATED_EMAIL]: [
      'TASK__IS_ACTION_AUTOMATED_EMAIL__NO',
      '__MATCH_EMPTY_ROWS__',
    ],
  };
  const sort = [
    {
      field: TASK_FIELDS_LOGIC_ROLE.SCHEDULED_DATETIME,
      direction: 'ASC' as Direction,
    },
  ];
  const queries = getHomeTasksQueriesAll(stage, taskFilters, overdue);

  const searchQuery: SearchRequest = queries
    ? {
        query,
        // @ts-ignore
        queries,
        formFields: true,
        pageSize: 50 * (page + 1),
        injectReferences: true,
        sort,
      }
    : undefined;

  const { data, isValidating } = useSearchSubscription(searchQuery, BOBJECT_TYPES.TASK);
  return { data, isLoading: !data && isValidating };
};
