import React from 'react';
import { Route, withRouter } from 'react-router';
import {
  APP_PLAYBOOK_BUYER_PERSONAS,
  APP_PLAYBOOK_CADENCES,
  APP_PLAYBOOK_CADENCES_EDIT,
  APP_PLAYBOOK_CUSTOM_TASKS,
  APP_PLAYBOOK_MESSAGING_EMAIL,
  APP_PLAYBOOK_MESSAGING_EMAIL_FORM,
  APP_PLAYBOOK_MESSAGING_LINKEDIN,
  APP_PLAYBOOK_MESSAGING_LINKEDIN_FORM,
  APP_PLAYBOOK_MESSAGING_PITCH,
  APP_PLAYBOOK_MESSAGING_PITCH_FORM,
  APP_PLAYBOOK_MESSAGING_QQ,
  APP_PLAYBOOK_MESSAGING_QQ_FORM,
  APP_PLAYBOOK_MESSAGING_QQ_SCORES,
  APP_PLAYBOOK_MESSAGING_SEGMENTATION,
  APP_PLAYBOOK_MESSAGING_SNIPPET,
  APP_PLAYBOOK_MESSAGING_SNIPPET_FORM,
  APP_PLAYBOOK_PRODUCTS,
  APP_PLAYBOOK_SALES_PIPELINE,
  APP_PLAYBOOK_SCENARIOS,
  APP_PLAYBOOK_TARGET_MARKET,
} from '../../app/_constants/routes';
import EmailTemplatesPage from './emailTemplatesPage/emailTemplatesPage';
import EmailTemplateForm from './emailTemplatesPage/emailTemplateForm';
import LinkedinTemplateForm from './linkedinTemplatesPage/linkedinTemplateForm';
import LinkedinTemplatesPage from './linkedinTemplatesPage/linkedinTemplatesPage.view';
import PitchTemplatesPage from './pitchTemplatesPage';
import PitchTemplateForm from './pitchTemplatesPage/pitchTemplateForm';
import QualifyingQuestionsPage from './qualifyingQuestionTemplatesPage';
import QualifyingQuestionsScore from './qualifyingQuestionTemplatesPage/qualifyingQuestionScoresPage';
import QualifyingQuestionTemplateForm from './qualifyingQuestionTemplatesPage/qualifyingQuestionTemplateForm';
import PlaybookSegmentationPage from './playbookSegmentationPage';
import BusinessAssetsPage from './businessAssetsPage/businessAssetsPage';
import { CadenceEditionPage } from './businessAssetsPage/cadenceTab/cadenceEditionPage/cadenceEditionPage';
import AutomationsPagesRoutes from './automationsPages/automationsPages.routes';
import ProductsPages from './productPage/productsPage';
import { SalesPipelinePage } from './salesPipelinePage/salesPipelinePage';
import { SnippetTemplatesPage } from './snippetTemplatesPage/snippetTemplatesPage.view';
import SnippetForm from './snippetTemplatesPage/snippetForm';
import { useNewCadenceTableEnabled } from '../../hooks/useFeatureFlags';
import { useUserSettings } from '../../components/userPermissions/hooks';
import { useCadenceV2Enabled } from '@bloobirds-it/hooks';
import { USER_PERMISSIONS } from '../../components/userPermissions/constants';

const Routes = () => {
  const settings = useUserSettings();
  const accountId = settings?.account?.id;
  const canViewCadences = settings?.user?.permissions.includes(USER_PERMISSIONS.VIEW_CADENCES);
  const canViewCustomTasks = canViewCadences && useNewCadenceTableEnabled();
  const cadenceV2Enabled = useCadenceV2Enabled(accountId);
  return (
    <>
      <AutomationsPagesRoutes />
      <Route exact path={APP_PLAYBOOK_MESSAGING_SNIPPET} component={SnippetTemplatesPage} />
      <Route exact path={APP_PLAYBOOK_MESSAGING_PITCH} component={PitchTemplatesPage} />
      <Route exact path={APP_PLAYBOOK_MESSAGING_EMAIL} component={EmailTemplatesPage} />
      <Route exact path={APP_PLAYBOOK_MESSAGING_QQ} component={QualifyingQuestionsPage} />
      <Route exact path={APP_PLAYBOOK_MESSAGING_QQ_SCORES} component={QualifyingQuestionsScore} />
      <Route exact path={APP_PLAYBOOK_MESSAGING_LINKEDIN} component={LinkedinTemplatesPage} />
      <Route exact path={APP_PLAYBOOK_MESSAGING_PITCH_FORM} component={PitchTemplateForm} />
      <Route exact path={APP_PLAYBOOK_MESSAGING_EMAIL_FORM} component={EmailTemplateForm} />
      <Route exact path={APP_PLAYBOOK_MESSAGING_SNIPPET_FORM} component={SnippetForm} />
      <Route
        exact
        path={APP_PLAYBOOK_MESSAGING_QQ_FORM}
        component={QualifyingQuestionTemplateForm}
      />
      <Route exact path={APP_PLAYBOOK_MESSAGING_LINKEDIN_FORM} component={LinkedinTemplateForm} />
      <Route
        exact
        path={APP_PLAYBOOK_MESSAGING_SEGMENTATION}
        component={PlaybookSegmentationPage}
      />
      <Route exact path={APP_PLAYBOOK_SALES_PIPELINE} component={SalesPipelinePage} />
      <Route exact path={APP_PLAYBOOK_TARGET_MARKET}>
        <BusinessAssetsPage tab="Target Markets" />
      </Route>
      <Route exact path={APP_PLAYBOOK_BUYER_PERSONAS}>
        <BusinessAssetsPage tab="Buyer personas (ICP)" />
      </Route>
      <Route exact path={APP_PLAYBOOK_SCENARIOS}>
        <BusinessAssetsPage tab="Scenarios" />
      </Route>
      {!cadenceV2Enabled && (
        <Route exact path={APP_PLAYBOOK_CADENCES}>
          <BusinessAssetsPage tab="Cadences" />
        </Route>
      )}
      {canViewCustomTasks && (
        <Route exact path={APP_PLAYBOOK_CUSTOM_TASKS}>
          <BusinessAssetsPage tab="Custom Tasks" />
        </Route>
      )}

      <Route exact path={APP_PLAYBOOK_CADENCES_EDIT}>
        <CadenceEditionPage />
      </Route>
      <Route path={APP_PLAYBOOK_PRODUCTS} component={ProductsPages} />
    </>
  );
};

export default withRouter(Routes);
