import { useToasts } from '@bloobirds-it/flamingo-ui';
import { atom, useRecoilState, useRecoilValue } from 'recoil';
import { v4 as uuid } from 'uuid';

import { Bobject } from '../../typings/bobjects';
import { usePreventWindowUnload } from '../usePreventWindowUnload';

export type MinimizableModalType = 'email' | 'meeting' | 'note' | 'task' | 'calendarMeeting';

export type MinimizableModal<T> = {
  id: string;
  title: string;
  open: boolean;
  data: T;
  bobject?: Bobject;
  type: MinimizableModalType;
  hasBeenMinimized: boolean;
};

const minimizableModalsAtom = atom({
  key: 'minimizableModals',
  default: [],
});

const confirmationModalAtom = atom({
  key: 'confirmationMinimizableModal',
  default: {
    open: false,
    id: null,
  },
});

const useMinimizableModals = () => {
  const confirmationModal = useRecoilValue(confirmationModalAtom);
  const [minimizableModals, setMinimizableModals] = useRecoilState(minimizableModalsAtom);
  usePreventWindowUnload(minimizableModals.length > 0);

  const openMinimizableModal = <T>({
    data,
    title,
    type,
    bobject,
  }: {
    data: T;
    title?: string;
    type: MinimizableModalType;
    bobject?: Bobject;
  }) => {
    const modal: MinimizableModal<T> = {
      id: uuid(),
      open: true,
      hasBeenMinimized: false,
      type,
      title,
      data,
      bobject,
    };
    setMinimizableModals([modal, ...minimizableModals]);
  };

  return {
    minimizableModals,
    openMinimizableModal,
    confirmationModal,
  };
};

const useMinimizableModal = <T extends object>(id: string) => {
  const [minimizableModals, setMinimizableModals] = useRecoilState(minimizableModalsAtom);
  const [confirmationModal, setConfirmationModal] = useRecoilState(confirmationModalAtom);
  const { createToast } = useToasts();

  const minimizableModal: MinimizableModal<T> = minimizableModals.find(modal => modal.id === id);

  const openConfirmModal = () => {
    setConfirmationModal({
      open: true,
      id,
    });
  };

  const cancelConfirmModal = () => {
    setConfirmationModal({
      open: false,
      id: null,
    });
  };

  return {
    ...minimizableModal,
    confirmationModal,
    closeModal: () => {
      setMinimizableModals(minimizableModals.filter(modal => modal.id !== id));
    },
    maximize: () => {
      setMinimizableModals(
        minimizableModals.map(modal => {
          if (modal.id === id) {
            return {
              ...modal,
              open: true,
            };
          }
          return modal;
        }),
      );
    },
    minimize: <T>({ data, title, bobject }: { data?: T; title?: string; bobject?: Bobject }) => {
      if (minimizableModals.length >= 10) {
        createToast({ message: 'You cannot minimize more than 10 windows', type: 'error' });
      } else {
        const modal: MinimizableModal<T> = {
          id,
          type: minimizableModal.type,
          open: false,
          hasBeenMinimized: true,
          title: title || minimizableModal.title,
          bobject: bobject,
          data: {
            ...minimizableModal['data'],
            ...data,
          },
        };
        setMinimizableModals([modal, ...minimizableModals.filter(modal => modal.id !== id)]);
      }
    },
    openConfirmModal,
    cancelConfirmModal,
  };
};

export { useMinimizableModals, useMinimizableModal };
