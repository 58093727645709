// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".changeTimezoneModal-modules__modal___yQbxi {\n  width: 300px !important;\n  background-color: var(--white);\n}\n\n.changeTimezoneModal-modules__header___QqoR\\+ {\n  padding: 24px;\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n}\n\n.changeTimezoneModal-modules__content___vLaz3 {\n  padding: 16px 24px;\n  padding-top: 0;\n}\n\n.changeTimezoneModal-modules__customButton___3pdpo {\n  margin-top: 8px;\n  justify-content: center;\n}\n", "",{"version":3,"sources":["webpack://./src/js/components/changeTimezoneModal/changeTimezoneModal.modules.css"],"names":[],"mappings":"AAAA;EACE,uBAAuB;EACvB,8BAA8B;AAChC;;AAEA;EACE,aAAa;EACb,aAAa;EACb,mBAAmB;EACnB,8BAA8B;AAChC;;AAEA;EACE,kBAAkB;EAClB,cAAc;AAChB;;AAEA;EACE,eAAe;EACf,uBAAuB;AACzB","sourcesContent":[".modal {\n  width: 300px !important;\n  background-color: var(--white);\n}\n\n.header {\n  padding: 24px;\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n}\n\n.content {\n  padding: 16px 24px;\n  padding-top: 0;\n}\n\n.customButton {\n  margin-top: 8px;\n  justify-content: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modal": "changeTimezoneModal-modules__modal___yQbxi",
	"header": "changeTimezoneModal-modules__header___QqoR+",
	"content": "changeTimezoneModal-modules__content___vLaz3",
	"customButton": "changeTimezoneModal-modules__customButton___3pdpo"
};
export default ___CSS_LOADER_EXPORT___;
