import React from 'react';
import PropTypes from 'prop-types';
import { ColorType, Icon, IconType, Text, Tooltip } from '@bloobirds-it/flamingo-ui';
import clsx from 'clsx';
import { useSubhome } from '../../subhomeLayout';
import styles from '../subhomeSidebar.module.css';
import { useSidebar } from '../../../../hooks/useSidebar';

interface SidebarHeaderProps {
  counter: number;
  active: boolean;
  icon?: IconType;
  color?: ColorType;
  tooltipText: string;
}

const SubhomeSidebarHeader = ({
  counter,
  active,
  icon,
  color,
  tooltipText,
}: SidebarHeaderProps) => {
  const { name } = useSubhome();
  const { isCollapsed } = useSidebar();
  return (
    <div className={styles._header}>
      {isCollapsed ? (
        <Icon name={icon} color={color} size={32} />
      ) : (
        <Text size="xl" color="softPeanut">
          {name}
        </Text>
      )}
      <Tooltip title={tooltipText || null} position="right">
        <div
          data-test="Tab-SidebarHeaderCounter"
          className={clsx(styles._counter__container, { [styles._active_counter]: active })}
        >
          {counter || '0'}
        </div>
      </Tooltip>
    </div>
  );
};

SubhomeSidebarHeader.propTypes = {
  counter: PropTypes.number,
};

export default SubhomeSidebarHeader;
