import { useSharedState } from './useSharedState';

const DEFAULT_TAB = 'Activity';

export const useContactView = () => {
  const [tab, setTab] = useSharedState('tab');
  const [subtab, setSubtab] = useSharedState('subtab');

  const setActiveTabs = ({ tab: newTab, subtab: newSubtab }) => {
    setTab(newTab);
    setSubtab(newSubtab);
  };

  const scrollToContactTabs = () => {
    document.getElementById('contact-tabs').scrollIntoView({ behavior: 'smooth' });
  };

  const resetTab = () => {
    setTab(DEFAULT_TAB);
  };

  return {
    subtab,
    tab,
    setActiveTabs,
    scrollToContactTabs,
    setTab,
    setSubtab,
    resetTab,
  };
};
