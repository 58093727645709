import React from 'react';
import { useAggregationSubscription } from '@bloobirds-it/plover';
import { TIME_WINDOW } from '../typings/home';
import { ACTIVITY_FIELDS_LOGIC_ROLE, ACTIVITY_TYPES } from '../../../constants/activity';
import { getRangeByType } from '../../dashboardPages/utils/getDashboardTimeRange';
import { BOBJECT_TYPES } from '../../../constants/bobject';
import { aggregationToChartData } from '../../../utils/aggregations.utils';

export const useTeamMeetingsChart = (timeWindow: TIME_WINDOW) => {
  const query = getRangeByType(timeWindow);

  const meetingsRequest = React.useMemo(
    () => ({
      query: {
        [ACTIVITY_FIELDS_LOGIC_ROLE.CREATION_DATETIME]: {
          query: {
            gte: query?.start,
            lte: query?.end,
          },
          searchMode: 'RANGE__SEARCH',
        },
        [ACTIVITY_FIELDS_LOGIC_ROLE.TYPE]: ACTIVITY_TYPES.MEETING,
      },
      aggregations: [ACTIVITY_FIELDS_LOGIC_ROLE.USER],
      formFields: true,
      page: 0,
      pageSize: 5000,
    }),
    [timeWindow],
  );
  const { data } = useAggregationSubscription(meetingsRequest, BOBJECT_TYPES.ACTIVITY);

  return {
    chartData: aggregationToChartData({ data: data?.data?.contents }),
    data: data?.data?.contents,
    values: data?.data?.contents?.flatMap((x: { fieldDataList: any }) => x.fieldDataList),
  };
};
