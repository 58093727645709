import React from 'react';
import { CommandBox, Icon, Text } from '@bloobirds-it/flamingo-ui';
import styles from '../generalSearchBar.module.css';
import { SearchData } from '../../../../assets/svg';
import { BobjectType, PluralBobjectTypes } from '../../../typings/bobjects';

export function NoResults({ bobjectType }: { bobjectType: BobjectType }) {
  const renderMessage = bobjectType ? PluralBobjectTypes[bobjectType].toLowerCase() : 'results';
  return (
    <div className={styles.emptyBox}>
      <div className={styles.emptyResultsContainer}>
        <SearchData className={styles.emptyResultsIcon} />
        <Text color="softPeanut" size={'xl'} weight={'bold'}>
          No {renderMessage} found
        </Text>
        <Text color="softPeanut" size={'m'}>
          It seems that there are no results for the criteria you have specified
        </Text>
      </div>
    </div>
  );
}

export function SearchHistoryList({ searchHistory }: { searchHistory: string[] }) {
  const store = CommandBox.useCommandBoxStore();
  return (
    <div className={styles.historyList}>
      {searchHistory.map(searchItem => (
        <div
          className={styles.historyListItem}
          key={`search-item-${searchItem}`}
          onClick={() => store.setState('search', searchItem)}
        >
          <Icon name="search" color="softPeanut" />
          <Text size="s" inline color="peanut">
            {searchItem}
          </Text>
        </div>
      ))}
    </div>
  );
}
