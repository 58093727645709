import React from 'react';
import { Tooltip } from '@bloobirds-it/flamingo-ui';

const WithTooltip = ({
  children,
  isDisabled,
  title,
}: {
  children: any;
  isDisabled: boolean;
  title: string;
}) =>
  isDisabled ? (
    <Tooltip title={title} position="top">
      {children}
    </Tooltip>
  ) : (
    children
  );

export default WithTooltip;
