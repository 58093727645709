import styles from './cadenceEditionPage.module.css';
import { Button, Callout, Icon, Label, Switch, Text, Tooltip } from '@bloobirds-it/flamingo-ui';
import React, { useEffect, useState } from 'react';
import { APP_CADENCES_MANAGE, APP_PLAYBOOK_CADENCES, BobjectTypes } from '@bloobirds-it/types';
import { useQueryParam } from '../../../hooks/useQueryParams';
import SessionManagerFactory from '../../../misc/session';
import { CadencePreview, useCadences, useCadenceSteps } from '@bloobirds-it/cadence';
import {
  CADENCE_EDIT_PERMISSIONS,
  CreateEditCadenceSettings,
} from '../components/createEditCadenceSettings/createEditCadenceSettings';
import { useRouter } from '../../../hooks';
import { useSidebar } from '../../../hooks/useSidebar';
import useModalVisibility from '../../../hooks/useModalVisibility';
import { useCadenceV2Enabled } from '@bloobirds-it/hooks';
import { api } from '../../../utils/api';
import clsx from 'clsx';
import { StepCardList } from './stepCardList/stepCardList';
import CadencesLayout from '../../../layouts/cadencesLayout/cadencesLayout';
import { CreateEditStepModal } from '../components/createEditStepModal/createEditStepModal';
import DeleteCadenceStepModal from '../components/deleteCadenceStepModal/deleteCadenceStepModal';
import { useUserSettings } from '../../../components/userPermissions/hooks';
import { USER_PERMISSIONS } from '../../../components/userPermissions/constants';
import NoPermissionsPage from '../../noPermissionsPage';

export const CadenceEditionPage = () => {
  const [isStepModalOpen, setIsStepModalOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const fromUrl = useQueryParam('from');
  const id = useQueryParam('cadence');
  const name = useQueryParam('name');
  const bobjectType = useQueryParam('bobjectType') as BobjectTypes;
  const SessionManager = SessionManagerFactory();
  const { cadences, refreshCadences } = useCadences(bobjectType, SessionManager?.getAccount()?.id);
  const cadence = cadences?.find((cadenceElement: any) => cadenceElement?.id === id);
  const isDefaultCadence = cadence?.defaultCadence;
  const [isEnabled, setIsEnabled] = useState(cadence?.enabled);
  const cadenceStatistics = cadence?.statistics;
  const canEditCadence =
    SessionManager.getRoleManager()?.isAccountAdmin() ||
    SessionManager.getUser()?.id === cadence?.owner ||
    cadence?.editMode === CADENCE_EDIT_PERMISSIONS.EVERYONE;
  const { history } = useRouter();
  const { steps } = useCadenceSteps(id);
  const { toggle } = useSidebar();
  const { isOpen: isDeleteStepModalOpen, closeModal: onCloseDeleteStepModal } = useModalVisibility(
    'deleteCadenceStep',
  );
  const permissionsText =
    cadence?.editMode === CADENCE_EDIT_PERMISSIONS.EVERYONE
      ? {
          label: 'Editable by everyone',
          tooltip: 'Everyone will be able to edit it and make changes',
        }
      : {
          label: 'Editable by owner',
          tooltip: 'Only the owner is able to edit it and make changes',
        };
  const settings = useUserSettings();
  const accountId = settings?.account?.id;
  const hasCadencePermission = settings?.user?.permissions?.includes(
    USER_PERMISSIONS.VIEW_CADENCES,
  );
  const cadenceV2Enabled = useCadenceV2Enabled(accountId);

  const handleBack = () => {
    history.push(fromUrl || (cadenceV2Enabled ? APP_CADENCES_MANAGE : APP_PLAYBOOK_CADENCES), {
      state: bobjectType,
    });
    toggle();
  };

  const getStatusTooltip = () => {
    let text = '';

    if (isDefaultCadence) {
      text = 'The default cadence can not be disabled';
    }
    return text;
  };

  const handleToggleEnabled = async () => {
    await api.put(`/messaging/cadences/${cadence.id}/${isEnabled ? 'disable' : 'enable'}`);
    setIsEnabled(!isEnabled);
  };

  const handleAddStep = () => {
    setIsStepModalOpen(true);
  };
  const handleOpenSettings = () => {
    setIsModalOpen(true);
  };

  useEffect(() => {
    setIsEnabled(cadence?.enabled);
  }, [cadence]);

  useEffect(() => {
    refreshCadences();
  }, []);

  if (!hasCadencePermission || !cadenceV2Enabled) {
    return <NoPermissionsPage />;
  }

  return (
    <CadencesLayout>
      <>
        <Button
          className={styles._back__button}
          variant="clear"
          onClick={handleBack}
          color="bloobirds"
          iconLeft="arrowLeft"
        >
          Back to cadences
        </Button>
        <div className={styles._statistics_callout}>
          <Callout width="800px" withoutIcon={true}>
            <div className={styles._callout_content}>
              <div className={styles._callout_block}>
                <Text weight="bold" color="softBloobirds">
                  {cadenceStatistics?.totalSteps}
                </Text>
                <Text color="softBloobirds" size="s">
                  Steps
                </Text>
              </div>
              <div className={styles._callout_block}>
                <Text weight="bold" color="softBloobirds">
                  {cadenceStatistics?.prospectCount}
                </Text>
                <Text color="softBloobirds" size="s">
                  Prospects
                </Text>
              </div>
              <div className={styles._callout_block}>
                <Text weight="bold" color="softBloobirds">
                  {cadenceStatistics?.totalDays}
                </Text>
                <Text color="softBloobirds" size="s">
                  Days
                </Text>
              </div>
              <div className={styles._callout_block}>
                <Text weight="bold" color="softBloobirds">
                  {Math.round(cadenceStatistics?.automatedPercentage * 100 || 0)}%
                </Text>
                <Text color="softBloobirds" size="s">
                  Automated
                </Text>
              </div>
              <div className={styles._callout_block}>
                <Text weight="bold" color="softBloobirds">
                  {cadenceStatistics?.activeCount}
                </Text>
                <Text color="softBloobirds" size="s">
                  Active
                </Text>
              </div>
              <div className={styles._callout_block}>
                <Text weight="bold" color="softBloobirds">
                  {cadenceStatistics?.optOutCount}
                </Text>
                <Text color="softBloobirds" size="s">
                  Opted-out
                </Text>
              </div>
            </div>
          </Callout>
          <div className={styles._cadence__form__content}>
            <div>
              <Text size="l" color="peanut">
                {cadence?.name || name}{' '}
                {isDefaultCadence && <Icon name="starChecked" color="softBanana" size={16} />}
              </Text>
              <span className={styles._right__actions}>
                <Tooltip title={permissionsText.tooltip} position="top">
                  <Label
                    size="small"
                    color="veryLightBloobirds"
                    textColor="bloobirds"
                    uppercase={false}
                  >
                    {permissionsText.label}
                  </Label>
                </Tooltip>
                <div className={styles._settings_button__container}>
                  <a
                    className={clsx(styles._link__button, {
                      [styles._disabled_link__button]: !canEditCadence,
                    })}
                    onClick={canEditCadence ? handleOpenSettings : null}
                  >
                    Settings
                  </a>
                  <Icon
                    size={16}
                    name="settings"
                    color={canEditCadence ? 'bloobirds' : 'lightBloobirds'}
                  />
                </div>
                <Tooltip title={getStatusTooltip()} position="top">
                  <Switch
                    checked={isEnabled}
                    disabled={!canEditCadence || isDefaultCadence}
                    onChange={handleToggleEnabled}
                    color="bloobirds"
                  />
                </Tooltip>

                <Text size="s" color="peanut">
                  Enabled
                </Text>
              </span>
            </div>
            <div className={styles._cadence__preview}>
              {!canEditCadence && <div className={styles._disabled_overlay} />}
              <CadencePreview cadenceId={id} />
            </div>
            <div className={styles._steps_header__wrapper}>
              {!canEditCadence && <div className={styles._disabled_overlay} />}
              <div>
                <Text size="s" color="softPeanut">
                  Cadence tasks per day
                </Text>
              </div>
              <span className={styles._right__actions}>
                <Button
                  iconLeft="plus"
                  size="small"
                  disabled={!canEditCadence}
                  color={canEditCadence ? 'bloobirds' : 'peanut'}
                  onClick={handleAddStep}
                >
                  Add step
                </Button>
              </span>
            </div>
            {steps.length > 0 ? (
              <div className={styles._steps__wrapper}>
                {!canEditCadence && <div className={styles._disabled_overlay} />}
                <StepCardList bobjectType={bobjectType} steps={steps} />
                <Button
                  className={styles._add_step_button}
                  disabled={!canEditCadence}
                  variant="clear"
                  iconLeft="plus"
                  size="small"
                  onClick={handleAddStep}
                  color={canEditCadence ? 'bloobirds' : 'lightBloobirds'}
                >
                  Add step
                </Button>
              </div>
            ) : (
              <div className={styles._steps__container}>
                {!canEditCadence && <div className={styles._disabled_overlay} />}
                <Icon name="plusSquare" color="softPeanut" />
                <Text size="l" color="peanut">
                  You haven&apos;t configured any cadence step yet
                </Text>
                <Text size="s" color="softPeanut">
                  Learn more about how to create engaging cadences
                </Text>
                <Button
                  className={styles._add_step_button}
                  disabled={!canEditCadence}
                  variant="clear"
                  iconLeft="plus"
                  size="small"
                  color={canEditCadence ? 'bloobirds' : 'lightBloobirds'}
                  onClick={handleAddStep}
                >
                  Add step
                </Button>
              </div>
            )}
          </div>
        </div>
        {isStepModalOpen && (
          <CreateEditStepModal
            bobjectType={bobjectType}
            refreshCadences={refreshCadences}
            onClose={() => setIsStepModalOpen(false)}
          />
        )}
        {isModalOpen && (
          <CreateEditCadenceSettings
            cadence={cadence}
            refreshCadences={refreshCadences}
            onClose={() => setIsModalOpen(false)}
            bobjectType={bobjectType}
          />
        )}
        {isDeleteStepModalOpen && (
          <DeleteCadenceStepModal
            refreshCadences={refreshCadences}
            onClose={onCloseDeleteStepModal}
          />
        )}
      </>
    </CadencesLayout>
  );
};
