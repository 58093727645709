import React from 'react';
import { Controller } from 'react-hook-form';
import { v4 as uuid } from 'uuid';
import { Text } from '@bloobirds-it/flamingo-ui';
import {
  RichTextEditor,
  useRichTextEditorPlugins,
  EditorToolbar,
  EditorToolbarControlsSection,
  EditorToolbarFontStylesSection,
  EditorToolbarListsSection,
  EditorToolbarTextMarksSection,
} from '@bloobirds-it/rich-text-editor';
import styles from './notesForm.module.css';
import { PlateEditor } from '@udecode/plate';

export const NotesForm = ({ control }: { control: any }) => {
  const plugins = useRichTextEditorPlugins({
    images: false,
    replaceParagraphs: true,
  });

  return (
    <div className={styles._notes__container}>
      <Text size="s" color="softPeanut">
        Notes to Calendar
      </Text>
      <Controller
        control={control}
        name="calendarNotes"
        render={({ onChange, value }) => (
          <RichTextEditor
            defaultValue={value}
            placeholder="Add your notes here..."
            plugins={plugins}
            onChange={onChange}
            style={{
              fontFamily: 'Proxima Nova Soft',
            }}
          >
            {(editor: PlateEditor) => (
              <>
                <div className={styles._notes__box}>
                  <EditorToolbar>
                    <EditorToolbarControlsSection />
                    <EditorToolbarFontStylesSection />
                    <EditorToolbarTextMarksSection />
                    <EditorToolbarListsSection />
                  </EditorToolbar>
                  <div className={styles._notes_content}>{editor}</div>
                </div>
              </>
            )}
          </RichTextEditor>
        )}
      />
    </div>
  );
};
