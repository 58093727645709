import React from 'react';
import LinkedInFieldsTab from './linkedInFieldsTab';
import LinkedInCompanyCaptureTab from './linkedInCompanyCreationTab';

const LinkedInTab = () => {
  return (
    <div>
      <LinkedInFieldsTab />
      <LinkedInCompanyCaptureTab />
    </div>
  );
};

export default LinkedInTab;
