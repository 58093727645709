import React, { forwardRef, useImperativeHandle } from 'react';
import clsx from 'clsx';
import { CommandBox, Icon, Text } from '@bloobirds-it/flamingo-ui';
import {
  StrDict,
  ClickElementFunctionCompanyType,
  ClickElementFunctionType,
  SearchBobjectCompany,
  SearchBobjectType,
  TypeFilterType,
} from '@bloobirds-it/types';
import styles from '../generalSearchBar.module.css';
import { useContextMenu, useEntity } from '../../../hooks';
import { PluralBobjectTypes } from '../../../typings/bobjects';
import { getStage, getStatus } from '../searchBar.utils';
import {
  SearchCardCenter,
  SearchCardLeft,
  SearchPreviewButton,
  SearchStatusLabel,
} from './bobjectCardComponents';
import { BobjectActions } from './actionButtons';
import RightClickContextMenu from '../../rightClickContextMenu';

interface BobjectItemProps {
  bobject: SearchBobjectType;
  hits: StrDict;
  isSelected?: boolean;
  handleElementClicked: (bobject: SearchBobjectCompany, event: MouseEvent) => void;
}

/**
 * Base Bobject Item Card - For Displaying Results
 * @param bobject - GlobalSearchResponse
 * @param hits - hits in the search
 * @param isSelected - when selected with arrows
 * @param handleElementClicked - close the search bar at clicking any internal action or link
 * @constructor
 */
export function BobjectItem({ bobject, hits, isSelected, handleElementClicked }: BobjectItemProps) {
  const bobjectFields = useEntity('bobjectFields');
  const bobjectPicklistFieldValues = useEntity('bobjectPicklistFieldValues');
  const {
    ref: refContextMenu,
    xPos,
    yPos,
    isContextMenuVisible,
    handleContextMenu,
    hideContextMenu,
  } = useContextMenu();

  const type = bobject.bobjectType;
  const stage = getStage(bobject);
  const status = getStatus(type, stage, bobject, bobjectFields, bobjectPicklistFieldValues);

  const classNames = clsx(styles.bobjectItem, {
    [styles.bobjectItem_selected]: isSelected,
    [styles.bobjectItem_prospecting]: stage === 'prospecting',
    [styles.bobjectItem_sales]: stage === 'sales',
  });

  return (
    <div className={classNames} ref={refContextMenu} onContextMenu={handleContextMenu}>
      <SearchCardLeft bobject={bobject} hits={hits} handleCompanyClicked={handleElementClicked} />
      {isSelected && <SearchPreviewButton isSelected={isSelected} bobject={bobject} />}
      <SearchCardCenter bobject={bobject} />
      {!isSelected ? (
        <SearchStatusLabel status={status} />
      ) : (
        <BobjectActions
          bobject={bobject}
          closeModal={() => handleElementClicked(undefined, undefined)}
        />
      )}
      {isContextMenuVisible && (
        <RightClickContextMenu
          url={bobject.url}
          xPos={Number.parseInt(xPos.split('px')[0]) - window.innerWidth / 2 + 400 + 'px'}
          yPos={Number.parseInt(yPos.split('px')[0]) - 48 + 'px'}
          hideContextMenu={hideContextMenu}
        />
      )}
    </div>
  );
}

interface BobjectItemCompressedProps {
  bobject: SearchBobjectType;
  handleClick?: ClickElementFunctionType;
  handleCompanyClicked: ClickElementFunctionCompanyType;
}

/**
 * Compressed Bobject Item Card - For Displaying Last Visited Bobjects (from the Search Bar)
 * @deprecated use the one in @bloobirds-it/bobjects
 * @param bobject
 * @param handleClick
 * @param handleCompanyClicked
 * @constructor
 */
export function BobjectItemCompressed({
  bobject,
  handleClick,
  handleCompanyClicked,
}: BobjectItemCompressedProps) {
  return (
    <div
      className={styles.bobjectItemCompressed}
      // @ts-ignore
      onClick={e => handleClick(bobject, e)}
    >
      <SearchCardLeft
        bobject={bobject}
        hits={undefined}
        handleCompanyClicked={handleCompanyClicked}
        compressed
      />
    </div>
  );
}

interface BobjectTypeMatchProps {
  bobjectType: TypeFilterType;
  applyFilter?: (bobjectType: TypeFilterType) => void;
  isSelected?: boolean;
}

/**
 * Bobject Type Match - Displays the Bobject Type that matches the search (Company, Lead, Opportunity) for organic search results
 * This is displayed as the first element on the list and sets the type filter when pressed.
 *
 * It is forward ref because we need to be able to control the search input from the outside (general component), and
 * it can only be done from a child component. This was, we allow calls to the deleteInput() function
 */
export const BobjectTypeMatch = forwardRef(
  ({ bobjectType, applyFilter, isSelected }: BobjectTypeMatchProps, ref) => {
    const store = CommandBox.useCommandBoxStore();
    useImperativeHandle(ref, () => ({
      deleteInput() {
        store.setState('search', '');
      },
    }));

    function handleClick(bobjectType: TypeFilterType) {
      store.setState('search', '');
      applyFilter(bobjectType);
    }

    const classNames = clsx(styles.bobjectItem, styles.bobjectItemType, {
      [styles.bobjectItem_selected]: isSelected,
    });

    return (
      <div className={classNames} onClick={() => handleClick(bobjectType)}>
        <div className={styles.circleIcon}>
          <Icon name={'filter'} size={20} color="bloobirds" />
        </div>
        <div className={styles.bobjectItemContent}>
          <Text size="s" color="bloobirds">
            {bobjectType === 'All' ? 'All' : PluralBobjectTypes[bobjectType]}
          </Text>
        </div>
      </div>
    );
  },
);
