import React, { useEffect, useState } from 'react';
import NoPermissionsPage from '../../noPermissionsPage';
import { Button, Label, SearchInput, Tooltip } from '@bloobirds-it/flamingo-ui';
import { useIsAccountAdmin } from '../../../hooks/usePermissions';
import AccountSettingsLayout from '../../../layouts/accountSettingsLayout';
import {
  AccountSettingsTab,
  AccountSettingsTabContent,
  AccountSettingsTabHeader,
  AccountSettingsTabHeaderLeft,
  AccountSettingsTabHeaderRight,
  AccountSettingsTableContainer,
  AccountSettingsTabSubtitle,
  AccountSettingsTabTitle,
} from '../../../layouts/accountSettingsLayout/accountSettingsTab/accountSettingsTab';
import { UsersList } from './components/usersList/usersList';
import { useMediaQuery } from '../../../hooks';
import { useCreateEditUserModal } from './hooks/useCreateEditUserModal';
import { CreateEditUserModal } from './components/createEditUserModal/createEditUserModal';
import { useUsersList } from './hooks/useUsersList';
import { USER_TYPES } from './constants/users.constants';
import styles from './styles/usersPage.module.css';
import { useUserSettings } from '../../../components/userPermissions/hooks';
import { UserCreatedModal } from './components/userCreatedModal/userCreatedModal';
import { useUserCreatedModal } from './hooks/useUserCreatedModal';

const UsersPage = () => {
  const isAccountAdmin = useIsAccountAdmin();
  const [searchValue, setSearchValue] = useState();
  const settings = useUserSettings();
  const { isSmallDesktop } = useMediaQuery();
  const { modalOpen, handleOpenCreateEditUserModal } = useCreateEditUserModal();
  const { modalOpen: userCreatedOpen, handleCloseUserCreatedModal } = useUserCreatedModal();

  if (!isAccountAdmin) {
    return <NoPermissionsPage />;
  }
  const { users } = useUsersList({
    filtersBody: {
      userType: USER_TYPES.LICENSE_USER,
    },
  });
  const [usersList, setUsersList] = useState(users?.users);
  useEffect(() => {
    if (searchValue) {
      const filteredList = users?.users?.filter(user =>
        user.name.toLowerCase().includes(searchValue.toLowerCase()),
      );
      setUsersList(filteredList);
    } else {
      setUsersList(users?.users);
    }
  }, [searchValue, users]);

  return (
    <AccountSettingsLayout
      title="Sales team"
      subtitle="Add new users and manage their permissions."
    >
      <AccountSettingsTab>
        <AccountSettingsTabHeader>
          <AccountSettingsTabHeaderLeft>
            <AccountSettingsTabTitle icon="person">
              <div className={styles._title_container}>
                {' '}
                Users{' '}
                <Label>
                  {users?.users?.length}{' '}
                  {settings?.account?.maxUsersAllowed
                    ? `from ${settings?.account?.maxUsersAllowed} allowed.`
                    : ''}
                </Label>{' '}
              </div>
            </AccountSettingsTabTitle>
            <AccountSettingsTabSubtitle>
              Users allowed are based on the users created. If you want to have more available you
              should delete old users. Remember that deleting an user doesn&apos;t delete the
              historic information from that user!
            </AccountSettingsTabSubtitle>
          </AccountSettingsTabHeaderLeft>
          <AccountSettingsTabHeaderRight>
            <SearchInput
              width={200}
              placeholder="Search"
              onChange={value => setSearchValue(value)}
            />
            {users?.users?.length >= settings?.account?.maxUsersAllowed ? (
              <Tooltip title="You reached the max amount of users allowed" position="top">
                <Button disabled iconLeft="plus">
                  {!isSmallDesktop && 'Create new user'}
                </Button>
              </Tooltip>
            ) : (
              <Button iconLeft="plus" onClick={handleOpenCreateEditUserModal}>
                {!isSmallDesktop && 'Create new user'}
              </Button>
            )}
          </AccountSettingsTabHeaderRight>
        </AccountSettingsTabHeader>
        <AccountSettingsTabContent>
          <AccountSettingsTableContainer>
            <UsersList users={users} usersList={usersList} />
          </AccountSettingsTableContainer>
        </AccountSettingsTabContent>
      </AccountSettingsTab>
      {modalOpen && <CreateEditUserModal />}
      {userCreatedOpen && <UserCreatedModal onClose={handleCloseUserCreatedModal} />}
    </AccountSettingsLayout>
  );
};

export default UsersPage;
