import React, { useMemo } from 'react';
import Field from './field';
import { ModalSection } from '@bloobirds-it/flamingo-ui';
import { toCamelCase } from '../../../utils/strings.utils';
import styles from './section.module.css';

const Section = ({
  title,
  icon,
  modalBobjectType,
  fields,
  hideActivityType,
  isRequiredBeforeMeeting,
  defaultValues,
  hasProducts,
}) => {
  const hasWriteableFields = useMemo(() => fields.some(field => !field.readOnly), [fields]);

  const resultFields = useMemo(
    () =>
      isRequiredBeforeMeeting
        ? fields
        : fields.filter(field => !field.readOnly && !field.deprecated),
    [isRequiredBeforeMeeting, fields],
  );

  if (!hasWriteableFields && !isRequiredBeforeMeeting) {
    return null;
  }

  if (resultFields.length === 0) {
    return null;
  }

  return (
    <div className={styles._grid}>
      <ModalSection title={title} icon={toCamelCase(icon)}>
        {resultFields.map(field => (
          <Field
            key={field.name}
            defaultValues={defaultValues}
            {...field}
            modalBobjectType={modalBobjectType}
            hideActivityType={hideActivityType}
            hasProducts={hasProducts}
          />
        ))}
      </ModalSection>
    </div>
  );
};

export default Section;
