// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".filters-module___wrapper___wvSFN {\n  color: var(--softPeanut);\n  font-size: 13px;\n  height: 100%;\n  margin: auto 0;\n  flex-shrink: 0;\n}\n\n.filters-module___filter_wrapper___b8Z0Q {\n  margin-left: 8px;\n  display: inline-block;\n}\n", "",{"version":3,"sources":["webpack://./src/js/pages/contactPages/cadenceTable/filters/filters.module.css"],"names":[],"mappings":"AAAA;EACE,wBAAwB;EACxB,eAAe;EACf,YAAY;EACZ,cAAc;EACd,cAAc;AAChB;;AAEA;EACE,gBAAgB;EAChB,qBAAqB;AACvB","sourcesContent":["._wrapper {\n  color: var(--softPeanut);\n  font-size: 13px;\n  height: 100%;\n  margin: auto 0;\n  flex-shrink: 0;\n}\n\n._filter_wrapper {\n  margin-left: 8px;\n  display: inline-block;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"_wrapper": "filters-module___wrapper___wvSFN",
	"_filter_wrapper": "filters-module___filter_wrapper___b8Z0Q"
};
export default ___CSS_LOADER_EXPORT___;
