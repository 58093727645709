import React, { useState } from 'react';
import {
  Button,
  DateTimePicker,
  Modal,
  ModalCloseIcon,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Text,
} from '@bloobirds-it/flamingo-ui';
import { useRescheduleTask } from '../../hooks/useRescheduleTask';
import styles from './rescheduleTaskModal.modules.css';
import { getTextFromLogicRole } from '../../utils/bobjects.utils';
import { TASK_FIELDS_LOGIC_ROLE } from '../../constants/task';

const isBulkAction = bobjectToCheck => Array.isArray(bobjectToCheck);

const RescheduleTaskModal = ({ onSave }) => {
  const [date, setDate] = useState(new Date());
  const { closeRescheduleModal, rescheduleTasks, bobject } = useRescheduleTask();
  const taskDate = !isBulkAction(bobject)
    ? new Date(getTextFromLogicRole(bobject, TASK_FIELDS_LOGIC_ROLE.SCHEDULED_DATETIME))
    : null;

  return (
    <Modal open width={600} onClose={closeRescheduleModal}>
      <ModalHeader>
        <Text size="xl">Reschedule tasks</Text>
        <ModalCloseIcon variant="primary" onClick={closeRescheduleModal} />
      </ModalHeader>
      <ModalContent>
        <Text size="m" weight="bold">
          Select a new due date
        </Text>
        <div className={styles._date__wrapper}>
          <DateTimePicker
            width="300px"
            placeholder="Due date-time"
            defaultValue={taskDate || new Date()}
            onChange={value => setDate(value)}
          />
        </div>
      </ModalContent>
      <ModalFooter>
        <Button onClick={closeRescheduleModal} variant="tertiary">
          Cancel
        </Button>
        <Button
          onClick={() => {
            rescheduleTasks(date).then(() => {
              onSave();
            });
            closeRescheduleModal();
          }}
        >
          Reschedule
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default RescheduleTaskModal;
