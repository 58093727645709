import { atom, useRecoilState } from 'recoil';
import { ACTIVITY_FIELDS_LOGIC_ROLE } from '../constants/activity';
import { useActivity } from './useActivity';
import { usePicklistValues } from './usePicklistValues';
import { Bobject } from '../typings/bobjects';

const meetingResultOpenAtom = atom({
  key: 'meetingResultOpenAtom',
  default: false,
});

const useMeetingResultVisibility = () => {
  const [meetingResultOpen, setMeetingResultOpen] = useRecoilState(meetingResultOpenAtom);

  const openMeetingResultModal = () => {
    if (!meetingResultOpen) {
      setMeetingResultOpen(true);
    }
  };

  const closeMeetingResultModal = () => {
    if (meetingResultOpen) {
      setMeetingResultOpen(false);
    }
  };

  return {
    isOpen: meetingResultOpen,
    openMeetingResultModal,
    closeMeetingResultModal,
  };
};

export const useMeetingResult = () => {
  const { closeMeetingResultModal, openMeetingResultModal, isOpen } = useMeetingResultVisibility();
  const {
    activity,
    setActivityWithId,
    updateActivity,
    reportedActivityResult,
    setActivityToUse,
  } = useActivity('meetingResult');
  const meetingResults = usePicklistValues({
    picklistLogicRole: ACTIVITY_FIELDS_LOGIC_ROLE.MEETING_RESULT,
  });

  const getEnabledMeetingResults = () => meetingResults.filter(result => result?.enabled);

  const openMeetingResult = (activity: string | Bobject) => {
    if (typeof activity === 'string') {
      setActivityWithId(activity);
    } else if ('id' in activity) {
      setActivityToUse(activity);
    } else {
      throw new Error('Passed value is not an activity or activityId');
    }
    openMeetingResultModal();
  };

  return {
    activity,
    isOpen,
    meetingResults: getEnabledMeetingResults(),
    closeMeetingResult: closeMeetingResultModal,
    openMeetingResult,
    reportedActivityResult,
    updateActivity,
  };
};
