// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".welcomeScreens-module__imgGmail___62BAE {\n  position: absolute;\n  top: 297px;\n  left: 535px;\n}\n\n.welcomeScreens-module__imgLinkedin___X3k8f {\n  position: absolute;\n  top: 147px;\n  left: 537px;\n}\n\n.welcomeScreens-module__imgCalendar___0Rgi4 {\n  position: absolute;\n  top: 223px;\n  left: 506px;\n}\n\n.welcomeScreens-module__imgJustCall___UsCBS {\n  position: absolute;\n  top: 276px;\n  left: 24px;\n}\n\n.welcomeScreens-module__imgAircall___DAfJf {\n  position: absolute;\n  top: 155px;\n  left: 47px;\n}\n\n.welcomeScreens-module__imgDialer___COCSU {\n  position: absolute;\n  top: 230px;\n  left: 99px;\n}\n\n.welcomeScreens-module__tooltip___nTo8Z {\n  position: absolute;\n}\n", "",{"version":3,"sources":["webpack://./src/js/components/welcomeScreensModal/welcomeScreens.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,UAAU;EACV,WAAW;AACb;;AAEA;EACE,kBAAkB;EAClB,UAAU;EACV,WAAW;AACb;;AAEA;EACE,kBAAkB;EAClB,UAAU;EACV,WAAW;AACb;;AAEA;EACE,kBAAkB;EAClB,UAAU;EACV,UAAU;AACZ;;AAEA;EACE,kBAAkB;EAClB,UAAU;EACV,UAAU;AACZ;;AAEA;EACE,kBAAkB;EAClB,UAAU;EACV,UAAU;AACZ;;AAEA;EACE,kBAAkB;AACpB","sourcesContent":[".imgGmail {\n  position: absolute;\n  top: 297px;\n  left: 535px;\n}\n\n.imgLinkedin {\n  position: absolute;\n  top: 147px;\n  left: 537px;\n}\n\n.imgCalendar {\n  position: absolute;\n  top: 223px;\n  left: 506px;\n}\n\n.imgJustCall {\n  position: absolute;\n  top: 276px;\n  left: 24px;\n}\n\n.imgAircall {\n  position: absolute;\n  top: 155px;\n  left: 47px;\n}\n\n.imgDialer {\n  position: absolute;\n  top: 230px;\n  left: 99px;\n}\n\n.tooltip {\n  position: absolute;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"imgGmail": "welcomeScreens-module__imgGmail___62BAE",
	"imgLinkedin": "welcomeScreens-module__imgLinkedin___X3k8f",
	"imgCalendar": "welcomeScreens-module__imgCalendar___0Rgi4",
	"imgJustCall": "welcomeScreens-module__imgJustCall___UsCBS",
	"imgAircall": "welcomeScreens-module__imgAircall___DAfJf",
	"imgDialer": "welcomeScreens-module__imgDialer___COCSU",
	"tooltip": "welcomeScreens-module__tooltip___nTo8Z"
};
export default ___CSS_LOADER_EXPORT___;
