import React, { useContext, useRef } from 'react';
import { CommandBox, Item, Select, Text, useVisible } from '@bloobirds-it/flamingo-ui';
import useSWR from 'swr';
import { atom, useRecoilState } from 'recoil';
import mixpanel from 'mixpanel-browser';
import clsx from 'clsx';
import styles from './generalSearchBar.module.css';
import { api } from '../../utils/api';
import { useActiveUser, useRouter } from '../../hooks';
import { bobjectUrl } from '../../app/_constants/routes';
import { keepPreviousResponse } from '../../utils/swr.utils';
import { BobjectItem, BobjectTypeMatch } from './displays/bobjectItem';
import EmptyList from './displays/emptyList';
import { BobjectType } from '../../typings/bobjects';
import {
  GlobalSearchResponse,
  SearchBobjectCompany,
  SearchBobjectType,
  SearchContextType,
  typeFilterConstants,
  TypeFilterType,
} from '@bloobirds-it/types';
import { searchBobjectTypeName } from './searchBar.utils';
import { MIXPANEL_EVENTS } from '../../constants/mixpanel';

function getBobjectTypes(bobjectType: TypeFilterType): BobjectType[] {
  if (bobjectType === 'All') {
    return ['Company', 'Lead', 'Opportunity'];
  } else return [bobjectType];
}

const SearchContext = React.createContext<SearchContextType>(undefined);

export const useGeneralSearch = () => useContext(SearchContext);

export const openGeneralSearchBarAtom = atom({
  key: 'openGeneralSearchBarAtom',
  default: false,
});

const PAGE_SIZE = 10;

function GeneralSearchBar() {
  const anchorRef = useRef();
  const [open, setOpen] = useRecoilState(openGeneralSearchBarAtom);
  const [bobjectTypeFilter, setBobjectTypeFilter] = React.useState<TypeFilterType>('All');
  const [bobjectTypeMatch, setBobjectTypeMatch] = React.useState<TypeFilterType | false>(false);
  const [searchQuery, setSearchQuery] = React.useState('');
  const [lastVisited, setLastVisited] = React.useState<SearchBobjectType[]>([]);
  const [searchHistory, setSearchHistory] = React.useState<string[]>([]);
  const [page, setPage] = React.useState<number>(1);
  const { activeAccount } = useActiveUser();
  const { history } = useRouter();
  const { ref, visible, setVisible } = useVisible(open, anchorRef);
  const childRef = useRef();
  const showBobjectTypeMatch = !!bobjectTypeMatch && bobjectTypeMatch !== bobjectTypeFilter;

  const { data: response, mutate, isValidating } = useSWR(
    searchQuery !== '' ? ['generalSearchBar', searchQuery] : null,
    () => {
      return api.post(`/bobjects/${activeAccount.id}/global-search`, {
        query: searchQuery,
        bobjectTypes: getBobjectTypes(bobjectTypeFilter),
        numberOfResults: page * PAGE_SIZE,
      });
    },
    { use: [keepPreviousResponse] },
  );
  const results: GlobalSearchResponse[] = response?.data?.results;
  const showShowMore = results?.length > page * PAGE_SIZE;

  async function loadNextPage() {
    const shouldMutate = page === 1 || results?.length > page * PAGE_SIZE + 1;
    await setPage(page => page + 1);
    if (shouldMutate) {
      mutate();
    }
  }

  // Toggle the menu when ctrl/⌘K is pressed and close it when esc
  React.useEffect(() => {
    const down = (e: any) => {
      if (
        (e.key === 'k' && e.metaKey) ||
        (navigator.appVersion.indexOf('Mac') == -1 && e.key === 'k' && e.ctrlKey)
      ) {
        e.stopPropagation();
        e.preventDefault();
        if (!open) {
          setSearchQuery('');
          setPage(1);
        }
        setOpen(open => !open);
      } else if (e.key === 'Escape') {
        e.stopPropagation();
        e.preventDefault();
        setSearchQuery('');
        setPage(1);
        setOpen(false);
      }
    };
    document.addEventListener('keydown', down);
    return () => document.removeEventListener('keydown', down);
  }, []);

  // Coordinate open/close from the hook controlling click outside and internal control of key down
  React.useEffect(() => {
    if (!visible) {
      setSearchQuery('');
    }
    setOpen(visible);
  }, [visible]);
  React.useEffect(() => {
    setVisible(open);
  }, [open]);

  // Update the search history when the search query changes
  React.useEffect(() => {
    addSearchToHistory(searchQuery);
  }, [searchQuery]);

  function addSearchToHistory(search: string) {
    if (search) {
      setSearchHistory(searchHistory => {
        // add to the beginning of the array
        if (!searchHistory.includes(search)) {
          searchHistory.unshift(search);
        } else {
          searchHistory.splice(searchHistory.indexOf(search), 1);
          searchHistory.unshift(search);
        }
        // History of max 10 searches
        if (searchHistory.length > 10) {
          searchHistory.pop();
        }
        return searchHistory;
      });
    }
  }

  // Update the last visited when a result item is clicked, avoiding repeated results
  function addVisitedToHistory(visited: SearchBobjectType) {
    if (visited) {
      setLastVisited(visitedHistory => {
        // add to the beginning of the array
        visitedHistory = visitedHistory.filter(v => v.rawBobject.id !== visited.rawBobject.id);
        visitedHistory.unshift(visited);
        // History of max 10 visited
        if (visitedHistory.length > 10) {
          visitedHistory.pop();
        }
        return visitedHistory;
      });
    }
  }

  /*** if bobject is undefined, only closes modal ***/
  function handleElementClicked(bobject: SearchBobjectCompany, event: MouseEvent) {
    if (bobject) {
      if (event.metaKey || (navigator.appVersion.indexOf('Mac') == -1 && event.ctrlKey)) {
        window.open(bobject.url, '_blank');
      } else {
        history.push(bobject.url);
      }
      addVisitedToHistory(bobject);
      mixpanel.track(MIXPANEL_EVENTS.GLOBAL_SEARCH_BAR_RESULT_CLICKED);
    } else {
      mixpanel.track(MIXPANEL_EVENTS.GLOBAL_SEARCH_BAR_QUICK_ACTION_CLICKED);
    }
    setSearchQuery('');
    setPage(1);
    setBobjectTypeMatch(false);
    setOpen(false);
  }

  function handleClickedItem(bobject: SearchBobjectType, event: MouseEvent) {
    addVisitedToHistory(bobject);
    if (
      !!event &&
      (event.metaKey || (navigator.appVersion.indexOf('Mac') == -1 && event.ctrlKey))
    ) {
      window.open(bobject.url, '_blank');
    } else {
      history.push(bobject.url);
    }
    setSearchQuery('');
    setPage(1);
    setBobjectTypeMatch(false);
    setOpen(false);
    mixpanel.track(MIXPANEL_EVENTS.GLOBAL_SEARCH_BAR_RESULT_CLICKED);
  }

  async function handleBobjectTypeMatchClick(bobjectType: TypeFilterType) {
    await setBobjectTypeFilter(bobjectType);
    setBobjectTypeMatch(false);
    mixpanel.track(MIXPANEL_EVENTS.GLOBAL_SEARCH_BAR_CHANGED_TYPE_FILTER);
    mutate();
  }

  function handleSearch(query: string) {
    const typeMatch = searchBobjectTypeName(query);
    setBobjectTypeMatch(typeMatch);
    setPage(1);
    mixpanel.track(MIXPANEL_EVENTS.GLOBAL_SEARCH_BAR_SEARCHED);
    setSearchQuery(query);
  }

  const boxClassNames = clsx(styles.box, {
    [styles.boxNoMoreResults]: !showShowMore,
    [styles.boxWithMoreResults]: showShowMore,
  });
  return open ? (
    <div>
      <CommandBox onSearch={handleSearch} className={boxClassNames} ref={ref}>
        <CommandBox.SearchBox>
          <Select
            value={bobjectTypeFilter}
            width="140px"
            onChange={async (value: TypeFilterType) => {
              await setPage(1);
              await setBobjectTypeFilter(value);
              mutate();
            }}
            /*@ts-ignore*/
            dropdownProps={{ ref: anchorRef }}
          >
            {typeFilterConstants?.map(type => (
              <Item key={type} value={type}>
                {type}
              </Item>
            ))}
          </Select>
          <CommandBox.Input value={searchQuery} />
        </CommandBox.SearchBox>
        {isValidating && <CommandBox.ProgressBar />}
        <SearchContext.Provider value={{ searchQuery, lastVisited, searchHistory }}>
          <CommandBox.List className={styles.searchResultsList}>
            <div className={styles.searchResults}>
              {showBobjectTypeMatch && (
                <CommandBox.Item
                  action={() => {
                    // @ts-ignore
                    childRef.current?.deleteInput();
                    handleBobjectTypeMatchClick(bobjectTypeMatch);
                  }}
                  key={'typeMatch'}
                >
                  <BobjectTypeMatch
                    bobjectType={bobjectTypeMatch}
                    applyFilter={handleBobjectTypeMatchClick}
                    ref={childRef}
                  />
                </CommandBox.Item>
              )}
              {searchQuery &&
                results &&
                results
                  .slice(
                    0,
                    showBobjectTypeMatch && page === 1 ? page * PAGE_SIZE - 1 : page * PAGE_SIZE,
                  )
                  .map(item => {
                    const url = bobjectUrl({
                      id: {
                        typeName: item.rawBobject.id.split('/')[1],
                        objectId: item.rawBobject.id.split('/')[2],
                      },
                    });
                    return (
                      <CommandBox.Item
                        // @ts-ignore
                        action={e => handleClickedItem({ ...item, url }, e)}
                        key={item.rawBobject?.id}
                      >
                        <BobjectItem
                          bobject={{ ...item, url }}
                          hits={item.highlights}
                          handleElementClicked={handleElementClicked}
                        />
                      </CommandBox.Item>
                    );
                  })}
            </div>
            {searchQuery && showShowMore && (
              <div className={styles.bobjectItem_show_more} onClick={loadNextPage}>
                <Text size="xs" color="bloobirds">
                  Show more
                </Text>
              </div>
            )}
          </CommandBox.List>
          <CommandBox.Empty>
            <EmptyList
              bobjectType={bobjectTypeFilter !== 'All' ? bobjectTypeFilter : undefined}
              handleBobjectCompressedClick={handleClickedItem}
              handleCompanyClicked={handleElementClicked}
            />
          </CommandBox.Empty>
        </SearchContext.Provider>
      </CommandBox>
    </div>
  ) : null;
}

export default GeneralSearchBar;
