// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".taskNavigator-module___container___mLZ7u {\n  display: flex;\n  align-items: center;\n}\n\n.taskNavigator-module___basicFlexItem___dJcQp {\n  display: flex;\n  align-items: center;\n  padding: 0 20px;\n  border-right: 1px solid rgba(255, 255, 255, 0.5);\n}\n\n.taskNavigator-module___currentTask__container___eXWFr {\n}\n\n.taskNavigator-module___currentTask__container___eXWFr > p {\n  margin-right: 22px;\n}\n\n.taskNavigator-module___paginator__container___6b5jK {\n  height: 22px;\n}\n\n.taskNavigator-module___paginator__text___ImCoB {\n  text-align: center;\n  width: 130px;\n}\n\n.taskNavigator-module___paginator__text___ImCoB > p {\n  line-height: 22px;\n}\n\n.taskNavigator-module___actions__container___T2pyq {\n  margin: 0 14px;\n}\n", "",{"version":3,"sources":["webpack://./src/js/pages/contactPages/navegationBar/taskNavigator/taskNavigator.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,eAAe;EACf,gDAAgD;AAClD;;AAEA;AAEA;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EAEE,YAAY;AACd;;AAEA;EACE,kBAAkB;EAClB,YAAY;AACd;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,cAAc;AAChB","sourcesContent":["._container {\n  display: flex;\n  align-items: center;\n}\n\n._basicFlexItem {\n  display: flex;\n  align-items: center;\n  padding: 0 20px;\n  border-right: 1px solid rgba(255, 255, 255, 0.5);\n}\n\n._currentTask__container {\n  composes: _basicFlexItem;\n}\n\n._currentTask__container > p {\n  margin-right: 22px;\n}\n\n._paginator__container {\n  composes: _basicFlexItem;\n  height: 22px;\n}\n\n._paginator__text {\n  text-align: center;\n  width: 130px;\n}\n\n._paginator__text > p {\n  line-height: 22px;\n}\n\n._actions__container {\n  margin: 0 14px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"_container": "taskNavigator-module___container___mLZ7u",
	"_basicFlexItem": "taskNavigator-module___basicFlexItem___dJcQp",
	"_currentTask__container": "taskNavigator-module___currentTask__container___eXWFr taskNavigator-module___basicFlexItem___dJcQp",
	"_paginator__container": "taskNavigator-module___paginator__container___6b5jK taskNavigator-module___basicFlexItem___dJcQp",
	"_paginator__text": "taskNavigator-module___paginator__text___ImCoB",
	"_actions__container": "taskNavigator-module___actions__container___T2pyq"
};
export default ___CSS_LOADER_EXPORT___;
